import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from "@angular/core";

import { Observable } from "rxjs";
import { tap, map, filter } from "rxjs/operators";
import { QueryBuilderConfig } from "angular2-query-builder";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

import {
  SearchService,
  KMSSearchResults
} from "src/app/services/search.service";
import { Network, NetworkType } from "src/app/commons/entities";
import { NetworkEditPageComponent } from "../network-edit-page/network-edit-page.component";
import { NetworkMergePageComponent } from "../network-merge-page/network-merge-page.component";
import { NetworkService } from "src/app/services/network.service";
import { SnackbarService } from "ngx-snackbar";
import { NotificationService } from "src/app/services/notification.service";
import { ConfigService } from "@ngx-config/core";
import { LoggerService } from "src/app/services/logger.service";
import { Constants } from "src/app/commons/util";

@Component({
  selector: "kms-admin-network-list-page",
  templateUrl: "./network-list-page.component.html",
  styleUrls: ["./network-list-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NetworkListPageComponent implements OnInit {
  queryConfig: QueryBuilderConfig = {
    fields: {
      name: {
        name: "Name",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      hashtag: {
        name: "Hashtag",
        type: "string",
        operators: ["contains"]
      },
      modified: {
        name: "Modified Date",
        type: "date",
        operators: ["<", "<=", ">", ">="]
      },
      type: {
        name: "Type",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      facilitators: {
        name: "Facilitator/#KMS Focal Point/Referents",
        type: "string",
        operators: ["contains"]
      },
      coFacilitators: {
        name: "Co-facilitator/Other KM Referents/Core Team/Members",
        type: "string",
        operators: ["contains"]
      },
      koUsers: {
        name: "Knowledge Owners",
        type: "string",
        operators: ["contains"]
      },
      status: {
        name: "Status",
        type: "string",
        operators: ["contains", "=", "!="]
      }
    }
  };

  networks: Observable<Network[]>;
  loadingIndicator = false;
  selectedNet = [];
  selectedNetCount:Number; 

  modalRef: BsModalRef;
  limit = Constants.TABLE_LIMITS;
  lastUsedQuery: any = null;

  constructor(
    private searchSvc: SearchService,
    private networkSvc: NetworkService,
    private notificationSvc: NotificationService,
    private readonly modalSvc: BsModalService,
    private readonly snackbarSvc: SnackbarService,
    private readonly config: ConfigService,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.search(null);
    this.selectedNet = [];
    this.selectedNetCount = 0;
  }

  async search(query: string) {
    this.lastUsedQuery = query;

    const queryFilter = {
      filter: query,
      top: 1000
    };

    this.loadingIndicator = true;
    this.networks = this.searchSvc.queryRaw("Networks", "*", queryFilter).pipe(
      map((networks: KMSSearchResults<Network>) =>
        networks.results.map(r => r.document)
      ),
      tap(() => (this.loadingIndicator = false))
    );
  }

  onCreate() {
    const initialState = {
      network: null,
      title: "Create Network",
      confirmBtnName: "Save",
      closeBtnName: "Cancel",
      newNetwork: true
    };
    this.modalRef = this.modalSvc.show(NetworkEditPageComponent, {
      initialState,
      class: "modal-transparent modal-lg mt-6",
      ignoreBackdropClick: true
    });
    this.modalRef.content.onClose.subscribe(
      result => {
        if (result) {
          this.search(this.lastUsedQuery);
        }
      },
      error => {
        this.logger.error(error);
      }
    );
  }

  async onEdit(selectedNetwork: string) {
    if (selectedNetwork) {
      const loadedNetwork: Network = await this.networkSvc
        .getNetworkById(selectedNetwork)
        .toPromise();
      const loadedUnionNetwork: Network = await this.networkSvc
        .getNetworkByUnionId(selectedNetwork)
        .toPromise();
      const initialState = {
        network: loadedNetwork,
        unionNetwork: loadedUnionNetwork,
        title: "Edit Network",
        confirmBtnName: "Save",
        closeBtnName: "Cancel",
        newNetwork: false
      };
      this.modalRef = this.modalSvc.show(NetworkEditPageComponent, {
        initialState,
        class: "modal-transparent modal-lg mt-6",
        ignoreBackdropClick: true
      });
      this.modalRef.content.onClose.subscribe(
        result => {
          if (result) {
            this.search(this.lastUsedQuery);
          }
        },
        error => {
          this.logger.error(error);
        }
      );
    } else {
      this.logger.warning(
        `Cannot find the selected network: ${selectedNetwork}`
      );
      this.snackbarSvc.add({
        msg: `Cannot find the selected network`,
        background: "red",
        action: { text: null }
      });
    }
  }

  async selectNet(selectedNetwork)
  {

    const loadedNetwork: Network = await this.networkSvc
    .getNetworkById(selectedNetwork)
    .toPromise();

    //alert(selectedNetwork +": "+loadedNetwork.name);
    var isSelected = false;
    var tempSelected = [];

    this.selectedNet.forEach(i => {
      
      if(i.networkId !== selectedNetwork)
      {
        tempSelected.push(i);
      }
      else 
      {
        isSelected = true;
      }
    });

    if(!isSelected)
    {
      tempSelected.push(loadedNetwork);       
    }

    this.selectedNet = tempSelected;

    //this.selectedNet.forEach(i => {
    //  alert(i.networkId);
    //});
    this.selectedNetCount = this.selectedNet.length;
    //alert(this.selectedNetCount);
   }

  openMergeNetwork()
  {
     
    const initialState = {
      networksList: this.selectedNet,
      title: "Merge Networks",
      confirmBtnName: "Save",
      closeBtnName: "Cancel",     
    };
    this.modalRef = this.modalSvc.show(NetworkMergePageComponent, {
      initialState,
      class: "modal-transparent modal-lg mt-6",
      ignoreBackdropClick: true
    });
    this.modalRef.content.onClose.subscribe(
      result => {
        if (result) {
          this.search(this.lastUsedQuery);
        }
      },
      error => {
        this.logger.error(error);
      }
    );
  }

  openNetwork(network) {
    const networkId = network ? network.id : null;
    const networkType = network ? network.typeId : null;
    if (networkId != null) {
      switch (networkType) {
        case NetworkType.CoP:
          window.open(
            `${this.config.getSettings().portalWebUrl}network/${networkId}/cop`,
            "_blank"
          );
          break;
        case NetworkType.BusinessUnit:
          window.open(
            `${this.config.getSettings().portalWebUrl}network/${networkId}/bu`,
            "_blank"
          );
          break;
        case NetworkType.SpecialTag:
          window.open(
            `${this.config.getSettings().portalWebUrl}specialtag/${networkId}`,
            "_blank"
          );
          break;
        case NetworkType.External:
          window.open(
            `${this.config.getSettings().portalWebUrl}external-network/${networkId}`,
            "_blank"
          );
          break;
      }
    } else {
      this.logger.warning(`Cannot find the selected network: ${network}`);
      this.snackbarSvc.add({
        msg: `Cannot find the selected network`,
        background: "red",
        action: { text: null }
      });
    }
  }
}
