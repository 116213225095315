import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from "@angular/core";
import { FormGroup, FormControl, Validators,
  FormBuilder,
  FormArray } from "@angular/forms";

import { SnackbarService } from "ngx-snackbar";
import { BsModalRef } from "ngx-bootstrap/modal";

import { IPMKeywordInfo  } from "src/app/commons/entities";
import { LoggerService } from "src/app/services/logger.service";
import { SecurityService } from "src/app/services/security.service";
import { UserInfo } from "../../../../../commons/entities";
import { NotificationService } from "src/app/services/notification.service";
import { Subject, Observable, concat, of } from "rxjs";

@Component({
  selector: "kms-admin-keywords-edit",
  templateUrl: "./keywords-edit.component.html",
  styleUrls: ["./keywords-edit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class KeywordsEditComponent implements OnInit {
  title: string;
  confirmBtnName: string;
  closeBtnName: string;
  keywordInfo: IPMKeywordInfo;
  keywordsList: IPMKeywordInfo[];
  form: FormGroup;
  editMode: boolean;

  isAdmin = false;
  isDuplicated = false;
  initialValue = null;

  //public inEditMode = false;
  public onClose: Subject<boolean>;

  constructor(
    public bsModalRef: BsModalRef,
    private readonly builder: FormBuilder,
    private securitySvc: SecurityService,
    private readonly snackbarSvc: SnackbarService,
    private logger: LoggerService,
    private notificationSvc: NotificationService
  ) {
    
  }

  ngOnInit() {
    this.onClose = new Subject();
    //this.editMode = false;
    //this.inEditMode = this.editMode;
    this.initForm();
    this.isDuplicated = false;
  
    //alert("editMode "+this.editMode);
    //alert("editMode "+this.editMode);
  }

  async confirm() {
    
    let action: Promise<any>;
   
    var yetPresent = false;
    
    this.keywordsList.forEach(item => {
     
      if( item.keyword.toLowerCase() == this.form.value.keyword.toLowerCase() )
      {
        yetPresent = true;      
      }
    });

    // && !this.editMode
    if(yetPresent && (this.initialValue == null || this.initialValue.toLowerCase() != this.form.value.keyword.toLowerCase()))
    {
      this.isDuplicated = true;
    }
    else 
    {
      this.bsModalRef.hide();
      this.isDuplicated = false;

      if(!this.editMode)
      {
        this.keywordInfo = new IPMKeywordInfo();
        //alert("creating");
        this.keywordInfo.keyword = this.form.value.keyword; 
        this.keywordInfo.userReferences = this.form.value.receipts;

        action = this.securitySvc
          .createIpmKeyword(this.keywordInfo).toPromise();
        
          const { transactionId } = await action;

          await this.notificationSvc
            .waitForTransactionResult(transactionId)
            .toPromise();
        }
        else
        {       
          
          //alert("Updating");
          this.keywordInfo.keyword = this.form.value.keyword;
          this.keywordInfo.userReferences = this.form.value.receipts;
    
          action = this.securitySvc
            .updateIpmKeyword(this.keywordInfo).toPromise();
            
            const { transactionId } = await action;

            await this.notificationSvc
              .waitForTransactionResult(transactionId)
              .toPromise();

            /*.subscribe(
              () => {
                this.securitySvc.rolesUpdateSubject.next(); //// ??????????????????
              },
              error => {
                this.logger.error(error);
              }
            );*/
        }
        this.onClose.next(true);
    }
  }
 
  private initForm() {    

    if(this.keywordInfo){
      this.initialValue = this.keywordInfo.keyword;
      this.form = new FormGroup({
        keyword: new FormControl(this.keywordInfo.keyword, Validators.required),
        receipts: this.createUserInfoArray(this.keywordInfo.userReferences,  this.builder)
      });
    }
    else{
      //new creation
      this.form = new FormGroup({
        keyword: new FormControl("", Validators.required),
        receipts: this.createUserInfoArray(null,  this.builder)
      });
    }

    /*
    if(this.keywordInfo)
    {
      this.editMode = true;  
      this.form = new FormGroup({
        keyword: new FormControl(this.keywordInfo.keyword, Validators.required),
        receipts: this.createUserInfoArray(this.keywordInfo.userReferences,  this.builder)
      });
    }
    else 
    {
      this.editMode = false;
      this.form = new FormGroup({
        keyword: new FormControl("", Validators.required),
        receipts: this.createUserInfoArray(null,  this.builder)
      });
           
    }
    */
 
  
  }
  
  private createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([],Validators.required);

    if (list) {
      list.forEach(n => {
        const user = new UserInfo(n);
        results.push(user.toFormGroup(builder));
      });
    }

    return results;
  }
}
