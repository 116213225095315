import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation
} from "@angular/core";

import {
  QueryBuilderConfig,
  QueryBuilderClassNames
} from "angular2-query-builder";

@Component({
  selector: "kms-admin-query-builder",
  templateUrl: "./query-builder.component.html",
  styleUrls: ["./query-builder.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class QueryBuilderComponent implements OnInit {
  @Input()
  config: QueryBuilderConfig = {
    fields: {
      Name: {
        name: "Name",
        type: "string",
        operators: ["contains", "=", "!="]
      }
    }
  };

  @Output()
  queryEvent = new EventEmitter<string>();

  query = {
    condition: "and",
    rules: []
  };

  public bootstrapClassNames: QueryBuilderClassNames = {
    removeIcon: "fa fa-minus",
    addIcon: "fa fa-plus",
    button: "btn",
    buttonGroup: "btn-group",
    rightAlign: "order-12 ml-auto",
    switchRow: "d-flex px-2",
    switchGroup: "d-flex align-items-center",
    switchRadio: "custom-control-input",
    switchLabel: "custom-control-label",
    switchControl: "custom-control custom-radio custom-control-inline",
    row: "row p-2 m-1",
    rule: "border",
    ruleSet: "border",
    invalidRuleSet: "alert alert-danger",
    emptyWarning: "text-danger mx-auto",
    operatorControl: "form-control",
    operatorControlSize: "col-auto pr-0",
    fieldControl: "form-control",
    fieldControlSize: "col-auto pr-0",
    entityControl: "form-control",
    entityControlSize: "col-auto pr-0",
    inputControl: "form-control",
    inputControlSize: "col-auto"
  };

  constructor() { }

  ngOnInit() { }

  reset() {
    this.query = {
      condition: "and",
      rules: []
    };
  }

  emitQuery() {
    const query = this.recursiveQueryBuild(this.query);
    this.queryEvent.emit(query ? `(${query})` : null);
  }

  private recursiveQueryBuild(query: any): string {
    return query.rules.reduce((initial, rule) => {
      return (
        initial +
        (initial ? " " + query.condition + " " : "") +
        (rule["condition"] != null
          ? "(" + this.recursiveQueryBuild(rule) + ")"
          : this.getQueryRule(rule))
      );
    }, "");
  }

  private getQueryRule(rule: any): string {
    const fieldType =
      rule && this.config.fields && this.config.fields[rule.field]
        ? this.config.fields[rule.field].type
        : null;
    const value =
      fieldType === "date" || "number"
        ? rule.value
          ? rule.value
          : ""
        : "'" + (rule.value ? rule.value : "") + "'";
    const fieldName = rule.field;
    if (fieldType === "number") {
      switch (rule.operator) {
        case "!=":
          return `${fieldName}/any(t: t eq ${value})`;
        case "=":
          return `${fieldName}/any(t: t eq ${value})`;
        case "contains":
          return `${fieldName}/any(t: t eq ${value})`;
        case ">":
          return `${fieldName}/any(t: t gt ${value})`;
        case ">=":
          return `${fieldName}/any(t: t ge ${value})`;
        case "<":
          return `${fieldName}/any(t: t lt ${value})`;
        case "<=":
          return `${fieldName}/any(t: t le ${value})`;
        default:
          return `${fieldName}/any(t: t eq ${value})`;
      }
    } else {
      switch (rule.operator) {
        case "!=":
          if (value !== '') {
            return (
              "not search.ismatch('" +
              value +
              "'" +
              ", '" +
              fieldName +
              "', 'full', 'all')"
            );
          }
          else {
            return (
              fieldName + " ne ''"
            )

          }
        case ">":
          return fieldName + " gt " + value;
        case "<":
          return fieldName + " lt " + value;
        case ">=":
          return fieldName + " ge " + value;
        case "<=":
          return fieldName + " le " + value;
        case "contains":
          return (
            "search.ismatch('" +
            this.escapingNames(value) +
            "', '" +
            fieldName +
            "', 'full', 'any')"
          );
        case "=": {
          if (value === "" || value === null) {
            return fieldName + " eq null";
          } else {
            return (
              "search.ismatch('" +
              value +
              "'" +
              ", '" +
              fieldName +
              "', 'simple', 'all')"
            );
          }
        }
        default:
          return (
            "search.ismatch('" +
            value +
            "'" +
            ", '" +
            fieldName +
            "', 'simple', 'all')"
          );
      }
    }
  }

  private escapingNames(name: string): string {
    name = name.replace(/^'/, "");
    name = name.replace(/'$/, "");
    return name
      .split(" ")
      .map(r => "/.*" + r + ".*/")
      .join(" ");
  }
}
