<nav
  *ngIf="userSvc?.authorizedUser"
  class="navbar navbar-expand-md  navbar-static ms-navbar ms-navbar-primary navbar-mode"
>
  <div class="container container-full navbar-width">
    <div class="navbar-header">
      <a class="navbar-brand" href="index.html">
        <!-- <img src="assets/img/demo/logo-navbar.png" alt=""> -->
        <span class="kms-logo mr-1"></span>
        <span class="ms-title"
          >#KMSAdmin
          <span class="badge-pill badge-pill-info">{{ env }}</span></span
        >
      </a>
    </div>
    <div class="collapse navbar-collapse" id="ms-navbar">
      <ul class="navbar-nav">
        <li
          class="nav-item dropdown"
          [ngClass]="{ active: activePage == routes.home }"
        >
          <a
            [routerLink]="routes.home"
            class="nav-link dropdown-toggle animated fadeIn animation-delay-7"
            data-toggle="dropdown"
            data-hover="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            data-name="home"
            >Home<i class="zmdi zmdi-chevron-down"></i
          ></a>
        </li>
        <li
          class="nav-item dropdown"
          [ngClass]="{ active: activePage == routes.businessUnits }"
        >
          <a
            [routerLink]="routes.businessUnits"
            class="nav-link dropdown-toggle animated fadeIn animation-delay-7"
            data-toggle="dropdown"
            data-hover="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            data-name="page"
            >Business Units<i class="zmdi zmdi-chevron-down"></i
          ></a>
        </li>
        <li
          class="nav-item dropdown"
          [ngClass]="{ active: activePage == routes.challenge }"
        >
          <a
            [routerLink]="routes.challenge"
            class="nav-link dropdown-toggle animated fadeIn animation-delay-7"
            data-toggle="dropdown"
            data-hover="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            data-name="blog"
            >Challenges<i class="zmdi zmdi-chevron-down"></i
          ></a>
        </li>
        <li
          class="nav-item dropdown"
          [ngClass]="{ active: activePage == routes.config }"
        >
          <a
            [routerLink]="routes.config"
            class="nav-link dropdown-toggle animated fadeIn animation-delay-7"
            data-toggle="dropdown"
            data-hover="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            data-name="page"
            >Configurations<i class="zmdi zmdi-chevron-down"></i
          ></a>
        </li>
        <li
          class="nav-item dropdown"
          [ngClass]="{ active: activePage == routes.deletedPosts }"
        >
          <a
            [routerLink]="routes.deletedPosts"
            class="nav-link dropdown-toggle animated fadeIn animation-delay-7"
            data-toggle="dropdown"
            data-hover="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            data-name="blog"
            >Deleted Posts<i class="zmdi zmdi-chevron-down"></i
          ></a>
        </li>
        <li
          class="nav-item dropdown"
          [ngClass]="{ active: activePage == routes.gamification }"
        >
          <a
            [routerLink]="routes.gamification"
            class="nav-link dropdown-toggle animated fadeIn animation-delay-7"
            data-toggle="dropdown"
            data-hover="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            data-name="page"
            >Gamification<i class="zmdi zmdi-chevron-down"></i
          ></a>
        </li>
        <li
          class="nav-item dropdown"
          [ngClass]="{ active: activePage == routes.hashtags }"
        >
          <a
            [routerLink]="routes.hashtags"
            class="nav-link dropdown-toggle animated fadeIn animation-delay-7"
            data-toggle="dropdown"
            data-hover="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            data-name="blog"
            >Hashtags<i class="zmdi zmdi-chevron-down"></i
          ></a>
        </li>
        <li
          class="nav-item dropdown dropdown-megamenu-container"
          [ngClass]="{ active: activePage == routes.networks }"
        >
          <a
            [routerLink]="routes.networks"
            class="nav-link dropdown-toggle animated fadeIn animation-delay-7"
            data-toggle="dropdown"
            data-hover="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            data-name="component"
            >Networks<i class="zmdi zmdi-chevron-down"></i
          ></a>
        </li>
        <li
          class="nav-item dropdown"
          [ngClass]="{ active: activePage == routes.strategicTags }"
        >
          <a
            [routerLink]="routes.strategicTags"
            class="nav-link dropdown-toggle animated fadeIn animation-delay-7"
            data-toggle="dropdown"
            data-hover="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            data-name="blog"
            >Strategic Tags<i class="zmdi zmdi-chevron-down"></i
          ></a>
        </li>
      </ul>
    </div>
  </div>
  <!-- container -->
</nav>
