import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef
} from "@angular/core";
import { QueryBuilderConfig } from "angular2-query-builder";
import { Observable, of } from "rxjs";
import { Gamification, GamificationSettings } from "src/app/commons/entities";
import { ConfigurationService } from "src/app/services/configuration.service";
import { tap } from "rxjs/operators";
import { FormArray, FormBuilder } from "@angular/forms";
import { NotificationService } from "src/app/services/notification.service";
import { Constants } from "src/app/commons/util";

@Component({
  selector: "kms-admin-gamification",
  templateUrl: "./gamification.component.html",
  styleUrls: ["./gamification.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class GamificationComponent implements OnInit {
  gamificationList: Gamification[];
  editingG = false;
  gameSettings: GamificationSettings;
  gameFA: FormArray;
  loadingIndicator = false;
  limit = Constants.TABLE_LIMITS;

  get isButtonDisabled() {
    return this.gameFA.invalid;
  }

  constructor(
    private readonly configSvc: ConfigurationService,
    private readonly cd: ChangeDetectorRef,
    private readonly notificationSvc: NotificationService,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit() {
    this.fetch();
  }

  fetch() {
    this.loadingIndicator = true;

    this.configSvc.getGamificationScores().subscribe(val => {
      this.loadingIndicator = false;
      this.gameSettings = val;
      this.createFormArray(val);

      this.cd.markForCheck();
    });
  }

  cancelClick() {
    this.editingG = false;
    this.fetch();
  }

  async saveClick() {
    try {
      this.loadingIndicator = true;
      this.editingG = false;
      this.gameSettings.events = this.gameFA.value;

      let action: Promise<any>;
      action = this.configSvc
        .saveGamificationEvent(this.gameSettings)
        .toPromise();
      const { transactionId } = await action;
      await this.notificationSvc
        .waitForTransactionResult(transactionId)
        .toPromise();
      this.loadingIndicator = false;
      this.fetch();
    } catch (e) {
      this.notificationSvc.showDetailsError(e);
    }
  }

  createFormArray(array) {
    this.gameFA = new FormArray([]);
    array.events.forEach(element => {
      this.gameFA.push(new Gamification(element).toFormGroup(this.fb));
    });
  }
}
