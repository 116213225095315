import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray
} from "@angular/forms";

import { BsModalRef } from "ngx-bootstrap/modal";

import {
  UserInfo,
  Challenge,
  HashtagType,
  HashtagInfo,
  NetworkInfo,
  ChoiceField,
  ImpactingKnowledgeType,
  KMSObject,
  BasicLookup,
  Choice,
  EvaluationCategory,
  EvaluationField
} from "src/app/commons/entities";
import { HashtagService } from "src/app/services/hashtag.service";
import { NetworkService } from "src/app/services/network.service";
import { ChallengeService } from "src/app/services/challenge.service";
import { SearchService } from "src/app/services/search.service";
import {
  Observable,
  Subject,
  concat,
  of,
  BehaviorSubject,
  forkJoin
} from "rxjs";
import {
  tap,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError,
  map
} from "rxjs/operators";
import { UserService } from "src/app/services/user.service";
import { CustomValidators } from "src/app/commons/validation";
import { NotificationService } from "src/app/services/notification.service";
import { LoggerService } from "src/app/services/logger.service";
import { ConfigService } from "@ngx-config/core";
import { uniq } from "lodash";

import * as dayjs from "dayjs";
import { ConfigurationService } from "src/app/services/configuration.service";
import { Utilities } from "src/app/commons/util";

@Component({
  selector: "kms-admin-challenge-edit-page",
  templateUrl: "./challenge-edit-page.component.html",
  styleUrls: ["./challenge-edit-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ChallengeEditPageComponent implements OnInit {
  @Output() close = new EventEmitter<any>();

  confirmBtnName: string;
  closeBtnName: string;

  newChallenge = true;
  editImage: boolean;
  canEditTag = true;
  isOpen = false;
  isValidInterval = false;

  type = KMSObject.Challenge;

  // business: ChoiceField = null;
  // preSelectBusinness: BasicLookup;
  // businessList: BasicLookup[] = [];

  impactedArea: ChoiceField = null;
  preSelectImpactedArea: BasicLookup;
  impactedAreaList: BasicLookup[] = [];

  challenge: Challenge;
  ChallengeGroup: FormGroup = null;

  interval: Date[] = [];

  hashtag$: Observable<HashtagInfo[]>;
  hashtagLoading = false;
  hashtagInput$ = new Subject<string>();
  hashtags: HashtagInfo[] = [];

  network$: Observable<NetworkInfo[]>;
  networkLoading = false;
  networkInput$ = new Subject<string>();
  network: NetworkInfo[] = [];

  networksObs = new BehaviorSubject<NetworkInfo[]>([]);
  dateRange$ = new BehaviorSubject<[Date, Date]>([null, null]);

  isPromoted$ = new BehaviorSubject(false);
  isPrivate$ = new BehaviorSubject(false);
  isAlreadyCreated$ = new BehaviorSubject(false);
  isAlreadyStarted$ = new BehaviorSubject(false);
  isAlreadyExpired$ = new BehaviorSubject(false);
  isSameDay$ = new BehaviorSubject(false);
  isDeprecated$ = new BehaviorSubject(false);
  constructor(
    public bsModalRef: BsModalRef,
    private readonly builder: FormBuilder,
    private readonly cd: ChangeDetectorRef,
    private readonly userSvc: UserService,
    private readonly hashtagSvc: HashtagService,
    private readonly networkSvc: NetworkService,
    public challengeSvc: ChallengeService,
    private readonly validationSvc: CustomValidators,
    private readonly configurationSvc: ConfigurationService,
    public searchSvc: SearchService,
    private readonly notificationSvc: NotificationService,
    private logger: LoggerService,
    private readonly config: ConfigService
  ) {}
  get canCreateChallenge() {
    return (
      this.ChallengeGroup.valid &&
      !this.isPromoted$.value &&
      !this.isDeprecated$.value &&
      !this.isPrivate$.value &&
      !this.isAlreadyCreated$.value &&
      this.isValidInterval
    );
  }
  ngOnInit() {
    if (this.newChallenge) {
      this.challenge = new Challenge();
      this.challenge.relatedTags = this.hashtags;
      // .filter(h => h.type === HashtagType.Generic)
      // .map(h => new HashtagInfo(h));

      this.challenge.type = ImpactingKnowledgeType.Challenge;

      this.challenge.id = this.generateGuid();

      this.challenge.createdBy = new UserInfo(this.userSvc.userProfile);
      this.canEditTag = true;
      this.isOpen = false;

      this.isValidInterval = false;
    } else {
      this.challenge = new Challenge(this.challenge);
      this.challenge.description = Utilities.replaceToken(
        this.challenge.description,
        this.configurationSvc.assetsConfig
      );
      const endDate = new Date(this.challenge.endDate)
      .toUTCString()
      .substr(0, 25); // Utilities.adjustDateMilanToUtc(this.challenge.endDate);
      
      this.interval.push(
        new Date(new Date(this.challenge.startDate)),
        new Date(new Date(endDate))
      );

      if (this.challenge.status.key === 2 || this.challenge.status.key === 3) {
        this.isOpen = true;
      } else {
        this.isOpen = false;
      }

      this.canEditTag = false;
    }

    this.ChallengeGroup = this.challenge.toFormGroup(this.builder);

    this.ChallengeGroup.get("name").setValidators(Validators.required);
    this.ChallengeGroup.get("description").setValidators(Validators.required);
    (<FormGroup>this.ChallengeGroup.get("hashtag"))
      .get("name")
      .setValidators([Validators.required, Validators.maxLength(100)]);

    // this.ChallengeGroup.get("focus").setValidators(Validators.required);

    this.ChallengeGroup.get("involvedPeople").setValidators(
      this.validationSvc.atLeastOneElements()
    );

    // this.ChallengeGroup.get("evaluationCommittee").setValidators(
    //   this.validationSvc.atLeastOneElements()
    // );

    this.ChallengeGroup.get("promotingNetworks").setValidators(
      this.validationSvc.atLeastOneElements()
    );

    // this.preSelectBusinness = this.challenge.businessImpact
    //   ? new BasicLookup({
    //       key: this.challenge.businessImpact.choiceId,
    //       value: this.challenge.businessImpact.choiceLabel
    //     })
    //   : new BasicLookup();

    this.preSelectImpactedArea = this.challenge.mostlyImpactedArea
      ? new BasicLookup({
          key: this.challenge.mostlyImpactedArea.fieldId,
          value: this.challenge.mostlyImpactedArea.fieldLabel
        })
      : null;

    this.loadHashtag();
    this.loadNetwork();
    // this.loadBusiness();
    this.loadImpactedArea();

    this.networkSvc
      .getNetworksByHastags(
        this.hashtags
          .filter(h => h.type === HashtagType.Network)
          .map(h => new HashtagInfo(h))
      )
      .subscribe(
        reply => {
          this.networksObs.next(reply.map(h => new NetworkInfo(h)));
          reply.forEach(r => {
            (<FormArray>this.ChallengeGroup.get("promotingNetworks")).push(
              new NetworkInfo(r).toFormGroup(this.builder)
            );
          });
          // this.calculatePermission();
        },
        error => {
          this.logger.error(error);
        }
      );
    this.ChallengeGroup.get("description").valueChanges.subscribe(val => {
      if (val === "<br>") {
        this.ChallengeGroup.get("description").setValue("");
      }
    });
    if (this.canEditTag) {
      this.ChallengeGroup.get("name").valueChanges.subscribe(value => {
        const hash = <FormGroup>this.ChallengeGroup.get("hashtag");

        hash.get("type").setValue(HashtagType.Generic);
        hash.get("name").setValue(
          value
            .replace(/(à|á|ã|ä)/gi, "a")
            .replace(/(è|é|ë|ē|ę)/gi, "e")
            .replace(/(í|ì|ī|ï)/gi, "i")
            .replace(/(õ|ó|ò|ö)/gi, "o")
            .replace(/(ü|û|ú|ù|ū)/gi, "u")
            .replace(/(^|\s)(the|a|an)\b/gi, "")
            .replace(/\b[a-z]/gi, c => c.toUpperCase())
            .replace(/\s/g, "")
            .replace(/[^a-zA-Z0-9 ]/g, "")
        );
        // this.creationChallengeHashtagCheck();
      });
    }

    (<FormGroup>this.ChallengeGroup.get("hashtag"))
      .get("name")
      .valueChanges.subscribe(value => {
        // call to check warning or alert
        this.creationChallengeHashtagCheck();
      });
  }

  async confirm() {
    let action: Promise<any>;
    if (this.newChallenge) {
      action = this.challengeSvc
        .createChallenge(this.getChallenge())
        .toPromise();
    } else {
      action = this.challengeSvc
        .updateChallenge(this.getChallenge())
        .toPromise();
    }

    try {
      const response = await action;

      await this.notificationSvc
        .waitForTransactionResult(response.transactionId)
        .toPromise();

      const {
        involvedPeople,
        hashtag,
        promotingNetworks
      } = this.ChallengeGroup.value;
      if (this.newChallenge) {
        const involvedNetworksStakeholders = await this.challengeSvc
          .getInvolvedNetworksStakeholdersAndKO(
            promotingNetworks.map(x => x.networkId)
          )
          .toPromise();
        this.logger.eventMultiple(
          "ik.challenge.create",
          uniq([...involvedPeople, ...involvedNetworksStakeholders]).map(u => ({
            hashtag: hashtag.name,
            ikId: this.challenge.id,
            targetUserId: u.userId ? u.userId : u,
            facilitator: u.userId ? false : true
          }))
        );
      } else {
        const addedPeople = involvedPeople
          ? involvedPeople.filter(x =>
              this.challenge.involvedPeople.every(y => y.userId !== x.userId)
            )
          : [];
        const addedNetwork: string[] = promotingNetworks
          .map(r => r.networkId)
          .filter(
            network =>
              !this.challenge.promotingNetworks
                .map(r => r.networkId)
                .includes(network)
          );
        const involvedNetworksStakeholders = await this.challengeSvc
          .getInvolvedNetworksStakeholdersAndKO(addedNetwork)
          .toPromise();
        this.logger.eventMultiple(
          "ik.challenge.update",
          uniq([...addedPeople, ...involvedNetworksStakeholders]).map(u => ({
            hashtag: hashtag.name,
            targetUserId: u.userId ? u.userId : u,
            ikId: this.challenge.id,
            facilitator: u.userId ? false : true
          }))
        );
        this.challenge = this.ChallengeGroup.value;
      }

      this.close.next(true);
      window.open(
        `${this.config.getSettings().portalWebUrl}challenge/${
          this.ChallengeGroup.value.id
        }`,
        "_blank"
      );
      this.bsModalRef.hide();
    } catch (e) {
      this.notificationSvc.showDetailsError(e);
    }
  }

  cancel() {
    this.bsModalRef.hide();
  }

  hashtagKeyDown(event) {
    if (event.keyCode === 32) {
      event.preventDefault();
    }

    if (/[^a-zA-Z0-9 ]/g.test(event.key) === true) {
      event.preventDefault();
    }

    // this.creationChallengeHashtagCheck();
  }

  onDifferentDateRange(ev: CustomEvent) {
    if (ev != null) {
      this.dateRange$.next(ev.detail || [null, null]);
      if (ev[0] != null && ev[1] != null) {
        this.validateDate(ev[0], ev[1]);
      }
    } else {
      this.isValidInterval = false;
    }
  }

  clear() {
    this.isAlreadyStarted$.next(false);
    this.isAlreadyExpired$.next(false);
    this.isSameDay$.next(false);
  }

  validateDate(start: Date, end: Date) {
    const today = dayjs();
    const startDate = dayjs(start);
    const endDate = dayjs(end);
    const st = new Date(
      Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())
    );
    const en = new Date(
      Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59)
    );

    if (this.isOpen) {
      this.ChallengeGroup.get("endDate").setValue(en.toISOString());
      this.isAlreadyExpired$.next(false);
      this.isSameDay$.next(false);
      this.isValidInterval = true;
    } else {
      if (!startDate.isBefore(today, "day")) {
        this.isAlreadyStarted$.next(false);
        if (!endDate.isBefore(startDate, "day")) {
          this.ChallengeGroup.get("startDate").setValue(st.toISOString());
          this.ChallengeGroup.get("endDate").setValue(en.toISOString());
          this.isAlreadyExpired$.next(false);
          this.isSameDay$.next(false);
          this.isValidInterval = true;
        } else {
          this.isSameDay$.next(true);
          this.isValidInterval = false;
        }
      } else {
        if (!endDate.isBefore(today, "day")) {
          this.isAlreadyExpired$.next(false);
        } else {
          this.isAlreadyExpired$.next(true);
        }
        this.isAlreadyStarted$.next(true);
        this.isValidInterval = false;
      }
    }
  }

  // modifyBusiness(event) {
  //   if (event) {
  //     const c = new Choice();
  //     c.choiceId = event.key;
  //     c.choiceLabel = event.value;
  //     c.fieldId = this.business.fieldId;
  //     c.fieldLabel = this.business.fieldTitle;
  //     // c.fieldTooltip = "";
  //     c.choiceValue = 0;
  //     (<FormGroup>this.ChallengeGroup.get("businessImpact")).setValue(c);
  //   } else {
  //     (<FormGroup>this.ChallengeGroup.get("businessImpact")).setValue(null);
  //   }
  // }

  modifyImpactedArea(event) {
    if (event) {
      const c = new Choice();
      c.choiceId = 0;
      c.choiceLabel = "";
      c.fieldId = event.key;
      c.fieldLabel = event.value;
      // c.fieldTooltip = "";
      c.choiceValue = 0;
      (<FormGroup>this.ChallengeGroup.get("mostlyImpactedArea")).setValue(c);
    } else {
      (<FormGroup>this.ChallengeGroup.get("mostlyImpactedArea")).setValue(null);
    }
  }

  loadHashtag() {
    this.hashtag$ = concat(
      of([]), // default items
      this.hashtagInput$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.hashtagLoading = true;
          this.cd.markForCheck();
        }),
        switchMap((term: string) =>
          this.hashtagSvc.searchRelatedHashtagAndStrategicTag(term).pipe(
            catchError(() => of([])), // empty list on error
            map(hashtagArray =>
              hashtagArray.filter(el =>
                this.ChallengeGroup.get("relatedTags").value.every(
                  item2 => item2.hashtagId !== el.hashtagId
                )
              )
            ),
            tap(() => {
              this.hashtagLoading = false;
              this.cd.markForCheck();
            })
          )
        )
      )
    );
  }

  loadNetwork() {
    this.network$ = concat(
      of([]), // default items
      this.networkInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          this.networkLoading = true;
          this.cd.markForCheck();
        }),
        switchMap((term: string) =>
          this.networkSvc.searchNetwork(term).pipe(
            catchError(() => of([])), // empty list on error
            map(networks =>
              networks.filter(el =>
                this.ChallengeGroup.get("promotingNetworks").value.every(
                  item2 => item2.networkId !== el.networkId
                )
              )
            ),
            tap(() => {
              this.networkLoading = false;
              this.cd.markForCheck();
            })
          )
        )
      )
    );
  }

  // loadBusiness() {
  //   this.configurationSvc.getChoiceField(1).subscribe((c: ChoiceField) => {
  //     this.business = new ChoiceField(c);
  //     this.businessList = c.choices.map(x => new BasicLookup(x));
  //   });
  // }

  async loadImpactedArea() {
    const fields: EvaluationField[] = await this.configurationSvc
      .getEvaluationField(EvaluationCategory.MostlyImpactedArea)
      .toPromise();

    // this.business = new ChoiceField(c);
    this.impactedAreaList = fields.map(
      r => new BasicLookup({ key: r.fieldId, value: r.fieldTitle })
    );
  }

  creationChallengeHashtagCheck() {
    const hashtagGroup = <FormGroup>this.ChallengeGroup.get("hashtag");
    const name = hashtagGroup.get("name").value;

    if (name !== null) {
      this.isAlreadyCreated$.next(false);

      forkJoin(
        this.searchSvc.query("Hashtags", name),
        this.searchSvc.query("Bookings", name)
      ).subscribe(async ([res1, res2]) => {
        const hashtag = (<any>res1).results.find(
          r => name.toLowerCase() === r.document.name.toLowerCase()
        );

        const candidateHashtag = (<any>res2).results.find(
          c => name.toLowerCase() === c.document.candidateHashtag.toLowerCase()
        );

        const isPrivate =
          hashtag != null && hashtag.document.isPrivate === true;
        this.isPrivate$.next(isPrivate);

        const isDeprecated =
          hashtag != null &&
          hashtag.document.isDeprecated === true &&
          !isPrivate;
        this.isDeprecated$.next(isDeprecated);

        this.isPromoted$.next(
          (hashtag != null && hashtag.document.isPromoted === true) ||
            !!candidateHashtag // bug #3075 - block also candidate hashtags for bookings
        );

        if (!this.isAlreadyCreated$.value && !this.isPromoted$.value) {

          if (!hashtag && name && name !== "") {
            // check if hashtag exists

            let hashtagExists;

            hashtagExists = await this.hashtagExists(name);
            if (hashtagExists) {
              this.isPromoted$.next(true);
            }

          } else {
            this.isAlreadyCreated$.next(
              hashtag != null &&
                hashtag.document.isPromoted === false &&
                !isDeprecated &&
                !isPrivate
            );

          }

        } else {
          this.isAlreadyCreated$.next(false);
        }

        this.cd.markForCheck();
      });
    }
  }

  endCropping() {
    this.editImage = false;
  }

  generateGuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  }

  // ng-select Relateed Hashtag

  addNetwork(event) {
    (<FormArray>this.ChallengeGroup.get("promotingNetworks")).push(
      new FormControl(new NetworkInfo(event))
    );
  }

  removeNetwork(event) {
    const arr = (<FormArray>this.ChallengeGroup.get("promotingNetworks")).value;

    arr.forEach((element: NetworkInfo, index) => {
      if (element.networkId === event.value.networkId) {
        (<FormArray>this.ChallengeGroup.get("promotingNetworks")).removeAt(
          index
        );
      }
    });
  }

  removeAllNetwork() {
    while (
      (<FormArray>this.ChallengeGroup.get("promotingNetworks").value).length !==
      0
    ) {
      (<FormArray>this.ChallengeGroup.get("promotingNetworks")).removeAt(0);
    }
  }

  // ng-select Relateed Hashtag

  addHashtag(event) {
    (<FormArray>this.ChallengeGroup.get("relatedTags")).push(
      new FormControl(new HashtagInfo(event))
    );
  }

  removeHashtag(event) {
    const arr = (<FormArray>this.ChallengeGroup.get("relatedTags")).value;

    arr.forEach((element: HashtagInfo, index) => {
      if (element.hashtagId === event.value.hashtagId) {
        (<FormArray>this.ChallengeGroup.get("relatedTags")).removeAt(index);
      }
    });
  }

  removeAllHashtag() {
    while ((<FormArray>this.ChallengeGroup.get("relatedTags")).length !== 0) {
      (<FormArray>this.ChallengeGroup.get("relatedTags")).removeAt(0);
    }
  }

  compareWith(a, b) {
    return a === b;
  }

  onSelectSingleDate(ev) {
    if (ev != null) {
      const date = dayjs(ev);
      this.ChallengeGroup.get("evaluationCommitteeDate").setValue(
        date
          .set("hour", 1)
          .set("minute", 0)
          .set("second", 0)
      );
    }
  }

  private getChallenge(): Challenge {
    const challenge: Challenge = this.ChallengeGroup.value;
    challenge.description = challenge.description.replace(
      /"(http:\/\/|https:\/\/)?www\.[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?"(\starget="_blank")?/g,
      (match: string) => {
        if (
          /(https?:\/\/[^\s]+)/g.test(match) ||
          /(http?:\/\/[^\s]+)/g.test(match)
        ) {
          return `${match}`;
        } else {
          return `"https://${match.slice(1, -1)}" target="_blank"`;
        }
      }
    );
    challenge.description = this.setImagesPlaceholder(challenge.description);
    return challenge;
  }
  setImagesPlaceholder(content: string) {
    const assetKey = this.configurationSvc.assetsConfig.value["item2"];
    const assetDomain = this.configurationSvc.assetsConfig.value["item1"];
    content = content.split("&amp;").join("&");
    const temp = content.split(assetKey).join("$_TOKEN");
    const temp2 = temp.split(assetDomain).join("$_BASEURL");
    return temp2;
  }

  private hashtagExists(name) {
    return this.hashtagSvc.existsByName(name).toPromise();
  }
}
