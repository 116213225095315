<span class="img-avatar" *ngIf="(imgUrl$ | async) as imgUrl">
    <span
      class="badge-pill avatar-pill"
      *ngIf="canEditImage"
      (click)="editImage()"
    >
      <i class="fa fa-camera" aria-hidden="true"></i>
    </span>
    <span
    *ngIf="isKO"
      class="badge-pill badge-pill-success avatar-pill"
      [ngClass]="badgeClass()"
      [tooltip]="koTooltip"
      containerClass="tooltip-class"
      container="body"
      
    > 
      <i class="fa fa-graduation-cap" aria-hidden="true"></i>
    </span>
    <img
      alt="..."
      class="img-avatar-circle animated zoomIn animation-delay-1 {{ color }}"
      [ngClass]="{
        'ms-avatar-hero': zoomIn,
        small: smallVersion,
        'extra-small': extraSmallVersion,
        'super-small': superSmallVersion,
        'super-extra-small': superExtraSmallVersion
      }"
      routerlink="/profile"
      [src]="imgUrl"
      tabindex="0"
    />
  </span>
  
