<ng-container *ngIf="!edit">
  <div class="d-flex col-lg-12 pl-0">
    <ng-container *ngFor="let item of user">
      <a class="mr-3" [routerLink]="'/profile/' + item.userId">
        <kms-admin-avatar-view
          data-toggle="tooltip"
          data-placement="top"
          title="{{item.displayName}}"
          [roles]="item.roles"
          [isKO]="item.isKOEligible"
          [extraSmallVersion]="true"
          [imageUrl]="item.avatarUrl"
          [type]="1"
          class="rounded-circle"
        ></kms-admin-avatar-view>
      </a>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="edit">
  <table style="border: 0px; width: 100%;">
    <tr>
      <td style="width: 100%;">
        <ng-select
        class="post-audience"
        [items]="user$ | async"
        bindLabel="displayName"
        [addTag]="false"
        [multiple]="isMulti"
        [hideSelected]="true"
        [loading]="userLoading"
        [placeholder]="placeholder"
        [dropdownPosition]="'bottom'"
        [typeahead]="userInput$"
        [(ngModel)]="user"
        (add)="addUser($event)"
        (remove)="removeUser($event)"
        (change) = "modifyUser($event)"
        (clear)="removeAll()"
      >
        <ng-template ng-option-tmp let-item="item">
          <p class="mt-1 mb-1">
            <kms-admin-mini-user [user]="item" [showUnit]="true"> </kms-admin-mini-user>
          </p>
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
          <span class="d-flex align-items-center color-black">
            <kms-admin-avatar-view *ngIf="!disableAllAvatarViews"
              [superSmallVersion]="true"
              [imageUrl]="item.avatarUrl"
              [type]="1"
              [color]="color"
              
              class="rounded-circle d-flex align-items-center"
            ></kms-admin-avatar-view>
            <span class="margin-picker font-smaller">{{ item.displayName }}</span>
            <span
              aria-hidden="true"
              class="ng-value-icon left margin-picker"
              (click)="removePills(item)"
              >x</span
            >
          </span>
        </ng-template>
      </ng-select>
      </td>
      <td style="padding-left: 20px;" *ngIf="enableCsvUpload">
        <kms-admin-file-upload 
        style="width: 30px; height: 30px;"
        [isGuestCsvFile]="true"
        [showCsvHelp]="false"
        [queryStringCsvParams]="queryStringCsvParams"
        (fileLoaded)="uploadedFile($event)"
        (uploadingProgress)="uploadingProgressFunction($event)"
        ></kms-admin-file-upload>
      </td>
    </tr>
  </table>
</ng-container>
