import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ElementRef
} from "@angular/core";
import {
  ImageCropperComponent,
  CropperSettings,
  Bounds
} from "ngx-img-cropper";
import { FormControl } from "@angular/forms";
import { UserService } from "src/app/services/user.service";
import { KMSObject } from "src/app/commons/entities";
import { NetworkService } from "src/app/services/network.service";
import { IkService } from "src/app/services/ik.service";
import { HashtagService } from "src/app/services/hashtag.service";
import { ChallengeService } from "src/app/services/challenge.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import { BehaviorSubject } from "rxjs";
import { NotificationService } from "src/app/services/notification.service";

@Component({
  selector: "kms-image-cropper",
  templateUrl: "./image-cropper.component.html",
  styleUrls: ["./image-cropper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KMSImageCropperComponent implements OnInit {
  imgData: any;
  cropperSetting: CropperSettings;

  @Input()
  imageUrl?: FormControl;

  @Input()
  imageKey: FormControl;

  @Output()
  endCropping: EventEmitter<boolean> = new EventEmitter();

  @Input()
  type: KMSObject;

  @Input()
  autoOpen = false;

  @Input()
  showFirstCancel = true;

  @ViewChild("cropper", {static: false})
  cropper: ImageCropperComponent;

  @ViewChild("uploader", {static: true})
  uploader: ElementRef;

  isLoaded = false;
  isSaved = false;

  loading = false;

  imgUrl$: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    private userSvc: UserService,
    private networkSvc: NetworkService,
    private ikSvc: IkService,
    private hashtagSvc: HashtagService,
    private cdRef: ChangeDetectorRef,
    private readonly notificationSvc: NotificationService,
    private readonly configSvc: ConfigurationService
  ) {
    this.cropperSetting = new CropperSettings();
    this.cropperSetting.width = 200;
    this.cropperSetting.height = 200;
    this.cropperSetting.keepAspect = true;

    this.cropperSetting.croppedWidth = 200;
    this.cropperSetting.croppedHeight = 200;

    this.cropperSetting.canvasWidth = 500;
    this.cropperSetting.canvasHeight = 300;

    this.cropperSetting.minWidth = 100;
    this.cropperSetting.minHeight = 100;
    this.cropperSetting.preserveSize = false;
    this.cropperSetting.dynamicSizing = true;
    this.cropperSetting.rounded = true;
    this.cropperSetting.minWithRelativeToResolution = false;

    this.cropperSetting.cropperDrawSettings.strokeColor = "#D0003E";
    this.cropperSetting.cropperDrawSettings.strokeWidth = 1;
    this.cropperSetting.noFileInput = true;
    this.cropperSetting.cropperClass = "cropper-class";
    this.imgData = {};
  }

  ngOnInit() {
    let call = null;
    if (this.imageUrl === null || this.imageUrl.value === null) {
      // no image create new
      if (this.type === KMSObject.User) {
        call = this.userSvc.getDefaultUserAvatar();
      } else if (this.type === KMSObject.Network) {
        call = this.networkSvc.getDefaultNetworkAvatar();
      } else if (this.type === KMSObject.IK) {
        call = this.ikSvc.getDefaultIKAvatar();
      } else if (this.type === KMSObject.Hashtag) {
        call = this.hashtagSvc.getDefaultHashtagAvatar();
      } else if (this.type === KMSObject.Idea) {
        call = this.ikSvc.getDefaultIKAvatar(KMSObject.Idea);
      } else if (this.type === KMSObject.SuccessStory) {
        call = this.ikSvc.getDefaultIKAvatar(KMSObject.SuccessStory);
      } else if (this.type === KMSObject.Challenge) {
        call = this.ikSvc.getDefaultIKAvatar(KMSObject.Challenge);
      }

      call.subscribe((data: string) => {
        const image: any = new Image();
        image.src = this.configSvc.createCDNImageUrl(data);
        this.cropper.setImage(image);
        this.cdRef.markForCheck();
      });
    } else if (this.imageUrl.value) {
      this.imgUrl$.next(this.configSvc.createCDNImageUrl(this.imageUrl.value));

      const image: any = new Image();
      image.src = this.configSvc.createCDNImageUrl(this.imageUrl.value);
      this.cropper.setImage(image);
      this.cdRef.markForCheck();
    }

    if (this.autoOpen) {
      this.uploader.nativeElement.click();
    }
  }

  fileChangeListener($event) {
    this.loading = true;
    const image: any = new Image();
    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();
    const that = this;
    if (file) {
      myReader.onloadend = function(loadEvent: any) {
        image.src = loadEvent.target.result;
        that.cropper.setImage(image);
        // For reset input file..if don't reset and set the same image fileChangeListener is not fired
        $event.target.value = "";
      };

      myReader.readAsDataURL(file);
    }
    this.isSaved = false;
    this.isLoaded = true;
    this.loading = false;
  }

  SaveCropped() {
    this.loading = true;
    const file: File = this.base64ToFile(this.imgData.image);

    if (this.type === KMSObject.User) {
      this.saveUserAvatar(file);
    } else if (this.type === KMSObject.Network) {
      this.saveNetworkAvatar(file);
    } else if (this.type === KMSObject.IK) {
      this.saveIKAvatar(file);
    } else if (this.type === KMSObject.Hashtag) {
      this.saveHashtagAvatar(file);
    } else if (this.type === KMSObject.Idea) {
      this.saveIKAvatar(file, KMSObject.Idea);
    } else if (this.type === KMSObject.SuccessStory) {
      this.saveIKAvatar(file, KMSObject.SuccessStory);
    } else if (this.type === KMSObject.Challenge) {
      this.saveIKAvatar(file, KMSObject.Challenge);
    }
    // TODO call a service that use blob storege for save image
  }

  Cancel() {
    this.isLoaded = false;
    this.isSaved = false;
    this.endCropping.emit(true);
    this.cdRef.markForCheck();
  }

  async saveUserAvatar(file: File) {
    try {
      const action: Promise<any> = this.userSvc
        .uploadUserAvatar(file, this.imageKey.value)
        .toPromise();

      const response = await action;

      await this.notificationSvc
        .waitForTransactionResult(response.transactionId)
        .toPromise();

      this.imageUrl.setValue(response.payload);
      this.isSaved = true;
      this.isLoaded = false;
      this.loading = false;
      this.endCropping.emit(true);
      this.cdRef.markForCheck();
    } catch (e) {
      this.loading = false;
      this.isSaved = false;
      this.isLoaded = false;
      this.cdRef.markForCheck();
    }
  }

  saveNetworkAvatar(file: File) {
    this.networkSvc.uploadNetworkAvatar(file, this.imageKey.value).subscribe(
      url => {
        this.imageUrl.setValue(url);
        this.isSaved = true;
        this.isLoaded = false;
        this.loading = false;
        this.endCropping.emit(true);
        this.cdRef.markForCheck();
      },
      error => {
        this.loading = false;
        this.isSaved = false;
        this.isLoaded = false;
        this.cdRef.markForCheck();
      }
    );
  }

  saveIKAvatar(file: File, type = KMSObject.IK) {
    this.ikSvc.uploadIkAvatar(file, this.imageKey.value, type).subscribe(
      url => {
        this.imageUrl.setValue(url);
        this.isSaved = true;
        this.isLoaded = false;
        this.loading = false;
        this.endCropping.emit(true);
        this.cdRef.markForCheck();
      },
      error => {
        this.loading = false;
        this.isSaved = false;
        this.isLoaded = false;
        this.cdRef.markForCheck();
      }
    );
  }

  saveHashtagAvatar(file: File) {
    this.hashtagSvc.uploadHashtagAvatar(file, this.imageKey.value).subscribe(
      url => {
        this.imageUrl.setValue(url);
        this.isSaved = true;
        this.isLoaded = false;
        this.loading = false;
        this.endCropping.emit(true);
        this.cdRef.markForCheck();
      },
      error => {
        this.loading = false;
        this.isSaved = false;
        this.isLoaded = false;
        this.cdRef.markForCheck();
      }
    );
  }

  base64ToFile(base64String): File {
    base64String = base64String.replace(/^data:/, "");

    const MIMEType = base64String.match(/image\/[^;]+/);
    const base64 = base64String.replace(/^[^,]+,/, "");
    // const arrayBuffer = new ArrayBuffer(base64.length);
    // const typedArray = new Uint8Array(arrayBuffer);
    const byteCharacters = atob(base64);
    const fileName = "Avatar." + MIMEType[0].split("/")[1];

    const sliceSize = 512;
    const byteArrays = [];

    // for (let i = 0; i < base64.length; i++) {
    //   typedArray[i] = base64.charCodeAt(i);
    // }

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new File(byteArrays, fileName, { type: MIMEType[0] });
  }

  BlobToBase64(blob: Blob) {
    const reader = new FileReader();
    let base64data;
    reader.onloadend = function() {
      base64data = reader.result;
    };
    reader.readAsDataURL(blob);

    return base64data;
  }
}
