import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewEncapsulation
} from "@angular/core";

import { QueryBuilderConfig } from "angular2-query-builder";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ConfigService } from "@ngx-config/core";
import { SnackbarService } from "ngx-snackbar";

import {
  SearchService,
  KMSSearchResults
} from "src/app/services/search.service";
import { NotificationService } from "src/app/services/notification.service";
import { LoggerService } from "src/app/services/logger.service";
import { PostReconcileEditComponent } from "./post-reconcile-edit/post-reconcile-edit.component";
import { Post } from "src/app/commons/entities";
import { BusinessUnitService } from "src/app/services/business-unit.service";
import { Constants } from "src/app/commons/util";

@Component({
  selector: "kms-admin-post-reconcile",
  templateUrl: "./post-reconcile.component.html",
  styleUrls: ["./post-reconcile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PostReconcileComponent implements OnInit {
  queryConfig: QueryBuilderConfig = {
    fields: {
      author: {
        name: "Author",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      description: {
        name: "Description",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      authorCity: {
        name: "Author City",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      authorCompany: {
        name: "Author Company",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      created: {
        name: "Creation Date",
        type: "date",
        operators: ["<", "<=", ">", ">="]
      }
    }
  };
  modalRef: BsModalRef;
  posts: Observable<Post[]>;
  postList: Post[];
  loadingIndicator = false;
  selected: Post[] = [];
  isModified = false;
  limit = Constants.TABLE_LIMITS;
  searchQuery: string = null;

  constructor(
    private readonly modalSvc: BsModalService,
    private searchSvc: SearchService,
    private cd: ChangeDetectorRef,
    private readonly businessUnitSvc: BusinessUnitService,
    private readonly notificationSvc: NotificationService,
    private readonly config: ConfigService,
    private readonly snackbarSvc: SnackbarService,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.search(null);
  }

  async search(query: string) {
    this.loadingIndicator = true;
    this.postList = [];
    this.searchQuery = query;
    this.selected = [];
    const filter = {
      filter:
        (query ? "(" + query + ") and " : "") + "authorBusinessUnit eq null",
      top: 1000
    };

    this.posts = this.searchSvc.queryRaw("Posts", "*", filter).pipe(
      map((posts: KMSSearchResults<Post>) =>
        posts.results.map(r => r.document)
      ),
      tap(() => (this.loadingIndicator = false))
    );
    this.posts.subscribe(list => {
      this.postList = list;
      this.cd.markForCheck();
    });
  }

  async saveAllClick() {
    let action: Promise<any>;
    this.isModified = false;
    const requestList = this.postList
      .filter(post => {
        return post && post.authorBusinessUnit;
      })
      .map(post => ({
        item1: post.id,
        item2: post.authorBusinessUnit
      }));

    if (requestList.length > 0) {
      action = this.businessUnitSvc
        .setPostBusinessUnits(requestList)
        .toPromise();
      const transactionId = await action;

      // await this.notificationSvc
      //   .waitForTransactionResult(transactionId)
      //   .toPromise();

      this.loadingIndicator = true;
      this.postList = [];
      this.cd.markForCheck();

      setTimeout(() => {
        this.search(this.searchQuery);
      }, 2000);
    }
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  editSelectedClick() {
    const initialState = {
      posts: this.selected,
      title: "Edit Selected Posts",
      confirmBtnName: "Confirm",
      closeBtnName: "Cancel"
    };
    this.modalRef = this.modalSvc.show(PostReconcileEditComponent, {
      initialState,
      class: "modal-transparent modal-md mt-6"
    });
    this.modalRef.content.onClose.subscribe((result: string) => {
      this.selected.forEach((element: Post) => {
        element.authorBusinessUnit = result;
        this.postList.find(
          post => post.id === element.id
        ).authorBusinessUnit = result;
        this.postList = [...this.postList];
        this.isModified = true;
      });
      this.cd.markForCheck();
    });
  }

  openConversation(post: any) {
    // console.log(post);
    const postId = post ? post.rootPostId : null;
    if (postId != null) {
      window.open(
        `${this.config.getSettings().portalWebUrl}conversation/${postId}/true`,
        "_blank"
      );
    } else {
      this.logger.warning(`Cannot find the selected post: ${post}`);
      this.snackbarSvc.add({
        msg: `Cannot find the selected post`,
        background: "red",
        action: { text: null }
      });
    }
  }
}
