<tabset>
  <tab heading="Business Units">
    <kms-admin-card>
      <kms-admin-business-unit-list></kms-admin-business-unit-list>
    </kms-admin-card>
  </tab>
  <tab heading="Posts Reconcile">
    <kms-admin-card>
      <kms-admin-post-reconcile></kms-admin-post-reconcile>
    </kms-admin-card>
  </tab>
  <tab heading="Knowledge Nuggets Reconcile">
    <kms-admin-card>
      <kms-admin-ik-reconcile [IkType]="impactingKnowledgeType?.KnowledgeNugget"></kms-admin-ik-reconcile>
    </kms-admin-card>
  </tab>
  <tab heading="Ideas Reconcile">
    <kms-admin-card>
      <kms-admin-ik-reconcile [IkType]="impactingKnowledgeType?.Idea"></kms-admin-ik-reconcile>
    </kms-admin-card>
  </tab>
  <tab heading="Challenges Reconcile">
    <kms-admin-card>
      <kms-admin-ik-reconcile [IkType]="impactingKnowledgeType?.Challenge"></kms-admin-ik-reconcile>
    </kms-admin-card>
  </tab>
  <tab heading="Success Stories Reconcile">
    <kms-admin-card>
      <kms-admin-ik-reconcile [IkType]="impactingKnowledgeType?.SuccessStory"></kms-admin-ik-reconcile>
    </kms-admin-card>
  </tab>
  <tab heading="Webinar Reconcile">
    <kms-admin-card>
      <kms-admin-ik-reconcile [IkType]="impactingKnowledgeType?.Webinar"></kms-admin-ik-reconcile>
    </kms-admin-card>
  </tab>
</tabset>
