import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";

@Component({
  selector: "kms-admin-business-unit-edit",
  templateUrl: "./business-unit-edit.component.html",
  styleUrls: ["./business-unit-edit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessUnitEditComponent implements OnInit {
  name: string;
  grouping: string;
  customBU: string;
  title: string;
  confirmBtnName: string;
  closeBtnName: string;
  bus: any[];
  public onClose: Subject<any>;

  constructor(public bsModalRef: BsModalRef) {
    this.onClose = new Subject();
  }

  ngOnInit() {}

  onConfirmClick() {
    const tuple = {
      item1: this.name,
      item2: this.grouping,
      item3: this.customBU
    };

    this.onClose.next(tuple);
    this.bsModalRef.hide();
  }
}
