<tabset>
    <tab heading="Event Scores" id="tab1">
      <kms-admin-card>
        <kms-admin-gamification></kms-admin-gamification>
      </kms-admin-card>
    </tab>
    <tab heading="Levels">
      <kms-admin-card>
        <kms-admin-medal></kms-admin-medal>
      </kms-admin-card>
    </tab>
  </tabset>
  