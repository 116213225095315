import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  EventEmitter,
  Output
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ConfigService } from "@ngx-config/core";
import Quill from "quill";
import ImageResize from "quill-image-resize-module";
import { from } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";

Quill.register("modules/imageResize", ImageResize);
Quill.register("attributors/class/background", true);
Quill.register("attributors/class/color", true);
Quill.register("attributors/style/size", true);

@Component({
  selector: "kms-admin-rich-text-editor-widget",
  templateUrl: "./rich-text-editor-widget.component.html",
  styleUrls: ["./rich-text-editor-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RichTextEditorWidgetComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() propertyName: string;
  @Input() placeholder: string;
  @Input() isEditable: boolean;

  @Output() textChanged = new EventEmitter<any>();

  config: AngularEditorConfig;
  editor: Quill;
  imgLoading = false;
  modules = {};
  constructor(
    private readonly configSvc: ConfigService,
    private readonly configurationSvc: ConfigurationService,
    private readonly cd: ChangeDetectorRef
  ) {
    this.modules = {
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ align: [] }],
          ["clean"],
          ["image"],
          ["link"],
          [{ "color": [] }, { "background": [] }]
        ],
        handlers: {
          // handlers object will be merged with default handlers object
          image: () => {
            this.imageHandler();
          }
        }
      },
      imageResize: {}
    };

  }

  ngOnInit() {
    const { apiPortalUrlPrefix } = this.configSvc.getSettings();
    // this.config = {
    //   editable: this.isEditable,
    //   spellcheck: true,
    //   height: "15rem",
    //   minHeight: "5rem",
    //   placeholder: this.placeholder,
    //   translate: "no",
    //   uploadUrl: `${apiPortalUrlPrefix}content/upload`
    // };
  }
  created(editorInstance) {
    this.editor = editorInstance;
    this.editor.on('text-change', this.handleTextChange);
  }

  handleTextChange = (delta, oldDelta, source) => {
    if (source == 'api') {
      //do nothing
    } else if (source == 'user') {
      //alert("changed "+this.textChanged);
      this.textChanged.emit(1);
    }
  }

  insertToEditor(url: string) {
    // push image url to rich editor.
    const range = this.editor.getSelection();
    this.editor.insertEmbed(range.index, "image", url);
    this.group
      .get(`${this.propertyName}`)
      .setValue(this.quillGetHTML(this.editor.getContents()));
  }
  imageHandler() {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.click();

    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0];

      // file type is only image.
      if (/^image\//.test(file.type)) {
        this.uploadImage(file);
      } else {
        console.warn("You could only upload images.");
      }
    };
  }

  uploadImage(file: File) {
    this.imgLoading = true;
    this.cd.markForCheck();
    const { apiUrlPrefix } = this.configSvc.getSettings();
    const testData: FormData = new FormData();
    testData.append("file_upload", file, file.name);
    // this.httpClient
    //   .post(`${apiUrlPrefix}portal/api/attachments/contents`, testData)
    //   .subscribe((x: any) => {
    //     this.insertToEditor(x.imageUrl);
    //   });
    from(
      fetch(`${apiUrlPrefix}portal/api/attachments/contents`, {
        method: "POST",
        body: testData,
        headers: {
          authorization: "Bearer " + localStorage.getItem("adal.idtoken")
        }
      }).then(res =>
        res.json().then(url => {
          this.imgLoading = false;
          this.insertToEditor(this.configurationSvc.createCDNImageUrl(url));
          this.cd.markForCheck();
        })
      )
    );
  }
  quillGetHTML(inputDelta) {
    const tempCont = document.createElement("div");
    new Quill(tempCont).setContents(inputDelta);
    return tempCont.getElementsByClassName("ql-editor")[0].innerHTML;
  }
}
