import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CardWidgetComponent } from "./card-widget/card-widget.component";

const IMPORTS = [CommonModule];
const DECLARATIONS = [CardWidgetComponent];

@NgModule({
  imports: [...IMPORTS],
  declarations: [...DECLARATIONS],
  exports: [...IMPORTS,
    ...DECLARATIONS
  ]
})
export class CardModule {}
