import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { SnackbarService } from "ngx-snackbar";
import { BsModalRef } from "ngx-bootstrap/modal";

import { UserPermissions, UserBadgeRoles } from "src/app/commons/entities";
import { UserService } from "src/app/services/user.service";
import { LoggerService } from "src/app/services/logger.service";
import { SecurityService } from "src/app/services/security.service";

@Component({
  selector: "kms-admin-badge-edit",
  templateUrl: "./badge-edit.component.html",
  styleUrls: ["./badge-edit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class BadgeEditComponent implements OnInit {
  title: string;
  confirmBtnName: string;
  closeBtnName: string;
  user: UserPermissions;
  form: FormGroup;

  userBadgeRoles = UserBadgeRoles;
  roles = [];
  selectedRoles = [];
  isAdmin = false;

  constructor(
    public bsModalRef: BsModalRef,
    private securitySvc: SecurityService,
    private readonly snackbarSvc: SnackbarService,
    private logger: LoggerService,
    private userSvc: UserService
  ) {}

  ngOnInit() {
    // Dropdown creation from static Enums
    this.roles = Object.keys(this.userBadgeRoles)
      .filter(key => /^\d+$/.test(key))
      .map(key => ({ id: Number(key), name: this.userBadgeRoles[key] }))
      .filter(
        role =>
          role.id === UserBadgeRoles.KMTeam ||
          role.id === UserBadgeRoles.KOEligible ||
          role.id === UserBadgeRoles.WebinarTeam ||
          (this.userSvc.userRoles.admin && role.id === UserBadgeRoles.Admin)
      );

    this.selectedRoles = this.user
      ? this.roles.filter(r => this.user.roleList.indexOf(r.name) >= 0)
      : [];

    this.initForm();
  }

  confirm() {
    this.bsModalRef.hide();
    const values = this.form.value;
    if (values && values.user && values.roles) {
      if (
        values.roles.map(r => r.id).indexOf(UserBadgeRoles.KOEligible) >= 0 ||
        !(
          values.user.roles &&
          values.user.roles.koScopes &&
          values.user.roles.koScopes.length
        )
      ) {
        this.securitySvc
          .updateBadges(values.user.userId, values.roles.map(r => r.id))
          .subscribe(
            () => {
              this.securitySvc.rolesUpdateSubject.next();
            },
            error => {
              this.logger.error(error);
            }
          );
      } else {
        this.logger.warning(
          `This user is a KO, cannot remove KO Eligibility: ${values}`
        );
        this.snackbarSvc.add({
          msg: `This user is a KO, cannot remove KO Eligibility`,
          background: "red",
          action: { text: null }
        });
      }
    } else {
      this.logger.warning(`Selected parameters not valid: ${values}`);
      this.snackbarSvc.add({
        msg: `Selected parameters not valid`,
        background: "red",
        action: { text: null }
      });
    }
  }

  addUser(user: UserPermissions) {
    if (user && user.userId) {
      this.form.get("user").setValue(user);
      this.form.get("user").updateValueAndValidity();
    } else {
      this.removeUser();
    }
  }

  private initForm() {
    this.form = new FormGroup({
      user: new FormControl(this.user, Validators.required),
      roles: new FormControl(this.selectedRoles)
    });
  }

  private removeUser() {
    this.form.get("user").setValue(null);
    this.form.get("user").updateValueAndValidity();
  }
}
