import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from "@angular/core";

@Component({
  selector: "kms-admin-configuration-page",
  templateUrl: "./configuration-page.component.html",
  styleUrls: ["./configuration-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ConfigurationPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
