<ng-container *ngIf="networkList.enabled && !isLoading">
  <div class="d-flex">
    <ng-select
      *ngIf="!(hasAll && showAllNetworks)"
      class="post-audience px-0"
      [ngClass]="{ 'col-12': enableSelectAll, 'col-12': !enableSelectAll }"
      [items]="networks$ | async"
      bindLabel="name"
      [addTag]="false"
      [multiple]="true"
      [hideSelected]="true"
      [loading]="loading"
      [placeholder]="placeholder"
      [dropdownPosition]="dropdownPosition"
      [typeahead]="input$"
      [ngModel]="networks"
      (add)="addNetwork($event)"
      (remove)="removeNetwork($event)"
      (clear)="clear()"
      [clearable]="edit"
      [searchable]="edit"
    >
      <!--         [ngModel]="networks"
        (remove)="removeNetwork($event)"
        [clearable]="edit"
        [searchable]="edit"-->
    </ng-select>
    <ng-select
      class="post-audience col-5"
      *ngIf="hasAll && showAllNetworks"
      [placeholder]="placeholder + ' <<All>> '"
    ></ng-select>

    <ng-container *ngIf="enableSelectAll">
      <button
        *ngIf="!(hasAll && showAllNetworks)"
        [disabled]="hasAll"
        class="col-1"
        type="button"
        (click)="addAll()"
        tooltip="Add all cops"
        class="ml-1 p-2 btn btn-raised btn-primary"
      >
        <!-- <i class="m-0 fa fa-plus-square"></i> -->
        ALL
      </button>
      <button
        *ngIf="hasAll && showAllNetworks"
        class="col-1"
        type="button"
        tooltip="Add all cops"
        class="ml-1 p-2 btn btn-raised btn-primary"
        (click)="showNetworks()"
      >
        <!-- <i class="m-0 fa fa-plus-square"></i> -->
        View All and choose
      </button>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="networkList.disabled && !isLoading">
  <ng-container *ngFor="let item of networks">
    <a class="mr-3" [routerLink]="getLink(item)">
      <kms-avatar-view
        data-toggle="tooltip"
        data-placement="top"
        tooltip="{{ item.name }}"
        [extraSmallVersion]="true"
        [imageUrl]="item.logoUrl"
        [type]="KMSObject.Network"
        class="rounded-circle"
      ></kms-avatar-view>
    </a>
  </ng-container>
</ng-container>

<ng-container *ngIf="isLoading"
  ><i class="fa fa-spinner fa-spin blue-icon" aria-hidden="true"></i
  >&nbsp;Loading
  {{ placeholder }}
</ng-container>
