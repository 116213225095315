<div class="card">
  <div class="card-body">
    <query-builder [(ngModel)]="query" [classNames]="bootstrapClassNames" [config]="config" [emptyMessage]="'A ruleset cannot be empty. Please add a rule or remove it.'">
      <div class="col-auto" *queryInput="let rule; type: 'textarea'">
        <textarea class="form-control" [(ngModel)]="rule.value"
          placeholder="Custom Textarea"></textarea>
      </div>
    </query-builder>
  </div>
  <div class="card-footer">
    <button type="button" class="btn btn-primary btn-raised mr-3" (click)="emitQuery()">
      Query
    </button>
    <button type="button" class="btn btn-warning btn-raised mr-3" (click)="reset()">Reset</button>
  </div>
</div>