import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { KMSImageCropperComponent } from "./image-cropper/image-cropper.component";
import { ImageCropperModule as NgxImageCropperModule } from "ngx-img-cropper";
import { CoreModule } from "../core/core.module";

const IMPORTS = [CommonModule, NgxImageCropperModule, CoreModule];
const DECLARATIONS = [KMSImageCropperComponent];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...IMPORTS],
  exports: [...IMPORTS, ...DECLARATIONS]
})
export class ImageCropperModule {}
