<kms-admin-query-builder
  [config]="queryConfig"
  (queryEvent)="search($event)"
></kms-admin-query-builder>



<div class="text-right">
  <button
      type="button"
      class="btn btn-primary btn-raised mr-3"
      (click)="openMergeNetwork()"
      [disabled]="selectedNetCount < 2"      
    >
      Merge
    </button>
  <button
    type="button"
    class="btn btn-primary btn-raised mr-3"
    (click)="onCreate()"
  >
    Create
  </button>
  
</div>

<kms-admin-card class="inner-card" animation="mt-2">
  <ngx-datatable
    class="bootstrap"
    [rows]="networks | async"
    [columnMode]="'force'"
    [rowHeight]="'auto'"
    [footerHeight]="'auto'"
    [limit]="limit"
    [messages]="{emptyMessage: '',totalMessage: 'total',selectedMessage: 'selected'}"
    [sorts]="[{ prop: 'name', dir: 'asc' }]"
  >
  <ngx-datatable-column
  [width]="30"
  [sortable]="false"
  [canAutoResize]="false"
  [draggable]="false"
  [resizeable]="false"
>
    <ng-template ngx-datatable-cell-template
      let-network="row" 
    >
        <input 
          [hidden]="network.type !== 'CoP' || network.status === 'Closed'"
          type="checkbox"
          (change)="selectNet(network?.id)"
        />
    </ng-template>
  </ngx-datatable-column>
    <ngx-datatable-column
      [width]="30"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template let-network="row" ngx-datatable-cell-template>
      <!--[hidden]="network.status === 'Merged'"-->
        <a     
          [hidden]="network.status === 'Closed'"
          [tooltip]="'Edit'"
          containerClass="tooltip-class"
          container="body"
          class="edit-icon"
          (click)="onEdit(network?.id)"
         
        >
          <i class="fa fa-pencil fa-lg pencil"></i>
        </a>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Name" prop="name">
      <ng-template let-name="value" ngx-datatable-cell-template>
        <div
          [tooltip]="name"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ name }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Hashtag" prop="hashtag">
      <ng-template let-hashtag="value" ngx-datatable-cell-template>
        <div
          [tooltip]="hashtag"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ hashtag }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Modified Date" prop="modified">
        <ng-template let-modified="value" ngx-datatable-cell-template>
          <div
            [tooltip]="modified"
            containerClass="tooltip-class"
            container="body"
            class="fluid-text"
          >
            {{ modified | amDateFormat: "Do MMM 'YY, HH:mm" }}
          </div>
        </ng-template>
      </ngx-datatable-column>
    <ngx-datatable-column [width]="110" [canAutoResize]="false" name="Type" prop="type">
      <ng-template let-type="value" ngx-datatable-cell-template>
        <div
          [tooltip]="type"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ type }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [width]="110" [canAutoResize]="false" name="Status" prop="status">
      <ng-template let-status="value" ngx-datatable-cell-template>
        <div
          [tooltip]="status"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ status }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Facilitator/#KMS Focal Point/Referents" prop="facilitators">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div
          [tooltip]="value ? value.join(', ') : ''"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ value ? value.join(", ") : "" }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Co-facilitator/Other KM Referents/Core Team/Members" prop="coFacilitators">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div
          [tooltip]="value ? value.join(', ') : ''"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ value ? value.join(", ") : "" }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Knowledge Owners" prop="koUsers">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div
          [tooltip]="value ? value.join(', ') : ''"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ value ? value.join(", ") : "" }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [width]="180"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template let-network="row" ngx-datatable-cell-template>
        <button
          class="btn btn-primary btn-raised mt-0 mb-0 pr-1 pl-1"
          (click)="openNetwork(network)"
        >
          Go to network
        </button>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer *ngIf="loadingIndicator"></ngx-datatable-footer>
  </ngx-datatable>

  <div *ngIf="loadingIndicator">
    <kms-admin-spinner [loading]="true"></kms-admin-spinner>
  </div>
</kms-admin-card>
