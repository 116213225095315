import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewEncapsulation
} from "@angular/core";

import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject, concat, of, Observable } from "rxjs";
import {
  map,
  tap,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError,
  take
} from "rxjs/operators";
import { uniq } from "lodash-es";

import {
  SearchService,
  KMSSearchResults
} from "src/app/services/search.service";
import { Post } from "src/app/commons/entities";

@Component({
  selector: "kms-admin-post-reconcile-edit",
  templateUrl: "./post-reconcile-edit.component.html",
  styleUrls: ["./post-reconcile-edit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PostReconcileEditComponent implements OnInit {
  title: string;
  confirmBtnName: string;
  closeBtnName: string;
  posts: Post[];
  public onClose: Subject<string>;

  loadingIndicator = false;
  network$: Observable<any[]>;
  networkInput$ = new Subject<string>();
  network: any = null;

  constructor(
    private searchSvc: SearchService,
    public bsModalRef: BsModalRef,
    private cd: ChangeDetectorRef
  ) {
    this.onClose = new Subject();
  }

  ngOnInit() {
    this.loadBusinessUnits();
  }

  onConfirmClick() {
    if (this.network) {
      this.onClose.next(this.network);
      this.bsModalRef.hide();
    }
  }

  private loadBusinessUnits() {
    this.network$ = concat(
      of([]), // default items
      this.networkInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          this.loadingIndicator = true;
          this.cd.markForCheck();
        }),
        switchMap(term =>
          this.searchSvc
            .queryRaw("BusinessUnits", this.searchSvc.escapingNames(term), {
              select: ["name"],
              searchFields: ["name"],
              top: 100
            })
            .pipe(
              catchError(() => of([])),
              map((searchRes: KMSSearchResults<any>) =>
                uniq(searchRes.results.map(r => r.document).map(r => r.name))
              ), // empty list on error
              take(10),
              tap(() => {
                this.loadingIndicator = false;
                this.cd.markForCheck();
              })
            )
        )
      )
    );
  }
}
