import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RichTextEditorWidgetComponent } from "./rich-text-editor-widget/rich-text-editor-widget.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { HttpClientModule } from "@angular/common/http";
import { QuillModule } from "ngx-quill";
import { CoreModule } from "../core/core.module";
const IMPORTS = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  AngularEditorModule,
  HttpClientModule,
  QuillModule,
  CoreModule
];
const DECLARATIONS = [RichTextEditorWidgetComponent];
@NgModule({
  imports: [...IMPORTS],
  declarations: [...DECLARATIONS],
  exports: [...IMPORTS, ...DECLARATIONS]
})
export class RichTextEditorModule {}
