import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef
} from "@angular/core";

import { FormGroup, FormControl, Validators } from "@angular/forms";

import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
  map
} from "rxjs/operators";
import { SnackbarService } from "ngx-snackbar";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject, Observable, concat, of } from "rxjs";

import {
  Network,
  NetworkInfo,
  GuestUser,
  GuestUsersCreatePayload,
  NetworkType,
  User,
  PeoplePickerUserRestriction
} from "src/app/commons/entities";
import {
  KMSSearchResults,
  SearchService
} from "src/app/services/search.service";
import { LoggerService } from "src/app/services/logger.service";
import { SecurityService } from "src/app/services/security.service";
import { NotificationService } from "src/app/services/notification.service";
import { UserService } from "src/app/services/user.service";
import { stringify } from "querystring";
import { JsonPipe } from "@angular/common";

@Component({
  selector: "kms-admin-guest-whitelist-edit",
  templateUrl: "./guest-whitelist-edit.component.html",
  styleUrls: ["./guest-whitelist-edit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class GuestWhitelistEditComponent implements OnInit {
  title: string;
  confirmBtnName: string;
  closeBtnName: string;
  user: GuestUser;
  form: FormGroup;

  network$: Observable<any[]>;
  networkLoading = false;
  networkInput$ = new Subject<string>();
  network = [];

  showWarning = false;
  warningMessage = "";
  warningMessageCsv = "";
  isMailConfirmed = false;
  existingUser: GuestUser = null;
  guestUsersCreatePayload: GuestUsersCreatePayload = null;
  // externalUserPayload:ExternalUserPayload
  isCsvView = false;
  addMode = true;
  fileUploading = false;
  progressLabel = ""

  public onClose: Subject<boolean>;
  public openUser: Subject<GuestUser>;

  private existingNetworks = [];

  referentRestriction = PeoplePickerUserRestriction.onlyHRE;

  constructor(
    public bsModalRef: BsModalRef,
    private securitySvc: SecurityService,
    private searchSvc: SearchService,
    private userSvc: UserService,
    private readonly snackbarSvc: SnackbarService,
    private logger: LoggerService,
    private cd: ChangeDetectorRef,
    private notificationSvc: NotificationService
  ) {}

  ngOnInit() {
    this.onClose = new Subject();
    this.openUser = new Subject();
    this.loadNetworks();
    this.initForm();
  }

  async confirm() {
    let action: Promise<any>;
    const values = this.form.value;

    var inError = false;

    if(this.isCsvView){

      if(values &&
        values.networks &&
        values.referent &&
        this.guestUsersCreatePayload!=null){
          try{

            this.guestUsersCreatePayload.networks = values.networks;
            this.guestUsersCreatePayload.employeeReference = values.referent;

            //TODO
            alert("We are going to send the invite request for "+this.guestUsersCreatePayload.guestUsers.length+" guest users. Click Ok to continue.\n\nPlease consider the action will take some time but please wait for completion message.");

            this.progressLabel = "";
            this.fileUploading = true;
            this.cd.markForCheck();

            //note that the following will return always an empty list as server side the control is disabled as it's performed on ValidatedSingleUserFromCsvOrNull during csv parsing
            var skippedUsers: Array<GuestUser> = await this.securitySvc
              .createGuestUserByPayload(this.guestUsersCreatePayload).toPromise();

            alert("Guest Users invitation or update completed successfully.");

            this.fileUploading = false;
            this.cd.markForCheck();

            if(skippedUsers.length>0){//never reached as per above note

              alert("The following users were skipped because already existent.\n"+
                skippedUsers.map(sku => sku.email).join(", "));
            }
            this.bsModalRef.hide();

          } catch (error) {
            this.logger.error(error);
            this.notificationSvc.showDetailsError(error);
          }
        }else{
          inError = true;
        }
    }
    else{

      if (
        values &&
        values.networks &&
        (this.user || values.mail) &&
        ((this.user && this.user.userId) ||
          (this.existingUser && this.existingUser.displayName) ||
          values.displayName)
      ) {
        try {
          const user: GuestUser = this.remapUser();
          if (this.user) {
            user.id = this.user.id;
            user.status = this.user.status;          
            action = this.securitySvc.updateGuestUser(user).toPromise();
          } else {
            action = this.securitySvc.createGuestUser(user).toPromise();
          }
          const { transactionId } = await action;

          await this.notificationSvc
            .waitForTransactionResult(transactionId)
            .toPromise();

          // Track event for network update
          if (this.user && this.user.userId) {
            const { networks } = this.form.value;
            const addedNetwork: string[] = networks
              .map(r => r.networkId)
              .filter(
                network =>
                  !this.existingNetworks.map(r => r.networkId).includes(network)
              );
            this.logger.eventMultiple(
              "network.changemembers",
              addedNetwork.map(network => ({
                targetUserId: this.user.userId,
                networkId: network
              }))
            );
          }

          this.onClose.next(true);
          alert(
            "Please be aware that this configuration will take up to 5 minutes to be effective"
          );
          this.bsModalRef.hide();
        } catch (error) {
          //alert("error "+JSON.stringify(error));
          var msg = (error.error.message)?error.error.message:JSON.stringify(error);

          this.logger.error(msg);
          this.notificationSvc.showDetailsError(msg);
          
        }
      } else {
        inError = true;
      }
    }
    if(inError){
      this.logger.warning(`Selected parameters not valid: ${values}`);
      this.snackbarSvc.add({
        msg: `Selected parameters not valid`,
        background: "red",
        action: { text: null }
      });
    }
  }

  addUser(user: any) {
    if (user && user.userId) {
      this.form.get("referent").setValue(user);
      this.form.get("referent").updateValueAndValidity();
    } else {
      this.removeUser();
    }
  }

  changeMail() {
    this.isMailConfirmed = false;
    this.existingUser = null;
    this.form.get("displayName").enable();
    this.form.get("displayName").updateValueAndValidity();
    this.cd.markForCheck();
  }

  changeUser() {
    this.bsModalRef.hide();
    this.openUser.next(this.existingUser);
  }

  async checkMail() {
    this.showWarning = false;
    if (this.form.get("mail") && this.form.get("mail").value) {
      const loadedUser: GuestUser = await this.securitySvc
        .getGuestUserByMail(this.form.get("mail").value)
        .toPromise();

      if (loadedUser && !loadedUser.isDeleted) {
        // user already created
        this.existingUser = loadedUser;
        this.warningMessage =
          "The user you are trying to insert already exists.";
        this.showWarning = true;
      } else if (loadedUser && loadedUser.userId) {
        this.existingUser = loadedUser;
        const user: User = await this.userSvc
          .getUserProfile(loadedUser.userId)
          .toPromise();

        if (user && user.displayName) {
          this.existingUser.displayName = user.displayName;
          this.form.get("displayName").setValue(user.displayName);
          this.form.get("displayName").disable();
          this.form.get("displayName").updateValueAndValidity();
        }

        this.isMailConfirmed = true;
      } else {
        this.isMailConfirmed = true;
      }
    } else {
      // mail not inserted by the user
      this.warningMessage = "Please insert an email";
      this.showWarning = true;
    }
    this.cd.markForCheck();
  }

  private initForm() {
    this.network = this.user ? this.user.networks : null;
    this.existingNetworks = this.user ? this.user.networks : [];
    this.form = new FormGroup({
      mail: new FormControl(
        this.user ? this.user.email : "",
        Validators.required
      ),
      displayName: new FormControl(
        this.user ? this.user.displayName : "",
        Validators.required
      ),
      referent: new FormControl(
        this.user ? this.user.employeeReference : null,
        Validators.required
      ),
      networks: new FormControl(this.network, Validators.required),
      company: new FormControl(
        this.user ? this.user.company : null,
        Validators.required
      )
    });

    if (this.user) {
      //here it's an invocation for edit user
      this.addMode = false;
      this.isMailConfirmed = true;
      this.form.get("mail").disable();
      this.form.get("mail").updateValueAndValidity();
      if (this.user.userId) {
        this.form.get("displayName").disable();
        this.form.get("displayName").updateValueAndValidity();
      }
    }
  }

  private loadNetworks() {
    const params = {
      filter: `typeId eq ${NetworkType.External} and status eq 'Published'`
    };
    this.network$ = concat(
      of([]), // default items
      this.networkInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          this.networkLoading = true;
          this.cd.markForCheck();
        }),
        switchMap(term =>
          this.searchSvc.query("Networks", term, params).pipe(
            catchError(() => of([])),
            map((searchRes: KMSSearchResults<Network>) =>
              searchRes.results.map(result => result.document)
            ), // empty list on error
            map(networks => {
              return networks.filter(network => network.typeId === 5);
            }),
            map(networks => {
              return networks.map(
                network =>
                  new NetworkInfo({
                    networkId: (<any>network).id,
                    name: network.name,
                    type: network.typeId
                  })
              );
            }),
            tap(() => {
              this.networkLoading = false;
              this.cd.markForCheck();
            })
          )
        )
      )
    );
  }

  private removeUser() {
    this.form.get("referent").setValue(null);
    this.form.get("referent").updateValueAndValidity();
  }

  private remapUser(): GuestUser {
    const user = new GuestUser();
    const selectedValues = this.form.value;

    user.displayName =
      this.user && this.user.userId
        ? this.user.displayName
        : selectedValues.displayName;
    user.email = this.user ? this.user.email : selectedValues.mail;
    user.employeeReference = selectedValues.referent;
    user.networks = selectedValues.networks;
    user.company = selectedValues.company;
    if (this.existingUser) {
      user.userId = this.existingUser.userId;
      user.upn = this.existingUser.upn;
      user.displayName = user.displayName
        ? user.displayName
        : this.existingUser.displayName;
    }

    return user;
  }

  onTabSelect(sourceTabId: any){
    if(sourceTabId){
      if(sourceTabId == 0){
        //this is the standard one
        this.form.get("mail").setValidators(Validators.required);
        this.form.get("displayName").setValidators(Validators.required);
        this.form.get("company").setValidators(Validators.required);
        this.isCsvView = false;
      }else if(sourceTabId == 1){
        this.form.get("mail").clearValidators();
        this.form.get("displayName").clearValidators();
        this.form.get("company").clearValidators();
        this.isCsvView = true;
      }
      this.guestUsersCreatePayload = null;
      this.isMailConfirmed = false;
      this.form.get("mail").updateValueAndValidity();
      this.form.get("displayName").updateValueAndValidity();
      this.form.get("company").updateValueAndValidity();
      this.cd.markForCheck();
    }
  }


  uploadingProgress(isUploading: boolean){
    if(isUploading){
      this.fileUploading = true;
      this.cd.markForCheck();
    }
  }

  async updateFile(guestUsersCreatePayload: any) {

    if(guestUsersCreatePayload.message){
      alert("An error has occurred\n\n"+ guestUsersCreatePayload.message);
      this.guestUsersCreatePayload = null;
      this.fileUploading = false;
    }else if(guestUsersCreatePayload.guestUsers.length>0){

      //retrieve singularly each user if valid. Done for reducing load on cosmos...
      var aa = 0;
      var total = guestUsersCreatePayload.guestUsers.length;
      //alert("total "+total);

      for(var i=0; i<total; i++){
        
      //guestUsersCreatePayload.guestUsers.forEach(element => {

        var element = guestUsersCreatePayload.guestUsers[i];

        var validGuest = await this.userSvc.ValidatedSingleUserFromCsvOrNull(element, false);//.then((validGuest: GuestUsers) => {

            //alert("validGuest is "+validGuest);

            if(validGuest!=undefined && validGuest.email.indexOf("_manual") == -1){
              //alert("managing " + guestUsersCreatePayload.guestUsers[i].email + " valid guest "+validGuest.email);
              for(var j=0; j<guestUsersCreatePayload.guestUsers.length; j++){
                if(guestUsersCreatePayload.guestUsers[j] != null && guestUsersCreatePayload.guestUsers[j].email == validGuest.email){
                  guestUsersCreatePayload.guestUsers[j] = validGuest;
                  break;
                }
              }
            }else{
              //alert("user null for "+element.email);
              for(var j=0; j<guestUsersCreatePayload.guestUsers.length; j++){
                if(guestUsersCreatePayload.guestUsers[j] != null && guestUsersCreatePayload.guestUsers[j].email == element.email){
                  //guestUsersCreatePayload.guestUsers.splice(j,1);//otherwise the lenght will be reduced and some items will be skipped
                  guestUsersCreatePayload.guestUsers[j] = null;
                  guestUsersCreatePayload.skippedAsNotGuestUsers.push(element);
                  break;
                }
              }
            }
            aa++;
            this.progressLabel = `${Math.round(aa / total * 100)}%`
            this.cd.markForCheck();
          //}
        //);
      //});
          }
      //alert("after "+aa);
      this.progressLabel = "100%";
      this.cd.markForCheck();
      //cleanup
      var newGuestArray = Array<GuestUser>();

      for(var i=0; i<guestUsersCreatePayload.guestUsers.length; i++){
        if(guestUsersCreatePayload.guestUsers[i]!=null)
          newGuestArray.push(guestUsersCreatePayload.guestUsers[i]);
      }

      guestUsersCreatePayload.guestUsers = newGuestArray;

      var kmsUserEmailsMsg = "";
      if(guestUsersCreatePayload.skippedAsNotGuestUsers.length > 0){

        //these are KMS users so they will be not considered
        kmsUserEmailsMsg = guestUsersCreatePayload.skippedAsNotGuestUsers.map(u =>{ 
          var idx = u.email.indexOf("_manual");

          if(idx!=-1){
            //alert("manage manually "+u.email);
            return "Manage manually -> " + u.email.substring(0, idx);
          }else
            return u.email;

        }).join("\n");
      }

      var simplyToUpdateUsersMsg = "";
      if(guestUsersCreatePayload.guestUsers.length > 0){

        //these are user to simply update - aka no invitation email
        simplyToUpdateUsersMsg = guestUsersCreatePayload.guestUsers.filter(u => u.toSimplyUpdate).map(u =>{ 

            return u.email;

        }).join("\n");
      }


      if(guestUsersCreatePayload.guestUsers.length>0){

        this.guestUsersCreatePayload = guestUsersCreatePayload;
        //alert(simplyToUpdateUsersMsg);

        var msg = "CSV file loaded successfuly.\nIdentified '"+(this.guestUsersCreatePayload.guestUsers.length - this.guestUsersCreatePayload.guestUsers.filter(u => u.toSimplyUpdate).length)+"' candidate Guest Users to be invited when you click 'SAVE'\n\n"+((simplyToUpdateUsersMsg.length>0)?"Please note the following users will be simply updated without sending the invitation email:\n"+simplyToUpdateUsersMsg:"Please note all identified users will receive the invitation email.");

        alert(msg);

        if(kmsUserEmailsMsg.length>0){
          alert("Please note the following users are standard KMS Users OR need to be managed manually.\nAnyone of the following users will not be considered at all when you click SAVE\nThe right section for adding these users is 'Networks'.\n\n"+kmsUserEmailsMsg);
        }
     
        this.isMailConfirmed = true;
        this.cd.markForCheck();
      }else{
        alert("No potential Guest Users were identified on the CSV.\n"+ (kmsUserEmailsMsg.length>0)?"Following users were found but they are standard KMS Users\n\n"+kmsUserEmailsMsg:"Is really populated the CSV?");
      }

      this.fileUploading = false;
    }else{
      alert("No user identified identified on the CSV.\nDoes the file contains entries?");
    }
  }
}
