import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from "@angular/core";
import { Medal, MedalSettings } from "src/app/commons/entities";
import { ConfigurationService } from "src/app/services/configuration.service";
import { NotificationService } from "src/app/services/notification.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { MedalModalComponent } from "./medal-modal/medal-modal.component";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray
} from "@angular/forms";
import { Constants } from "src/app/commons/util";

@Component({
  selector: "kms-admin-medal",
  templateUrl: "./medal.component.html",
  styleUrls: ["./medal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedalComponent implements OnInit {
  medalSettings: MedalSettings;
  editing = false;
  loadingIndicator = false;
  limit = Constants.TABLE_LIMITS;
  medalListFA: FormArray;
  modalRef: BsModalRef;

  get isSaveDisabled() {
    return this.medalListFA.invalid;
  }

  constructor(
    private readonly configSvc: ConfigurationService,
    private readonly cd: ChangeDetectorRef,
    private readonly modalSvc: BsModalService,
    private readonly notificationSvc: NotificationService,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit() {
    this.fetch();
  }

  fetch() {
    this.loadingIndicator = true;
    this.configSvc.getGamificationMedals().subscribe(val => {
      this.loadingIndicator = false;
      this.medalSettings = val;
      this.createMedalFormArray(val);
      this.cd.markForCheck();
    });
  }

  cancelClick() {
    this.editing = false;
    this.fetch();
  }

  async saveClick() {
    try {
      this.editing = false;
      let action: Promise<any>;
      this.medalSettings.medals = this.medalListFA.value;
      action = this.configSvc
        .saveGamificationMedalsEvent(this.medalSettings)
        .toPromise();
      const { transactionId } = await action;
      await this.notificationSvc
        .waitForTransactionResult(transactionId)
        .toPromise();

      this.fetch();
    } catch (e) {
      this.notificationSvc.showDetailsError(e);
    }
  }

  openModal() {
    const initialState = {
      title: "Add a new level",
      confirmBtnName: "Save",
      closeBtnName: "Cancel"
    };
    this.modalRef = this.modalSvc.show(MedalModalComponent, {
      initialState,
      class: "modal-transparent modal-md mt-6"
    });
    this.modalRef.content.onClose.subscribe(val => {
      this.medalListFA.push(val);
      this.saveClick();
    });
  }

  createMedalFormArray(array) {
    this.medalListFA = new FormArray([]);
    array.medals.forEach(val => {
      this.medalListFA.push(new Medal(val).toFormGroup(this.fb));
    });
    this.cd.markForCheck();
  }

  onDelete(medal) {
    if (
      confirm(`Are you sure you want to delete the item ${medal.value.name}`)
    ) {
      const index = this.medalListFA.value.findIndex(
        x => x.name === medal.value.name
      );
      this.medalListFA.removeAt(index);
      this.saveClick();
    }
  }
}
