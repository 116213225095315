import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[kmsAdminNumberDirective]"
})
export class NumberDirectiveDirective {
  constructor() {}

  @HostListener("keydown", ["$event"])
  onKeydown(ev: KeyboardEvent) {
    if (ev.key === "e") {
      ev.preventDefault();
    }
  }
}
