<div class="card-trasparent">
  <div class="card-body">
    <div class="img-container container-margin">
      <div class="img-container" *ngIf="!loading">
        <div class="img-container" *ngIf="isLoaded">
          <img-cropper #cropper [image]="imgData" [settings]="cropperSetting"></img-cropper>
        </div>
        <div class="img-container" *ngIf="isSaved">
          <span class="result rounded" *ngIf="imgData.image">
            <img [src]="imgData.image" [width]="cropperSetting.croppedWidth" [height]="cropperSetting.croppedHeight">
          </span>
        </div>
      </div>
      <div class="img-container" *ngIf="loading">
        <kms-admin-spinner [loading]="loading" [class]="'primary'"></kms-admin-spinner>
      </div>
    </div>
    <div class="file-upload">
      <span class="col-lg-2">
        <input id="custom-input" #uploader  type="file" (change)="fileChangeListener($event)">
        <label for="custom-input" class="color-white label-btn btn-circle-sm btn-circle btn-circle-raised btn-circle-info ms-upload-pointer">
          <i class="fa fa-upload" aria-hidden="true"></i>
        </label>
      </span>
      <span class="col-lg-2" >
        <button [disabled]="!isLoaded" class="btn-circle btn-circle-sm btn-circle-raised btn-circle-info" (click)="SaveCropped()">
          <i class="fa fa-floppy-o" aria-hidden="true"></i>
        </button>
      </span>
      <span class="col-lg-2">
          <button class="btn-circle btn-circle-sm btn-circle-raised btn-circle-info" (click)="Cancel()">
            <i class="fa fa-undo" aria-hidden="true"></i>
          </button>
        </span>
    </div>
  </div>
</div>