import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { GamificationPageComponent } from "./gamification-page/gamification-page.component";
import { RedirectGuard } from "src/app/commons/guards";
import { AuthenticationGuard } from "microsoft-adal-angular6";

const routes: Routes = [
  {
    path: "",
    component: GamificationPageComponent,
    canActivate: [RedirectGuard, AuthenticationGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GamificationRoutingModule {}
