<div *ngIf="imgLoading" id="cover-spin">
  <kms-admin-spinner [loading]="true" class="spin"></kms-admin-spinner>
</div>
<div [formGroup]="group">
  <!-- <angular-editor
    formControlName="{{propertyName}}"
    [config]="config"
  ></angular-editor> -->
  <quill-editor
    [sanitize]="true"
    formControlName="{{ propertyName }}"
    [readOnly]="!isEditable"
    [modules]="modules"
    [style]="{ height: '15rem' }"
    (onEditorCreated)="created($event)"
    [placeholder]="placeholder"
  ></quill-editor>
</div>
