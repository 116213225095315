import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewEncapsulation,
  Input
} from "@angular/core";

import { QueryBuilderConfig } from "angular2-query-builder";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ConfigService } from "@ngx-config/core";
import { SnackbarService } from "ngx-snackbar";

import {
  SearchService,
  KMSSearchResults
} from "src/app/services/search.service";
import { LoggerService } from "src/app/services/logger.service";
import {
  ImpactingKnowledgeType,
  ImpactingKnowledge
} from "src/app/commons/entities";
import { BusinessUnitService } from "src/app/services/business-unit.service";
import { Constants } from "src/app/commons/util";
import { IkReconcileEditComponent } from "./ik-reconcile-edit/ik-reconcile-edit.component";

@Component({
  selector: "kms-admin-ik-reconcile",
  templateUrl: "./ik-reconcile.component.html",
  styleUrls: ["./ik-reconcile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class IkReconcileComponent implements OnInit {
  @Input() IkType: ImpactingKnowledgeType;

  queryConfig: QueryBuilderConfig = {
    fields: {
      hashtag: {
        name: "Hashtag",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      involvedPeopleBUsUser: {
        name: "Involved People",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      name: {
        name: "Name",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      authorCity: {
        name: "Author City",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      authorCompany: {
        name: "Author Company",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      created: {
        name: "Creation Date",
        type: "date",
        operators: ["<", "<=", ">", ">="]
      }
    }
  };
  modalRef: BsModalRef;
  iks: Observable<ImpactingKnowledge[]>;
  ikList: ImpactingKnowledge[];
  loadingIndicator = false;
  selected: ImpactingKnowledge[] = [];
  isModified = false;
  limit = Constants.TABLE_LIMITS;
  searchQuery: string = null;

  private get getModalTitle() {
    switch (this.IkType) {
      case ImpactingKnowledgeType.Idea:
        return "Edit Selected Ideas";
      case ImpactingKnowledgeType.Challenge:
        return "Edit Selected Challenges";
      case ImpactingKnowledgeType.SuccessStory:
        return "Edit Selected Success Stories";
      case ImpactingKnowledgeType.Webinar:
        return "Edit Selected Webinars";
      case ImpactingKnowledgeType.KnowledgeNugget:
      default:
        return "Edit Selected Knowledge Knuggets";
    }
  }

  constructor(
    private readonly modalSvc: BsModalService,
    private searchSvc: SearchService,
    private cd: ChangeDetectorRef,
    private readonly businessUnitSvc: BusinessUnitService,
    // private readonly notificationSvc: NotificationService,
    private readonly config: ConfigService,
    private readonly snackbarSvc: SnackbarService,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.search(null);
  }

  async search(query: string) {
    this.loadingIndicator = true;
    this.ikList = [];
    this.searchQuery = query;
    this.selected = [];

    const filter = {
      filter: (query ? `(${query}) and ` : "") + `typeId eq ${this.IkType}`,
      top: 1000
    };
    this.iks = this.searchSvc.queryRaw("ImpactingKnowledges", "*", filter).pipe(
      map((iks: KMSSearchResults<any>) => iks.results.map(r => r.document)),
      map(iks =>
        iks.filter(
          ik =>
            ik.involvedPeopleBUsName &&
            ik.involvedPeopleBUsName.length > 0 &&
            ik.involvedPeopleBUsName.some(r => !r)
        )
      ),
      map(iks => this.updateIkList(iks)),
      tap(() => (this.loadingIndicator = false))
    );
    this.iks.subscribe(list => {
      this.ikList = list;
      this.cd.markForCheck();
    });
  }

  async saveAllClick() {
    let action: Promise<any>;
    this.isModified = false;
    const requestList = this.ikList
      .filter(ik => {
        return ik && !ik.alreadyPopulatedBU && ik.buName;
      })
      .map(ik => ({
        item1: ik.id,
        item2: ik.involvedPeopleId,
        item3: ik.buName
      }));

    if (requestList.length > 0) {
      action = this.businessUnitSvc
        .setIKBusinessUnits(requestList, this.IkType)
        .toPromise();
      const transactionId = await action;

      // await this.notificationSvc
      //   .waitForTransactionResult(transactionId)
      //   .toPromise();

      this.loadingIndicator = true;
      this.ikList = [];
      setTimeout(() => {
        this.search(this.searchQuery);
      }, 2000);
    }
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(
      ...selected.filter((ik: ImpactingKnowledge) => ik && !ik.buName)
    );
  }

  editSelectedClick() {
    const initialState = {
      posts: this.selected,
      title: this.getModalTitle,
      confirmBtnName: "Confirm",
      closeBtnName: "Cancel"
    };
    this.modalRef = this.modalSvc.show(IkReconcileEditComponent, {
      initialState,
      class: "modal-transparent modal-md mt-6"
    });
    this.modalRef.content.onClose.subscribe((result: string) => {
      this.selected.forEach((element: ImpactingKnowledge) => {
        element.buName = result;
        this.ikList.find(
          kn =>
            kn.id === element.id &&
            kn.involvedPeopleId === element.involvedPeopleId
        ).buName = result;
        this.ikList = [...this.ikList];
        this.isModified = true;
      });
      this.cd.markForCheck();
    });
  }

  openImpactingKnowledge(ik: ImpactingKnowledge) {
    // console.log(ik);
    const ikId = ik ? ik.id : null;
    if (ikId != null) {
      let link = "";
      switch (this.IkType) {
        case ImpactingKnowledgeType.Idea:
          link = "idea";
          break;
        case ImpactingKnowledgeType.Challenge:
          link = "challenge";
          break;
        case ImpactingKnowledgeType.SuccessStory:
          link = "success-story/view";
          break;
        case ImpactingKnowledgeType.Webinar:
          link = "webinars/list/video";
          break;
        case ImpactingKnowledgeType.KnowledgeNugget:
          link = "ik";
          break;
      }

      window.open(
        `${this.config.getSettings().portalWebUrl}${link}/${ikId}`,
        "_blank"
      );
    } else {
      this.logger.warning(`Cannot find the selected ik: ${ik}`);
      this.snackbarSvc.add({
        msg: `Cannot find the selected ik`,
        background: "red",
        action: { text: null }
      });
    }
  }

  private updateIkList(iks: Array<ImpactingKnowledge>) {
    const newList = new Array<ImpactingKnowledge>();

    iks.forEach(ik => {
      ik.involvedPeopleBUsName.forEach((r, i) => {
        const newIk = new ImpactingKnowledge();
        Object.assign(newIk, ik);
        newIk.buName = r;
        newIk.involvedPeopleName = ik.involvedPeopleBUsUser[i];
        newIk.involvedPeopleId = ik.involvedPeopleBUsUserIds[i];
        newIk.alreadyPopulatedBU = !!r;
        newList.push(newIk);
      });
    });

    return newList;
  }
}
