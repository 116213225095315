<ng-container *ngIf="(userSvc.isLoaded | async); else loading">
  <kms-admin-navbar></kms-admin-navbar>
  <div class="page-container">
    <router-outlet></router-outlet>
  </div>
  <ngx-snackbar
    [position]="'bottom-right'"
    [max]="3"
    [timeout]="3000"
    [accent]="'#2196f3'"
  >
  </ngx-snackbar>
</ng-container>

<ng-template #loading>
  <div
    class="loader-page"
    style="margin: 20% auto; max-width: 400px; text-align: center;"
  >
    <span class="kms-logo d-block"></span>
    <div class="loader-message">
      <kms-admin-spinner loading="true" [class]="'small-loader'"></kms-admin-spinner>
      Bringing you to #KMS Admin...
    </div>
  </div>
</ng-template>
<lib-cookie-consent></lib-cookie-consent>
