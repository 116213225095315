import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  Webinar,
  Chapter,
  VideoInfo,
  InsightsFace,
  TranscriptElement,
  WebinarVideoTranscriptEditInfo,
  GetChapterVideosTranscriptEditsRequest,
  WebinarSlimInfo
} from "../commons/webinars-entities";

@Injectable({
  providedIn: "root"
})
export class WebinarsService {
  constructor(
    private readonly httpClient: HttpClient//,
    //private readonly permissionSvc: NgxPermissionsService
  ) {}

  public getVideo(videoId: string): Promise<any> {
    return this.httpClient
      .get<any>(`webinar/api/ik/webinar/Video/${videoId}`)
      .pipe(catchError(value => of(console.error(value)))).toPromise();
  }

  public async getVideoList() {
    const response = await this.httpClient
      .get(`webinar/api/webinar/VideoList`)
      .toPromise();
    return response;
  }

   public updateWebinar(webinar: Webinar): Observable<string> {
    return this.httpClient.post<string>(
      `webinar/api/ik/webinar/update`,
      webinar
    );
  }

  public getVideoByid(id: string): Promise<Webinar> {
    return (
      this.httpClient
        .get<Webinar>(`webinar/api/ik/webinar/${id}`)
        // .pipe(
        //   map ((val: Webinar) => {
        //     val.chapters = val.chapters.filter(c => !!c.indexed);
        //     return val;
        //   })
        // )
        .toPromise()
    );
  }

  public getVideoNameList(): Promise<Chapter[]> {
    return this.httpClient
      .get<Chapter[]>(`webinar/api/ik/webinar/Video`)
      .toPromise();
  }

  public updateTranscript(
    transcript: TranscriptElement[],
    videoId: string,
    webinarId: string
  ): Promise<any> {
    return this.httpClient
      .post(
        `webinar/api/ik/webinar/${webinarId}/Video/${videoId}/transcript`,
        transcript
      )
      .toPromise();
  }


  public publishTranscript(
    webinarId: string,
    videoId: string,
    showTranscript: boolean
  ) {
    return this.httpClient
      .post(
        `webinar/api/ik/webinar/${webinarId}/${videoId}/PublishTranscript/${showTranscript}`,
        null
      )
      .toPromise();
  }

  public updateVideoTranscriptEdits(
    transcriptEdits: WebinarVideoTranscriptEditInfo,
    videoId: string,
    chapterId: string
  ): Promise<any> {
    return this.httpClient
      .post(
        `webinar/api/ik/webinar/Video/${videoId}/Chapter/${chapterId}/transcripteditssave`,
        transcriptEdits
      )
      .toPromise();
  }

  public getAllWebimarSlimInfos(webinarNameSuffix: string, itemsCount: number, pageNum: number): Observable<WebinarSlimInfo[]> {
    var request: GetChapterVideosTranscriptEditsRequest = {
      webinarNameSuffix: webinarNameSuffix,
      itemsCount: itemsCount,
      pageNum: pageNum
    };
    // console.log(JSON.stringify(request));
    return this.httpClient
      .post<WebinarSlimInfo[]>(
        `webinar/api/ik/webinar/ListWithEditedTranscripts`,
        JSON.stringify(request)
      );
  }

  
  public generateTranscriptLearningFile(
    webinarId: string
  ): Observable<string[]> {
    return this.httpClient
      .post<string[]>(
        `webinar/api/ik/webinar/Webinar/${webinarId}/generatetranscriptfile`, ''
      )
  }

  // private calculatePermission() {
  //   return Promise.all([
  //     this.permissionSvc.hasPermission(RoleNameVariable.Admin),
  //     this.permissionSvc.hasPermission(RoleNameVariable.WebinarTeam)
  //   ]);
  // }
}
