<div class="text-right">
  <button
    type="button"
    class="btn btn-primary btn-raised mr-3"
    (click)="search()"
  >
    Reload
  </button>
  <button
    type="button"
    class="btn btn-primary btn-raised mr-3"
    (click)="onCreate()"
  >
    Add
  </button>
</div>

<ngx-datatable
  class="bootstrap"
  [rows]="users | async"
  [columnMode]="'force'"
  [rowHeight]="'auto'"
  [footerHeight]="'auto'"
  [limit]="limit"
  [messages]="{
    emptyMessage: '',
    totalMessage: 'total',
    selectedMessage: 'selected'
  }"
  [sorts]="[{ prop: 'displayName', dir: 'asc' }]"
>
  <ngx-datatable-column
    [width]="30"
    [sortable]="false"
    [canAutoResize]="false"
    [draggable]="false"
    [resizeable]="false"
  >
    <ng-template let-user="row" ngx-datatable-cell-template>
      <a
        [tooltip]="'Edit'"
        containerClass="tooltip-class"
        container="body"
        class="clickable-icon"
        (click)="onEdit(user)"
      >
        <i class="fa fa-pencil fa-lg pencil"></i>
      </a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [width]="30"
    [sortable]="false"
    [canAutoResize]="false"
    [draggable]="false"
    [resizeable]="false"
  >
    <ng-template let-user="row" ngx-datatable-cell-template>
      <a
        [tooltip]="'Delete'"
        containerClass="tooltip-class"
        container="body"
        class="clickable-icon"
        (click)="openAlertModal(alertDelete, user)"
      >
        <i class="fa fa-trash-o fa-lg"></i>
      </a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Name" prop="displayName">
    <ng-template let-name="value" ngx-datatable-cell-template>
      <div>{{ name }}</div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Permissions" prop="roleList">
    <ng-template let-role="value" ngx-datatable-cell-template>
      <div>{{ role ? role.join(", ") : "" }}</div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-footer *ngIf="loadingIndicator"></ngx-datatable-footer>
</ngx-datatable>

<div *ngIf="loadingIndicator">
  <kms-admin-spinner [loading]="true"></kms-admin-spinner>
</div>

<ng-template #alertDelete>
  <div class="modal-header">
    <h4 class="modal-title pull-left mt-0">Confirm user delete</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to remove the user?</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary btn-raised"
      (click)="onDelete()"
    >
      Ok
    </button>
    <button
      type="button"
      class="btn btn-warning btn-raised"
      (click)="modalRef?.hide()"
    >
      Cancel
    </button>
  </div>
</ng-template>
