import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Medal } from "src/app/commons/entities";
import { Subject } from "rxjs";

@Component({
  selector: "kms-admin-medal-modal",
  templateUrl: "./medal-modal.component.html",
  styleUrls: ["./medal-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MedalModalComponent implements OnInit {
  title: string;
  confirmBtnName: string;
  closeBtnName: string;
  color: string;
  medal: FormGroup;
  public onClose: Subject<FormGroup>;
  constructor(
    public bsModalRef: BsModalRef,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit() {
    this.onClose = new Subject();
    this.medal = new Medal({
      name: null,
      minPoints: null,
      maxPoints: null,
      cssClass: null
    }).toFormGroup(this.fb);
  }

  onConfirm() {
    this.medal.get("cssClass").setValue(this.color ? this.color : "#000000");
    this.onClose.next(this.medal);
    this.bsModalRef.hide();
  }
}
