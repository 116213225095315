import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ConfigService } from "@ngx-config/core";
import { Observable, of } from "rxjs";
import { Cacheable } from "ngx-cacheable";
import { catchError, map, shareReplay } from "rxjs/operators";

import {
  Network,
  MergedNetwork,
  HashtagInfo,
  NetworkInfo,
  MapDoc,
  NetworkType
} from "src/app/commons/entities";
import { UploadService } from "./upload.service";
import { SearchService, KMSSearchResults } from "./search.service";

@Injectable({
  providedIn: "root"
})
export class NetworkService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly config: ConfigService,
    private readonly uploaderSvc: UploadService,
    private readonly searchSvc: SearchService
  ) {}

  public getNetworkById(networkId: string): Observable<Network> {
    return this.httpClient.get<Network>(`portal/api/network/` + networkId);
  }

  public countNetworkByName(networkName: string): Observable<number> {
    return this.httpClient.get<number>(`portal/api/network/CountFromName/` + networkName);
  }
  public getNetworkByUnionId(networkId: string): Observable<Network> {
    return this.httpClient.get<Network>(`portal/api/network/union/` + networkId);
  }

  public searchNetwork(query: string): Observable<NetworkInfo[]> {
    const params = {
      filter: "status eq 'Published' and isPrivate eq false",
      includeTotalResultCount: false,
      top: 10
    };

    return this.searchSvc
      .query("Networks", query, params)
      .pipe(
        map((searchRes: KMSSearchResults<NetworkInfo>) =>
          searchRes.results
            .map(result => result.document)
            .map(val => MapDoc.docToNetInfo(val))
        )
      );
  }

  public createNetwork(network: Network): Observable<any> {
    return this.httpClient.post<Network>(`admin/api/network/Create`, network);
  }

  public mergeNetwork(newNetwork: Network, mergedNetwork: Network[]): Observable<any> {
    var postParam = new MergedNetwork();
    postParam.newNetwork = newNetwork;
    postParam.merged = [];
    mergedNetwork.forEach(n => {
      //alert(n.networkId);
      postParam.merged.push(n.networkId);
    });
    return this.httpClient.post<MergedNetwork>(`admin/api/network/Merge`, postParam);
  }

  public updateNetwork(network: Network): Observable<any> {
    return this.httpClient.post<Network>(`admin/api/network/Update`, network);
  }

 

  public getNetworksByHastags(
    hashtags: HashtagInfo[]
  ): Observable<NetworkInfo[]> {
    return this.httpClient.post<NetworkInfo[]>(
      `portal/api/network/FromHashtags`,
      hashtags
    );
  }

  public uploadNetworkAvatar(
    file: File,
    networkId: string
  ): Observable<string> {
    return this.uploaderSvc.uploadImage(
      `network/${networkId}/avatar/upload`,
      file
    );
    // const formData = new FormData();
    // formData.append("files", file);
    // return this.httpClient.post<string>(
    //   `network/${networkId}/avatar/upload`,
    //   formData
    // );
  }

  public uploadNetworkBackground(
    file: File,
    networkId: string
  ): Observable<string> {
    return this.uploaderSvc.uploadImage(
      `network/${networkId}/background/upload/`,
      file
    );
    // const formData = new FormData();
    // formData.append("files", file);
    // return this.httpClient.post<string>(
    //   `network/${networkId}/background/upload/`,
    //   formData
    // );
  }

  public getDefaultNetworkAvatar(): Observable<any> {
    return this.httpClient.get(`network/avatar/default/get`);
  }

  public getNetworkAvatar(imageUrl: string): Observable<any> {
    return this.httpClient.get(
      `network/avatar/get/icon?path=${encodeURIComponent(imageUrl)}`
    );
  }

  public getNetworkThumbnail(imageUrl: string): Observable<any> {
    return this.httpClient.get(
      `network/avatar/get/thumbnail/image?path=${encodeURIComponent(imageUrl)}`
    );
  }

  @Cacheable({ maxCacheCount: 10 })
  public getNetworkBackground(imageUrl: string): Observable<any> {
    return this.httpClient
      .get(
        `admin/api/network/background/get/icon?path=${encodeURIComponent(
          imageUrl
        )}`,
        {
          responseType: "blob"
        }
      )
      .pipe(
        map((file: File) => URL.createObjectURL(file)),
        shareReplay()
      );
  }

  public searchPrivateNetwork(
    query: string
  ): Observable<NetworkInfo[]> {
    // NetworkType: Private 
    return this.getExternalNetworkWithFilter(query);
  }

  public getExternalNetworkWithFilter(query: string) : Observable<NetworkInfo[]> {
    return this.httpClient.post<NetworkInfo[]>(
      `external/api/network/getWithQuery`,
      {
        query
      });
  }

  public searchNetworkByType(
    query: string,
    networkType: NetworkType
  ): Observable<NetworkInfo[]> {    

    if(networkType === NetworkType.CoPAndPrivate)
    {           
      const params = {
        filter: `typeId eq ${NetworkType.CoP} and status eq 'Published'`,
        includeTotalResultCount: false,
        top: 10
      };

      return this.searchSvc.queryWithExternal(query, params).pipe(
        catchError(() => of([])),
        map((searchRes: KMSSearchResults<NetworkInfo>) =>
          searchRes.results
            ? searchRes.results
                .map(result => result.document)
                .map(val => MapDoc.docToNetInfo(val))
            : []
        )
      );      
    } 
    else 
    { 

      const params = {
        filter: `typeId eq ${networkType} and status eq 'Published'`,
        includeTotalResultCount: false,
        top: 10
      };

      return this.searchSvc.query("Networks", query, params).pipe(
        catchError(() => of([])),
        map((searchRes: KMSSearchResults<NetworkInfo>) =>
          searchRes.results
            .map(result => result.document)
            .map(val => MapDoc.docToNetInfo(val))
        )  
      );
    }
  }

  public getNetworkCOP(): Observable<NetworkInfo[]> {
    return this.httpClient.get<NetworkInfo[]>("portal/api/network/cops");
  }

  public getNetworkBU(): Observable<NetworkInfo[]> {
    return this.httpClient.get<NetworkInfo[]>("portal/api/network/bus");
  }

  public getNetworkSpecialTag(): Observable<NetworkInfo[]> {
    return this.httpClient.get<NetworkInfo[]>("portal/api/network/specialtags");
  }

  public getNetworkPrivate(): Observable<NetworkInfo[]> {
    return this.httpClient.get<NetworkInfo[]>("external/api/network");
  }
}
