import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  OnDestroy,
  ChangeDetectorRef,
  TemplateRef,
  Input
} from "@angular/core";

import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject } from "rxjs";
import { map, tap, takeUntil, take } from "rxjs/operators";
import { SnackbarService } from "ngx-snackbar";
import { QueryBuilderConfig } from "angular2-query-builder";

import { LoggerService } from "src/app/services/logger.service";
import { WhitelistEditComponent } from "./whitelist-edit/whitelist-edit.component";
import { SecurityService } from "src/app/services/security.service";
import { ExternalUser, NetworkInfo } from "src/app/commons/entities";
import { NotificationService } from "src/app/services/notification.service";
import { Constants } from "src/app/commons/util";
import {
  SearchService,
  KMSSearchResults
} from "src/app/services/search.service";
import { NetworkService } from "src/app/services/network.service";
import { Router } from "@angular/router";
import { DestroyerComponent } from "src/app/commons/destroyer";

@Component({
  selector: "kms-admin-whitelist",
  templateUrl: "./whitelist.component.html",
  styleUrls: ["./whitelist.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class WhitelistComponent extends DestroyerComponent implements OnInit, OnDestroy {
  queryConfig: QueryBuilderConfig = {
    fields: {
      firstName: {
        name: "Name",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      lastName: {
        name: "Surname",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      email: {
        name: "Mail",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      upn: {
        name: "UPN",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      manager: {
        name: "Referent",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      company: {
        name: "Company",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      city: {
        name: "City",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      businessUnit: {
        name: "Business Unit",
        type: "string",
        operators: ["contains", "=", "!="]
      }
    }
  };

  externalUsers: Observable<Array<ExternalUser>>;
  loadingIndicator = false;
  userToDelete: ExternalUser = null;
  mailList: string[];
  modalRef: BsModalRef;
  limit = Constants.TABLE_LIMITS;
  lastUsedQuery: any = null;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  allCops: NetworkInfo[] = [];
  allBus: NetworkInfo[] = [];
  allST: NetworkInfo[] = [];
  allPN: NetworkInfo[] = [];
  IntervalId:any;

  constructor(
    private securitySvc: SecurityService,
    private searchSvc: SearchService,
    private readonly modalSvc: BsModalService,
    private readonly snackbarSvc: SnackbarService,
    private notificationSvc: NotificationService,
    private cd: ChangeDetectorRef,
    private logger: LoggerService,
    private readonly networkSvc: NetworkService,
    private router : Router
  ) {
    super();
    cd.detach();
    this.IntervalId=setInterval(() => { this.cd.detectChanges(); }, 2000);
  }

  ngOnInit() {
    this.search(null);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    clearInterval(this.IntervalId)
  }

  openAlertModal(template: TemplateRef<any>, selectedUser: ExternalUser) {
    this.userToDelete = selectedUser;
    this.modalRef = this.modalSvc.show(template);
  }

  async search(query: string) {
    this.allBus=[]
    this.allCops=[]
    this.allPN=[]
    this.allST=[]
    this.lastUsedQuery = query;

    const queryFilter = {
      filter: query,
      top: 1000
    };

    this.loadingIndicator = true;
    // this.cd.markForCheck();

    this.externalUsers = this.searchSvc
      .queryRaw("ExternalUsers", "*", queryFilter)
      .pipe(
        takeUntil(this.destroyed$),
        map((users: KMSSearchResults<ExternalUser>) =>
          users.results.map(r => r.document)
        ),
        map(users => users.map(user => new ExternalUser(user))),
        tap((users: Array<ExternalUser>) => {
          this.mailList = users.map(user => new ExternalUser(user).email);
          this.networkSvc.getNetworkCOP().pipe( takeUntil(this.destroyed$)).subscribe(
                  networks => {
                    networks.forEach(n => {
                      this.allCops.push(n);
                      users.forEach(n => {
                        var assCops: NetworkInfo[] = this.allCops.filter(item => n.networks.includes(item.networkId))
                        var assCopsStrings: string[] = [];
                        assCops.forEach(n => assCopsStrings.push(n.name));
                        n.assignedCops = assCops.length == networks.length ? "<<All>>" : assCopsStrings.toString(); 
                        
                      });
                    });
                    this.cd.markForCheck();
                  }
          );
          this.networkSvc.getNetworkBU().pipe( takeUntil(this.destroyed$)).subscribe(
                  networks => {
                    networks.forEach(n => {
                      this.allBus.push(n);
                      users.forEach(n => {
                        var assBus: NetworkInfo[] = this.allBus.filter(item => n.networks.includes(item.networkId))
                        var assBusStrings: string[] = [];
                        assBus.forEach(n => assBusStrings.push(n.name));
                        // n.assignedBU = assBusStrings.toString();

                        n.assignedBU = assBus.length == networks.length ? "<<All>>" : assBusStrings.toString();   
                                            
                    });
                  }
                  
                );
                this.cd.markForCheck();
          });
          this.networkSvc.getNetworkSpecialTag().pipe( takeUntil(this.destroyed$)).subscribe(
                  networks => {
                    networks.forEach(n => {
                      this.allST.push(n);
                      users.forEach(n => {            
                        var assST: NetworkInfo[] = this.allST.filter(item => n.networks.includes(item.networkId))
                        var assSTStrings: string[] = [];
                        assST.forEach(n => assSTStrings.push(n.name));
                        // n.assignedSpTags = assSTStrings.toString();
                        n.assignedSpTags = assST.length == networks.length ? "<<All>>" : assSTStrings.toString(); 
                      });
                    });
                    this.cd.markForCheck();
                  }
          );
          this.networkSvc.getNetworkPrivate().pipe( takeUntil(this.destroyed$)).subscribe(
                  networks => {
                    networks.forEach(n => {
                      this.allPN.push(n);
                      users.forEach(n => {            
                        var assPN: NetworkInfo[] = this.allPN.filter(item => n.networks.includes(item.networkId))
                        var assPNStrings: string[] = [];
                        assPN.forEach(n => assPNStrings.push(n.name));
                        // n.assignedPN = assPNStrings.toString();
                        n.assignedPN = assPN.length == networks.length ? "<<All>>" : assPNStrings.toString(); 
                      });
                    });
                    this.cd.markForCheck();
                  }
          );      

          this.loadingIndicator = false;
          // this.cd.markForCheck();
        }),
        // tap(()=>  )
      );

  }

  onCreate() {
    const initialState = {
      user: null,
      title: "Add User to Whitelist",
      confirmBtnName: "Save",
      closeBtnName: "Cancel",
      mailList: this.mailList
    };
    this.modalRef = this.modalSvc.show(WhitelistEditComponent, {
      initialState,
      class: "modal-transparent modal-lg mt-6"
    });
    this.modalRef.content.onClose.subscribe(
      result => {
        if (result) {
          setTimeout(async () => {
            this.search(this.lastUsedQuery);
            this.router.navigate(["/configuration"]);
          }, 1000);
          
        }
      },
      error => {
        this.logger.error(error);
      }
    );
  }

  async onEdit(selectedUPN: string) {
    this.allCops = [];
    this.allBus = [];
    this.allST = [];
    this.allPN= [];
    if (selectedUPN) {
      const loadedUser: ExternalUser = await this.securitySvc
        .getExternalUserByUPN(selectedUPN)
        .toPromise();
      const initialState = {
        user: loadedUser,
        title: "Edit User in Whitelist",
        confirmBtnName: "Save",
        closeBtnName: "Cancel"
      };
      this.modalRef = this.modalSvc.show(WhitelistEditComponent, {
        initialState,
        class: "modal-transparent modal-lg mt-6"
      });
      this.modalRef.content.onClose.subscribe(
        result => {
          if (result) {
            setTimeout(async () => {
              this.search(this.lastUsedQuery);
            }, 500);
          }
        },
        error => {
          this.logger.error(error);
        }
      );
    } else {
      this.logger.warning(`Cannot find the selected user: ${selectedUPN}`);
      this.snackbarSvc.add({
        msg: `Cannot find the selected user`,
        background: "red",
        action: { text: null }
      });
    }
  }

  async onDelete() {
    if (this.userToDelete) {
      try {
        let action: Promise<any>;
        action = this.securitySvc
          .deleteExternalUser(this.userToDelete.id)
          .toPromise();
        const { transactionId } = await action;

        await this.notificationSvc
          .waitForTransactionResult(transactionId)
          .toPromise();

        setTimeout( async () => {
          this.search(this.lastUsedQuery);
          this.router.navigate(["/configuration"]);
        }, 1000);
       
      } catch (error) {
        this.logger.error(error);
        this.notificationSvc.showDetailsError(error);
      }
    } else {
      this.logger.warning(`This user does not exist`);
      this.snackbarSvc.add({
        msg: `This user does not exist`,
        background: "red",
        action: { text: null }
      });
    }
    this.modalRef.hide();
  }
}
