import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TabsModule } from "ngx-bootstrap/tabs";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { ConfigurationRoutingModule } from "./configuration-routing.module";
import { ConfigurationPageComponent } from "./configuration-page/configuration-page.component";
import { CardModule } from "src/app/components/card/card.module";
import { RichTextEditorModule } from "src/app/components/rich-text-editor/rich-text-editor.module";
import { AvatarViewModule } from "src/app/components/avatar-view/avatar-view.module";
import { PeoplePickerModule } from "src/app/components/people-picker/people-picker.module";
import { KMSFormsModule } from "src/app/components/forms/forms.module";
import { BadgeComponent } from "./configuration-page/badge/badge.component";
import { BadgeEditComponent } from "./configuration-page/badge/badge-edit/badge-edit.component";
import { WelcomeExperienceComponent } from "./configuration-page/welcome-experience/welcome-experience.component";
import { FileUploadModule } from "src/app/components/file-upload/file-upload.module";
import { CoreModule } from "src/app/components/core/core.module";
import { QueryBuilderComponentModule } from "src/app/components/query-builder/query-builder.module";
import { PipesModule } from "src/app/components/pipes/pipes.module";
import { WhitelistComponent } from "./configuration-page/whitelist/whitelist.component";
import { WhitelistEditComponent } from "./configuration-page/whitelist/whitelist-edit/whitelist-edit.component";
import { KeywordsComponent } from "./configuration-page/keywords/keywords.component";
import { KeywordsEditComponent } from "./configuration-page/keywords/keywords-edit/keywords-edit.component";
import { IPManagementComponent } from "./configuration-page/ipmanagement/ipmanagement.component";
import { IPManagementEditComponent } from "./configuration-page/ipmanagement/ipmanagement-edit/ipmanagement-edit.component";
import { GuestWhitelistComponent } from "./configuration-page/guest-whitelist/guest-whitelist.component";
import { GuestWhitelistEditComponent } from "./configuration-page/guest-whitelist/guest-whitelist-edit/guest-whitelist-edit.component";
import { ClickWaitModule } from "src/app/components/click-wait/click-wait.module";
import { NetworkPickerModule } from "src/app/components/network-picker/network-picker.module";

const IMPORTS = [
  CommonModule,
  CardModule,
  TabsModule,
  RichTextEditorModule,
  NgxDatatableModule,
  FormsModule,
  NgSelectModule,
  KMSFormsModule,
  PeoplePickerModule,
  QueryBuilderComponentModule,
  AvatarViewModule,
  TooltipModule,
  FileUploadModule,
  CoreModule,
  PipesModule,
  ClickWaitModule,
  NetworkPickerModule
];
const DECLARATIONS = [
  ConfigurationPageComponent,
  BadgeComponent,
  BadgeEditComponent,
  WelcomeExperienceComponent,
  WhitelistComponent,
  WhitelistEditComponent,
  KeywordsComponent,
  KeywordsEditComponent,
  IPManagementComponent,
  IPManagementEditComponent,
  GuestWhitelistComponent,
  GuestWhitelistEditComponent
];
const ENTRY_COMPONENTS = [
  BadgeEditComponent,
  WhitelistEditComponent,
  KeywordsEditComponent,
  GuestWhitelistEditComponent,
  IPManagementEditComponent
];

@NgModule({
  imports: [...IMPORTS, ConfigurationRoutingModule],
  declarations: [...DECLARATIONS],
  entryComponents: [...ENTRY_COMPONENTS],
  exports: [...IMPORTS, ...DECLARATIONS]
})
export class ConfigurationModule {}
