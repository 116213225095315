// custom templates for ng-select
// https://github.com/ng-select/ng-select/blob/master/demo/app/examples/custom-templates.component.ts
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewEncapsulation,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from "@angular/core";
import { FormArray, FormControl } from "@angular/forms";

import { Observable, Subject, concat, of } from "rxjs";
import {
  tap,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError,
  map
} from "rxjs/operators";

import {
  UserInfo,
  PeoplePickerUserRestriction,
  GuestUser
} from "src/app/commons/entities";
import { UserService } from "src/app/services/user.service";
import { stringify } from "querystring";

@Component({
  selector: "kms-admin-people-picker",
  templateUrl: "./people-picker.component.html",
  styleUrls: ["./people-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PeoplePickerComponent implements OnInit {
  user$: Observable<UserInfo[]>;
  userLoading = false;
  userInput$ = new Subject<string>();
  user: UserInfo[] = [];
  disableAllAvatarViews = false;

  @Input()
  userList: FormArray = new FormArray([]);

  @Input()
  singleUser: UserInfo;

  @Input()
  isMulti = true;

  @Input()
  edit = true;

  @Input()
  enableCsvUpload = false;

  @Input()
  queryStringCsvParams = "";

  @Output()
  add = new EventEmitter();

  @Output()
  remove = new EventEmitter();

  @Input()
  parentClearUserSubject: Subject<any>;

  @Input()
  restrictions: PeoplePickerUserRestriction = PeoplePickerUserRestriction.None;

  @Input()
  placeholder = "Choose people";

  @Input()
  showExternalConsultant = true;

  @Output() 
  fileLoaded = new EventEmitter<any>();

  @Output() uploadingProgress = new EventEmitter<boolean>(); //true is uploading

  constructor(private userSvc: UserService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.user = this.isMulti
      ? this.userList.value
        ? [...this.userList.value.map(u => new UserInfo(u))]
        : []
      : <any>this.singleUser;

    if(this.user!=null && this.user.length>50){
      this.disableAllAvatarViews = true;
    }
    this.loadUser();

    if (this.parentClearUserSubject) {
      this.parentClearUserSubject.subscribe(event => {
        this.user = null;
        this.cd.markForCheck();
      });
    }
  }

  // ngOnDestroy() {
  //   // needed if child gets re-created (eg on some model changes)
  //   // note that subsequent subscriptions on the same subject will fail
  //   // so the parent has to re-create parentSubject on changes
  //   this.parentClearUserSubject.unsubscribe();
  // }

  addUser(event) {
    //var usi = new UserInfo(event);
    //alert("event "+usi.userId + ", "+usi.displayName + ", "+usi.email + ", avatarUrl="+usi.avatarUrl +", externalConsultant="+usi.isExternalConsultant+", koEligible="+usi.isKOEligible + ", unitCode="+usi.unitCode + ", roles="+usi.roles);
    this.userList.push(new FormControl(new UserInfo(event)));
    this.add.emit(new UserInfo(event));
    this.cd.markForCheck();
  }

  containsUser(specificUser:UserInfo):boolean{
    const arr = this.userList.value;

    var found = false;

    arr.forEach((element: UserInfo, index) => {
      if (element.userId === specificUser.userId) {
        found = found || true;
      }
    });
    
    //alert("return "+found);
    return found;
  }

  removeUser(event) {
    const arr = this.userList.value;

    arr.forEach((element: UserInfo, index) => {
      if (element.userId === event.value.userId) {
        this.remove.emit(element);
        this.userList.removeAt(index);
      }
    });

    this.userList.markAsTouched();
    this.cd.markForCheck();
  }

  modifyUser(event) {
    // use for single mode

    if (this.isMulti === true) {
      return;
    }

    if (event === null) {
      this.remove.emit(null);
    } else {
      this.add.emit(new UserInfo(event));
    }

    this.userList.markAsTouched();
  }

  removePills(user: UserInfo) {
    const arr = this.userList.value;

    this.user = this.user.filter(u => u.userId !== user.userId);

    arr.forEach((element: UserInfo, index) => {
      if (element.userId === user.userId) {
        this.userList.removeAt(index);
      }
    });

    this.userList.markAsTouched();
    this.cd.markForCheck();
  }

  removeAll() {
    this.user = [];

    while (this.userList.length !== 0) {
      this.userList.removeAt(0);
    }

    this.userList.markAsTouched();
    this.cd.markForCheck();
  }

  private loadUser() {
    this.user$ = concat(
      of([]), // default items
      this.userInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          this.userLoading = true;
          this.cd.markForCheck();
        }),
        switchMap((term: any) => 
          this.userSvc.searchUser(term, this.restrictions).pipe(
            catchError(() => of([])), // empty list on error
            map(users =>
              users.filter(el =>
                this.userList.value
                  ? this.userList.value.every(
                      item2 => item2.userId !== el.userId
                    )
                  : true
              )
            ),
            map(
              users => users.filter(user => this.showExternalConsultant || !user.isExternalConsultant )
            ),
            tap(() => {
              this.userLoading = false;
              this.cd.markForCheck();
            })
          )
        )
      )
    );
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  uploadingProgressFunction(isUploading: boolean){
    if(isUploading){
      this.userLoading = true;
      this.cd.markForCheck();
    }
    this.uploadingProgress.emit(isUploading);
  }
  
  async uploadedFile(guestUsersCreatePayload: any) {
    if(guestUsersCreatePayload.message){
      alert("An error has occurred\n\n"+ guestUsersCreatePayload.message);
      this.userLoading = false;
      this.fileLoaded.emit(null);//so I inform in any cases the calling component
    }else{
/*
      new Promise(()=>{
        guestUsersCreatePayload.guestUsers.forEach(element => {
          ////guids.push(element.id);
  
          this.userLoading = true;
          this.cd.markForCheck();
    
          this.sleep(250).then(()=>{
  
              this.userSvc.GetUserInfoFor(element.id).then((value: UserInfo[]) => {
                //alert(value[0].displayName);
                value.map(v => {
                  //alert("v.userId "+v.userId);
                  if(!this.containsUser(v)){
                    if(this.showExternalConsultant || !v.isExternalConsultant){
                      this.addUser(v);
                    }else{
                      alert("The user with email '"+v.email+"' is an External user and will not be added to list");
                    }
                  }
                });
                this.user = [...this.userList.value.map(u => new UserInfo(u))];
                this.userList.markAsTouched();
              }
            )
          });
        });
      }).then(()=>{
        //alert("loaduser");
        this.loadUser();
        //alert("lenght = "+this.userInfoList.length);
        this.userLoading = false;
        this.cd.markForCheck();
        
        this.fileLoaded.emit(guestUsersCreatePayload);
      });
*/

      //retrieve singularly each user if valid. Done for reducing load on cosmos...
      var aa = 0;

      for(var i=0; i<guestUsersCreatePayload.guestUsers.length; i++){
        
        //guestUsersCreatePayload.guestUsers.forEach(element => {
  
        var element = guestUsersCreatePayload.guestUsers[i];

        var validGuest = await this.userSvc.ValidatedSingleUserFromCsvOrNull(element, true);//.then((validGuest: GuestUsers) => {

            //alert("validGuest is "+validGuest.id);

            if(validGuest!=undefined && validGuest.id!="" && validGuest.email.indexOf("_manual")==-1){
              //alert("managing " + guestUsersCreatePayload.guestUsers[i].email + " valid guest "+validGuest +"em "+validGuest.email);
              for(var j=0; j<guestUsersCreatePayload.guestUsers.length; j++){
                if(guestUsersCreatePayload.guestUsers[j] != null && guestUsersCreatePayload.guestUsers[j].email == validGuest.email){
                  guestUsersCreatePayload.guestUsers[j] = validGuest;
                  break;
                }
              }
            }else{
              //alert("user null for "+element.email);
              for(var j=0; j<guestUsersCreatePayload.guestUsers.length; j++){
                if(guestUsersCreatePayload.guestUsers[j] != null && guestUsersCreatePayload.guestUsers[j].email == element.email){
                  //guestUsersCreatePayload.guestUsers.splice(j,1);
                  guestUsersCreatePayload.guestUsers[j] = null;
                  guestUsersCreatePayload.skippedAsNotGuestUsers.push(element);
                  break;
                }
              }
            }
            aa++;
          //}
        //);
      //});
          }
      //alert("after "+aa);

      //cleanup
      var newGuestArray = Array<GuestUser>();

      for(var i=0; i<guestUsersCreatePayload.guestUsers.length; i++){
        if(guestUsersCreatePayload.guestUsers[i]!=null)
          newGuestArray.push(guestUsersCreatePayload.guestUsers[i]);
      }

      guestUsersCreatePayload.guestUsers = newGuestArray;

      ////previous implementation
      var guids = Array<string>();

      guestUsersCreatePayload.guestUsers.forEach(element => {
        if(element!=null && element.id != null && element.id.trim().length!=0)
          guids.push(element.id);
      });

      if(guids.length>50){
        this.disableAllAvatarViews = true;
      }
      this.userLoading = true;
      this.cd.markForCheck();
      
      //this.sleep(50).then( ()=>{

        for(var i=0; i<guids.length; i++){

          var tmpSingleGuidArray = Array<string>();
          tmpSingleGuidArray.push(guids[i]);

            await this.userSvc.GetUserInfoFor(tmpSingleGuidArray).then((value: UserInfo[]) => {
              //alert(value[0].displayName);
              value.map(v => {
                if(!this.containsUser(v)){
                  if(this.showExternalConsultant || !v.isExternalConsultant){
                    this.addUser(v);
                  }else{
                    alert("The user with email '"+v.email+"' is an External user and will not be added to list");
                  }
                }
              });
              this.user = [...this.userList.value.map(u => new UserInfo(u))];
              this.userList.markAsTouched();
              this.loadUser();
              //alert("lenght = "+this.userInfoList.length);
              //this.userLoading = false;
              this.cd.markForCheck();
            }
          );
        }
        this.userLoading = false;
        this.cd.markForCheck();

        this.fileLoaded.emit(guestUsersCreatePayload);
      //);
    }
  }
}
