import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef,
} from "@angular/core";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";

import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
  map,
  takeUntil,
  finalize,
} from "rxjs/operators";
import { SnackbarService } from "ngx-snackbar";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject, Observable, concat, of, BehaviorSubject } from "rxjs";

import {
  Network,
  SecurityProfile,
  BusinessUnit,
  ExternalUser,
  PeoplePickerUserRestriction,
  NetworkType,
} from "src/app/commons/entities";
import {
  KMSSearchResults,
  SearchService,
} from "src/app/services/search.service";
import { LoggerService } from "src/app/services/logger.service";
import { SecurityService } from "src/app/services/security.service";
import { NotificationService } from "src/app/services/notification.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import { UserService } from "src/app/services/user.service";
import { NetworkService } from "src/app/services/network.service";
import { DestroyerComponent } from "src/app/commons/destroyer";
import { delay } from "lodash-es";

@Component({
  selector: "kms-admin-whitelist-edit",
  templateUrl: "./whitelist-edit.component.html",
  styleUrls: ["./whitelist-edit.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class WhitelistEditComponent
  extends DestroyerComponent
  implements OnInit
{
  title: string;
  confirmBtnName: string;
  closeBtnName: string;
  user: ExternalUser;
  form: FormGroup;
  mailList: string[];
  isMailConfirmed = false;
  showWarning = false;
  hideElements = true;
  warningMessage = "";

  company$: Observable<string[]>;
  companyLoading = false;
  companyInput$ = new Subject<string>();
  company: any = null;

  city$: Observable<string[]>;
  cityLoading = false;
  cityInput$ = new Subject<string>();
  city: any = null;
  addMode = true;

  network: any = null;
  isLeleEnabled: boolean;
  isMultipleUser: boolean = false;
  externalUsers: Array<ExternalUser> = [];
  existReferent: boolean = false;

  profile: SecurityProfile = null;

  public onClose: Subject<boolean>;

  referentRestriction = PeoplePickerUserRestriction.onlyHRE;

  get isBULoading(): boolean {
    return this.city && this.company && !this.network;
  }

  networkList: FormArray = new FormArray([]);

  public userCops: any[] = [];
  public userBus: any[] = [];
  public userSTs: any[] = [];
  public userPNs: any[] = [];

  public hasAllCops: boolean = false;
  public hasAllBus: boolean = false;
  public hasAllSTs: boolean = false;
  public hasAllPNs: boolean = false;

  public hasLoadedCops$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public hasLoadedBus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public hasLoadedSTs$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public hasLoadedPNs$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    public bsModalRef: BsModalRef,
    private securitySvc: SecurityService,
    private configSvc: ConfigurationService,
    private searchSvc: SearchService,
    private readonly snackbarSvc: SnackbarService,
    private logger: LoggerService,
    private cd: ChangeDetectorRef,
    private notificationSvc: NotificationService,
    private readonly networkSvc: NetworkService
  ) {
    super();
  }

  ngOnInit() {
    this.hasLoadedCops$.next(true);
    this.hasLoadedBus$.next(true);
    this.hasLoadedSTs$.next(true);
    this.hasLoadedPNs$.next(true);

    this.networkSvc
      .getNetworkCOP()
      .pipe(
        takeUntil(this.destroyed$),
        tap((_) => {
          this.hasLoadedCops$.next(true);
        }),
        tap((cops) => {
          // console.log(cops)
          this.userCops = this.networkList.value?.filter(
            (n) => n.type == NetworkType.CoP
          );
          this.userCops.length == cops.length
            ? (this.hasAllCops = true)
            : (this.hasAllCops = false);

          this.hasLoadedCops$.next(false);
          // console.log(this.hasAllCops)
        }),
        finalize(() => {
          this.hasLoadedCops$.next(false);
        })
      )
      .subscribe();

    this.networkSvc
      .getNetworkBU()
      .pipe(
        takeUntil(this.destroyed$),
        tap((_) => {
          this.hasLoadedBus$.next(true);
        }),
        tap((bus) => {
          // console.log(bus)
          this.userBus = this.networkList.value?.filter(
            (n) => n.type == NetworkType.BusinessUnit
          );
          // console.log(this.userBus)

          this.userBus.length == bus.length
            ? (this.hasAllBus = true)
            : (this.hasAllBus = false);
          // console.log(this.hasAllBus)
          this.hasLoadedBus$.next(false);
        }),
        finalize(() => {
          this.hasLoadedBus$.next(false);
        })
      )
      .subscribe();

    this.networkSvc
      .getNetworkPrivate()
      .pipe(
        takeUntil(this.destroyed$),
        tap((_) => {
          this.hasLoadedPNs$.next(true);
        }),
        tap((pn) => {
          // console.log(pn)
          this.userPNs = this.networkList.value?.filter(
            (n) => n.type == NetworkType.External
          );
          this.userPNs.length == pn.length
            ? (this.hasAllPNs = true)
            : (this.hasAllPNs = false);
          // console.log(this.userPNs)
          this.hasLoadedPNs$.next(false);
        }),
        finalize(() => {
          this.hasLoadedPNs$.next(false);
        })
      )
      .subscribe();

    this.networkSvc
      .getNetworkSpecialTag()
      .pipe(
        takeUntil(this.destroyed$),
        tap((_) => {
          this.hasLoadedSTs$.next(true);
        }),
        tap((sp) => {
          // console.log(sp)
          this.userSTs = this.networkList.value?.filter(
            (n) => n.type == NetworkType.SpecialTag
          );
          this.userSTs.length == sp.length
            ? (this.hasAllSTs = true)
            : (this.hasAllSTs = false);
          // console.log(this.userSTs)

          this.hasLoadedSTs$.next(false);
        }),
        finalize(() => {
          this.hasLoadedSTs$.next(false);
        })
      )
      .subscribe();

    this.onClose = new Subject();
    this.hideElements = this.user == null;
    // this.loadNetworks();
    this.loadCompanies();
    this.loadCities();
    this.initForm();

    this.form.valueChanges.subscribe(() => {
      this.existReferent = this.form.get("referent").value ? true : false;
    });
  }

  async confirm() {
    let action: Promise<any>;
    const values = this.form.value;
    if (this.isMultipleUser == true) {
      try {
        this.externalUsers.map((user) => this.remapUser2(user));
        // console.log(this.externalUsers)
        let result = await this.securitySvc
          .createMultipleExternalUser(this.externalUsers)
          .toPromise();
        // console.log(result);
        this.onClose.next(true);
        alert(
          "Please be aware that this configuration will take up to 5 minutes to be effective"
        );
        this.bsModalRef.hide();
        return;
      } catch (error) {
        this.logger.error(error);
        this.notificationSvc.showDetailsError(error);
      }
    }

    if (values && values.referent && (this.user || values.mail)) {
      try {
        const user = this.remapUser();
        if (this.user) {
          user.id = this.user.id;
          action = await this.securitySvc.updateExternalUser(user).toPromise();
        } else {
          action = await this.securitySvc.createExternalUser(user).toPromise();
        }
        const { transactionId } = await action;

        // await this.notificationSvc
        //   .waitForTransactionResult(transactionId)
        //   .toPromise();
        this.onClose.next(true);
        alert(
          "Please be aware that this configuration will take up to 5 minutes to be effective"
        );
        this.bsModalRef.hide();
      } catch (error) {
        this.logger.error(error);
        this.notificationSvc.showDetailsError(error);
      }
    } else {
      this.logger.warning(`Selected parameters not valid: ${values}`);
      this.snackbarSvc.add({
        msg: `Selected parameters not valid`,
        background: "red",
        action: { text: null },
      });
    }
  }

  addUser(user: any) {
    if (user && user.userId) {
      this.form.get("referent").setValue(user);
      this.form.get("referent").updateValueAndValidity();
    } else {
      this.removeUser();
    }
  }

  async updateFile(guestUsersCreatePayload: any) {
    console.log(guestUsersCreatePayload);
    if (guestUsersCreatePayload.message) {
      alert("An error has occurred\n\n" + guestUsersCreatePayload.message);
      // this.guestUsersCreatePayload = null;
      // this.fileUploading = false;
    } else if (guestUsersCreatePayload.length > 0) {
      for (var i = 0; i < guestUsersCreatePayload.length; i++) {
        if (guestUsersCreatePayload[i] != null)
          this.externalUsers.push(guestUsersCreatePayload[i]);
      }
    } else {
      alert(
        "No user identified identified on the CSV.\nDoes the file contains entries?"
      );
    }
  }

  uploadingProgress(isUploading: boolean) {
    if (!isUploading) {
      this.hideElements = false;
      this.isMailConfirmed = true;
      this.isMultipleUser = true;

      this.cd.markForCheck();
    }
  }
  // addNt(event: any) {

  //     this.form.get("networkList").setValue(this.networkList);
  //     this.form.get("networkList").updateValueAndValidity();

  // }

  async checkMail() {
    this.showWarning = false;
    if (this.form.get("mail") && this.form.get("mail").value) {
      if (this.mailList.find((x) => x === this.form.get("mail").value)) {
        alert("The email is already in use");
      } else {
        const profile = await this.securitySvc
          .getUserByEmail(this.form.get("mail").value)
          .toPromise();

        if (profile) {
          if (!/#EXT#/.test(profile.upn)) {
            this.profile = profile;
            this.populateProfile();
          } else {
            // mail found but not in azure AD
            this.warningMessage =
              "Only organizational account allowed in this section. For external user use guest whitelist instead.";
            this.showWarning = true;
          }
        } else {
          // profile not found
          this.warningMessage = "No user found with the specified email";
          this.showWarning = true;
        }
      }
    } else {
      // mail not inserted by the user
      this.warningMessage = "Please insert an email";
      this.showWarning = true;
    }
    this.cd.markForCheck();
  }

  changeMail() {
    this.isMailConfirmed = false;
    this.form.get("name").setValue("");
    this.form.get("surname").setValue("");
    this.form.get("upn").setValue("");
    this.cd.markForCheck();
  }

  changeCompany(newCompany: string) {
    this.company = newCompany;
    this.city = null;
    this.changeBUValue(null);
    this.loadCities();
    if (this.form && this.form.get("city") && this.form.get("city").disabled) {
      this.form.get("city").enable();
      this.form.get("city").updateValueAndValidity();
    }
    this.getBusinessUnit();
  }

  changeCity(newCity: string) {
    this.city = newCity;
    this.changeBUValue(null);
    this.getBusinessUnit();
  }

  private changeBUValue(newNetwork: any) {
    this.network = newNetwork;
    this.form.get("bu").setValue(newNetwork ? newNetwork.name : null);
    this.cd.markForCheck();
  }

  private initForm() {
    this.network = this.user
      ? {
          id: this.user.businessUnit.businessUnitId,
          name: this.user.businessUnit.name,
        }
      : null;
    this.company = this.user ? this.user.company : null;
    this.city = this.user ? this.user.city : null;

    this.form = new FormGroup({
      mail: new FormControl(
        this.user ? this.user.email : "",
        Validators.required
      ),
      name: new FormControl(
        { value: this.user ? this.user.firstName : "", disabled: true },
        Validators.required
      ),
      surname: new FormControl(
        { value: this.user ? this.user.lastName : "", disabled: true },
        Validators.required
      ),
      upn: new FormControl(
        { value: this.user ? this.user.upn : "", disabled: true },
        Validators.required
      ),
      referent: new FormControl(
        this.user ? this.user.employeeReference : null,
        Validators.required
      ),
      company: new FormControl(this.company),
      city: new FormControl({
        value: this.city,
        disabled: true,
      }),
      bu: new FormControl({
        value: this.network ? this.network.name : "",
        disabled: true,
      }),
    });

    if (this.user) {
      //here it's an invocation for edit user
      this.addMode = false;
      this.isMailConfirmed = true;
      this.form.get("mail").disable();
      this.form.get("mail").updateValueAndValidity();
      this.form.get("city").enable();
      this.form.get("city").updateValueAndValidity();
    }

    this.user?.networks?.forEach((n) =>
      this.networkList.push(
        new FormControl({
          networkId: n.item1,
          name: n.item3,
          hashtag: {},
          type: n.item2,
        })
      )
    );
    this.isLeleEnabled = this.user?.isLeleEnabled;
  }

  private loadCompanies() {
    const params = {
      top: 20,
      facets: ["company"],
      filter: "",
    };
    this.company$ = concat(
      of([]), // default items
      this.companyInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          this.companyLoading = true;
          this.cd.markForCheck();
        }),
        switchMap((term) => {
          params.filter = `search.ismatch('${this.escapingNames(
            term
          )}', 'company', 'full', 'any')`;
          return this.searchSvc.queryRaw("BusinessUnits", "*", params).pipe(
            catchError(() => of([])),
            map((searchRes: KMSSearchResults<any>) =>
              searchRes && searchRes.facets && searchRes.facets.company
                ? searchRes.facets.company.map((result) => result.value)
                : null
            ), // empty list on error
            tap(() => {
              this.company = null;
              this.companyLoading = false;
              this.cd.markForCheck();
            })
          );
        })
      )
    );
  }

  private loadCities() {
    const params = {
      top: 20,
      facets: ["city"],
      filter: "",
    };
    this.city$ = concat(
      of([]), // default items
      this.cityInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          this.cityLoading = true;
          this.cd.markForCheck();
        }),
        switchMap((term) => {
          params.filter = `company eq '${
            this.company || ""
          }' and (search.ismatch('${this.escapingNames(
            term
          )}', 'city', 'full', 'any'))`;
          return this.searchSvc.queryRaw("BusinessUnits", "*", params).pipe(
            catchError(() => of([])),
            map((searchRes: KMSSearchResults<any>) =>
              searchRes && searchRes.facets && searchRes.facets.city
                ? searchRes.facets.city.map((result) => result.value)
                : null
            ), // empty list on error
            tap(() => {
              this.city = null;
              this.cityLoading = false;
              this.cd.markForCheck();
            })
          );
        })
      )
    );
  }

  private escapingNames(name: string): string {
    if (name) {
      name = name.replace(/^'/, "");
      name = name.replace(/'$/, "");
      return name
        .split(" ")
        .map((r) => "/.*" + r + ".*/")
        .join(" ");
    } else {
      return "/.*.*/";
    }
  }

  private async getBusinessUnit() {
    if (this.company && this.city) {
      this.logger.information(
        `Admin: Get BU for tuple (${this.company}, ${this.city})`
      );
      const businessUnit: any = await this.configSvc
        .getBusinessUnit(this.company, this.city)
        .toPromise();
      if (businessUnit) {
        this.changeBUValue({ id: businessUnit.BUId, name: businessUnit.name });
      }
    }
  }

  private removeUser() {
    this.form.get("referent").setValue(null);
    this.form.get("referent").updateValueAndValidity();
  }

  private populateProfile() {
    this.form.get("name").setValue(this.profile.firstName);
    this.form.get("surname").setValue(this.profile.lastName);
    this.form.get("upn").setValue(this.profile.upn);

    this.isMailConfirmed = true;
    this.hideElements = false;
  }

  private remapUser(): ExternalUser {
    const user = new ExternalUser();
    const selectedValues = this.form.value;

    user.upn = this.profile ? this.profile.upn : this.user.upn;
    user.firstName = this.profile
      ? this.profile.firstName
      : this.user.firstName;
    user.lastName = this.profile ? this.profile.lastName : this.user.lastName;
    user.displayName = this.profile
      ? this.profile.lastName + " " + this.profile.firstName
      : this.user.displayName;
    user.email = this.user ? this.user.email : selectedValues.mail;
    user.businessUnit = new BusinessUnit({
      businessUnitId: this.network ? this.network.id : "",
      name: this.network ? this.network.name : "",
    });
    user.employeeReference = selectedValues.referent;
    user.company = this.network ? this.company : null;
    user.city = this.network ? this.city : null;
    user.networks = new Array<{
      Item1: string;
      Item2: number;
      Item3: string;
    }>();
    this.networkList.value.forEach((n) => {
      user.networks.push({ Item1: n.networkId, Item2: n.type, Item3: n.name });
    });
    user.isLeleEnabled = this.isLeleEnabled;

    return user;
  }

  private remapUser2(user: ExternalUser): ExternalUser {
    const selectedValues = this.form.value;

    user.upn = this.profile ? this.profile?.upn : user?.upn;
    user.firstName = this.profile ? this.profile?.firstName : user?.firstName;
    user.lastName = this.profile ? this.profile?.lastName : user.lastName;
    user.displayName = this.profile
      ? this.profile.lastName + " " + this.profile.firstName
      : user.displayName;
    user.email = this.user ? user.email : selectedValues.mail;
    user.businessUnit = new BusinessUnit({
      businessUnitId: this.network ? this.network.id : "",
      name: this.network ? this.network.name : "",
    });
    user.employeeReference = selectedValues.referent;
    user.company = this.network ? this.company : null;
    user.city = this.network ? this.city : null;
    user.networks = new Array<{
      Item1: string;
      Item2: number;
      Item3: string;
    }>();
    this.networkList.value.forEach((n) => {
      user.networks.push({ Item1: n.networkId, Item2: n.type, Item3: n.name });
    });
    user.isLeleEnabled = this.isLeleEnabled;

    return user;
  }
  isCsvView = false;
  onTabSelect(sourceTabId: any) {
    if (sourceTabId) {
      if (sourceTabId == 0) {
        //this is the standard one
        // this.form.get("mail").setValidators(Validators.required);
        // this.form.get("displayName").setValidators(Validators.required);
        // this.form.get("company").setValidators(Validators.required);
        this.isCsvView = false;
      } else if (sourceTabId == 1) {
        // this.form.get("mail").clearValidators();
        // this.form.get("displayName").clearValidators();
        // this.form.get("company").clearValidators();
        this.isCsvView = true;
      }
      // this.guestUsersCreatePayload = null;
      // this.isMailConfirmed = false;
      // this.form.get("mail").updateValueAndValidity();
      // this.form.get("displayName").updateValueAndValidity();
      // this.form.get("company").updateValueAndValidity();
      // this.cd.markForCheck();
    }
  }

  hasAllNetworksByType(networks: Network[], type: number) {}
}
