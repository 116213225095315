import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from "@angular/core";

import { Observable } from "rxjs";
import { tap, map } from "rxjs/operators";
import { QueryBuilderConfig } from "angular2-query-builder";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

import {
  SearchService,
  KMSSearchResults
} from "src/app/services/search.service";
import { StrategicTagsEditComponent } from "../strategic-tags-edit/strategic-tags-edit.component";
import { LoggerService } from "src/app/services/logger.service";
import { SnackbarService } from "ngx-snackbar";
import { ConfigService } from "@ngx-config/core";
import { Constants } from "src/app/commons/util";

@Component({
  selector: "kms-admin-strategic-tags-page",
  templateUrl: "./strategic-tags-page.component.html",
  styleUrls: ["./strategic-tags-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class StrategicTagsPageComponent implements OnInit {
  queryConfig: QueryBuilderConfig = {
    fields: {
      name: {
        name: "Strategic Tag",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      promotingNetworks: {
        name: "Related Networks",
        type: "string",
        operators: ["contains"]
      },
      modified: {
        name: "Modified Date",
        type: "date",
        operators: ["<", "<=", ">", ">="]
      },
      referenceYear: {
        name: "Reference Year",
        type: "number",
        operators: ["contains"]
      }
    }
  };

  strategicTags: Observable<any[]>;
  loadingIndicator = false;
  limit = Constants.TABLE_LIMITS;
  modalRef: BsModalRef;
  lastUsedQuery: any = null;

  constructor(
    private searchSvc: SearchService,
    private readonly modalSvc: BsModalService,
    private readonly snackbarSvc: SnackbarService,
    private readonly config: ConfigService,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.search(null);
  }

  async search(query: string) {
    this.lastUsedQuery = query;

    const filter = {
      filter: "(typeId eq 5)" + (query ? " and " + query : ""),
      top: 1000
    };
    this.loadingIndicator = true;
    this.strategicTags = this.searchSvc.queryRaw("Hashtags", "*", filter).pipe(
      map((networks: KMSSearchResults<any>) =>
        networks.results.map(r => r.document)
      ),
      tap(() => (this.loadingIndicator = false))
    );
  }

  onCreate() {
    const initialState = {
      isNew: true,
      title: "Create or Promote Strategic Tag",
      confirmBtnName: "Save",
      closeBtnName: "Cancel",
      demote: "demote"
    };
    this.modalRef = this.modalSvc.show(StrategicTagsEditComponent, {
      initialState,
      class: "modal-transparent modal-lg mt-6",
      ignoreBackdropClick: true
    });
    this.modalRef.content.onClose.subscribe(
      result => {
        if (result) {
          this.search(this.lastUsedQuery);
        }
      },
      error => {
        this.logger.error(error);
      }
    );
  }

  onEdit(selectedTag: any) {
    const initialState = {
      isNew: false,
      existingHash: selectedTag,
      title: "Change Reference Years For #" + selectedTag.name,
      confirmBtnName: "Save",
      closeBtnName: "Cancel"
    };
    this.modalRef = this.modalSvc.show(StrategicTagsEditComponent, {
      initialState,
      class: "modal-transparent modal-lg mt-6",
      ignoreBackdropClick: true
    });
    this.modalRef.content.onClose.subscribe(
      result => {
        if (result) {
          this.search(this.lastUsedQuery);
        }
      },
      error => {
        this.logger.error(error);
      }
    );
  }

  openStrategicTag(tag: any) {
    const tagId = tag ? tag.id : null;
    if (tagId != null) {
      window.open(
        `${this.config.getSettings().portalWebUrl}hashtag/${tagId}`,
        "_blank"
      );
    } else {
      this.logger.warning(`Cannot find the selected tag: ${tag}`);
      this.snackbarSvc.add({
        msg: `Cannot find the selected Strategic Tag`,
        background: "red",
        action: { text: null }
      });
    }
  }
}
