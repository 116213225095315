<div class="modal-header">
  <h4 class="modal-title pull-left mt-0">{{ title }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid" [formGroup]="form">
    <div class="row">
      <div
        form-field
        class="col-sm-6 input-alignment"
        [control]="form.get('title')"
        [group]="form"
      >
        <input
          type="text"
          class="form-control no-box"
          id="inputTitle"
          formControlName="title"
          (keyup)="onTitleChange($event)"
        />
        <label
          class="floating-label"
          [ngClass]="{ 'go-top': form?.get('title')?.valid }"
          >Title *</label
        >
      </div>
      <div
        form-field
        class="col-sm-6"
        [control]="form.get('type')"
        [group]="form"
      >
        <ng-select
          class="edit-network"
          [items]="types"
          bindLabel="name"
          [dropdownPosition]="'bottom'"
          formControlName="type"
          [placeholder]="'Type *'"
          (change)="changeNetworkType($event)" 
          [disabled]="true"     
        >
        </ng-select>
      </div>
    </div>
    <div class="row">
      <div
        form-field
        class="col-sm-6 input-alignment"
        [control]="form.get('tag')"
        [group]="form"
        [ngClass]="{'disabled-custom': this.form.get('tag').disabled }"
      >
        <span class="pb-7 hashtag-icon">#</span>
        <input
        [ngClass]="{'disabled-custom': this.form.get('tag').disabled }"
          type="text"
          class="form-control no-box"
          id="inputTag"
          formControlName="tag"
          (keydown)="hashtagKeyDown($event)"
        />
        <label
          class="floating-label"
          [ngClass]="{
            'go-top': form?.get('tag')?.valid || form?.get('tag')?.disabled,
            'disabled-custom': this.form.get('tag').disabled 
          }"
          >Tag *</label
        >
        <div
          class="col-12 alert alert-danger alert-light"
          role="alert"
          *ngIf="(isAlreadyCreated$ | async)"
        >
          <strong>Warning!</strong> This Hashtag is already used
        </div>
      </div>
      <div class="col-sm-6">
        <ng-select
          class="edit-network"
          [items]="visibilities"
          bindLabel="name"
          [dropdownPosition]="'bottom'"
          [(ngModel)]="selectedVisibility"
          formControlName="visibility"
          [placeholder]="'Status *'"
          (change)="setValidators()"
        >
        </ng-select>
      </div>
    </div>
    <ng-container *ngIf="form?.get('type')?.value">
      <div class="row">
        <div class="col-sm-6">
          <kms-admin-people-picker
            [isMulti]="true"
            [userList]="form.get('facilitators')"
            [placeholder]="facilitatorPlaceholder"
          ></kms-admin-people-picker>
        </div>
        <div class="col-sm-6">
          <kms-admin-people-picker
            [enableCsvUpload]="true"
            [queryStringCsvParams]="'payloadNotForGuests=true'"
            [isMulti]="true"
            [userList]="form.get('coFacilitators')"
            [placeholder]="coFacilitatorPlaceholder"
            (fileLoaded)="fileHasBeenProcessed($event)"
            (uploadingProgress)="uploadingProgressFunction($event)"
          ></kms-admin-people-picker>
        </div>
      </div>
      <div class="row" *ngIf="form?.get('type')?.value?.id == networkTypes?.CoP">
        <div class="col-sm-6">
          <kms-admin-people-picker
            [isMulti]="true"
            [userList]="form.get('knowledgeOwners')"
            [placeholder]="'Knowledge Owners'"
            [restrictions]="PeoplePickerUserRestriction.OnlyKO"
          ></kms-admin-people-picker>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary btn-raised"
    [disabled]="form.invalid || (isAlreadyCreated$ | async) || csvLoading"
    [kmsClickWait]="confirm.bind(this)"
  >
    {{ confirmBtnName }}
  </button>
  <button
    type="button"
    class="btn btn-warning btn-raised"
    (click)="bsModalRef.hide()"
  >
    {{ closeBtnName }}
  </button>
</div>
