<div class="row" [formGroup]="form">
  <div
    form-field
    class="input-alignment col-sm-9"
    [control]="form.get('query')"
    [group]="form"
  >
    <input
      type="text"
      class="form-control no-box"
      formControlName="query"
    />
    <label
      class="floating-label"
      [ngClass]="{ 'go-top': form?.get('query')?.value }"
    >
      Search
    </label>
  </div> 

  <div class="col-sm-3">
    <div class="text-center">
      <button
        type="button"
        class="btn btn-primary btn-raised mr-3"
        (click)="search()"
      >
        Search
      </button>
      <button
        type="button"
        class="btn btn-primary btn-raised mr-3"
        (click)="reset()"
      >
        Reset
      </button>
    </div>
  </div>
</div>
<br/>
<ngx-datatable
  class="bootstrap"
  [rows]="transcriptInfosMapping"
  [columnMode]="'force'"
  [rowHeight]="'auto'"
  [footerHeight]="'auto'"
  [limit]="limit"
  [messages]="{
    emptyMessage: '',
    totalMessage: 'total',
    selectedMessage: 'selected'
  }"
  [sorts]="[{ prop: 'transcriptWER', dir: 'asc' }]"
>
  <ngx-datatable-column
    [width]="30"
    [sortable]="false"
    [canAutoResize]="false"
    [draggable]="false"
    [resizeable]="false"
  >
    <ng-template let-transcriptedit="row" ngx-datatable-cell-template>
      <a
        [tooltip]="'Show'"
        containerClass="tooltip-class"
        container="body"
        class="clickable-icon"
        (click)="onShow(transcriptedit)"
      >
        <i class="fa fa-search fa-lg search"></i>
      </a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Webinar Title" prop="name"><!-- inside WebinarSlimInfo -->
    <ng-template let-name="value" ngx-datatable-cell-template>
      <div>{{ name }}</div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Chapter Name" prop="chapterName"><!-- inside Chapter -->
    <ng-template let-chapterName="value" ngx-datatable-cell-template>
      <div>{{ chapterName }}</div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Word Error Rate" prop="transcriptWER"><!-- inside WebinarVideoTranscriptEditInfo -->
    <ng-template let-transcriptWER="value" ngx-datatable-cell-template>
      <div>{{ transcriptWER }}</div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Confidence Score" prop="confidenceScore"><!-- inside Chapter -->
    <ng-template let-confidenceScore="value" ngx-datatable-cell-template>
      <div>{{ confidenceScore }}</div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Transcript Visible" prop="showTranscript"><!-- inside Chapter -->
    <ng-template let-showTranscript="value" ngx-datatable-cell-template>
      <div>{{ showTranscript }}</div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Transcript On VI" prop="isCommittedOnVI"><!-- inside WebinarSlimInfo -->
    <ng-template let-isCommittedOnVI="value" ngx-datatable-cell-template>
      <div>{{ isCommittedOnVI }}</div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Promoting Networks" prop="promotingNetworks"><!-- inside WebinarSlimInfo -->
    <ng-template let-promotingNetworks="value" ngx-datatable-cell-template>
      <div>{{ promotingNetworks }}</div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
      [width]="160"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
  <ng-template let-transcriptEdit="row" ngx-datatable-cell-template>
    <button
      class="btn btn-primary btn-raised mt-0 mb-0 pr-1 pl-1"
      (click)="openWebinar(transcriptEdit.webinarId)"
    >
      Go to webinar
    </button>
  </ng-template>
</ngx-datatable-column>
  <ngx-datatable-footer *ngIf="loadingIndicator"></ngx-datatable-footer>
</ngx-datatable>

<div *ngIf="loadingIndicator">
  <kms-admin-spinner [loading]="true"></kms-admin-spinner>
</div>
