import { Component, OnDestroy } from "@angular/core";
import { ReplaySubject } from "rxjs";


@Component({
    selector:"admin-destroyer",
    template:"",
    styleUrls:[],  
})
export class DestroyerComponent implements OnDestroy{

    public destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.unsubscribe();
    }
}