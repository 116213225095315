import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  OnDestroy,
  ChangeDetectorRef,
  TemplateRef
} from "@angular/core";

import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject } from "rxjs";
import { map, tap, takeUntil } from "rxjs/operators";
import { SnackbarService } from "ngx-snackbar";
import { QueryBuilderConfig } from "angular2-query-builder";
import { KeywordsEditComponent } from "./keywords-edit/keywords-edit.component";
import { IPMKeywordInfo } from "src/app/commons/entities";
import { LoggerService } from "src/app/services/logger.service";
import { SecurityService } from "src/app/services/security.service";
import { Constants } from "src/app/commons/util";
import { FormGroup, FormControl } from "@angular/forms";
import { NotificationService } from "src/app/services/notification.service";

@Component({
  selector: "kms-admin-keywords",
  templateUrl: "./keywords.component.html",
  styleUrls: ["./keywords.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class KeywordsComponent implements OnInit, OnDestroy {

  query: string;
  form: FormGroup;
  keywords: Observable<IPMKeywordInfo[]>;
  keywordsList: IPMKeywordInfo[] = [];
  loadingIndicator = false;
  limit = Constants.TABLE_LIMITS;
  modalRef: BsModalRef;

  keywordToDelete: IPMKeywordInfo = null;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private securitySvc: SecurityService,
    private readonly modalSvc: BsModalService,
    private readonly snackbarSvc: SnackbarService,
    private cd: ChangeDetectorRef,
    private logger: LoggerService,
    private notificationSvc: NotificationService
  ) {}

  ngOnInit() {
    this.form = new FormGroup(
      {
        query: new FormControl(this.query)
      });

    this.search();
   
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openAlertModal(template: TemplateRef<any>, selectedKeyword: IPMKeywordInfo) {
    this.keywordToDelete = selectedKeyword;
    this.modalRef = this.modalSvc.show(template);
  }

  async search() {   
    
   this.query = this.form.value.query;

    this.loadingIndicator = true;
    //this.cd.markForCheck();
    
    this.keywords = this.securitySvc.getAllIpmKeywords(this.query ? this.query : "").pipe(      
      tap(() => {
        this.loadingIndicator = false;
        this.cd.markForCheck();
      })
    );

    this.keywordsList = [];
    this.keywords.subscribe((data) => {
      data.forEach(item => {
        this.keywordsList.push(item);
      });      
    })
  }

  onCreate() { 
    const initialState = {  
      keywordInfo: null,   
      title: "Add IP keyword",
      confirmBtnName: "Save",
      closeBtnName: "Cancel",
      keywordsList: this.keywordsList,
      editMode: false
    };
    this.modalRef = this.modalSvc.show(KeywordsEditComponent, {
      initialState,
      class: "modal-transparent modal-lg mt-6"
    });

    this.modalRef.content.onClose.subscribe(
      result => {      
        if (result) {
          this.search();
        }
      });
  }

  async onEdit(selectedKeyword: IPMKeywordInfo) {
    if (selectedKeyword) {
      const initialState = {
        keywordInfo: selectedKeyword,
        title: "Edit IP keyword",
        confirmBtnName: "Update",
        closeBtnName: "Cancel",
        keywordsList: this.keywordsList,
        editMode: true
      };
      this.modalRef = this.modalSvc.show(KeywordsEditComponent, {
        initialState,
        class: "modal-transparent modal-lg mt-6"
      });

      this.modalRef.content.onClose.subscribe(
        result => {      
         
            this.search();
       
        });
    } else {
      this.logger.warning(`Cannot find the selected keyword: ${selectedKeyword}`);
      this.snackbarSvc.add({
        msg: `Cannot find the selected keyword`,
        background: "red",
        action: { text: null }
      });
    }
  }

  async onDelete() {
    let action: Promise<any>;
    action = this.securitySvc.deleteIpmKeyword(this.keywordToDelete.id).toPromise();
      
    const { transactionId } = await action;

    await this.notificationSvc
      .waitForTransactionResult(transactionId)
      .toPromise();

    this.modalRef.hide();  
   
    this.search();
  
  }
}
