<ng-container *ngIf="ChallengeGroup">
  <!-- <div class="modal-header">
    <h4 class="modal-title pull-left mt-0">{{ title }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> -->

  <div class="modal-body promote-body pb-0 pr-0 pl-0 ">
    <div
      class="ms-hero-page-override ms-hero-img-puzzle ms-bg-fixed ms-hero-bg-primary pb-1 pt-1"
    >
      <div class="text-center">
        <div class="avatar-view-container" *ngIf="!editImage">
          <kms-admin-avatar-view
            (edit)="editImage = true"
            [type]="type"
            [imageUrl]="ChallengeGroup.get('logoUrl').value"
            [zoomIn]="true"
            [canEditImage]="true"
          >
          </kms-admin-avatar-view>
        </div>
        <div class="avatar-edit-container" *ngIf="editImage">
          <kms-image-cropper
            [autoOpen]="true"
            [imageKey]="ChallengeGroup.get('id')"
            [imageUrl]="ChallengeGroup.get('logoUrl')"
            (endCropping)="endCropping()"
            [type]="type"
          >
          </kms-image-cropper>
        </div>
      </div>
    </div>
    <!-- HEADER END -->
    <tabset [justified]="true">
      <tab>
        <!-- PRIMO TAB START -->
        <ng-template tabHeading> Challenge Main Information </ng-template>
        <div class="form-group container-fluid">
          <div class="form-group row is-empty" [formGroup]="ChallengeGroup">
            <label
              for="inputTitle"
              autocomplete="false"
              class="col-lg-2 control-label labelSize"
              >Name *</label
            >
            <div
              class="col-lg-10 pl-0 input-alignment"
              form-field
              [control]="ChallengeGroup.get('name')"
              [group]="ChallengeGroup"
            >
              <input
                type="text"
                class="form-control"
                id="inputTitle"
                formControlName="name"
                placeholder="Name"
              />
            </div>
          </div>
          <div
            class="form-group row is-empty"
            [formGroup]="ChallengeGroup.get('hashtag')"
          >
            <label
              for="inputHashtag"
              autocomplete="false"
              class="col-lg-2 control-label labelSize"
              >Hashtag *
            </label>
            <div
              class="col-lg-10 pl-0"
              form-field
              [control]="ChallengeGroup.get('hashtag').get('name')"
              [group]="ChallengeGroup.get('hashtag')"
            >
              <div class="d-flex align-items-center">
                <span class="pb-7">#</span>
                <input
                  type="text"
                  [attr.disabled]="canEditTag ? null : 'disabled'"
                  (keydown)="hashtagKeyDown($event)"
                  class="form-control"
                  id="inputHashtag"
                  formControlName="name"
                  placeholder="Hashtag"
                />
              </div>
            </div>
          </div>
          <div
            class="col-lg-10 offset-lg-2 alert alert-warning "
            role="alert"
            *ngIf="isAlreadyCreated$ | async"
          >
            <strong>Warning!</strong> This tag already exists, but hasn't been
            promoted yet. You can use it, but that is not encouraged
          </div>
          <div
            class="col-lg-10 offset-lg-2 alert alert-danger alert-light "
            role="alert"
            *ngIf="isPromoted$ | async"
          >
            <strong>Warning!</strong> You cannot use this tag, since it has
            already been used
          </div>
          <div
            class="col-lg-10 offset-lg-2 alert alert-danger alert-light "
            role="alert"
            *ngIf="(isDeprecated$ | async) && !(isPromoted$ | async)"
          >
            <strong>Warning!</strong> You cannot use this tag, since it has been
            deprecated
          </div>
          <div
            class="col-lg-10 offset-lg-2 alert alert-danger alert-light "
            role="alert"
            *ngIf="isPrivate$ | async"
          >
            <strong>Warning!</strong> You cannot use this tag, since it is
            private
          </div>
          <div class="form-group row is-empty" [formGroup]="ChallengeGroup">
            <label
              for="inputFocus"
              autocomplete="false"
              class="col-lg-2 control-label labelSize"
              >Description *</label
            >
            <div
              class="col-lg-10 pl-0"
              form-field
              [control]="ChallengeGroup.get('description')"
              [group]="ChallengeGroup"
            >
              <!-- <input
                type="text"
                class="form-control"
                id="inputFocus"
                formControlName="focus"
                placeholder="Description"
              /> -->
              <kms-admin-rich-text-editor-widget
                [group]="ChallengeGroup"
                [isEditable]="true"
                [propertyName]="'description'"
                [placeholder]="'Description'"
              ></kms-admin-rich-text-editor-widget>
            </div>
          </div>
          <div class="form-group row is-empty">
            <label
              for="inputInterval"
              autocomplete="false"
              class="col-lg-2 control-label labelSize"
              >Validity Date *
              <i
                class="fa fa-info-circle"
                data-placement="top"
                data-toggle="tooltip"
                title="To select the dates range, please click on the Start Date and then on End Date in the calendar"
              ></i
            ></label>
            <div
              class="calendar col-lg-10 pl-0"
              *ngIf="newChallenge; else loadedChallenge"
            >
              <kms-admin-date-picker
                (click)="clear()"
                (rangeChange)="onDifferentDateRange($event)"
                [isOpen]="false"
              ></kms-admin-date-picker>
            </div>
            <ng-template #loadedChallenge>
              <div class="calendar col-lg-10 pl-0">
                <kms-admin-date-picker
                  [isDisabled]="isOpen"
                  [range]="interval"
                  (click)="clear()"
                  (rangeChange)="onDifferentDateRange($event)"
                  [isOpen]="false"
                ></kms-admin-date-picker>
              </div>
            </ng-template>
          </div>
          <div
            class="col-lg-10 offset-lg-2 alert alert-danger alert-light "
            role="alert"
            *ngIf="!isOpen && (isAlreadyStarted$ | async)"
          >
            <strong>Warning!</strong> Start Date cannot be before today
          </div>
          <div
            class="col-lg-10 offset-lg-2 alert alert alert-danger alert-light "
            role="alert"
            *ngIf="isSameDay$ | async"
          >
            <strong>Warning!</strong> Start Date and End Date cannot overlap
          </div>
          <div
            class="col-lg-10 offset-lg-2 alert alert-danger alert-light"
            role="alert"
            *ngIf="isAlreadyExpired$ | async"
          >
            <strong>Warning!</strong> End Date cannot be before today
          </div>
          <div
            class="col-lg-10 offset-lg-2 alert alert-danger alert-light "
            role="alert"
            *ngIf="isOpen"
          >
            <strong>Warning!</strong> You can only modify the End Date.
          </div>
        </div>
      </tab>
      <!-- PRIMA TAB END -->

      <!-- SECONDO TAB START -->
      <tab>
        <ng-template tabHeading> Challenge Additional Information * </ng-template>
        <div class="form-group">
          <div class="form-group row is-empty">
            <label
              for="inputInvolvedPeople"
              autocomplete="false"
              class="col-lg-2 mb-1 control-label labelSize"
              >Involved <span>People *</span></label
            >
            <div
              class="col-lg-10 pl-0"
              form-field
              [control]="ChallengeGroup.get('involvedPeople')"
              [group]="ChallengeGroup"
            >
              <kms-admin-people-picker
                [userList]="ChallengeGroup.get('involvedPeople')"
                [edit]="true"
              ></kms-admin-people-picker>
            </div>
          </div>
          <div class="form-group row is-empty">
            <label
              for="inputEvaluationCommittee"
              autocomplete="false"
              class="col-lg-2 mb-1 control-label labelSize"
              >Evaluation Committee</label
            >
            <div
              class="col-lg-10 pl-0"
              form-field
              [control]="ChallengeGroup.get('evaluationCommittee')"
              [group]="ChallengeGroup"
            >
              <kms-admin-people-picker
                [userList]="ChallengeGroup.get('evaluationCommittee')"
                [edit]="true"
              ></kms-admin-people-picker>
            </div>
          </div>

          <div class="form-group row is-empty">
            <label
              for="inputInterval"
              autocomplete="false"
              class="col-lg-2 control-label labelSize"
              >Date of the Evaluation Committee
            </label>
            <div class="calendar col-lg-10 pl-0">
              <kms-admin-date-picker
                (click)="clear()"
                (singleChange)="onSelectSingleDate($event)"
                [isOpen]="false"
                [isSingleDate]="true"
                [singleDate]="
                  this.ChallengeGroup?.get('evaluationCommitteeDate')?.value
                "
              ></kms-admin-date-picker>
            </div>
          </div>

          <div class="form-group row is-empty">
            <label
              for="inputPromotingNetwork"
              autocomplete="false"
              class="col-lg-2 mb-1 control-label labelSize"
              >Promoting Networks *</label
            >
            <div class="col-lg-10 pl-0">
              <ng-select
                class="post-audience"
                [items]="network$ | async"
                bindLabel="name"
                [addTag]="false"
                [multiple]="true"
                [hideSelected]="true"
                [loading]="networkLoading"
                [placeholder]="'Choose network'"
                [dropdownPosition]="'bottom'"
                [typeahead]="networkInput$"
                [ngModel]="ChallengeGroup.get('promotingNetworks').value"
                (add)="addNetwork($event)"
                (remove)="removeNetwork($event)"
                (clear)="removeAllNetwork()"
              >
              </ng-select>
            </div>
          </div>
          <div class="form-group row is-empty" *ngIf="false">
            <label
              for="inputBusinessImpact"
              autocomplete="false"
              class="col-lg-2 mb-1 control-label labelSize"
              >Business Impact</label
            >
            <div class="col-lg-10 pl-0">
              <ng-select
                class="post-audience"
                [items]="businessList"
                bindLabel="value"
                [addTag]="false"
                [multiple]="false"
                [hideSelected]="false"
                [dropdownPosition]="'bottom'"
                [ngModel]="preSelectBusinness"
                (change)="modifyBusiness($event)"
              >
              </ng-select>
            </div>
          </div>
          <div class="form-group row is-empty">
            <label
              autocomplete="false"
              class="col-lg-2 mb-1 control-label labelSize"
              >Mostly Impacted Area</label
            >
            <div class="col-lg-10 pl-0">
              <ng-select
                class="post-audience"
                [items]="impactedAreaList"
                bindLabel="value"
                [addTag]="false"
                [multiple]="false"
                [hideSelected]="false"
                [dropdownPosition]="'bottom'"
                [placeholder]="'Choose Impacted Area'"
                [ngModel]="preSelectImpactedArea"
                (change)="modifyImpactedArea($event)"
              >
              </ng-select>
            </div>
          </div>
          <div class="form-group row is-empty">
            <label
              for="inputStrategicRelatedTag"
              autocomplete="false"
              class="col-lg-2 mb-1 control-label labelSize"
              >Strategic and Related Tags</label
            >
            <div class="col-lg-10 pl-0">
              <ng-select
                class="post-audience"
                [items]="hashtag$ | async"
                bindLabel="name"
                [addTag]="false"
                [multiple]="true"
                [hideSelected]="true"
                [loading]="hashtagLoading"
                [placeholder]="'Choose hashtag'"
                [dropdownPosition]="'bottom'"
                [typeahead]="hashtagInput$"
                [ngModel]="ChallengeGroup.get('relatedTags').value"
                (add)="addHashtag($event)"
                (remove)="removeHashtag($event)"
                (clear)="removeAllHashtag()"
                [compareWith]="compareWith"
              >
              </ng-select>
            </div>
          </div>
        </div>
      </tab>
    </tabset>

    <!-- BUTTONS -->
    <div class="form-group row is-empty flex-row-reverse">
      <button class="btn btn-raised btn-warning mr-1" (click)="cancel()">
        {{ closeBtnName }}
      </button>
      <button
        type="button"
        class="btn btn-primary btn-raised"
        [disabled]="!canCreateChallenge"
        [kmsClickWait]="confirm.bind(this)"
      >
        {{ confirmBtnName }}
      </button>
    </div>
  </div>
</ng-container>
