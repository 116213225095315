<div class="modal-header">
  <h4 class="modal-title pull-left mt-0">{{ title }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid" [formGroup]="form">
    <div class="row">
      <div class="col-sm-6">
        <kms-admin-people-picker
          [isMulti]="false"
          [singleUser]="form.get('user').value"
          [placeholder]="'User *'"
          (add)="addUser($event)"
        ></kms-admin-people-picker>
      </div>
      <div class="col-sm-6">
        <ng-select
          class="edit-user"
          [items]="roles"
          bindLabel="name"
          [dropdownPosition]="'bottom'"
          formControlName="roles"
          [placeholder]="'Roles'"
          [multiple]="true"
        >
        </ng-select>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="form.invalid" class="btn btn-primary btn-disabled-style btn-raised" (click)="confirm()">
    {{ confirmBtnName }}
  </button>
  <button
    type="button"
    class="btn btn-warning btn-raised"
    (click)="bsModalRef.hide()"
  >
    {{ closeBtnName }}
  </button>
</div>
