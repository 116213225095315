<button
  *ngIf="editing"
  class="btn btn-primary btn-raised mt-0 mb-0 pr-1 pl-1 mr-1"
  [disabled]="isSaveDisabled"
  [kmsClickWait]="saveClick.bind(this)"
>
  <i class="fa fa-floppy-o" aria-hidden="true"></i>
  Save
</button>
<button
  class="btn btn-warning btn-raised mt-0 mb-0 pr-1 pl-1"
  (click)="cancelClick()"
  *ngIf="editing"
>
  <i class="fa fa-trash fa-lg trash"></i> Cancel
</button>
<button
  *ngIf="!editing"
  class="btn btn-primary btn-raised mt-0 mb-0 pr-1 pl-1 mr-1"
  (click)="editing = true"
>
  <i class="fa fa-pencil fa-lg pencil"></i>
  Edit
</button>
<button
  *ngIf="!editing"
  class="btn btn-primary btn-raised mt-0 mb-0 pr-1 pl-1"
  (click)="openModal()"
>
  <i class="fa fa-plus fa-lg plus"></i>
  Add
</button>
<kms-admin-card class="inner-card" animation="mt-2" *ngIf="medalSettings">
  <ngx-datatable
    class="bootstrap"
    [rows]="medalListFA.controls"
    [columnMode]="'force'"
    [rowHeight]="'auto'"
    [footerHeight]="'auto'"
    [limit]="limit"
    [messages]="{
      emptyMessage: '',
      totalMessage: 'total',
      selectedMessage: 'selected'
    }"
    [sorts]="[{ prop: 'minPoints', dir: 'asc' }]"
  >
    <ngx-datatable-column name="Name" prop="value.name">
      <ng-template let-medal="row" ngx-datatable-cell-template>
        <div *ngIf="!editing" class="fluid-text">
          {{ medal.value.name }}
        </div>
        <div
          form-field
          [control]="medal.get('name')"
          [formGroup]="medal"
          *ngIf="editing"
        >
          <input
            autofocus
            type="string"
            formControlName="name"
            class="no-box form-control"
          />
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Min Points" prop="value.minPoints">
      <ng-template let-medal="row" ngx-datatable-cell-template>
        <div
          [tooltip]="value"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
          *ngIf="!editing"
        >
          {{ medal.value.minPoints }}
        </div>
        <div
          form-field
          [control]="medal.get('minPoints')"
          [formGroup]="medal"
          *ngIf="editing"
        >
          <input
            autofocus
            type="number"
            kmsAdminNumberDirective
            formControlName="minPoints"
            class="no-box form-control"
          />
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Max Points" prop="value.maxPoints">
      <ng-template
        let-medal="row"
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
      >
        <div
          [tooltip]="value"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
          *ngIf="!editing"
        >
          {{ medal.value.maxPoints }}
        </div>
        <div
          form-field
          [control]="medal.get('maxPoints')"
          [formGroup]="medal"
          *ngIf="editing"
        >
          <input
            autofocus
            type="number"
            kmsAdminNumberDirective
            formControlName="maxPoints"
            class="no-box form-control"
          />
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Level Color" prop="value.cssClass">
      <ng-template
        let-medal="row"
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
      >
        <div class="fluid-text" *ngIf="!editing">
          <span [style.background-color]="medal.value.cssClass" class="border">&nbsp;&nbsp;&nbsp;&nbsp;</span>
          : {{ medal.value.cssClass.toUpperCase() }}
        </div>
        <div
          form-field
          [control]="medal.get('cssClass')"
          [formGroup]="medal"
          *ngIf="editing"
        >
          <input
            autofocus
            type="color"
            formControlName="cssClass"
            class="no-box form-control"
          />
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [width]="30"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
      *ngIf="editing"
    >
      <ng-template let-medal="row" ngx-datatable-cell-template>
        <a class="edit-icon" (click)="onDelete(medal)">
          <i class="fa fa-trash fa-lg trash color-danger"></i>
        </a>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer *ngIf="loadingIndicator"></ngx-datatable-footer>
  </ngx-datatable>
  <div *ngIf="loadingIndicator">
    <kms-admin-spinner [loading]="true"></kms-admin-spinner>
  </div>
</kms-admin-card>
