import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  OnDestroy,
  ChangeDetectorRef,
  TemplateRef
} from "@angular/core";

import { ConfigService } from "@ngx-config/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable, of, Subject } from "rxjs";
import { map, tap, takeUntil } from "rxjs/operators";
import { SnackbarService } from "ngx-snackbar";
import { QueryBuilderConfig } from "angular2-query-builder";
import { IPManagementEditComponent } from "./ipmanagement-edit/ipmanagement-edit.component";
import { IPMKeywordInfo } from "src/app/commons/entities";
import { LoggerService } from "src/app/services/logger.service";
import { SecurityService } from "src/app/services/security.service";
import { Constants } from "src/app/commons/util";
import { WebinarsService } from "src/app/services/webinars.service"
import { FormGroup, FormControl, ɵangular_packages_forms_forms_q } from "@angular/forms";
import { WebinarVideoTranscriptEditInfo, WebinarSlimInfo, TranscriptElementEditInfo } from 'src/app/commons/webinars-entities';
import { NetworkService } from 'src/app/services/network.service';

@Component({
  selector: "kms-admin-ipmanagement",
  templateUrl: "./ipmanagement.component.html",
  styleUrls: ["./ipmanagement.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class IPManagementComponent implements OnInit, OnDestroy {

  query: string = "";
  form: FormGroup;
  //keywords: Observable<IPMKeywordInfo[]>;
  webinarSlimInfos: WebinarSlimInfo[] = [];
  transcriptInfosMapping: TranscriptElementEditInfo[] = [];

  loadingIndicator = true;
  limit = Constants.TABLE_LIMITS;
  modalRef: BsModalRef;

  keywordToDelete: IPMKeywordInfo = null;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private securitySvc: SecurityService,
    private readonly modalSvc: BsModalService,
    private readonly snackbarSvc: SnackbarService,
    private cd: ChangeDetectorRef,
    private logger: LoggerService,
    private webinarsService: WebinarsService,
    private readonly config: ConfigService,
    private networkService: NetworkService
  ) { }

  ngOnInit() {
    this.form = new FormGroup(
      {
        query: new FormControl(this.query)
      });
    // first get video edits, they contain ids of videos ?
    this.getTranscripts();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openAlertModal(template: TemplateRef<any>, selectedKeyword: IPMKeywordInfo) {
    this.keywordToDelete = selectedKeyword;
    this.modalRef = this.modalSvc.show(template);
  }

  getTranscripts() {
    this.loadingIndicator = true;
    this.cd.markForCheck();

    this.webinarsService.getAllWebimarSlimInfos(this.query, this.limit, 0).subscribe(
      (retrievedWebinarSlimInfos) => {
        this.webinarSlimInfos = retrievedWebinarSlimInfos;
        //console.log("Retrieved from API " + JSON.stringify(this.webinarSlimInfos));
        this.loadingIndicator = false;
        this.cd.markForCheck();

        // flatten the retrievedWebinarSlimInfos for the table (transcriptInfosMapping)
        this.transcriptInfosMapping = [];
        retrievedWebinarSlimInfos.forEach((wSlimInfo) => {
          wSlimInfo.chapters.forEach((chap) => {
            this.transcriptInfosMapping.push(new TranscriptElementEditInfo(
              wSlimInfo.name, wSlimInfo.promotingNetworks, chap.name,
              chap.confidenceScore, chap.transcriptEdits.transcriptWER,
              chap.transcriptEdits.transcriptEdits, wSlimInfo.id, wSlimInfo.areEditsOnVI, chap.showTranscript
            ));
          });
        });

// Uncomment this to test the modal resizing when there are many edits;
      // var chapter = this.transcriptInfosMapping[0];
      // for (var i = 1; i <= 10; i++) {
      //   this.transcriptInfosMapping.push(chapter);
      //   this.transcriptInfosMapping[i].name = "Webinar Name";
      // }
      
      // Uncomment this to test the modal resizing when there are many edits;
        // var textEdit = this.transcriptInfosMapping[0].transcriptEdits[0];
        // for (var i = 20; i >= 1; i--) {
        //   this.transcriptInfosMapping[0].transcriptEdits.push(textEdit);
        // }

    this.networkService.searchNetwork("").subscribe((retrievedNetworks) => {

      this.transcriptInfosMapping.forEach((chap) => {
        var networkNames: string[] = [];

        chap.promotingNetworks.forEach((pn) => {
          //console.log('Checking for name ' + pn);
          var foundNetwork = retrievedNetworks.find(n => n.networkId === pn)
          if (foundNetwork != null) {
            networkNames.push(foundNetwork.name);
          }
          else {
            networkNames.push(pn);
          }
        });

        chap.promotingNetworks = networkNames;
        this.cd.markForCheck();
      });

    });
  });
  }

  reset() {
    this.query = "";
    this.form.reset();
    this.getTranscripts();
  }

  search() {
    this.query = this.form.value.query;
    this.getTranscripts();
  }

  async onShow(transcriptInfo: TranscriptElementEditInfo) {
    if (transcriptInfo) {
      const initialState = {
        transcript: transcriptInfo,
        title: "Transcript edits view for webinar \""+ transcriptInfo.name + "\"",
        confirmBtnName: (!transcriptInfo.isCommittedOnVI)?"Generate knowledge":"Re-Generate knowledge",
        closeBtnName: "Cancel"
      };
      this.modalRef = this.modalSvc.show(IPManagementEditComponent, {
        initialState,
        class: "modal-transparent modal-lg mt-6"
      });
    }
    else {
      this.logger.warning(`Cannot find the selected transcript info: ${transcriptInfo}`);
      this.snackbarSvc.add({
        msg: `Cannot find the selected transcript`,
        background: "red",
        action: { text: null }
      });
    }
  }

  openWebinar(webinar: any) {

    const webinarId = webinar ? webinar : null;

    if (webinarId != null) {
      window.open(
        `${this.config.getSettings().portalWebUrl}/webinars/calendar/video/${webinarId}`,
        "_blank"
      );
    } else {
      this.logger.warning(`Cannot find the selected webinar: ${webinar}`);
      this.snackbarSvc.add({
        msg: `Cannot find the selected webinar`,
        background: "red",
        action: { text: null }
      });
    }
  }

  onDelete() {
    this.securitySvc.deleteIpmKeyword(this.keywordToDelete.id).subscribe(
      () => {
        this.securitySvc.rolesUpdateSubject.next(); //// ??????????????????
      },
      error => {
        this.logger.error(error);
      }
    );
    this.modalRef.hide();
  }
}
