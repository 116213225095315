import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  OnDestroy,
  ChangeDetectorRef,
  TemplateRef
} from "@angular/core";

import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject } from "rxjs";
import { map, tap, takeUntil } from "rxjs/operators";
import { SnackbarService } from "ngx-snackbar";

import { LoggerService } from "src/app/services/logger.service";
import { SecurityService } from "src/app/services/security.service";
import { GuestUser } from "src/app/commons/entities";
import { GuestWhitelistEditComponent } from "./guest-whitelist-edit/guest-whitelist-edit.component";
import { NotificationService } from "src/app/services/notification.service";
import { Constants } from "src/app/commons/util";
import { QueryBuilderConfig } from "angular2-query-builder";
import {
  SearchService,
  KMSSearchResults
} from "src/app/services/search.service";

@Component({
  selector: "kms-admin-guest-whitelist",
  templateUrl: "./guest-whitelist.component.html",
  styleUrls: ["./guest-whitelist.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class GuestWhitelistComponent implements OnInit, OnDestroy {
  guestUsers: Observable<Array<GuestUser>>;
  loadingIndicator = false;
  userToDelete: GuestUser = null;

  modalRef: BsModalRef;

  lastUsedQuery = null;

  limit = Constants.TABLE_LIMITS;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  queryConfig: QueryBuilderConfig = {
    fields: {
      name: {
        name: "Display Name",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      email: {
        name: "Mail",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      manager: {
        name: "Referent",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      networks: {
        name: "Networks",
        type: "string",
        operators: ["contains"]
      },
      company: {
        name: "Company",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      status: {
        name: "Status",
        type: "string",
        operators: ["contains", "=", "!="]
      }
    }
  };

  constructor(
    private securitySvc: SecurityService,
    private readonly modalSvc: BsModalService,
    private readonly snackbarSvc: SnackbarService,
    private notificationSvc: NotificationService,
    private readonly searchSvc: SearchService,
    private cd: ChangeDetectorRef,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.search(null);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openAlertModal(template: TemplateRef<any>, selectedUser: GuestUser) {
    this.userToDelete = selectedUser;
    this.modalRef = this.modalSvc.show(template);
  }

  async search(query: string) {
    this.lastUsedQuery = query;

    const queryFilter = {
      filter: query,
      top: 1000
    };

    this.loadingIndicator = true;
    this.cd.markForCheck();

    this.guestUsers = this.searchSvc
      .queryRaw("GuestUsers", "*", queryFilter)
      .pipe(
        map((users: KMSSearchResults<GuestUser>) =>
          users.results.map(r => r.document)
        ),
        map(users => users.map(user => new GuestUser(user))),
        tap(() => {
          this.loadingIndicator = false;
          this.cd.markForCheck();
        })
      );
  }

  onCreate() {
    const initialState = {
      user: null,
      title: "Add User to Private Network (Guest)",
      confirmBtnName: "Save",
      closeBtnName: "Cancel"
    };
    this.modalRef = this.modalSvc.show(GuestWhitelistEditComponent, {
      initialState,
      class: "modal-transparent modal-lg mt-6"
    });
    this.modalRef.content.onClose.subscribe(
      result => {
        if (result) {
          setTimeout(() => {
            this.search(this.lastUsedQuery);
          }, 500);
        }
      },
      error => {
        this.logger.error(error);
      }
    );
    this.modalRef.content.openUser.subscribe(
      (result: GuestUser) => {
        if (result) {
          setTimeout(() => {
            this.onEdit(result.email, result);
          }, 500);
        }
      },
      error => {
        this.logger.error(error);
      }
    );
  }

  async onEdit(selectedMail: string, user?: GuestUser) {
    if (selectedMail) {
      let loadedUser: GuestUser = user;
      if (!user) {
        loadedUser = await this.securitySvc
          .getGuestUserByMail(selectedMail)
          .toPromise();
      }
      const initialState = {
        user: loadedUser,
        title: "Edit User in Private Network (Guest)",
        confirmBtnName: "Save",
        closeBtnName: "Cancel"
      };
      this.modalRef = this.modalSvc.show(GuestWhitelistEditComponent, {
        initialState,
        class: "modal-transparent modal-lg mt-6"
      });
      this.modalRef.content.onClose.subscribe(
        result => {
          if (result) {
            setTimeout(() => {
              this.search(this.lastUsedQuery);
            }, 500);
          }
        },
        error => {
          this.logger.error(error);
        }
      );
    } else {
      this.logger.warning(`Cannot find the selected user: ${selectedMail}`);
      this.snackbarSvc.add({
        msg: `Cannot find the selected user`,
        background: "red",
        action: { text: null }
      });
    }
  }

  async onDelete() {
    if (this.userToDelete) {
      try {
        let action: Promise<any>;
        action = this.securitySvc
          .deleteGuestUser(this.userToDelete.id)
          .toPromise();
        const { transactionId } = await action;

        await this.notificationSvc
          .waitForTransactionResult(transactionId)
          .toPromise();

        setTimeout(() => {
          this.search(this.lastUsedQuery);
        }, 500);
      } catch (error) {
        this.logger.error(error);
        this.notificationSvc.showDetailsError(error);
      }
    } else {
      this.logger.warning(`This user does not exist`);
      this.snackbarSvc.add({
        msg: `This user does not exist`,
        background: "red",
        action: { text: null }
      });
    }
    this.modalRef.hide();
  }
}
