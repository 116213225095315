import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NavbarComponent } from "./navbar/navbar.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { NumberDirectiveDirective } from "src/app/commons/number-directive.directive";
import { ClickWaitModule } from "../click-wait/click-wait.module";

const IMPORTS = [CommonModule, RouterModule, ClickWaitModule];
const DECLARATIONS = [
  NavbarComponent,
  SpinnerComponent,
  NumberDirectiveDirective
];

@NgModule({
  imports: [...IMPORTS],
  declarations: [...DECLARATIONS],
  exports: [...IMPORTS, ...DECLARATIONS]
})
export class CoreModule {}
