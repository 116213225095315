import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { QueryBuilderModule } from "angular2-query-builder";

import { QueryBuilderComponent } from "./query-builder/query-builder.component";

const IMPORTS = [CommonModule, QueryBuilderModule, FormsModule];
const DECLARATIONS = [QueryBuilderComponent];

@NgModule({
  imports: [...IMPORTS],
  declarations: [...DECLARATIONS],
  exports: [...IMPORTS, ...DECLARATIONS]
})
export class QueryBuilderComponentModule {}
