<kms-admin-query-builder
  [config]="queryConfig"
  (queryEvent)="search($event)"
></kms-admin-query-builder>
<kms-admin-card class="inner-card" animation="mt-2">
  <button
    class="btn btn-primary btn-raised mt-0 mb-0 pr-1 pl-1 mr-1"
    (click)="editClick()"
    *ngIf="!isEditing"
  >
    <i class="fa fa-pencil fa-lg pencil"></i> Edit
  </button>
  <button
    class="btn btn-primary btn-raised mt-0 mb-0 pr-1 pl-1 mr-1"
    (click)="editSelectedClick()"
    *ngIf="!isEditing"
    [disabled]="selected <= 0"
  >
    <i class="fa fa-pencil fa-lg pencil"></i> Edit Selected
  </button>
  <button
    class="btn btn-primary btn-raised mt-0 mb-0 pr-1 pl-1"
    (click)="saveAllClick()"
    *ngIf="!isEditing"
    [disabled]="!isModified"
  >
    <i class="fa fa-floppy-o fa-lg"></i> Save All
  </button>
  <button
    class="btn btn-warning btn-raised mt-0 mb-0 pr-1 pl-1"
    (click)="cancelClick()"
    *ngIf="isEditing"
  >
    <i class="fa fa-trash fa-lg trash"></i> Cancel
  </button>

  <button
    class="btn btn-info btn-raised mt-0 mb-0 pr-1 pl-1 f-right"
    (click)="searchOnlyEmptyName()"
    
  >
  <i class="fa fa-search"></i> Show not mapped
  </button>
  <ngx-datatable 
    class="bootstrap"
    [rows]="bu | async"
    [columnMode]="'force'"
    [rowHeight]="'auto'"
    [footerHeight]="'auto'"
    [limit]="limit"
    [messages]="{
      emptyMessage: '',
      totalMessage: 'total',
      selectedMessage: 'selected'
    }"
    [sorts]="[{ prop: 'name', dir: 'asc' }]"
    [selected]="selected"
    [selectionType]="'multiClick'"
    
    (select)="onSelect($event)"
  >
    <ngx-datatable-column
      [width]="30"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
      
     
    >
      <!-- <ng-template
        ngx-datatable-header-template
        let-value="value"
        let-allRowsSelected="allRowsSelected"
        let-selectFn="selectFn"
      >
        <input 
          type="checkbox"
          [checked]="allRowsSelected"
          (change)="myselectFn(!allRowsSelected)"
        />
      </ng-template> -->

       <ng-template
        ngx-datatable-cell-template
        let-bu="row"
        let-isSelected="isSelected"
        let-onCheckboxChangeFn="onCheckboxChangeFn"
      >
        <input
          type="checkbox"
          [checked]="isSelected"
          *ngIf="( bu?.name || bu?.grouping || bu?.customBU ) ? false : true"
          (change)="onCheckboxChangeFn($event)"
        />
      </ng-template>

    </ngx-datatable-column>

    <ngx-datatable-column name="City" prop="city">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div
          [tooltip]="value"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ value }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Company" prop="company">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div
          [tooltip]="value"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ value }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Business Unit" prop="name">
      <ng-template let-bu="row" ngx-datatable-cell-template>
        <div
          *ngIf="!isEditing"
          [tooltip]="value"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ bu.name }}
        </div>
        <input
          autofocus
          *ngIf="isEditing"
          type="text"
          [(ngModel)]="bu.name"
          class="no-box form-control"
        />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Grouping" prop="grouping">
      <ng-template let-bu="row" ngx-datatable-cell-template>
        <div
          *ngIf="!isEditing"
          [tooltip]="value"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ bu.grouping }}
        </div>
        <input
          autofocus
          *ngIf="isEditing"
          type="text"
          [(ngModel)]="bu.grouping"
          class="no-box form-control"
        />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Custom BU" prop="customBU">
      <ng-template let-bu="row" ngx-datatable-cell-template>
        <div
          *ngIf="!isEditing"
          [tooltip]="value"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ bu.customBU }}
        </div>
        <input
          autofocus
          *ngIf="isEditing"
          type="text"
          [(ngModel)]="bu.customBU"
          class="no-box form-control"
        />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
      *ngIf="isEditing"
    >
      <ng-template let-bu="row" ngx-datatable-cell-template>
        <button
          class="btn btn-primary btn-raised mt-0 mb-0 pr-1 pl-1"
          [disabled]="isDisable(bu)"
          [kmsClickWait]="saveClick.bind(this, bu)"
        >
          <i class="fa fa-floppy-o" aria-hidden="true"></i>

          Save
        </button>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer *ngIf="loadingIndicator"></ngx-datatable-footer>
  </ngx-datatable>
  <div *ngIf="loadingIndicator">
    <kms-admin-spinner [loading]="true"></kms-admin-spinner>
  </div>
</kms-admin-card>
