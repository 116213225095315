<kms-admin-query-builder
  [config]="queryConfig"
  (queryEvent)="search($event)"
></kms-admin-query-builder>

<div class="text-right">
  <button
    type="button"
    class="btn btn-primary btn-raised mr-3"
    (click)="onCreate()"
  >
    Add
  </button>
</div>

<ngx-datatable
  class="bootstrap"
  [rows]="externalUsers | async"
  [columnMode]="'force'"
  [rowHeight]="'auto'"
  [footerHeight]="'auto'"
  [limit]="limit"
  [messages]="{
    emptyMessage: '',
    totalMessage: 'total',
    selectedMessage: 'selected'
  }"
  [sorts]="[{ prop: 'upn', dir: 'asc' }]"
>
  <ngx-datatable-column
    [width]="30"
    [sortable]="false"
    [canAutoResize]="false"
    [draggable]="false"
    [resizeable]="false"
  >
    <ng-template let-user="row" ngx-datatable-cell-template>
      <a
        [tooltip]="'Edit'"
        containerClass="tooltip-class"
        container="body"
        class="clickable-icon"
        (click)="onEdit(user?.upn)"
      >
        <i class="fa fa-pencil fa-lg pencil"></i>
      </a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [width]="30"
    [sortable]="false"
    [canAutoResize]="false"
    [draggable]="false"
    [resizeable]="false"
  >
    <ng-template let-user="row" ngx-datatable-cell-template>
      <a
        [tooltip]="'Delete'"
        containerClass="tooltip-class"
        container="body"
        class="clickable-icon"
        (click)="openAlertModal(alertDelete, user)"
      >
        <i class="fa fa-trash-o fa-lg"></i>
      </a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Name" prop="firstName" [width]="140" [canAutoResize]="false">
    <ng-template let-name="value" ngx-datatable-cell-template>
      <div
        [tooltip]="name"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ name }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Surname" prop="lastName" [width]="140" [canAutoResize]="false">
    <ng-template let-surname="value" ngx-datatable-cell-template>
      <div
        [tooltip]="surname"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ surname }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Mail" prop="email">
    <ng-template let-mail="value" ngx-datatable-cell-template>
      <div
        [tooltip]="mail"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ mail }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="UPN" prop="upn">
    <ng-template let-upn="value" ngx-datatable-cell-template>
      <div
        [tooltip]="upn"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ upn }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Referent" prop="employeeReference">
    <ng-template let-referent="value" ngx-datatable-cell-template>
      <div
        [tooltip]="referent?.displayName"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ referent?.displayName }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Company" prop="company">
    <ng-template let-company="value" ngx-datatable-cell-template>
      <div
        [tooltip]="company"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ company }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="City" prop="city">
    <ng-template let-city="value" ngx-datatable-cell-template>
      <div
        [tooltip]="city"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ city }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Business Unit" prop="businessUnit">
    <ng-template let-bu="value" ngx-datatable-cell-template>
      <div
        [tooltip]="bu?.name"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ bu?.name }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Assigned<br />CoPs" prop="assignedCops">
    <ng-template let-assignedCops="value" ngx-datatable-cell-template>
      <div
        [tooltip]="assignedCops"
        tooltipPlacement="top"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ assignedCops }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Assigned<br />BUs" prop="assignedBU">
    <ng-template let-assignedBU="value" ngx-datatable-cell-template>
      <div
        [tooltip]="assignedBU"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ assignedBU }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Assigned<br />Sp. Tags" prop="assignedSpTags">
    <ng-template let-assignedSpTags="value" ngx-datatable-cell-template>
      <div
        [tooltip]="assignedSpTags"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ assignedSpTags }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Assigned<br />Private<br />Networks" prop="assignedPN">
    <ng-template let-assignedPN="value" ngx-datatable-cell-template>
      <div
        [tooltip]="assignedPN"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ assignedPN }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="LeLe<br />enabled" prop="isLeleEnabled">
    <ng-template let-isLeleEnabled="value" ngx-datatable-cell-template>
      <div
        container="body"
        class="fluid-text"
      >
        {{ isLeleEnabled === true ? 'Sì' : 'No' }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-footer *ngIf="loadingIndicator"></ngx-datatable-footer>
</ngx-datatable>

<div *ngIf="loadingIndicator">
  <kms-admin-spinner [loading]="true"></kms-admin-spinner>
</div>

<ng-template #alertDelete>
  <div class="modal-header">
    <h4 class="modal-title pull-left mt-0">Confirm user delete</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to remove the user?</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary btn-raised"
      [kmsClickWait]="onDelete.bind(this)"
    >
      Ok
    </button>
    <button
      type="button"
      class="btn btn-warning btn-raised"
      (click)="modalRef?.hide()"
    >
      Cancel
    </button>
  </div>
</ng-template>
