import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./components/core/core.module";
import { TabsModule } from "ngx-bootstrap/tabs";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient
} from "@angular/common/http";
import { CustomInterceptor } from "./services/http-interceptor";
import { ConfigLoader, ConfigModule } from "@ngx-config/core";
import { ConfigHttpLoader } from "@ngx-config/http-loader";
import {
  MsAdalAngular6Module,
  AuthenticationGuard
} from "microsoft-adal-angular6";
import {
  ApplicationInsightsModule,
  AppInsightsService
} from "@markpieszak/ng-application-insights";
import { environment } from "src/environments/environment";
import { NgxPermissionsModule } from "ngx-permissions";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { SnackbarModule } from "ngx-snackbar";

import { NetworkModule } from "./pages/network/network.module";
import { ChallengeModule } from "./pages/challenge/challenge.module";
import { StrategicTagsModule } from "./pages/strategic-tags/strategic-tags.module";
import { ConfigurationModule } from "./pages/configuration/configuration.module";
import { RedirectGuard } from "./commons/guards";
import { GamificationModule } from "./pages/gamification/gamification.module";
import { BusinessUnitModule } from "./pages/business-unit/business-unit.module";
import { CookieConsentComponent, CookieConsentModule, CookieConsentService } from "@mcs/cookie-consent";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function configFactory(http: HttpClient): ConfigLoader {
  return new ConfigHttpLoader(http, `config/config.${window['__KMS_ENV']}.json`); // API ENDPOINT
  
}

export function getAdalConfig() {
  return {
    tenant: environment.authenticationOptions.tenant,
    clientId: environment.authenticationOptions.clientId,
    endpoints: environment.authenticationOptions.endpoints,
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl:
      environment.authenticationOptions.navigateToLoginRequestUrl,
    cacheLocation: environment.authenticationOptions.cacheLocation
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CookieConsentModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    SnackbarModule.forRoot(),
    HttpClientModule,
    MsAdalAngular6Module.forRoot(getAdalConfig),
    NgxPermissionsModule.forRoot(),
    ConfigModule.forRoot({
      provide: ConfigLoader,
      useFactory: configFactory,
      deps: [HttpClient]
    }),
    ApplicationInsightsModule.forRoot({
      instrumentationKeySetLater: true
    }),
    TooltipModule.forRoot(),
    NetworkModule, // Imported here because of a problem on the entryComponent
    ChallengeModule, // Imported here because of a problem on the entryComponent
    StrategicTagsModule, // Imported here because of a problem on the entryComponent
    ConfigurationModule, // Imported here because of a problem on the entryComponent
    GamificationModule, // Imported here because of a problem on the entryComponent
    BusinessUnitModule // Imported here because of a problem on the entryComponent
    , BrowserAnimationsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true },
    AuthenticationGuard,
    AppInsightsService,
    RedirectGuard,
    CookieConsentComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
