<div class="modal-header">
  <h4 class="modal-title pull-left mt-0">{{ title }}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [formGroup]="form">
  <div class="container-fluid">
    <tabset [justified]="true" class="tab-container">
      <tab class="pr-1 pl-1 pt-2" id="0" (click)="onTabSelect(0)">
        <ng-template tabHeading *ngIf="addMode">
          <strong>Single User mode</strong>
        </ng-template>
        <ng-template tabHeading *ngIf="!addMode">
          User Details
        </ng-template>
          <div class="row">
            <div form-field class="input-alignment" [control]="form.get('mail')" [group]="form"
              [ngClass]="{ 'col-sm-10': !user, 'col-sm-12': user }">
              <input type="text" class="form-control no-box" formControlName="mail" (keyup)="changeMail()" />
              <label class="floating-label" [ngClass]="{ 'go-top': form?.get('mail')?.value }">Mail *</label>
              <div class="col-12 alert alert-danger alert-light" role="alert" *ngIf="showWarning">
                <strong>Warning!</strong> {{ warningMessage }}
              </div>
            </div>
            <div class="col-sm-2" *ngIf="!user">
              <button type="button" class="btn btn-primary btn-raised mr-3" [kmsClickWait]="checkMail.bind(this)">
                <i class="fa fa-check mr-0"></i><span hidden>confirm</span>
              </button>
              <button *ngIf="showWarning" type="button" class="btn btn-primary btn-raised mr-3 pr-1 pl-1"
                (click)="changeUser()">
                See User
              </button>
            </div>
          </div>
          <div class="row">
            <div form-field class="col-sm-6 input-alignment mt-1 pb-1" [control]="form.get('displayName')"
              [group]="form">
              <input type="text" class="form-control no-box" formControlName="displayName" />
              <label class="floating-label" [ngClass]="{ 'go-top': form?.get('displayName')?.value }">Display Name
                (LastName FirstName)*</label>
            </div>
            <div form-field class="col-sm-6 input-alignment mt-1 pb-1" [control]="form.get('company')"
              [group]="form">
              <input type="text" class="form-control no-box" formControlName="company" />
              <label class="floating-label" [ngClass]="{ 'go-top': form?.get('company')?.value }">Company*</label>
            </div>
          </div>
      </tab>

      <tab class="pr-1 pl-1 pt-2" id="1" (click)="onTabSelect(1)" *ngIf="addMode">
        <ng-template tabHeading>
          <strong>Multiple Users mode</strong>
        </ng-template>
          <div class="row">
            <kms-admin-file-upload
            class="pr-1 pl-1 pt-2"
            style="width: 85%;"
            [queryStringCsvParams]="'payloadNotForGuests=false'"
            [isGuestCsvFile]="true"
            [showCsvHelp]="true"
            (fileLoaded)="updateFile($event)"
            (uploadingProgress)="uploadingProgress($event)"
          ></kms-admin-file-upload>
          <div class="row" *ngIf="fileUploading">
            <label>
              {{progressLabel}}
            </label>
            <kms-admin-spinner [loading]="true" style="width: 35px; height: 35px;"></kms-admin-spinner>
          </div>
          </div>
      </tab>
    </tabset>
  </div>
  <div class="row" style="margin-left:10px;margin-right:8px">
    <div form-field class="col-sm-6 input-alignment mt-1" [control]="form.get('referent')" [group]="form">
      <kms-admin-people-picker [isMulti]="false" [singleUser]="form.get('referent').value" [placeholder]="'Referent *'"
        [showExternalConsultant]="false" [restrictions]="referentRestriction" (add)="addUser($event)">
      </kms-admin-people-picker>
    </div>
    <div form-field class="col-sm-6 input-alignment mt-1" [control]="form.get('networks')" [group]="form">
      <ng-select class="edit-network" [items]="network$ | async" bindLabel="name" formControlName="networks"
        [addTag]="false" [multiple]="true" [hideSelected]="true" [loading]="networkLoading"
        [placeholder]="'Private Networks *'" [typeahead]="networkInput$" [(ngModel)]="network">
      </ng-select>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="form.invalid || !isMailConfirmed"
    class="btn btn-primary btn-disabled-style btn-raised" [kmsClickWait]="confirm.bind(this)">
    {{ confirmBtnName }}
  </button>
  <button type="button" class="btn btn-warning btn-raised" (click)="bsModalRef.hide()">
    {{ closeBtnName }}
  </button>
</div>