<kms-admin-query-builder
  [config]="queryConfig"
  (queryEvent)="search($event)"
></kms-admin-query-builder>

<div class="text-right">
  <button
    type="button"
    class="btn btn-primary btn-raised mr-3"
    (click)="onCreate()"
  >
    NEW STRATEGIC TAG
  </button>
</div>

<kms-admin-card class="inner-card" animation="mt-2">
  <ngx-datatable
    class="bootstrap"
    [rows]="strategicTags | async"
    [columnMode]="'force'"
    [rowHeight]="'auto'"
    [footerHeight]="'auto'"
    [limit]="limit"
    [messages]="{
      emptyMessage: '',
      totalMessage: 'total',
      selectedMessage: 'selected'
    }"
    [sorts]="[{ prop: 'name', dir: 'asc' }]"
  >
    <ngx-datatable-column
      [width]="30"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template let-strategic="row" ngx-datatable-cell-template>
        <a class="edit-icon" (click)="onEdit(strategic)">
          <i class="fa fa-pencil fa-lg pencil"></i>
        </a>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Strategic Tag" prop="name">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div
          [tooltip]="value"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          #{{ value }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Related Networks" prop="promotingNetworks">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div
          [tooltip]="value ? value.join(', ') : ''"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ value ? value.join(", ") : "" }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Modified Date" prop="modified">
        <ng-template let-modified="value" ngx-datatable-cell-template>
          <div
            [tooltip]="modified"
            containerClass="tooltip-class"
            container="body"
            class="fluid-text"
          >
            {{ modified | amDateFormat: "Do MMM 'YY, HH:mm" }}
          </div>
        </ng-template>
      </ngx-datatable-column>
    <ngx-datatable-column name="Reference Years" prop="referenceYear">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div
          [tooltip]="value ? value.join(', ') : ''"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ value ? value.join(", ") : "" }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [width]="140"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template let-tag="row" ngx-datatable-cell-template>
        <button
          class="btn btn-primary btn-raised mt-0 mb-0 pr-1 pl-1"
          (click)="openStrategicTag(tag)"
        >
          Go to tag
        </button>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer *ngIf="loadingIndicator"></ngx-datatable-footer>
  </ngx-datatable>
  <div *ngIf="loadingIndicator">
    <kms-admin-spinner [loading]="true"></kms-admin-spinner>
  </div>
</kms-admin-card>
