<div class="card card-{{color}} {{animation}}">
  <div class="card-header pt-0" [hidden]="!hasHeader">
      <h3 class="card-title" #header>
        <ng-content select="header"></ng-content>
      </h3>
  </div>
  <div class="card-body">
      <ng-content></ng-content>
  </div>
  <div class="card-footer" #footer [hidden]="!hasFooter">
      <ng-content select="footer"></ng-content>
  </div>
</div>