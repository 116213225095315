
export abstract class Routes {
    static home = "/";
    static config = "/configuration";
    static strategicTags = "/strategic-tags";
    static networks = "/networks";
    static challenge = "/challenge";
    static deletedPosts = "/deleted-posts";
    static gamification = "/gamification";
    static businessUnits = "/business-units";
    static hashtags = "/hashtags";
}
