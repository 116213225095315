<span class="d-flex">
  <kms-admin-avatar-view
    [extraSmallVersion]="true"
    [imageUrl]="user.avatarUrl"
    [type]="1"
    [color]="color"
    [isKO]="user.isKOEligible"
    [roles]="user.roles"
    class="rounded-circle"
  ></kms-admin-avatar-view>
  <!-- <span class="badge-pill badge-pill-success ko-pill" *ngIf="isKoOwner">
        <i class="fa fa-graduation-cap" aria-hidden="true"></i>
      </span> -->
  <span>
    <div *ngIf="showDisplayName" class="color-black ml-1 mt-1 col-12">
      {{ user.displayName }}
    </div>
    <div *ngIf="showUnit" class="color-black ml-1 mt-1 col-12">
      <h6 class="m-0">
        <em>{{ user.unitCode }}</em>
      </h6>
    </div>
  </span>
</span>
