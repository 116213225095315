<div class="modal-header">
  <h4 class="modal-title pull-left mt-0">{{ title }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid" [formGroup]="form">
    <div class="row">
      <div
      form-field
      class="input-alignment col-sm-12"
      [control]="form.get('keyword')"
      [group]="form"
      >
      <input
        type="text"
        class="form-control no-box"
        formControlName="keyword"
      />
      <!--
      <input
      type="text"
      class="form-control no-box"
      formControlName="keyword"
      disabled="true"
      *ngIf="editMode"
      />-->
      <label
        class="floating-label"
        [ngClass]="{ 'go-top': form?.get('keyword')?.value }"
        >Keyword *</label>
        <div
          class="col-12 alert alert-danger alert-light"
          role="alert"
          *ngIf="isDuplicated"
        >
          <strong>The IPM keyword specified is already defined in keyword list!</strong>
        </div>
     
    </div>
      <div class="col-sm-12">
        <kms-admin-people-picker
          [isMulti]="true"
          [userList]="form.get('receipts')"
          [placeholder]="'Recipients *'"  
          [showExternalConsultant]="false" 
        ></kms-admin-people-picker>
     
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="form.invalid" class="btn btn-primary btn-disabled-style btn-raised" (click)="confirm()">
    {{ confirmBtnName }}
  </button>
  <button
    type="button"
    class="btn btn-warning btn-raised"
    (click)="bsModalRef.hide()"
  >
    {{ closeBtnName }}
  </button>
</div>
