<button
  *ngIf="editingG"
  class="btn btn-primary btn-raised mt-0 mb-0 pr-1 pl-1 mr-1"
  [disabled]="isButtonDisabled"
  [kmsClickWait]="saveClick.bind(this)"
>
  <i class="fa fa-floppy-o" aria-hidden="true"></i>
  Save
</button>
<button
  class="btn btn-warning btn-raised mt-0 mb-0 pr-1 pl-1"
  (click)="cancelClick()"
  *ngIf="editingG"
>
  <i class="fa fa-trash fa-lg trash"></i> Cancel
</button>
<button
  *ngIf="!editingG"
  class="btn btn-primary btn-raised mt-0 mb-0 pr-1 pl-1"
  (click)="editingG = true"
>
  <i class="fa fa-pencil fa-lg pencil"></i>
  Edit
</button>
<kms-admin-card class="inner-card" animation="mt-2" *ngIf="gameSettings">
  <ngx-datatable
    class="bootstrap"
    [rows]="gameFA.controls"
    [columnMode]="'force'"
    [rowHeight]="'auto'"
    [footerHeight]="'auto'"
    [limit]="limit"
    [messages]="{
      emptyMessage: '',
      totalMessage: 'total',
      selectedMessage: 'selected'
    }"
    [sorts]="[{ prop: 'eventName', dir: 'asc' }]"
  >
    <ngx-datatable-column name="Gamification Event" prop="value.name">
      <ng-template let-game="row" ngx-datatable-cell-template>
        <div class="fluid-text">
          {{ game.value.name }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    
    <ngx-datatable-column name="Action message" prop="value.sourceLabel">
      <ng-template
        let-game="row"
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
      >
        <div class="fluid-text" *ngIf="!editingG">
          {{ game.value.sourceLabel }}
        </div>
        <div
          form-field
          [control]="game.get('sourceLabel')"
          [formGroup]="game"
          *ngIf="editingG"
        >
          <input
            autofocus
            type="string"
            formControlName="sourceLabel"
            class="no-box form-control"
          />
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Received action message" prop="value.targetLabel">
        <ng-template
          let-game="row"
          ngx-datatable-cell-template
          let-rowIndex="rowIndex"
        >
          <div class="fluid-text" *ngIf="!editingG">
            {{ game.value.targetLabel }}
          </div>
          <div
            form-field
            [control]="game.get('targetLabel')"
            [formGroup]="game"
            *ngIf="editingG"
          >
            <input
              autofocus
              type="string"
              formControlName="targetLabel"
              class="no-box form-control"
            />
          </div>
        </ng-template>
      </ngx-datatable-column>

    <ngx-datatable-column name="Sender Points" prop="value.sourcePoints" [width]="120" [canAutoResize]="false">
      <ng-template
        let-game="row"
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
      >
        <div class="fluid-text text-right pr-2-05" *ngIf="!editingG">
          {{ game.value.sourcePoints }}
        </div>
        <div
          form-field
          [control]="game.get('sourcePoints')"
          [formGroup]="game"
          *ngIf="editingG"
        >
          <input
            autofocus
            type="number"
            kmsAdminNumberDirective
            formControlName="sourcePoints"
            class="no-box form-control"
          />
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Receiver  Points" prop="value.targetPoints"  [width]="120" [canAutoResize]="false">
      <ng-template
        let-game="row"
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
      >
        <div class="fluid-text text-right pr-1-05" *ngIf="!editingG">
          {{ game.value.targetPoints }}
        </div>
        <div
          form-field
          [control]="game.get('targetPoints')"
          [formGroup]="game"
          *ngIf="editingG"
        >
          <input
            autofocus
            type="number"
            kmsAdminNumberDirective
            formControlName="targetPoints"
            class="no-box form-control"
          />
        </div>
      </ng-template>

    </ngx-datatable-column>
    <ngx-datatable-footer *ngIf="loadingIndicator"></ngx-datatable-footer>
  </ngx-datatable>
  <div *ngIf="loadingIndicator">
    <kms-admin-spinner [loading]="true"></kms-admin-spinner>
  </div>
</kms-admin-card>
