<div class="row">
  <div class="col-9" *ngIf="fileUrlToDownload">
    <a 
      class="clickable-link"
      [attr.href]="fileUrlToDownload"
    >{{ fileName }}</a>
  </div>
  <div class="pl-2" style="width: 60%;" *ngIf="showCsvHelp && !fileUrlToDownload">
    Please click upload button to choose & upload the CSV file
  </div>
  <div class="pl-2" style="width: 60%;" *ngIf="showCsvHelp && isExternalCsvFile">
    Please click upload button to choose & upload the CSV file
  </div>
  <div class="col-3" *ngIf="showCsvHelp">
    <input #uploadFile type="file" class="d-none" (change)="fileChange($event)" [accept]="accept" />
    <a class="clickable-link" (click)="uploadFile.click()">
      <i class="fa fa-2x fa-upload"></i></a>
  </div>
  <div *ngIf="!showCsvHelp">
    <input #uploadFile type="file" class="d-none" (change)="fileChange($event)" [accept]="accept" />
    <a class="clickable-link" (click)="uploadFile.click()">
      <i class="fa fa-2x fa-upload"></i></a>
  </div>
</div>
