import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { TooltipModule } from "ngx-bootstrap/tooltip";

import { NetworkRoutingModule } from "./network-routing.module";
import { NetworkListPageComponent } from "./network-list-page/network-list-page.component";
import { QueryBuilderComponentModule } from "src/app/components/query-builder/query-builder.module";
import { CardModule } from "src/app/components/card/card.module";
import { NetworkEditPageComponent } from "./network-edit-page/network-edit-page.component";
import { NetworkMergePageComponent } from "./network-merge-page/network-merge-page.component";
import { KMSFormsModule } from "src/app/components/forms/forms.module";
import { PeoplePickerModule } from "src/app/components/people-picker/people-picker.module";
import { AvatarViewModule } from "src/app/components/avatar-view/avatar-view.module";
import { CoreModule } from "src/app/components/core/core.module";
import { PipesModule } from "src/app/components/pipes/pipes.module";
import { ClickWaitModule } from "src/app/components/click-wait/click-wait.module";

const IMPORTS = [
  CommonModule,
  NetworkRoutingModule,
  NgxDatatableModule,
  QueryBuilderComponentModule,
  CardModule,
  FormsModule,
  NgSelectModule,
  KMSFormsModule,
  PeoplePickerModule,
  AvatarViewModule,
  TooltipModule,
  CoreModule,
  PipesModule,
  ClickWaitModule
];
const DECLARATIONS = [NetworkListPageComponent, NetworkEditPageComponent, NetworkMergePageComponent];

@NgModule({
  imports: [...IMPORTS],
  declarations: [...DECLARATIONS],
  entryComponents: [NetworkEditPageComponent, NetworkMergePageComponent],
  exports: [...IMPORTS, ...DECLARATIONS]
})
export class NetworkModule {}
