import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TabsModule } from "ngx-bootstrap/tabs";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { CardModule } from "src/app/components/card/card.module";
import { CoreModule } from "src/app/components/core/core.module";
import { QueryBuilderComponentModule } from "src/app/components/query-builder/query-builder.module";
import { PipesModule } from "src/app/components/pipes/pipes.module";
import { BusinessUnitRoutingModule } from "./business-unit-routing.module";
import { BusinessUnitPageComponent } from "./business-unit-page/business-unit-page.component";
import { BusinessUnitListComponent } from "./business-unit-page/business-unit-list/business-unit-list.component";
import { BusinessUnitEditComponent } from "./business-unit-page/business-unit-list/business-unit-edit/business-unit-edit.component";
import { PostReconcileComponent } from "./business-unit-page/post-reconcile/post-reconcile.component";
import { PostReconcileEditComponent } from "./business-unit-page/post-reconcile/post-reconcile-edit/post-reconcile-edit.component";
import { IkReconcileComponent } from "./business-unit-page/ik-reconcile/ik-reconcile.component";
import { IkReconcileEditComponent } from "./business-unit-page/ik-reconcile/ik-reconcile-edit/ik-reconcile-edit.component";

const IMPORTS = [
  CommonModule,
  CardModule,
  TabsModule,
  NgxDatatableModule,
  FormsModule,
  QueryBuilderComponentModule,
  TooltipModule,
  CoreModule,
  PipesModule,
  NgSelectModule
];
const DECLARATIONS = [
  BusinessUnitPageComponent,
  BusinessUnitListComponent,
  BusinessUnitEditComponent,
  PostReconcileComponent,
  PostReconcileEditComponent,
  IkReconcileComponent,
  IkReconcileEditComponent
];
const ENTRY_COMPONENTS = [
  BusinessUnitEditComponent,
  PostReconcileEditComponent,
  IkReconcileEditComponent
];

@NgModule({
  imports: [...IMPORTS, BusinessUnitRoutingModule],
  declarations: [...DECLARATIONS],
  entryComponents: [...ENTRY_COMPONENTS],
  exports: [...IMPORTS, ...DECLARATIONS]
})
export class BusinessUnitModule {}
