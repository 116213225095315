import { NgModule, Injector } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { FormsModule } from "@angular/forms";
import { DatePickerComponent } from "./date-picker/date-picker.component";
import { PipesModule } from "../pipes/pipes.module";

const DECLARATIONS = [DatePickerComponent];
const IMPORTS = [CommonModule, FormsModule, PipesModule];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...IMPORTS, BsDatepickerModule.forRoot()],
  exports: [...DECLARATIONS, ...IMPORTS, BsDatepickerModule]
})
export class AdminDatePickerModule {
}
