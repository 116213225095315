<kms-admin-query-builder
  [config]="queryConfig"
  (queryEvent)="search($event)"
></kms-admin-query-builder>

<div class="text-right">
  <button
    type="button"
    class="btn btn-primary btn-raised mr-3"
    (click)="onCreate()"
  >
    Create
  </button>
</div>

<kms-admin-card class="inner-card" animation="mt-2">
  <ngx-datatable
    class="bootstrap"
    [rows]="challenges | async"
    [columnMode]="'force'"
    [rowHeight]="'auto'"
    [footerHeight]="'auto'"
    [limit]="limit"
    [messages]="{
      emptyMessage: '',
      totalMessage: 'total',
      selectedMessage: 'selected'
    }"
    [sorts]="[{ prop: 'name', dir: 'asc' }]"
  >
    <ngx-datatable-column
      [width]="30"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template let-challenge="row" ngx-datatable-cell-template>
        <a
          [tooltip]="'Edit'"
          containerClass="tooltip-class"
          container="body"
          class="edit-icon"
          (click)="onEdit(challenge?.id)"
        >
          <i class="fa fa-pencil fa-lg pencil"></i>
        </a>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Name" prop="name">
      <ng-template let-name="value" ngx-datatable-cell-template>
        <div
          [tooltip]="name"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ name }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Hashtag" prop="hashtag">
      <ng-template let-hashtag="value" ngx-datatable-cell-template>
        <div
          [tooltip]="hashtag"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ hashtag }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Modified Date" prop="modified">
      <ng-template let-modified="value" ngx-datatable-cell-template>
        <div
          [tooltip]="modified"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ modified | amDateFormat: "Do MMM 'YY, HH:mm" }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [width]="90"
      [canAutoResize]="false"
      name="Status"
      prop="status"
    >
      <ng-template let-status="value" ngx-datatable-cell-template>
        <div
          [tooltip]="status"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ status }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Involved People" prop="involvedPeople">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div
          [tooltip]="value ? value.join(', ') : ''"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ value ? value.join(", ") : "" }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Evaluation Committee"
      prop="evaluationCommittee"
    >
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div
          [tooltip]="value ? value.join(', ') : ''"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ value ? value.join(", ") : "" }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <!-- <ngx-datatable-column name="Business Impact" prop="businessImpact">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div
          [tooltip]="value"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ value }}
        </div>
      </ng-template>
    </ngx-datatable-column> -->
    <ngx-datatable-column name="Promoting Networks" prop="promotingNetworks">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div
          [tooltip]="value ? value.join(', ') : ''"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ value ? value.join(", ") : "" }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [width]="145"
      [canAutoResize]="false"
      name="Start Date"
      prop="challengeStartDate"
    >
      <ng-template let-date="value" ngx-datatable-cell-template>
        <div>
          {{ date | amDateFormat: "Do MMM 'YY, HH:mm" }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [width]="145"
      [canAutoResize]="false"
      name="End Date"
      prop="challengeEndDate"
    >
      <ng-template let-date="value" ngx-datatable-cell-template>
        <div>
          {{ date | amDateFormat: "Do MMM 'YY, HH:mm" }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [width]="190"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template let-challenge="row" ngx-datatable-cell-template>
        <button
          class="btn btn-primary btn-raised mt-0 mb-0 pr-1 pl-1"
          (click)="openChallenge(challenge)"
        >
          Go to challenge
        </button>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer *ngIf="loadingIndicator"></ngx-datatable-footer>
  </ngx-datatable>
  <div *ngIf="loadingIndicator">
    <kms-admin-spinner [loading]="true"></kms-admin-spinner>
  </div>
</kms-admin-card>
