import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TabsModule } from "ngx-bootstrap/tabs";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { GamificationRoutingModule } from "./gamification-routing.module";
import { GamificationComponent } from "./gamification-page/gamification/gamification.component";
import { GamificationPageComponent } from "./gamification-page/gamification-page.component";
import { QueryBuilderComponentModule } from "src/app/components/query-builder/query-builder.module";
import { CardModule } from "src/app/components/card/card.module";
import { CoreModule } from "src/app/components/core/core.module";
import { PipesModule } from "src/app/components/pipes/pipes.module";
import { MedalComponent } from "./gamification-page/medal/medal.component";
import { MedalModalComponent } from "./gamification-page/medal/medal-modal/medal-modal.component";
import { FormsModule } from "@angular/forms";
import { KMSFormsModule } from "src/app/components/forms/forms.module";
import { ClickWaitModule } from "src/app/components/click-wait/click-wait.module";

const IMPORTS = [
  CommonModule,
  GamificationRoutingModule,
  TabsModule,
  NgxDatatableModule,
  NgSelectModule,
  FormsModule,
  QueryBuilderComponentModule,
  KMSFormsModule,
  CardModule,
  TooltipModule,
  CoreModule,
  PipesModule,
  ClickWaitModule
];
const DECLARATIONS = [
  GamificationComponent,
  GamificationPageComponent,
  MedalComponent,
  MedalModalComponent
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...IMPORTS],
  entryComponents: [MedalModalComponent],
  exports: [...IMPORTS, ...DECLARATIONS]
})
export class GamificationModule {}
