/**
 * @module Models
 */
/** DO NOT REMOVE */
// See https://coryrylan.com/blog/angular-2-form-builder-and-validation-management
import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ValidationService } from "src/app/services/validation.service";

@Component({
  selector: "kms-admin-control-messages",
  styleUrls: ["./control-messages.component.scss"],
  template: `
    <span *ngFor="let err of controlErrors" class="error-block">{{
      err.key
    }}</span>
    <span *ngFor="let err of groupErrors" class="error-block">{{
      err.key
    }}</span>
  `
})
export class ControlMessagesComponent {
  constructor(private readonly validationSvc: ValidationService) {}
  @Input("control")
  set control(ctrl: FormControl) {
    if (ctrl) {
      this.ctrl = ctrl;
      this.setErrorMessages();
      this.ctrl.valueChanges.subscribe(() => this.setErrorMessages());
    }
  }
  get control() {
    return this.ctrl;
  }

  @Input("group")
  set group(grp: FormControl) {
    if (grp) {
      this.grp = grp;
      this.setGroupErrorMessages();
      this.grp.statusChanges.subscribe(() => this.setGroupErrorMessages());
    }
  }
  get group() {
    return this.grp;
  }

  @Input()
  visibleGroupErrors: Array<string>;

  public controlErrors: Array<Message>;
  public groupErrors: Array<Message>;
  private ctrl: FormControl;
  private grp: FormControl;

  private setErrorMessages(): Array<Message> {
    this.controlErrors = new Array<Message>();

    if (this.ctrl) {
      for (const propertyName in this.ctrl.errors) {
        if (
          this.ctrl.errors.hasOwnProperty(propertyName) &&
          this.ctrl.touched
        ) {
          const error = new Message(
            this.validationSvc.getMessages(this.ctrl.errors),
            this.ctrl.errors[propertyName]
          );
          this.controlErrors.push(error);
        }
      }
    }

    return this.controlErrors;
  }

  private setGroupErrorMessages(): Array<Message> {
    this.groupErrors = new Array<Message>();

    if (this.grp != null) {
      for (const propertyName in this.grp.errors) {
        if (
          this.grp.errors.hasOwnProperty(propertyName) &&
          this.grp.touched &&
          this.isGroupErrorsVisible(propertyName)
        ) {
          const error = new Message(
            this.validationSvc.getMessages(this.grp.errors),
            this.grp.errors[propertyName]
          );
          this.groupErrors.push(error);
        }
      }
    }

    return this.groupErrors;
  }

  private isGroupErrorsVisible(propertyName: string): boolean {
    if (!this.visibleGroupErrors) {
      return true;
    } else {
      return this.visibleGroupErrors.indexOf(propertyName) > -1;
    }
  }
}

class Message {
  public key: string;
  public validator: any;

  constructor(key: string, validator: any) {
    this.key = key;
    this.validator = validator;
  }
}
