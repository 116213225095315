import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from "@angular/core";

import { Observable } from "rxjs";
import { tap, map } from "rxjs/operators";
import { QueryBuilderConfig } from "angular2-query-builder";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

import {
  SearchService,
  KMSSearchResults
} from "src/app/services/search.service";
import { Challenge } from "src/app/commons/entities";
import { ChallengeEditPageComponent } from "../challenge-edit-page/challenge-edit-page.component";
import { ChallengeService } from "src/app/services/challenge.service";
import { SnackbarService } from "ngx-snackbar";
import { ConfigService } from "@ngx-config/core";
import { LoggerService } from "src/app/services/logger.service";
import { Constants } from "src/app/commons/util";

@Component({
  selector: "kms-admin-challenge-list-page",
  templateUrl: "./challenge-list-page.component.html",
  styleUrls: ["./challenge-list-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ChallengeListPageComponent implements OnInit {
  queryConfig: QueryBuilderConfig = {
    fields: {
      name: {
        name: "Name",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      hashtag: {
        name: "Hashtag",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      modified: {
        name: "Modified Date",
        type: "date",
        operators: ["<", "<=", ">", ">="]
      },
      status: {
        name: "Status",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      involvedPeople: {
        name: "Referents",
        type: "string",
        operators: ["contains"]
      },
      evaluationCommittee: {
        name: "Evaluation Committee",
        type: "string",
        operators: ["contains"]
      },
      // businessImpact: {
      //   name: "Business Impact",
      //   type: "string",
      //   operators: ["contains"]
      // },
      promotingNetworks: {
        name: "Promoting Network",
        type: "string",
        operators: ["contains"]
      },
      challengeStartDate: {
        name: "Start Date",
        type: "date",
        operators: ["<", "<=", ">", ">="]
      },
      challengeEndDate: {
        name: "End Date",
        type: "date",
        operators: ["<", "<=", ">", ">="]
      }
    }
  };

  challenges: Observable<Challenge[]>;
  loadingIndicator = false;
  limit = Constants.TABLE_LIMITS;
  modalRef: BsModalRef;
  lastUsedQuery: any = null;

  constructor(
    private searchSvc: SearchService,
    private challengeSvc: ChallengeService,
    private readonly config: ConfigService,
    private readonly modalSvc: BsModalService,
    private readonly snackbarSvc: SnackbarService,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.search(null);
  }

  async search(query: string) {
    this.lastUsedQuery = query;

    const filter = {
      filter: "(typeId eq 2)" + (query ? " and " + query : ""),
      top: 1000
    };

    this.loadingIndicator = true;
    this.challenges = this.searchSvc
      .queryRaw("ImpactingKnowledges", "*", filter)
      .pipe(
        map((challenges: KMSSearchResults<Challenge>) =>
          challenges.results.map(r => r.document)
        ),
        map(challenges =>
          challenges.map((challenge: any) => {
            challenge.challengeStartDate = new Date(
              challenge.challengeStartDate
            )
              .toUTCString()
              .substr(0, 25);
            challenge.challengeEndDate = new Date(challenge.challengeEndDate)
              .toUTCString()
              .substr(0, 25);
            challenge.involvedPeople = [...challenge.involvedPeople];
            return challenge;
          })
        ),
        tap(() => (this.loadingIndicator = false))
      );
  }

  onCreate() {
    const initialState = {
      challenge: null,
      confirmBtnName: "Save",
      closeBtnName: "Cancel",
      newChallenge: true
    };
    // title: "Create Challenge"
    this.modalRef = this.modalSvc.show(ChallengeEditPageComponent, {
      initialState,
      class: "modal-transparent modal-lg mt-6"
    });
    (this.modalRef.content as ChallengeEditPageComponent).close.subscribe(
      result => {
        this.search(this.lastUsedQuery);
      }
    );
  }

  async onEdit(selectedChallenge: string) {
    if (selectedChallenge) {
      const loadedChallenge: Challenge = await this.challengeSvc
        .getChallengeById(selectedChallenge)
        .toPromise();
      const initialState = {
        challenge: loadedChallenge,
        confirmBtnName: "Save",
        closeBtnName: "Cancel",
        newChallenge: false
      };
      // title: "Edit Challenge"
      this.modalRef = this.modalSvc.show(ChallengeEditPageComponent, {
        initialState,
        class: "modal-transparent modal-lg mt-6"
      });
      (this.modalRef.content as ChallengeEditPageComponent).close.subscribe(
        result => {
          this.search(this.lastUsedQuery);
        }
      );
    } else {
      this.logger.warning(
        `Cannot find the selected challenge: ${selectedChallenge}`
      );
      this.snackbarSvc.add({
        msg: `Cannot find the selected challenge`,
        background: "red",
        action: { text: null }
      });
    }
  }

  openChallenge(challenge: any) {
    const challengeId = challenge ? challenge.id : null;
    if (challengeId != null) {
      window.open(
        `${this.config.getSettings().portalWebUrl}challenge/${challengeId}`,
        "_blank"
      );
    } else {
      this.logger.warning(`Cannot find the selected challenge: ${challenge}`);
      this.snackbarSvc.add({
        msg: `Cannot find the selected challenge`,
        background: "red",
        action: { text: null }
      });
    }
  }
}
