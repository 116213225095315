<span *ngIf="range">
  <button class="badge badge-pill badge-info badge-custom-font" [disabled]="true">
    <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
    {{ range[0] | amDateFormat: "Do MMM YY" }} -
    {{ range[1] | amDateFormat: "Do MMM YY" }}
  </button>

  <button (click)="clear()" class="btn btn-sm" [disabled]="isDisabled">
    <i class="fa fa-calendar-times-o" aria-hidden="true"></i>
  </button>
</span>
<span *ngIf="singleDate">
  <button class="badge badge-pill badge-info badge-custom-font" [disabled]="true">
    <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
    {{ singleDate | amDateFormat: "Do MMM YY" }}
  </button>

  <button (click)="clearSingle()" class="btn btn-sm" [disabled]="isDisabled">
    <i class="fa fa-calendar-times-o" aria-hidden="true"></i>
  </button>
</span>
<ng-container *ngIf="!isDisabled && !isSingleDate">
  <input
    placeholder="Daterangepicker"
    class="invisible"
    #dp="bsDaterangepicker"
    placement="right"
    [bsValue]="range"
    (bsValueChange)="valueChange($event)"
    [bsConfig]="bsConfig"
    bsDaterangepicker
    [isOpen]="isOpen"
    style="width: 5px"
  />
  <button
    #toggler
    class="btn btn-sm btn-info btn-toggle-datepicker"
    (click)="dp.toggle()"
    [attr.aria-expanded]="dp.isOpen"
  >
    <i class="fa fa-calendar"></i>
  </button>
</ng-container>
<ng-container *ngIf="isDisabled && !isSingleDate">
  <input
    placeholder="Datepicker"
    class="invisible"
    #dp2="bsDatepicker"
    placement="right"
    [minDate]="today"
    [bsValue]="range[1]"
    (bsValueChange)="singleValueChange($event)"
    [bsConfig]="bsConfig"
    bsDatepicker
    [isOpen]="isOpen"
    style="width: 5px"
  />
  <button
    #toggler
    class="btn btn-sm btn-info btn-toggle-datepicker"
    (click)="dp2.toggle()"
    [attr.aria-expanded]="dp2.isOpen"
  >
    <i class="fa fa-calendar"></i>
  </button>
</ng-container>
<ng-container *ngIf="isSingleDate">
  <input
    placeholder="Datepicker"
    class="invisible"
    #dp3="bsDatepicker"
    placement="right"
    [minDate]="today"
    (bsValueChange)="onlyValueChange($event)"
    [bsConfig]="bsConfig"
    bsDatepicker
    [isOpen]="isOpen"
    style="width: 5px"
  />
  <button
    #toggler
    class="btn btn-sm btn-info btn-toggle-datepicker"
    (click)="dp3.toggle()"
    [attr.aria-expanded]="dp3.isOpen"
  >
    <i class="fa fa-calendar"></i>
  </button>
</ng-container>