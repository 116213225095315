<div class="modal-header">
  <h4 class="modal-title pull-left mt-0">{{ title }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid" [formGroup]="form">
    <tabset [justified]="true" class="tab-container">
      <tab class="pr-1 pl-1 pt-2" id="0" (click)="onTabSelect(0)">
        <ng-template tabHeading *ngIf="addMode">
          <strong>Single User mode</strong>
        </ng-template>
        <ng-template tabHeading *ngIf="!addMode"> User Details </ng-template>
        <div class="row">
          <div
            form-field
            class="input-alignment"
            [control]="form.get('mail')"
            [group]="form"
            [ngClass]="{ 'col-sm-10': !user, 'col-sm-12': user }"
          >
            <input
              type="text"
              class="form-control no-box"
              formControlName="mail"
              (keyup)="changeMail()"
            />
            <label
              class="floating-label"
              [ngClass]="{ 'go-top': form?.get('mail')?.value }"
              >Mail *</label
            >
            <div
              class="col-12 alert alert-danger alert-light"
              role="alert"
              *ngIf="showWarning"
            >
              <strong>Warning!</strong> {{ warningMessage }}
            </div>
          </div>
          <div class="col-sm-2" *ngIf="!user">
            <button
              type="button"
              class="btn btn-primary btn-raised mr-3"
              [kmsClickWait]="checkMail.bind(this)"
            >
              <i class="fa fa-check mr-0"></i><span hidden>confirm</span>
            </button>
          </div>
        </div>
        <div class="row" *ngIf="!hideElements">
          <div
            form-field
            class="col-sm-6 input-alignment mt-1 pb-1"
            [control]="form.get('name')"
            [group]="form"
          >
            <input
              type="text"
              class="form-control no-box"
              formControlName="name"
            />
            <label
              class="floating-label"
              [ngClass]="{ 'go-top': form?.get('name')?.value }"
              >Name</label
            >
          </div>
          <div
            form-field
            class="col-sm-6 input-alignment mt-1 pb-1"
            [control]="form.get('surname')"
            [group]="form"
          >
            <input
              type="text"
              class="form-control no-box"
              formControlName="surname"
            />
            <label
              class="floating-label"
              [ngClass]="{ 'go-top': form?.get('surname')?.value }"
              >Surname</label
            >
          </div>
        </div>
        <div class="row" *ngIf="!hideElements">
          <div
            form-field
            class="col-sm-6 input-alignment mt-1"
            [control]="form.get('upn')"
            [group]="form"
          >
            <input
              type="text"
              class="form-control no-box"
              formControlName="upn"
            />
            <label
              class="floating-label"
              [ngClass]="{ 'go-top': form?.get('upn')?.value }"
              >Upn</label
            >
          </div>
          <div
            form-field
            class="col-sm-6 mt-1"
            [control]="form.get('referent')"
            [group]="form"
          >
            <kms-admin-people-picker
              [isMulti]="false"
              [singleUser]="form.get('referent').value"
              [placeholder]="'Referent *'"
              [restrictions]="referentRestriction"
              [showExternalConsultant]="false"
              (add)="addUser($event)"
            ></kms-admin-people-picker>
          </div>
        </div>
      </tab>

      <tab
        class="pr-1 pl-1 pt-2"
        id="1"
        (click)="onTabSelect(1)"
        *ngIf="addMode"
      >
        <ng-template tabHeading>
          <strong>Multiple Users mode</strong>
        </ng-template>
        <div class="row">
          <kms-admin-file-upload
            class="pr-1 pl-1 pt-2"
            style="width: 85%"
            [queryStringCsvParams]="'payloadNotForGuests=false'"
            [isExternalCsvFile]="true"
            [showCsvHelp]="true"
            (fileLoaded)="updateFile($event)"
            (uploadingProgress)="uploadingProgress($event)"
          ></kms-admin-file-upload>
          <div class="row" *ngIf="fileUploading">
            <label>
              {{ progressLabel }}
            </label>
            <kms-admin-spinner
              [loading]="true"
              style="width: 35px; height: 35px"
            ></kms-admin-spinner>
          </div>
        </div>
      </tab>
    </tabset>

    <div class="row" *ngIf="!hideElements">
      <div
        form-field
        class="col-sm-6 input-alignment"
        [control]="form.get('company')"
        [group]="form"
      >
        <ng-select
          class="edit-network"
          [items]="company$ | async"
          bindLabel="name"
          formControlName="company"
          [addTag]="false"
          [multiple]="false"
          [hideSelected]="true"
          [loading]="companyLoading"
          [placeholder]="'Company'"
          [typeahead]="companyInput$"
          [(ngModel)]="company"
          (change)="changeCompany($event)"
        >
        </ng-select>
      </div>
      <div
        form-field
        class="col-sm-6 input-alignment"
        [control]="form.get('city')"
        [group]="form"
      >
        <ng-select
          class="edit-network"
          [items]="city$ | async"
          bindLabel="name"
          formControlName="city"
          [addTag]="false"
          [multiple]="false"
          [hideSelected]="true"
          [loading]="cityLoading"
          [placeholder]="'City'"
          [typeahead]="cityInput$"
          [(ngModel)]="city"
          (change)="changeCity($event)"
        >
        </ng-select>
      </div>
      <div
        *ngIf="isMultipleUser"
        form-field
        class="col-sm-6 mt-1"
        [control]="form.get('referent')"
        [group]="form"
      >
        <kms-admin-people-picker
          [isMulti]="false"
          [singleUser]="form.get('referent').value"
          [placeholder]="'Referent *'"
          [restrictions]="referentRestriction"
          [showExternalConsultant]="false"
          (add)="addUser($event)"
        ></kms-admin-people-picker>
      </div>
    </div>
    <div class="row" *ngIf="!hideElements">
      <div
        form-field
        class="col-sm-6 input-alignment mt-1"
        [control]="form.get('bu')"
        [group]="form"
      >
        <input type="text" class="form-control no-box" formControlName="bu" />
        <label
          class="floating-label"
          [ngClass]="{ 'go-top': form?.get('bu')?.value }"
          >Business Unit</label
        >
      </div>
    </div>

    <div class="row" *ngIf="!hideElements">
      <div class="col-sm-6 input-alignment mt-1 mb-4">
        <h5 class="floating-label no-box">Networks</h5>
      </div>
    </div>

    <div class="row" *ngIf="!hideElements">
      <div class="col input-alignment">
        <!-- QUI -->
        <kms-network-picker
          [ngClass]="{
            hidden: (hasLoadedCops$ | async)
          }"
          [networkList]="networkList"
          [networkType]="1"
          [placeholder]="'Assigned CoPs**'"
          [enableSelectAll]="true"
          [hasAll]="hasAllCops"
        ></kms-network-picker>

        <div
          class="elevated"
          [ngClass]="{
            hidden: !(hasLoadedCops$ | async)
          }"
        >
          <i class="fa fa-spinner fa-spin blue-icon" aria-hidden="true"></i
          >&nbsp;Loading Assigned CoPs
        </div>

        <!-- QUI -->
        <kms-network-picker
          [ngClass]="{
            hidden: (hasLoadedBus$ | async)
          }"
          [networkList]="networkList"
          [networkType]="2"
          [placeholder]="'Assigned Business Units**'"
          [enableSelectAll]="true"
          [hasAll]="hasAllBus"
        ></kms-network-picker>

        <div
          class="elevated"
          [ngClass]="{
            hidden: !(hasLoadedBus$ | async)
          }"
        >
          <i class="fa fa-spinner fa-spin blue-icon" aria-hidden="true"></i
          >&nbsp;Loading Business Units
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!hideElements">
      <div class="col input-alignment">
        <!-- QUI -->
        <kms-network-picker
          [ngClass]="{
            hidden: (hasLoadedPNs$ | async)
          }"
          [networkList]="networkList"
          [networkType]="5"
          [placeholder]="'Assigned Private Networks**'"
          [enableSelectAll]="true"
          [hasAll]="hasAllPNs"
        ></kms-network-picker>

        <div
          class="elevated"
          [ngClass]="{
            hidden: !(hasLoadedPNs$ | async)
          }"
        >
          <i class="fa fa-spinner fa-spin blue-icon" aria-hidden="true"></i
          >&nbsp;Loading Private Networks
        </div>

        <!-- QUI -->
        <kms-network-picker
          [ngClass]="{
            hidden: (hasLoadedSTs$ | async)
          }"
          [networkList]="networkList"
          [networkType]="4"
          [placeholder]="'Assigned Special Tags**'"
          [enableSelectAll]="true"
          [hasAll]="hasAllSTs"
        ></kms-network-picker>

        <div
          class="elevated"
          [ngClass]="{
            hidden: !(hasLoadedSTs$ | async)
          }"
        >
          <i class="fa fa-spinner fa-spin blue-icon" aria-hidden="true"></i
          >&nbsp;Loading Special Tags
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!hideElements">
      <div class="col-sm-6 input-alignment">
        <label>
          <input
            type="checkbox"
            [checked]="isLeleEnabled"
            (change)="isLeleEnabled = !isLeleEnabled"
          />
          LeLe enabled*
        </label>
      </div>
    </div>
    <div class="row" *ngIf="!hideElements">
      <div class="col-sm-6 input-alignment">
        <label> ** Please select at least one option </label>
      </div>
    </div>
  </div>
</div>
<!-- form.invalid || -->
<div class="modal-footer">
  <button
    type="button"
    [disabled]="
      !existReferent ||
      !isMailConfirmed ||
      isBULoading ||
      networkList.length == 0
    "
    class="btn btn-primary btn-disabled-style btn-raised"
    [kmsClickWait]="confirm.bind(this)"
  >
    {{ confirmBtnName }}
  </button>
  <button
    type="button"
    class="btn btn-warning btn-raised"
    (click)="bsModalRef.hide()"
  >
    {{ closeBtnName }}
  </button>
</div>
