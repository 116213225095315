<kms-admin-query-builder
  [config]="queryConfig"
  (queryEvent)="search($event)"
></kms-admin-query-builder>

<div class="text-right">
  <button
    type="button"
    class="btn btn-primary btn-raised mr-3"
    (click)="onCreate()"
  >
    Add
  </button>
</div>

<ngx-datatable
  class="bootstrap"
  [rows]="guestUsers | async"
  [columnMode]="'force'"
  [rowHeight]="'auto'"
  [footerHeight]="'auto'"
  [limit]="limit"
  [messages]="{
    emptyMessage: '',
    totalMessage: 'total',
    selectedMessage: 'selected'
  }"
  [sorts]="[{ prop: 'email', dir: 'asc' }]"
>
  <ngx-datatable-column
    [width]="30"
    [sortable]="false"
    [canAutoResize]="false"
    [draggable]="false"
    [resizeable]="false"
  >
    <ng-template let-user="row" ngx-datatable-cell-template>
      <a
        [tooltip]="'Edit'"
        containerClass="tooltip-class"
        container="body"
        class="clickable-icon"
        (click)="onEdit(user?.email)"
      >
        <i class="fa fa-pencil fa-lg pencil"></i>
      </a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [width]="30"
    [sortable]="false"
    [canAutoResize]="false"
    [draggable]="false"
    [resizeable]="false"
  >
    <ng-template let-user="row" ngx-datatable-cell-template>
      <a
        [tooltip]="'Delete'"
        containerClass="tooltip-class"
        container="body"
        class="clickable-icon"
        (click)="openAlertModal(alertDelete, user)"
      >
        <i class="fa fa-trash-o fa-lg"></i>
      </a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Display Name" prop="displayName" [width]="230" [canAutoResize]="false">
    <ng-template let-name="value" ngx-datatable-cell-template>
      <div
        [tooltip]="name"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ name }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Mail" prop="email">
    <ng-template let-mail="value" ngx-datatable-cell-template>
      <div
        [tooltip]="mail"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ mail }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Referent" prop="employeeReference" [width]="230" [canAutoResize]="false">
    <ng-template let-referent="value" ngx-datatable-cell-template>
      <div
        [tooltip]="referent?.displayName"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ referent?.displayName }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Company" prop="company">
    <ng-template let-company="value" ngx-datatable-cell-template>
      <div
        [tooltip]="company"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ company }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Networks" prop="networkNames">
    <ng-template let-networks="value" ngx-datatable-cell-template>
      <div
        [tooltip]="networks ? networks.join(', ') : ''"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ networks ? networks.join(", ") : "" }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Status" prop="statusName" [width]="100" [canAutoResize]="false">
    <ng-template let-status="value" ngx-datatable-cell-template>
      <div
        [tooltip]="status"
        containerClass="tooltip-class"
        container="body"
        class="fluid-text"
      >
        {{ status }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-footer *ngIf="loadingIndicator"></ngx-datatable-footer>
</ngx-datatable>

<div *ngIf="loadingIndicator">
  <kms-admin-spinner [loading]="true"></kms-admin-spinner>
</div>

<ng-template #alertDelete>
  <div class="modal-header">
    <h4 class="modal-title pull-left mt-0">Confirm user delete</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to remove the user?</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary btn-raised"
      [kmsClickWait]="onDelete.bind(this)"
    >
      Ok
    </button>
    <button
      type="button"
      class="btn btn-warning btn-raised"
      (click)="modalRef?.hide()"
    >
      Cancel
    </button>
  </div>
</ng-template>
