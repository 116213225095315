import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ControlMessagesComponent } from "./control-messages/control-messages.component";
import { FormFieldComponent } from "./form-field/form-field.component";

const IMPORTS = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
];
const DECLARATIONS = [
  ControlMessagesComponent,
  FormFieldComponent,
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...IMPORTS],
  exports: [...DECLARATIONS, ...IMPORTS]
})
export class KMSFormsModule {}
