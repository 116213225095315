import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { NgSelectModule } from "@ng-select/ng-select";

import { PeoplePickerComponent } from "./people-picker/people-picker.component";
import { AvatarViewModule } from "../avatar-view/avatar-view.module";
import { MiniUserModule } from "../mini-user/mini-user.module";
import { FileUploadModule } from "../file-upload/file-upload.module";

const IMPORT = [CommonModule, NgSelectModule, FormsModule, AvatarViewModule, MiniUserModule, FileUploadModule ] ;

const DECLARATION = [PeoplePickerComponent];

@NgModule({
  imports: [...IMPORT],
  declarations: [...DECLARATION],
  exports: [...IMPORT, ...DECLARATION]
})
export class PeoplePickerModule {}
