import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export class Constants {
  static readonly TABLE_LIMITS = 50;
}

export class Guid {
  static empty = "00000000-0000-0000-0000-000000000000";

  static newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      // tslint:disable-next-line:no-bitwise
      const r = (Math.random() * 16) | 0,
        // tslint:disable-next-line:no-bitwise
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}

export class Utilities {
  static replaceToken(url: string, assetsConfig: any) {
    if (url) {
      const assetKey = assetsConfig.value["item2"];
      const assetDomain = assetsConfig.value["item1"];
      const temp = url.replace(/\$_TOKEN/g, assetKey);
      const temp2 = temp.replace(/\$_BASEURL/g, assetDomain);
      return temp2;
    } else {
      return "";
    }
  }

  static adjustDateLocalToMilan(date: string | Date): Date {
    const refDate = new Date();
    const localeDate = new Date(refDate.toLocaleString("en-US"));
    const euDate = new Date(
      refDate.toLocaleString("en-US", { timeZone: "Europe/Rome" })
    );

    const deltaLocalToMilan = dayjs(euDate).diff(localeDate, "minute");

    return dayjs(date)
      .add(deltaLocalToMilan, "minute")
      .toDate();
  }

  static adjustDateMilanToUtc(date?: string | Date | number): Date {
    const app: Date = date ? dayjs(date).toDate() : new Date();

    const myDate = new Date();
    const utcDate = new Date(myDate.toLocaleString("en-US", { timeZone: "UTC" }));
    const euDate = new Date(
      myDate.toLocaleString("en-US", { timeZone: "Europe/Rome" })
    );

    const deltaMilanUTC = dayjs(utcDate).diff(euDate, "minute");

    return dayjs(app)
      .add(deltaMilanUTC, "minute")
      .toDate();
  }

  static adjustDateUtcToMilan(date: string | Date) {
    const myDate = new Date();
    const utcDate = new Date(myDate.toLocaleString("en-US", { timeZone: "UTC" }));
    const euDate = new Date(
      myDate.toLocaleString("en-US", { timeZone: "Europe/Rome" })
    );
    const deltaUTCtoMilan = dayjs(euDate).diff(utcDate, "minute");

    const app = dayjs(date).toDate();

    const newDate = dayjs(date)
      .year(app.getUTCFullYear())
      .month(app.getUTCMonth())
      .date(app.getUTCDate())
      .hour(app.getUTCHours())
      .minute(app.getUTCMinutes())
      .second(0);

    return newDate.add(deltaUTCtoMilan, "minute").toDate();
  }
}
