import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AbstractControl } from "@angular/forms";

@Injectable({
  providedIn: "root"
})
export class CustomValidators {
  constructor(private http: HttpClient) {}

  atLeastOneElements() {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length >= 1) {
        return null;
      }

      return { atLeastOneElements: { valid: false } };
    };
  }
}
