<kms-admin-query-builder
  [config]="queryConfig"
  (queryEvent)="search($event)"
></kms-admin-query-builder>
<kms-admin-card class="inner-card" animation="mt-2">
  <button
    class="btn btn-primary btn-raised mt-0 mb-0 pr-1 pl-1 mr-1"
    (click)="editSelectedClick()"
    [disabled]="selected?.length <= 0"
  >
    <i class="fa fa-pencil fa-lg pencil"></i> Edit Selected
  </button>
  <button
    class="btn btn-primary btn-raised mt-0 mb-0 pr-1 pl-1"
    (click)="saveAllClick()"
    [disabled]="!isModified"
  >
    <i class="fa fa-floppy-o fa-lg"></i> Save All
  </button>
  <ngx-datatable
    class="bootstrap"
    [rows]="postList"
    [columnMode]="'force'"
    [rowHeight]="'auto'"
    [footerHeight]="'auto'"
    [limit]="limit"
    [messages]="{
      emptyMessage: '',
      totalMessage: 'total',
      selectedMessage: 'selected'
    }"
    [sorts]="[{ prop: 'author', dir: 'asc' }]"
    [selected]="selected"
    [selectionType]="'multiClick'"
    (select)="onSelect($event)"
  >
    <ngx-datatable-column
      [width]="30"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
      [headerCheckboxable]="true"
      [checkboxable]="true"
    >
      <ng-template
        ngx-datatable-header-template
        let-value="value"
        let-allRowsSelected="allRowsSelected"
        let-selectFn="selectFn"
      >
        <input
          type="checkbox"
          [checked]="allRowsSelected"
          (change)="selectFn(!allRowsSelected)"
        />
      </ng-template>

      <!-- <ng-template
        ngx-datatable-cell-template
        let-value="value"
        let-isSelected="isSelected"
        let-onCheckboxChangeFn="onCheckboxChangeFn"
      >
        <input
          type="checkbox"
          [checked]="isSelected"
          (change)="onCheckboxChangeFn($event)"
        />
      </ng-template> -->
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Author"
      prop="author"
      [width]="180"
      [canAutoResize]="false"
    >
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div
          [tooltip]="value"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ value }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Description" prop="description">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div
          [tooltip]="value"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ value }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Business Unit"
      prop="authorBusinessUnit"
      [width]="180"
      [canAutoResize]="false"
    >
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div
          [tooltip]="value"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ value }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Author City"
      prop="authorCity"
      [width]="180"
      [canAutoResize]="false"
    >
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div
          [tooltip]="value"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ value }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Author Company"
      prop="authorCompany"
      [width]="200"
      [canAutoResize]="false"
    >
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div
          [tooltip]="value"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ value }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Creation Date"
      prop="created"
      [width]="140"
      [canAutoResize]="false"
    >
      <ng-template let-date="value" ngx-datatable-cell-template>
        <div
          [tooltip]="date"
          containerClass="tooltip-class"
          container="body"
          class="fluid-text"
        >
          {{ date | amDateFormat: "Do MMM 'YY, HH:mm" }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [width]="160"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template let-post="row" ngx-datatable-cell-template>
        <button
          class="btn btn-primary btn-raised mt-0 mb-0 pr-1 pl-1"
          (click)="openConversation(post)"
        >
          Go to post
        </button>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer *ngIf="loadingIndicator"></ngx-datatable-footer>
  </ngx-datatable>
  <div *ngIf="loadingIndicator">
    <kms-admin-spinner [loading]="true"></kms-admin-spinner>
  </div>
</kms-admin-card>
