import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  HashtagInfo,
  Hashtag,
  UserInfo,
  HashtagBadge,
  MapDoc,
  SimilarHashtag,
  HashtagType,
} from "../commons/entities";
import { Observable, Subject, of } from "rxjs";
import { Cacheable } from "ngx-cacheable";
import { shareReplay, map, catchError, tap } from "rxjs/operators";
import { UploadService } from "./upload.service";
import { SearchService, KMSSearchResults } from "./search.service";
import { ConfigService } from "@ngx-config/core";
import { filter } from "lodash-es";

@Injectable({
  providedIn: "root",
})
export class HashtagService {
  constructor(
    private httpClient: HttpClient,
    private readonly config: ConfigService,
    private readonly uploaderSvc: UploadService,
    private searchSvc: SearchService
  ) {}

  public hashtagsToReplace$: Subject<any[]> = new Subject<any[]>();

  createStrategicTags(hashtag: Hashtag) {
    return this.httpClient.post("admin/api/hashtag/strategic/create", hashtag);
  }

  updateStrategicTags(hashtag: Hashtag) {
    return this.httpClient.post("admin/api/hashtag/strategic/update", hashtag);
  }

  demoteStrategicTags(hashtag: Hashtag) {
    return this.httpClient.post("admin/api/hashtag/strategic/demote", hashtag);
  }

  // public searchHashtag(query: string): Observable<HashtagInfo[]> {
  //   return this.httpClient.get<HashtagInfo[]>(`
  //   ${this.config.getSettings().apiPortalUrlPrefix}Hashtag/Search/${query}`);
  // }

  // public searchNetworkHashtag(query: string): Observable<HashtagInfo[]> {
  //   return this.httpClient.get<HashtagInfo[]>(
  //     `${
  //       this.config.getSettings().apiPortalUrlPrefix
  //     }Hashtag/Audiences/Search/${query}`
  //   );
  // }

  public searchRelatedHashtag(query: string): Observable<HashtagInfo[]> {
    let currentDate = new Date().getFullYear();
    const params = {
      filter: `(typeId eq 1 or typeId eq 5) and isPrivate eq false`,
      includeTotalResultCount: false,
      top: 10,
    };
    return this.searchSvc.query<HashtagInfo>("Hashtags", query, params).pipe(
      map((searchRes: any) =>
        searchRes.results
          .map((result) => result.document)
          // .filter(document => this.isCurrentYear(document))
          .map((val) => MapDoc.docToHashInfo(val))
      ),
      // map((searchRes: KMSSearchResults<HashtagInfo>) =>
      //   searchRes.results
      //     .map(result => result.document)
      //     .filter(document => document.referenceYear)
      //     .map(val => MapDoc.docToHashInfo(val))
      // ),
      tap(console.log)
    );
  }

  public searchRelatedHashtagAndStrategicTag(
    query: string
  ): Observable<HashtagInfo[]> {
    let currentDate = new Date().getFullYear();
    const params = {
      filter: `((typeId eq 5) and isPrivate eq false and referenceYear/any(r: r eq ${currentDate})) or (typeId eq 1 and isPrivate eq false)`,
      includeTotalResultCount: false,
      top: 10,
    };
    return this.searchSvc.query<HashtagInfo>("Hashtags", query, params).pipe(
      map((searchRes: any) =>
        searchRes.results
          .map((result) => result.document)
          // .filter(document => this.isCurrentYear(document))
          .map((val) => MapDoc.docToHashInfo(val))
      )
    );
  }

  // and referenceYear/any(r: r eq ${currentDate})
  // isCurrentYear(document:any):boolean{
  //   let currentDate = (new Date()).getFullYear();
  //   return (document.typeId == HashtagType.StrategicTag && (document.referenceYear.find(currentDate) != undefined)) || document.typeId == HashtagType.Generic
  // }

  public addNetworkRelatedHashtag(
    networkHastagId: string,
    hashtagId: string
  ): Observable<HashtagInfo[]> {
    return this.httpClient.post<HashtagInfo[]>(
      `admin/api/Hashtag/RelatedHashtag/${networkHastagId}/${hashtagId}/add`,
      null
    );
  }

  public addIKRelatedHashtag(
    ImpactingKnowledgeId: string,
    hashtagId: string
  ): Observable<HashtagInfo[]> {
    return this.httpClient.post<HashtagInfo[]>(
      `admin/api/Hashtag/RelatedHashtag/${ImpactingKnowledgeId}/${hashtagId}/add`,
      null
    );
  }

  public addHashtagRelatedHashtag(
    hashtagId: string,
    relatedHashtagId: string
  ): Observable<HashtagInfo[]> {
    return this.httpClient.post<HashtagInfo[]>(
      `admin/api/Hashtag/RelatedHashtag/${hashtagId}/${relatedHashtagId}/add`,
      null
    );
  }

  public removeNetworkRelatedHashtag(
    networkHastagId: string,
    hashtagId: string
  ): Observable<HashtagInfo[]> {
    return this.httpClient.post<HashtagInfo[]>(
      `admin/api/Hashtag/RelatedHashtag/${networkHastagId}/${hashtagId}/remove`,
      null
    );
  }

  public addNetworkStrategicTag(
    networkHashtagId: string,
    strategicTagId: string
  ): Observable<HashtagInfo[]> {
    return this.httpClient.post<HashtagInfo[]>(
      `admin/api/Hashtag/StrategicTag/${networkHashtagId}/${strategicTagId}/add`,
      null
    );
  }

  public removeNetworkStrategicTag(
    networkHashtagId: string,
    strategicTagId: string
  ): Observable<HashtagInfo[]> {
    return this.httpClient.post<HashtagInfo[]>(
      `admin/api/Hashtag/StrategicTag/${networkHashtagId}/${strategicTagId}/remove`,
      null
    );
  }

  public removeHashtagRelatedHashtag(
    hashtagId: string,
    removeHashtagId: string
  ): Observable<HashtagInfo[]> {
    return this.httpClient.post<HashtagInfo[]>(
      `admin/api/Hashtag/RelatedHashtag/${hashtagId}/${removeHashtagId}/remove`,
      null
    );
  }

  public removeIKRelatedHashtag(
    ImpactingKnowledgeId: string,
    hashtagId: string
  ): Observable<HashtagInfo[]> {
    return this.httpClient.post<HashtagInfo[]>(
      `admin/api/Hashtag/RelatedHashtag/${ImpactingKnowledgeId}/${hashtagId}/remove`,
      null
    );
  }
  public getHashtagInfoById(hashtagId: string): Observable<HashtagInfo> {
    return this.httpClient.get<HashtagInfo>("admin/api/Hashtag/" + hashtagId);
  }

  public getHashTagFollower(networkId: string): Observable<UserInfo[]> {
    return this.httpClient.get<UserInfo[]>(
      `admin/api/Hashtag/${networkId}/followers`
    );
  }

  public getHashTagBadge(hashtagId: string): Observable<HashtagBadge> {
    return this.httpClient.get<HashtagBadge>(
      `admin/api/Hashtag/badge/${hashtagId}`
    );
  }

  public getRelatedHashtags(id: string) {
    return this.httpClient.get<HashtagInfo[]>(
      `admin/api/hashtag/RelatedHashtag/${id}`
    );
  }

  public getHashtagAvatar(imageUrl: string): Observable<string> {
    return this.httpClient.get<string>(
      `admin/api/hashtag/avatar/get/image?path=${encodeURIComponent(imageUrl)}`
    );
  }

  public getDefaultHashtagAvatar(): Observable<string> {
    return this.httpClient.get<string>(`portal/api/hashtag/avatar/default/get`);
  }

  public getHashtagThumbnail(imageUrl: string): Observable<string> {
    return this.httpClient.get<string>(
      `admin/api/hashtag/avatar/get/thumbnail/image?path=${encodeURIComponent(
        imageUrl
      )}`
    );
  }

  public existsByName(name: string): Observable<any> {
    return this.httpClient
      .get<string>(`admin/api/hashtag/exists/${name}`)
      .pipe(catchError(() => of(false)));
  }

  public getDefaultHashtagThumbnail(): Observable<string> {
    return this.httpClient.get<string>(
      `admin/api/hashtag/avatar/default/thumbnail/get`
    );
  }

  public getStrategicTagByYear(
    years: number[],
    networkId: string
  ): Observable<HashtagInfo[]> {
    return this.httpClient.post<HashtagInfo[]>(
      `admin/api/hashtag/strategic/${networkId}/get/years`,
      years
    );
  }

  public getStrategicTagBadgeByYear(
    year: number,
    hashtagsId: string[]
  ): Observable<any> {
    return this.httpClient.post(
      `admin/api/hashtag/badge/strategic/${year}`,
      JSON.stringify(hashtagsId)
    );
  }

  public saveDescription(description: string, hashtagId: string) {
    return this.httpClient.post(
      `admin/api/hashtag/${hashtagId}/description/set`,
      JSON.stringify(description)
    );
  }

  public uploadHashtagAvatar(
    file: File,
    sourcePostId: string
  ): Observable<string> {
    return this.uploaderSvc.uploadImage(
      `hashtag/${encodeURIComponent(sourcePostId)}/avatar/upload`,
      file
    );
    // const formData = new FormData();
    // formData.append("files", file);
    // return this.httpClient.post<string>(
    //   `hashtag/${encodeURIComponent(sourcePostId)}/avatar/upload`,
    //   formData
    // );
  }

  renameHashtag(hashtag: Hashtag, newName: string) {
    return this.httpClient.post(
      `portal/api/Hashtag/${hashtag.id}/rename`,
      JSON.stringify(newName)
    );
  }
  replaceHashtag(oldHashtag: Hashtag, newHashtag: Hashtag) {
    return this.httpClient.post(
      `portal/api/Hashtag/${oldHashtag.id}/merge`,
      JSON.stringify(newHashtag.id)
    );
  }

  replaceMultipleHashtag(oldHashtags: string[], newHashtag: Hashtag) {
    return this.httpClient.post(
      `portal/api/Hashtag/${newHashtag.id}/mergeMultiple`,
      JSON.stringify(oldHashtags)
    );
  }

  searchSimilarHashtags(hashtagId: string) {
    return this.httpClient
      .post(`search/api/search/cluster/hashtags/${hashtagId}`, {
        item1: 5,
        item2: 10,
      })
      .pipe(
        map((el: SimilarHashtag) => {
          return el.item2.map((item) => new Hashtag(item));
        })
      );
  }

  searchSimilarHashtagByHashtagList(hashtagsIds: string[]) {
    return this.httpClient
      .post(`search/api/search/cluster/hashtagsList`, {
        item1: 5,
        item2: 10,
        item3: hashtagsIds,
      })
      .pipe(
        map((el: SimilarHashtag) => {
          return el.item2.map((item) => new Hashtag(item));
        })
      );
  }

  searchSimilarClusterHashtags(hashtagId: string) {
    return this.httpClient
      .post(`search/api/search/cluster/hashtags/${hashtagId}`, {
        item1: 5,
        item2: 10,
      })
      .pipe(
        map((el: SimilarHashtag) => {
          return el.item2.map((item) => new SimilarHashtag(item));
        })
      );
  }

  searchSimilarClusterHashtagsByHashtagList(hashtagList: HashtagInfo[]) {
    return this.httpClient
      .post(`search/api/search/cluster/hashtagsByName`, {
        item1: 20,
        item2: 10,
        item3: hashtagList,
      })
      .pipe
      //   map((el: SimilarHashtag) => {
      //     console.log(el)
      //     console.log(el[0].item2?.map(item => new SimilarHashtag(item)))
      //   return el.item2?.map(item => new SimilarHashtag(item));
      // })
      ();
  }

  getSimilarHashtagsList() {
    return this.httpClient
      .post(`search/api/search/cluster/hashtags`, {
        item1: 5,
        item2: 20,
      })
      .pipe(
        map((el: SimilarHashtag[]) => {
          return el.map((item) => new SimilarHashtag(item));
        })
      );
  }
}
