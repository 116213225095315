<div class="modal-header">
  <h4 class="modal-title pull-left mt-0">{{ title }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div
      class="col-6 input-alignment"
      form-field
      [control]="medal.get('name')"
      [formGroup]="medal"
    >
      <input
        type="text"
        formControlName="name"
        class="no-box form-control"
      />
      <label
        class="floating-label"
        [ngClass]="{
          'go-top':
            medal.get('name')?.valid || medal.get('name')?.disabled,
          'disabled-custom': medal.get('name').disabled
        }"
        >Name *</label
      >
    </div>
    <div class="col-6 input-alignment mt-1">
      <label class="mr-1">Level Color</label>
      <input
        type="color"
        [(ngModel)]="color"
        id="head"
        name="head"
      />

      <!-- <label
        class="floating-label color-white"
        [ngClass]="{
          'go-top':
            medal.get('cssClass')?.valid || medal.get('cssClass')?.disabled,
          'disabled-custom': medal.get('cssClass').disabled
        }"
        >Level Color *</label
      > -->
    </div>
  </div>
  <div class="row">
    <div
      class="col-6 input-alignment"
      form-field
      [control]="medal.get('minPoints')"
      [formGroup]="medal"
    >
      <input
        type="number"
        kmsAdminNumberDirective
        formControlName="minPoints"
        class="no-box form-control"
      />
      <label
        class="floating-label"
        [ngClass]="{
          'go-top':
            medal.get('minPoints')?.valid || medal.get('minPoints')?.disabled,
          'disabled-custom': medal.get('minPoints').disabled
        }"
        >Min Points *</label
      >
    </div>
    <div
      class="col-6 input-alignment"
      form-field
      [control]="medal.get('maxPoints')"
      [formGroup]="medal"
    >
      <input
        type="number"
        kmsAdminNumberDirective
        formControlName="maxPoints"
        class="no-box form-control"
      />
      <label
        class="floating-label"
        [ngClass]="{
          'go-top':
            medal.get('maxPoints')?.valid || medal.get('maxPoints')?.disabled,
          'disabled-custom': medal.get('maxPoints').disabled
        }"
        >Max Points *</label
      >
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary btn-raised"
    (click)="onConfirm()"
    [disabled]="medal.invalid"
  >
    {{ confirmBtnName }}
  </button>
  <button
    type="button"
    class="btn btn-warning btn-raised"
    (click)="bsModalRef.hide()"
  >
    {{ closeBtnName }}
  </button>
</div>
