import { Injectable } from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  FormArray,
  ValidationErrors
} from "@angular/forms";

@Injectable({
  providedIn: "root"
})
export class ValidationService {
  constructor() {}

  getMessage(
    key: string,
    value: any,
    ctrlName: string = null
  ): string {
    if (
      key === "maxlength"
    ) {
      return MESSAGES[key](value.requiredLength, ctrlName);
    } else {
      return MESSAGES[key](ctrlName);
    }
  }

  getMessages(input: ValidationErrors) {
    return Object.keys(input)
      .map(k => this.getMessage(k, input[k]))
      .join("\n\n");
  }

  getMessagesDeep(input: FormGroup) {
    const errors = [];
    this.addMessage(input, errors);
    return errors.join("\n");
  }

  private addMessage(
    input: AbstractControl,
    errors: string[],
    ctrlName: string = null
  ) {
    if (input.errors) {
      if (input.errors.requiredQuest) {
        let isQuest: boolean;
        let index: number;
        let subIndex: number;
        if (input.parent.parent.parent.parent.parent.value.surveyId) {
          isQuest = true;
          const survey = input.parent.parent.parent.parent.parent.value;
          const question = input.parent.parent.parent.value;
          index = survey.questions.indexOf(question);
        } else {
          isQuest = false;
          const subQuest = input.parent.parent.parent.value;
          const quest = input.parent.parent.parent.parent.parent.value;
          const surv =
            input.parent.parent.parent.parent.parent.parent.parent.value;
          subIndex = quest.subQuestion.indexOf(subQuest);
          index = surv.questions.indexOf(quest);
        }
        errors.push(
          ...Object.keys(input.errors).map(k =>
            this.getMessage(
              k,
              input.errors[k],
              ctrlName
            )
          )
        );
      } else if (
        input.errors.minAnswerValue ||
        input.errors.maxAnswerValue ||
        input.errors.typeRequired ||
        input.errors.min ||
        input.errors.maxAnswerValue === 0 ||
        input.errors.minAnswer
      ) {
        let isQuest: boolean;
        let index: number;
        let subIndex: number;
        if (input.parent.parent.parent.value.surveyId) {
          isQuest = true;
          const survey = input.parent.parent.parent.value;
          const question = input.parent.value;
          index = survey.questions.indexOf(question);
        } else {
          isQuest = false;
          const subQuest = input.parent.value;
          const quest = input.parent.parent.parent.value;
          const surv = input.parent.parent.parent.parent.parent.value;
          subIndex = quest.subQuestion.indexOf(subQuest);
          index = surv.questions.indexOf(quest);
        }
        errors.push(
          ...Object.keys(input.errors).map(k =>
            this.getMessage(
              k,
              input.errors[k],
              this.getControlName(input)
            )
          )
        );
      } else {
        errors.push(
          ...Object.keys(input.errors).map(k =>
            this.getMessage(
              k,
              input.errors[k],
              ctrlName
            )
          )
        );
      }
    }
    if (input instanceof FormGroup || input instanceof FormArray) {
      Object.keys(input.controls).map(k =>
        this.addMessage(input.controls[k], errors, k)
      );
    }
  }
  getControlName(c: AbstractControl): string | null {
    if (!c.parent) {
      return null;
    }
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }
}

const MESSAGES = {
  minDate: () => "You cannot choose a Start Date in the past.",
  minDateRef: () => "Choose an End Date later than the Start Date",
  maxDateRef: () => "Choose a StartDate earlier than the EndDate",
  noSurveyInfo: () => "You must complete at least one title field!",
  noNegative: () => "It must be a number > 0",
  required: field => `${field || "Field"} is required`,
  maxlength: (ml, field) => `${field || "Field"} max length is ${ml}`,
  minValue: () => "The value is too much small",
  maxValue: () => "The value is too much big",
  atLeastOneElements: () => "At least one element is required"
};
