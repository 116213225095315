<div class="modal-header pl-2">
  <span class="d-flex flex-column">
    <h4 class="modal-title pull-left mt-0 pl-2">{{ title }}</h4>
    <i class="font-weight-light small pl-2"
      >This operation will set a business unit for all the selected posts</i
    >
  </span>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  ></button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <ng-select
          class="edit-network"
          [items]="network$ | async"
          bindLabel="name"
          [addTag]="false"
          [multiple]="false"
          [hideSelected]="true"
          [loading]="loadingIndicator"
          [placeholder]="'Business Unit *'"
          [typeahead]="networkInput$"
          [(ngModel)]="network"
        >
        </ng-select>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary btn-disabled-style btn-raised"
    [disabled]="!network"
    (click)="onConfirmClick()"
  >
    {{ confirmBtnName }}
  </button>
  <button
    type="button"
    class="btn btn-warning btn-raised"
    (click)="bsModalRef.hide()"
  >
    {{ closeBtnName }}
  </button>
</div>
