import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { KMSObject } from "src/app/commons/entities";
import { Cacheable } from "ngx-cacheable";
import { map, shareReplay } from "rxjs/operators";
import { UploadService } from "./upload.service";
import { ConfigService } from "@ngx-config/core";

@Injectable({
  providedIn: "root"
})
export class IkService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly uploaderSvc: UploadService,
    private readonly config: ConfigService
  ) {}

  public getIKAvatar(imageUrl: string, type = KMSObject.IK): Observable<any> {
    let url = "";
    if (type === KMSObject.IK) {
      url = `portal/api/ik/avatar/get/image?path=${encodeURIComponent(
        imageUrl
      )}`;
    } else if (type === KMSObject.Idea) {
      url = `portal/api/ik/ideas/avatar/get/image?path=${encodeURIComponent(
        imageUrl
      )}`;
    } else if (type === KMSObject.SuccessStory) {
      url = `portal/api/ik/successstories/avatar/get/image?path=${encodeURIComponent(
        imageUrl
      )}`;
    } else if (type === KMSObject.Challenge) {
      url = `portal/api/ik/challenge/avatar/get/image?path=${encodeURIComponent(
        imageUrl
      )}`;
    }
    return this.httpClient.get(url);
  }

  public getDefaultIKAvatar(type = KMSObject.IK): Observable<any> {
    let url = "";
    if (type === KMSObject.IK) {
      url = `portal/api/ik/knowledgenugget/avatar/default/get`;
    } else if (type === KMSObject.Idea) {
      url = `portal/api/ik/idea/avatar/default/get`;
    } else if (type === KMSObject.SuccessStory) {
      url = `portal/api/ik/successstory/avatar/default/get`;
    } else if (type === KMSObject.Challenge) {
      url = `portal/api/ik/challenge/avatar/default/get`;
    }

    return this.httpClient.get(url);
  }

  public getIKThumbnail(
    imageUrl: string,
    type = KMSObject.IK
  ): Observable<any> {
    return this.httpClient.get(
      `admin/api/ik/avatar/get/thumbnail/image?path=${encodeURIComponent(
        imageUrl
      )}`
    );
  }

  public getDefaultIKThumbnail(type = KMSObject.IK): Observable<any> {
    return this.httpClient.get(`admin/api/ik/avatar/default/thumbnail/get`);
  }

  public uploadIkAvatar(
    file: File,
    sourcePostId: string,
    type = KMSObject.IK
  ): Observable<string> {
    let url = "";
    if (type === KMSObject.IK) {
      url = `ik/${encodeURIComponent(sourcePostId)}/avatar/upload`;
    } else if (type === KMSObject.Idea) {
      url = `ik/ideas/${encodeURIComponent(
        sourcePostId
      )}/avatar/upload`;
    } else if (type === KMSObject.Challenge) {
      url = `ik/challenge/${encodeURIComponent(
        sourcePostId
      )}/avatar/upload`;
    }

    return this.uploaderSvc.uploadImage(url, file);

    // const formData = new FormData();
    // formData.append("files", file);
    // return this.httpClient.post<string>(
    //   `ik/${encodeURIComponent(sourcePostId)}/avatar/upload`,
    //   formData
    // );
  }
}
