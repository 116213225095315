import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { NetworkInfo, NetworkType, KMSObject } from "src/app/commons/entities";
import { Subject, Observable, concat, of } from "rxjs";
import { FormArray, FormControl } from "@angular/forms";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { NetworkService } from "src/app/services/network.service";
import { UserService } from "src/app/services/user.service";
// import { NetworkInfo, NetworkType, KMSObject } from "src/app/shared/entities";
// import { FormArray, FormBuilder, FormControl } from "@angular/forms";
// import { NetworkService } from "src/app/services/network.service";
// import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, map } from "rxjs/operators";
// import { UserService } from "src/app/services/user.service";

@Component({
  selector: "kms-network-picker",
  templateUrl: "./network-picker.component.html",
  template: "",
  styleUrls: ["./network-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkPickerComponent implements OnInit {
  @Input() placeholder: string;
  @Input() networkType: NetworkType = null;
  @Input() networkList: FormArray = new FormArray([]);
  @Input() enableSelectAll = false;
  @Input() edit = true;
  @Input() dropdownPosition = "bottom";
  @Input() customRemove = null;
  @Input() removeWhenNotMemberOrFacilitator = false;
  @Input() isSuperUser = false;
  @Input() hasAll: boolean = false;
  @Input() isLoading = false;

  public showAllNetworks: boolean = true;

  @Output()
  add = new EventEmitter();

  @Output()
  remove = new EventEmitter();

  input$ = new Subject<string>();
  networks$: Observable<NetworkInfo[]>;

  loading: boolean;
  networks: NetworkInfo[];
  KMSObject = KMSObject;

  constructor(
    private cd: ChangeDetectorRef,
    private readonly networkSvc: NetworkService,
    private readonly userSvc: UserService
  ) {}

  ngOnInit() {
    // console.log(this.hasAll)
    if (this.hasAll) {
      this.showAllNetworks = false;
    } else {
      this.showAllNetworks = true;
    }

    this.networks = [
      ...this.networkList.value
        .filter(
          (n) =>
            !this.networkType ||
            n.type === this.networkType ||
            this.networkType === NetworkType.CoPAndPrivate ||
            this.networkType === NetworkType.Private
        )
        .map((n) => new NetworkInfo(n)),
    ];

    // console.log(this.networks);

    this.networkList.valueChanges.subscribe(() => {
      this.networks = [
        ...this.networkList.value
          .filter((n) => !this.networkType || n.type === this.networkType)
          .map((n) => new NetworkInfo(n)),
      ];
      this.cd.markForCheck();
    });
    this.loadNetworks();
  }

  private loadNetworks() {
    this.networks$ = concat(
      of([]), // default items
      this.input$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          this.loading = true;
          this.cd.markForCheck();
        }),
        switchMap((term) => {
          return this.networkType
            ? this.networkType === NetworkType.Private
              ? this.networkSvc.searchPrivateNetwork(term).pipe(
                  catchError(() => of([])), // empty list on error
                  map((networksCoP) =>
                    !this.isSuperUser && this.removeWhenNotMemberOrFacilitator
                      ? networksCoP.filter((el) => {
                          return (
                            el.facilitatorsIds.some(
                              (id) => id === this.userSvc.userId
                            ) ||
                            el.coFacilitatorsIds.some(
                              (id) => id === this.userSvc.userId
                            ) ||
                            el.guestsIds.some(
                              (id) => id === this.userSvc.userId
                            )
                          );
                        })
                      : networksCoP
                  ),
                  map((networksCoP) =>
                    networksCoP.filter((el) =>
                      this.networkList.value.every(
                        (item2) => item2.networkId !== el.networkId
                      )
                    )
                  ),
                  tap(() => {
                    this.loading = false;
                    this.cd.markForCheck();
                  })
                )
              : this.networkSvc
                  .searchNetworkByType(term, this.networkType)
                  .pipe(
                    catchError(() => of([])), // empty list on error
                    map((networksCoP) =>
                      networksCoP.filter((el) =>
                        this.networkList.value.every(
                          (item2) => item2.networkId !== el.networkId
                        )
                      )
                    ),
                    tap(() => {
                      this.loading = false;
                      this.cd.markForCheck();
                    })
                  )
            : this.networkSvc.searchNetwork(term).pipe(
                catchError(() => of([])), // empty list on error
                map((networksCoP) =>
                  networksCoP.filter((el) =>
                    this.networkList.value.every(
                      (item2) => item2.networkId !== el.networkId
                    )
                  )
                ),
                tap(() => {
                  this.loading = false;
                  this.cd.markForCheck();
                })
              );
        })
      )
    );
  }

  public showNetworks(): void {
    this.showAllNetworks = !this.showAllNetworks;
  }
  addNetwork(event) {
    var ni = new NetworkInfo(event);

    (<FormArray>this.networkList).push(new FormControl(ni));

    //this.networkList.push(new NetworkInfo(event).toFormGroup(this.builder));

    this.add.emit(new NetworkInfo(event));
    this.cd.markForCheck();
  }

  removeNetwork(event) {
    if (this.edit || this.customRemove === null) {
      const arr = this.networkList.value;

      arr.forEach((element: NetworkInfo, index) => {
        if (element.networkId === event.value.networkId) {
          this.remove.emit(element);
          this.networkList.removeAt(index);
        }
      });
      this.hasAll = false;
    } else {
      this.customRemove(event);
      this.hasAll = false;
    }

    this.networkList.markAsTouched();
    this.cd.markForCheck();
  }

  /*
  removeNetwork(event) {
      const arr = this.networkList.value;

      arr.forEach((element: NetworkInfo, index) => {
        if (element.networkId === event.value.networkId) {
          this.remove.emit(element);
          this.networkList.removeAt(index);
        }
      });

    this.networkList.markAsTouched();
    this.cd.markForCheck();
  }
*/
  clear() {
    if (
      !this.networkType ||
      this.networkType === NetworkType.CoPAndPrivate ||
      this.networkType === NetworkType.Private
    ) {
      this.removeAll();
    } else {
      const rest: NetworkInfo[] = this.networkList.value.filter(
        (n) => n.type !== this.networkType
      );
      this.removeAll();
      //rest.forEach(n => this.addNetwork(n));
    }

    this.hasAll = false;
  }

  removeAll() {
    this.networks = [];

    this.networkList.value.forEach((element) => {
      if (element.type === this.networkType) {
        // console.log("indice: ");
        // console.log(this.networkList.value.indexOf(element));
        this.networkList.removeAt(this.networkList.value.indexOf(element));
      }
    });

    // while (this.networkList.length !== 0) {
    //   this.networkList.removeAt(0);
    // }

    this.networkList.markAsTouched();
    this.cd.markForCheck();
  }

  addAll() {
    if (this.networkType === 1) {
      this.removeAll();
      this.networkSvc.getNetworkCOP().subscribe((networks) => {
        networks.forEach((n) => {
          this.addNetwork(n);
        });
        this.hasAll = true;
        this.cd.markForCheck();
      });
    } else if (this.networkType === 2) {
      this.removeAll();
      this.networkSvc.getNetworkBU().subscribe((networks) => {
        networks.forEach((n) => {
          this.addNetwork(n);
        });
        this.hasAll = true;
        this.cd.markForCheck();
      });
    } else if (this.networkType === 5) {
      this.removeAll();
      this.networkSvc.getNetworkPrivate().subscribe((networks) => {
        networks.forEach((n) => {
          this.addNetwork(n);
        });
        this.hasAll = true;
        this.cd.markForCheck();
      });
    } else if (this.networkType === 4) {
      this.removeAll();
      this.networkSvc.getNetworkSpecialTag().subscribe((networks) => {
        networks.forEach((n) => {
          this.addNetwork(n);
        });
        this.hasAll = true;
        this.cd.markForCheck();
      });
    }
    this.showAllNetworks = true;
    // console.log(this.networks);
  }

  getLink(item) {
    let type;
    switch (this.networkType) {
      case NetworkType.CoP:
        type = "cop";
        break;
      case NetworkType.BusinessUnit:
        type = "bu";
        break;
    }
    return ["/network", item.networkId, type];
  }
}
