import {
  HashtagInfo,
  NetworkInfo,
  UserInfo,
  Choice,
  IAttachment,
  ImpactingKnowledgeType,
  IKChoices
} from "./entities";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  FormArray,
  Validators
} from "@angular/forms";
import * as dayjs from "dayjs";
import { Utilities } from "../commons/util";
import { NumberDirectiveDirective } from './number-directive.directive';
// import {
//   dateValida,
//   bookingCoPsValidator,
//   nullValidator,
//   emailValidator
// } from "./validation";

export interface WebinarsResponse {
  accountId?: string;
  id?: string;
  partition?: string;
  name: string;
  description?: string;
  userName?: string;
  created?: Date;
  privacyMode?: string;
  state?: string;
  isOwned?: boolean;
  isEditable?: boolean;
  isBase?: boolean;
  durationInSeconds?: number;
  summarizedInsights?: SummarizedInsights;
  videos?: Video[];
  videosRanges?: VideosRange[];
  founded?: number;
  members?: string[];
}

export interface SummarizedInsights {
  name: string;
  id: string;
  privacyMode: string;
  duration: Duration;
  thumbnailVideoId: string;
  thumbnailId: string;
  faces: SummarizedInsightsFace[];
  keywords: LabelElement[];
  sentiments: SummarizedInsightsSentiment[];
  emotions: SummarizedInsightsEmotion[];
  audioEffects: any[];
  labels: LabelElement[];
  brands: SummarizedInsightsBrand[];
  statistics: Statistics;
  topics: SummarizedInsightsTopic[];
}

export interface SummarizedInsightsBrand {
  referenceId: string;
  referenceUrl: string;
  confidence: number;
  description: string;
  seenDuration: number;
  id: number;
  name: string;
  appearances: Appearance[];
}

export interface Appearance {
  startTime: string;
  endTime: string;
  startSeconds: number;
  endSeconds: number;
}

export interface Duration {
  time: string;
  seconds: number;
}

export interface SummarizedInsightsEmotion {
  type: string;
  seenDurationRatio: number;
  appearances: Appearance[];
}

export interface SummarizedInsightsFace {
  videoId: string;
  referenceId: string;
  referenceType: string;
  knownPersonId: string;
  confidence: number;
  description: string;
  title: string;
  thumbnailId: string;
  seenDuration: number;
  seenDurationRatio: number;
  id: number;
  name: string;
  appearances: Appearance[];
}

export interface LabelElement {
  isTranscript?: boolean;
  id: number;
  name: string;
  appearances: Appearance[];
}

export interface SummarizedInsightsSentiment {
  sentimentKey: string;
  seenDurationRatio: number;
  appearances: Appearance[];
}

export interface Statistics {
  correspondenceCount: number;
  speakerTalkToListenRatio: { [key: string]: number };
  speakerLongestMonolog: { [key: string]: number };
  speakerNumberOfFragments: { [key: string]: number };
  speakerWordCount: { [key: string]: number };
}

export interface SummarizedInsightsTopic {
  referenceUrl: string;
  iptcName: string;
  iabName: string;
  confidence: number;
  id: number;
  name: string;
  appearances: Appearance[];
}

export interface Video {
  accountId: string;
  id: string;
  state: string;
  moderationState: string;
  reviewState: string;
  privacyMode: string;
  processingProgress: string;
  failureCode: string;
  failureMessage: string;
  externalId: string;
  externalUrl: string;
  metadata: string;
  insights: Insights;
  thumbnailId: string;
  publishedUrl: string;
  publishedProxyUrl: string;
  viewToken: string;
  detectSourceLanguage: boolean;
  sourceLanguage: string;
  language: string;
  indexingPreset: string;
  linguisticModelId: string;
  personModelId: string;
  isAdult: boolean;
}

export interface Insights {
  version: string;
  duration: string;
  sourceLanguage: string;
  language: string;
  transcript: TranscriptElement[];
  ocr: Ocr[];
  keywords: InsightsKeywords[];
  topics: InsightsTopic[];
  faces: InsightsFace[];
  labels: Label[];
  shots: Shot[];
  brands: InsightsBrand[];
  sentiments: InsightsSentiment[];
  emotions: InsightsEmotion[];
  visualContentModeration: VisualContentModeration[];
  blocks: Block[];
  framePatterns: FramePattern[];
  speakers: Speaker[];
  textualContentModeration: TextualContentModeration;
  statistics: Statistics;
  sourceLanguageConfidence: number;
}

export interface Block {
  id: number;
  instances: Instance[];
}

export interface Instance {
  adjustedStart: string;
  adjustedEnd: string;
  start: string;
  end: string;
  duration: string;
  brandType?: string;
  confidence?: number;
  thumbnailsIds?: string[];
  thumbnailId?: string;
  url?: string;
}

export interface InsightsBrand {
  id: number;
  name: string;
  referenceId: string;
  referenceUrl: string;
  referenceType: string;
  description: string;
  tags: any[];
  confidence: number;
  isCustom: boolean;
  instances: Instance[];
}

export interface InsightsEmotion {
  id: number;
  type: string;
  instances: Instance[];
}

export interface InsightsFace {
  id: number;
  name: string;
  confidence?: number;
  description?: string;
  thumbnailId?: string;
  referenceId?: string;
  referenceType?: string;
  title?: string;
  imageUrl?: string;
  thumbnails?: Thumbnail[];
  instances?: Instance[];
  user?: UserInfo;
}

export interface Thumbnail {
  id: string;
  fileName: string;
  instances: Instance[];
}

export interface FramePattern {
  id: number;
  patternType: string;
  confidence: number;
  instances: Instance[];
}

// export class VideoTranscriptEdits {
//   constructor(videoId: string, webinarId: string,  transcriptEdits:  TranscriptElementEdit[]) {
//     this.videoId = videoId;
//     this.webinarId = webinarId;
//     this.transcriptEdits = transcriptEdits;
// }
//   videoId: string;
//   webinarId: string;
//   transcriptEdits: TranscriptElementEdit[] 
// }

export interface TranscriptElement {
  id: number;
  text: string;
  confidence: number;
  language: string;
  instances: Instance[];
  speakerId?: number;
}

export interface GetChapterVideosTranscriptEditsRequest {
  webinarNameSuffix: string;
  itemsCount: number;
  pageNum: number;
}


export class TranscriptElementEdit {
  constructor(id: number, confidence: number,  language: string, speakerId: number, textEdits: TextEdit[]) {
  this.textEdits = textEdits;
  this.id = id;
  this.confidence = confidence;
  this.language = language;
  this.speakerId = speakerId;
  }

  textEdits: TextEdit[];
  id: number;
  text: string;
  confidence: number;
 // wordErrorRate: number;
  language: string;
  instances: any; // ?????????????????????????
  speakerId: number;
}

export class TextEdit {
  constructor(id: number, originalText: string,  editedText: string) {
    this.editId = id;
    this.text = originalText;
    this.editedText = editedText;
}

  editId: number;
  text: string;
  editedText: string;
}

export class WERandWordCount {
  constructor(wordErrorRate: number, wordCount: number) {
    this.wordErrorRate = wordErrorRate;
    this.wordCount = wordCount;
}

  wordErrorRate: number;
  wordCount: number;
}

export interface WebinarSlimInfo {
  name: string;
  description: string;
  promotingNetworks: string[];
  visibility: number;
  chapters: Chapter[];
  id: string;
  transactionId: string;
  created: string;
  createdBy: string;
  modified: string;
  modifiedBy: string;
  isDeleted: boolean;
  deleted: string;
  deletedBy: string;
  $type: string;
  areEditsOnVI: boolean;
}

export interface Chapter {
  transcriptEdits: WebinarVideoTranscriptEditInfo;
  id: string;
  videoId: string;
  name: string;
  zipFilePath: string;
  showTranscript: boolean;
  confidenceScore: number;
  indexed: boolean;
  indexing: boolean;
}

export class WebinarVideoTranscriptEditInfo{
  constructor(videoId: string, chapterId: string,  transcriptEdits:  TranscriptElementEdit[]) {
        this.videoId = videoId;
        this.chapterId = chapterId;
        this.transcriptEdits = transcriptEdits;
    }
  id: string;
  videoId: string;
  chapterId: string;
  transcriptEdits: TranscriptElementEdit[];
  transcriptVersion: number;
  transcriptWER: number;
}

export class TranscriptElementEditInfo {
  constructor(
    name: string, promotingNetworks: string[], chapterName: string, confidenceScore: number,
    transcriptWER: number, transcriptEdits: TranscriptElementEdit[], webinarId: string, isCommittedOnVI: boolean, showTranscript: boolean
    ) {
      this.name = name;
      this.promotingNetworks = promotingNetworks;
      this.chapterName = chapterName;
      this.confidenceScore = confidenceScore;
      this.transcriptWER = transcriptWER;
      this.transcriptEdits = transcriptEdits;
      this.webinarId = webinarId;
      this.isCommittedOnVI = isCommittedOnVI;
      this.showTranscript = showTranscript;
    }
  name: string;
  promotingNetworks: string[];
  chapterName: string;
  confidenceScore: number;
  transcriptWER: number;
  transcriptEdits: TranscriptElementEdit[];
  webinarId: string;
  isCommittedOnVI: boolean;
  showTranscript: boolean;
}

export interface InsightsKeywords {
  id: number;
  text: string;
  confidence: number;
  language: string;
  instances: Instance[];
  speakerId?: number;
}

export interface Label {
  id: number;
  name: string;
  language: string;
  instances: Instance[];
}

export interface Ocr {
  id: number;
  text: string;
  confidence: number;
  left: number;
  top: number;
  width: number;
  height: number;
  language: string;
  instances: Instance[];
}

export interface InsightsSentiment {
  id: number;
  averageScore: number;
  sentimentType: string;
  instances: Instance[];
}

export interface Shot {
  id: number;
  keyFrames: Block[];
  instances: Instance[];
}

export interface Speaker {
  id: number;
  name: string;
  instances: Instance[];
  user: UserInfo;
}

export interface TextualContentModeration {
  id: number;
  bannedWordsCount: number;
  bannedWordsRatio: number;
  instances: any[];
}

export interface InsightsTopic {
  id: number;
  name: string;
  referenceId: string;
  referenceType: string;
  iptcName?: string;
  confidence: number;
  iabName: string;
  language: string;
  instances: Instance[];
  referenceUrl?: string;
}

export interface VisualContentModeration {
  id: number;
  adultScore: number;
  racyScore: number;
  instances: Instance[];
}

export interface VideosRange {
  videoId: string;
  range: Range;
}

export interface Range {
  start: string;
  end: string;
  duration: string;
}

///////////////////// list ////////////////////////////////
export interface WebinarsListResponse {
  results?: VideoInfo[];
  nextPage?: NextPage;
  name?: string;
  founded?: number;
  members?: string[];
}

export interface NextPage {
  pageSize: number;
  skip: number;
  done: boolean;
}

export interface VideoInfo {
  accountId: string;
  id: string;
  partition: string;
  externalId: string;
  metadata: string;
  name: string;
  description: string;
  created: Date;
  lastModified: Date;
  lastIndexed: Date;
  privacyMode: string;
  userName: string;
  isOwned: boolean;
  isBase: boolean;
  hasSourceVideoFile: boolean;
  state: string;
  moderationState: string;
  reviewState: string;
  processingProgress: string;
  durationInSeconds: number;
  thumbnailVideoId: string;
  thumbnailId: string;
  searchMatches: any[];
  indexingPreset: string;
  streamingPreset: string;
  sourceLanguage: string;
  personModelId: string;
}

export const WebinarVideoState = {
  PROCESSING: "Processing",
  PROCESSED: "Processed"
};

///// class ////

export class WebinarInfo {
  public date: Date;
  public durationMinutes: number;
  public location: string;
  public visibility: number;
  public allowSharing: boolean;
  public status: number;
  public videoUrl: string;
  public videoId: string;
  public videoName: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.date = initialData.date;
      this.durationMinutes = initialData.durationMinutes;
      this.location = initialData.location;
      this.visibility = initialData.visibility;
      this.allowSharing = initialData.allowSharing;
      this.status = initialData.status;
      this.videoUrl = initialData.videoUrl;
      this.videoId = initialData.videoId;
      this.videoName = initialData.videoName;
    }
  }
}

export class WebinarBase extends IKChoices {
  id: string;
  type = ImpactingKnowledgeType.Webinar;
  logoUrl: string;
  name: string;
  date: Date;
  durationMinutes: string;
  promotingNetworks: NetworkInfo[];
  privateNetworks: NetworkInfo[];
  location: string;
  involvedPeople: UserInfo[];
  involvedPeopleIds: string[];
  author: string;
  description: string;
  visibility: WebinarVisibilityType;
  allowSharing: boolean;
  internalSpeakers: UserInfo[];
  internalSpeakersIds: string[];
  externalSpeakers: string[];
  remoteSpeaker: boolean;
  invitedCoPs: NetworkInfo[];
  otherRecipients: string;
  businessImpact: Choice;
  attachments: IAttachment[];
  created: Date;
  createdBy: UserInfo;
  modified: Date;
  modifiedBy: UserInfo;
  isDeleted: boolean;
  deleted: Date;
  deletedBy: UserInfo;
  webinarType: WebinarType;
  languageCodes = WebinarLanguage.EN;
  eventLink: string;
  originalEvaluationChoices: string[];
  bookingRequestDate: Date;
  additionalInformation: string;
  otherInvolvedPeople: string;
  speakersBUs: NetworkInfo[];
  relatedTags: HashtagInfo[];
  isImported: boolean;

  contentResponsibility: boolean;

  constructor(initialData?: any) {
    super(initialData);
    if (initialData) {
      this.id = initialData.id;
      this.type = initialData.type;
      this.logoUrl = initialData.logoUrl;
      this.name = initialData.name;
      this.date = initialData.date;
      this.durationMinutes = initialData.durationMinutes;
      this.relatedTags = initialData.relatedTags
        ? initialData.relatedTags.map(r => new HashtagInfo(r))
        : [];
      this.promotingNetworks = initialData.promotingNetworks
        ? initialData.promotingNetworks.map(p => new NetworkInfo(p)).filter(m=> m.type != 5)
        : [];

      this.privateNetworks = initialData.promotingNetworks
        ? initialData.promotingNetworks.map(p => new NetworkInfo(p)).filter(m=> m.type === 5)
        : [];
           
      this.location = initialData.location;
      this.involvedPeople = initialData.involvedPeople
        ? initialData.involvedPeople.map(i => new UserInfo(i))
        : [];
      this.description = initialData.description;
      this.visibility = initialData.visibility;
      this.allowSharing = initialData.allowSharing;
      this.internalSpeakers = initialData.internalSpeakers
        ? initialData.internalSpeakers.map(i => new UserInfo(i))
        : [];
      this.externalSpeakers = initialData.externalSpeakers;
      this.remoteSpeaker = initialData.remoteSpeaker;
      this.invitedCoPs = initialData.invitedCoPs
        ? initialData.invitedCoPs.map(i => new NetworkInfo(i))
        : [];
      this.otherRecipients = initialData.otherRecipients;
      this.attachments = initialData.attachments;
      this.created = initialData.created;
      this.createdBy = new UserInfo(initialData.createdBy);
      this.modified = initialData.modified;
      this.modifiedBy = new UserInfo(initialData.modifiedBy);
      this.businessImpact = initialData.businessImpact
        ? new Choice(initialData.businessImpact)
        : null;
      this.isDeleted = initialData.isDeleted;
      this.deleted = initialData.deleted;
      this.deletedBy = new UserInfo(initialData.deletedBy);
      this.author = initialData.author;
      this.languageCodes =
        initialData.languageCodes && initialData.languageCodes.trim() !== ""
          ? initialData.languageCodes
          : this.languageCodes;
      this.eventLink = initialData.eventLink;
      this.originalEvaluationChoices = initialData.originalEvaluationChoices;
      this.bookingRequestDate = initialData.bookingRequestDate;
      this.additionalInformation = initialData.additionalInformation;
      this.otherInvolvedPeople = initialData.otherInvolvedPeople;
      this.speakersBUs = initialData.speakersBUs
        ? initialData.speakersBUs.map(i => new NetworkInfo(i))
        : [];
      this.isImported = initialData.isImported;

      this.contentResponsibility = initialData.contentResponsibility;
  } else {
      this.involvedPeople = [];
      this.internalSpeakers = [];
      this.promotingNetworks = [];
      this.privateNetworks = [];
    }
  }

  createStringArray(list: string[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(new FormControl(s));
    });

    return results;
  }
}
export class Webinar extends WebinarBase {
  videoId: string;
  hashtag: HashtagInfo;
  videoUrl: string;
  videoName: string;
  impactingKnowledge: string;
  remoteParticipants: UserInfo[];
  chapters: Chapter[];
  localParticipants: number;
  bookingId: string;
  otherParticipants: string[];
  status: WebinarStatus | string;
  speechesNumber: number;
  showTranscript: boolean;

  constructor(initialData?: Webinar | any) {
    super(initialData);
    if (initialData) {
      this.videoId = initialData.videoId;
      this.hashtag = new HashtagInfo(initialData.hashtag);
      this.videoUrl = initialData.videoUrl;
      this.videoName = initialData.videoName;
      this.remoteParticipants = initialData.remoteParticipants
        ? initialData.remoteParticipants.map(r => new UserInfo(r))
        : [];
      this.chapters = initialData.chapters
        ? initialData.chapters.map(r => new Chapter(r))
        : [];
      this.localParticipants = initialData.localParticipants;
      this.bookingId = initialData.bookingId;
      this.otherParticipants = initialData.otherParticipants || [];
      this.status = initialData.status;
      this.speechesNumber = initialData.speechesNumber;
    } else {
      this.otherParticipants = [];
      this.speechesNumber = 1;
    }
    this.webinarType = WebinarType.Webinar;
  }

  // toFormGroup(formBuilder: FormBuilder): FormGroup {
  //   const form = formBuilder.group({
  //     id: [this.id],
  //     type: [this.type],
  //     logoUrl: [this.logoUrl],
  //     name: [this.name],
  //     hashtag: this.hashtag
  //       ? this.hashtag.toFormGroup(formBuilder)
  //       : new HashtagInfo().toFormGroup(formBuilder),
  //     // hashtag: this.hashtag
  //     //     ? this.hashtag.toFormGroup(formBuilder)
  //     //     : new HashtagInfo().toFormGroup(formBuilder),
  //     date: [this.date],
  //     durationMinutes: [this.durationMinutes],
  //     promotingNetworks: NetworkInfo.createNetworkInfoArray(
  //       this.promotingNetworks || [],
  //       formBuilder
  //     ),
  //     privateNetworks: NetworkInfo.createNetworkInfoArray(
  //       this.privateNetworks || [],
  //       formBuilder
  //     ),
  //     location: [this.location],
  //     relatedTags: HashtagInfo.createHashtagInfoArray(
  //       this.relatedTags || [],
  //       formBuilder
  //     ),
  //     involvedPeople: UserInfo.createUserInfoArray(
  //       this.involvedPeople || [],
  //       formBuilder
  //     ),
  //     description: [this.description],
  //     visibility: [this.visibility],
  //     allowSharing: [this.allowSharing],
  //     internalSpeakers: UserInfo.createUserInfoArray(
  //       this.internalSpeakers || [],
  //       formBuilder
  //     ),
  //     externalSpeakers: this.createStringArray(
  //       this.externalSpeakers || [],
  //       formBuilder
  //     ),
  //     remoteSpeaker: [this.remoteSpeaker],
  //     invitedCoPs: NetworkInfo.createNetworkInfoArray(
  //       this.invitedCoPs || [],
  //       formBuilder
  //     ),
  //     otherRecipients: [this.otherRecipients],
  //     videoName: [this.videoName],
  //     created: [this.created],
  //     createdBy: this.createdBy
  //       ? this.createdBy.toFormGroup(formBuilder)
  //       : new UserInfo().toFormGroup(formBuilder),
  //     modified: [this.modified],
  //     modifiedBy: this.modifiedBy
  //       ? this.modifiedBy.toFormGroup(formBuilder)
  //       : new UserInfo().toFormGroup(formBuilder),
  //     isDeleted: [this.isDeleted],
  //     deleted: [this.deleted],
  //     deletedBy: this.deletedBy
  //       ? this.deletedBy.toFormGroup(formBuilder)
  //       : new UserInfo().toFormGroup(formBuilder),
  //     businessImpact: this.businessImpact
  //       ? new FormControl(this.businessImpact)
  //       : null,
  //     videoId: [this.videoId],
  //     remoteParticipants: UserInfo.createUserInfoArray(
  //       this.remoteParticipants || [],
  //       formBuilder
  //     ),
  //     author: [this.author],
  //     chapters: Chapter.createChapterArray(this.chapters || [], formBuilder),
  //     localParticipants: [this.localParticipants],
  //     speechesNumber: [
  //       this.speechesNumber,
  //       [Validators.required, Validators.min(1)]
  //     ],
  //     bookingId: [this.bookingId],
  //     otherParticipants: this.createStringArray(
  //       this.otherParticipants || [],
  //       formBuilder
  //     ),
  //     languageCodes: [this.languageCodes],
  //     eventLink: [this.eventLink],
  //     status: [this.status],
  //     originalEvaluationChoices: this.createStringArray(
  //       this.originalEvaluationChoices || [],
  //       formBuilder
  //     ),
  //     crucialGap: this.crucialGap ? new FormControl(this.crucialGap) : null,
  //     deploymentCost: this.deploymentCost
  //       ? new FormControl(this.deploymentCost)
  //       : null,
  //     deploymentTime: this.deploymentTime
  //       ? new FormControl(this.deploymentTime)
  //       : null,
  //     contentDeliverer: this.contentDeliverer
  //       ? new FormControl(this.contentDeliverer)
  //       : null,
  //     availabilityTime: this.availabilityTime
  //       ? new FormControl(this.availabilityTime)
  //       : null,
  //     expectedRevenue: this.expectedRevenue
  //       ? new FormControl(this.expectedRevenue)
  //       : null,
  //     additionalInformation: [this.additionalInformation],
  //     otherInvolvedPeople: [this.otherInvolvedPeople],
  //     speakersBUs: NetworkInfo.createNetworkInfoArray(
  //       this.speakersBUs || [],
  //       formBuilder
  //     ),
  //     isImported: [this.isImported],
  //     attachments: this.attachments
  //       ? new FormArray(this.attachments.map(a => new FormControl(a)))
  //       : [],
  //     contentResponsibility : [this.contentResponsibility]
  //   });

  //   form.get("name").setValidators(Validators.required);
  //   form.get("hashtag").setValidators(Validators.required);
  //   form
  //     .get("date")
  //     .setValidators([Validators.required, dateValidator(dayjs())]);
  //   form.get("visibility").setValidators(Validators.required);
  //   form.get("promotingNetworks").setValidators(bookingCoPsValidator);
  //   form.get("durationMinutes").setValidators(Validators.required);
  //   form.get("involvedPeople").setValidators(Validators.required);
  //   form.get("originalEvaluationChoices").setValidators(nullValidator);
  //   form.get("otherRecipients").setValidators(emailValidator);

  //   form.get("contentResponsibility").setValidators(Validators.requiredTrue);

  //   return form;
  // }

  get getChapters() {
    if (this.chapters) {
      return this.chapters.filter(c => !!c.indexed);
    } else {
      return null;
    }
  }
}

// export class Booking extends WebinarBase {
//   candidateHashtag: string;
//   status: BookingStatus | string;
//   statusDescription: string;

//   constructor(initialData?: any) {
//     super(initialData);
//     if (initialData) {
//       this.candidateHashtag = initialData.candidateHashtag;
//       this.status = initialData.status;
//       this.statusDescription = initialData.statusDescription;
//     } else {
//       this.status = BookingStatus.Pending;
//     }
//     this.webinarType = WebinarType.Booking;
// //   }

//   toFormGroup(formBuilder: FormBuilder): FormGroup {
//     return formBuilder.group({
//       id: [this.id],
//       type: [this.type],
//       logoUrl: [this.logoUrl],
//       name: [this.name],
//       candidateHashtag: [this.candidateHashtag],
//       date: [this.date],
//       durationMinutes: [this.durationMinutes],
//       promotingNetworks: NetworkInfo.createNetworkInfoArray(
//         this.promotingNetworks || [],
//         formBuilder
//       ),
//       privateNetworks: NetworkInfo.createNetworkInfoArray(
//         this.privateNetworks || [],
//         formBuilder
//       ),
//       location: [this.location],
//       involvedPeople: UserInfo.createUserInfoArray(
//         this.involvedPeople || [],
//         formBuilder
//       ),
//       description: [this.description],
//       visibility: [this.visibility],
//       allowSharing: [this.allowSharing],
//       internalSpeakers: UserInfo.createUserInfoArray(
//         this.internalSpeakers || [],
//         formBuilder
//       ),
//       externalSpeakers: this.createStringArray(
//         this.externalSpeakers || [],
//         formBuilder
//       ),
//       remoteSpeaker: [this.remoteSpeaker],
//       invitedCoPs: NetworkInfo.createNetworkInfoArray(
//         this.invitedCoPs || [],
//         formBuilder
//       ),
//       relatedTags: HashtagInfo.createHashtagInfoArray(
//         this.relatedTags || [],
//         formBuilder
//       ),
//       otherRecipients: [this.otherRecipients],
//       created: [this.created],
//       createdBy: this.createdBy
//         ? this.createdBy.toFormGroup(formBuilder)
//         : new UserInfo().toFormGroup(formBuilder),
//       modified: [this.modified],
//       modifiedBy: this.modifiedBy
//         ? this.modifiedBy.toFormGroup(formBuilder)
//         : new UserInfo().toFormGroup(formBuilder),
//       isDeleted: [this.isDeleted],
//       deleted: [this.deleted],
//       deletedBy: this.deletedBy
//         ? this.deletedBy.toFormGroup(formBuilder)
//         : new UserInfo().toFormGroup(formBuilder),
//       businessImpact: this.businessImpact
//         ? new FormControl(this.businessImpact)
//         : null,
//       status: [this.status],
//       author: [this.author],
//       languageCodes: [this.languageCodes],
//       eventLink: [this.eventLink],
//       originalEvaluationChoices: this.createStringArray(
//         this.originalEvaluationChoices || [],
//         formBuilder
//       ),
//       bookingRequestDate: [this.bookingRequestDate],
//       crucialGap: this.crucialGap ? new FormControl(this.crucialGap) : null,
//       deploymentCost: this.deploymentCost
//         ? new FormControl(this.deploymentCost)
//         : null,
//       deploymentTime: this.deploymentTime
//         ? new FormControl(this.deploymentTime)
//         : null,
//       contentDeliverer: this.contentDeliverer
//         ? new FormControl(this.contentDeliverer)
//         : null,
//       availabilityTime: this.availabilityTime
//         ? new FormControl(this.availabilityTime)
//         : null,
//       expectedRevenue: this.expectedRevenue
//         ? new FormControl(this.expectedRevenue)
//         : null,
//       additionalInformation: [this.additionalInformation],
//       otherInvolvedPeople: [this.otherInvolvedPeople],
//       speakersBUs: NetworkInfo.createNetworkInfoArray(
//         this.speakersBUs || [],
//         formBuilder
//       ),
//       contentResponsibility : [this.contentResponsibility]
//     });
//   }
// }

export class WebinarEvent {
  title: string;
  start: string;
  backgroundColor: string;
  videoId: string;
  videoUrl: string;
  webinarId: string;
  textColor: string;
  type: WebinarType;
  borderColor: string;
  tooltipHours: string;

  constructor(initialData: Webinar) {
    if (initialData) {
      this.title = initialData.name;

      this.start = dayjs(
        Utilities.adjustDateLocalToMilan(new Date(initialData.date))
      ).format("YYYY-MM-DDTHH:mm");
      this.tooltipHours = dayjs(
        Utilities.adjustDateLocalToMilan(new Date(initialData.date))
      ).format("h:mmA");
      this.backgroundColor = WebinarColor.green;
      this.textColor = "white";
      this.borderColor = this.backgroundColor;
      this.videoId = initialData.videoId;
      this.videoUrl = initialData.videoUrl;
      this.webinarId = initialData.id;
    }
    this.type = WebinarType.Webinar;
  }
}

export class BookingEvent {
  title: string;
  start: string;
  backgroundColor: string;
  bookingId: string;
  textColor: string;
  type: WebinarType;
  borderColor: string;
  visibility: WebinarVisibilityType;
  tooltipHours: string;

  // constructor(initialData: Booking) {
  //   if (initialData) {
  //     this.start = dayjs(
  //       Utilities.adjustDateLocalToMilan(new Date(initialData.date))
  //     ).format("YYYY-MM-DDTHH:mm");
  //     this.tooltipHours = dayjs(
  //       Utilities.adjustDateLocalToMilan(new Date(initialData.date))
  //     ).format("h:mmA");
  //     this.title = initialData.name;
  //     this.backgroundColor = this.getEventBackgroundColor(
  //       initialData.status,
  //       initialData.visibility
  //     );
  //     this.borderColor = this.backgroundColor;
  //     this.textColor = this.getEventColor(initialData.status);
  //     this.bookingId = initialData.id;
  //     this.visibility = initialData.visibility;
  //   }
  //   this.type = WebinarType.Booking;
  // }

  private getEventBackgroundColor(status: any, visibility: any) {
    switch (status.toLowerCase()) {
      case "pending":
        return WebinarColor.ligthRed;
      // case "preapproved":
      //   return visibility === "Private"
      //     ? WebinarColor.lavender
      //     : WebinarColor.ligthBlue;
      case "confirmed":
        return WebinarColor.green;
      case "locked":
        return WebinarColor.ligthRed;
      case "cancelled":
        return WebinarColor.ligthRed;
      default:
        return WebinarColor.ligthRed;
    }
  }

  private getEventColor(status: any) {
    switch (status.toLowerCase()) {
      case "pending":
        return "white";
      case "locked":
        return WebinarColor.textColor;
      default:
        return "white";
    }
  }
}

export enum WebinarColor {
  grey = "#CCCCCC",
  ligthBlue = "#0070B8",
  lavender = "#869ebc",
  green = "#019680",
  ligthRed = "#fd5959",
  red = "#D0003E",
  blue = "#0070B8",
  textColor = "#757575"
}

export class WebinarAgendaItem {
  date: Date;
  events: WebinarEvent[];

  constructor(date: Date, events: WebinarEvent) {
    this.date = date;
    this.events = [events];
  }
}

export class WebinarNameListItem {
  name: string;
  zipFile: string;

  private regex = /([^\/]+)$/gi;

  constructor(initialData: any) {
    if (initialData) {
      this.name = this.regex.exec(initialData)[1];
      this.zipFile = initialData;
    }
  }
}

export class Chapter {
  videoId: string;
  name: string;
  zipFile: string;
  showTranscript: boolean;
  confidenceScore: number;
  chapterId: string;
  indexed: boolean;
  thumbnailId: string;
  hiddenFaces: number[];
  keywords: InsightsKeywords[];
  topics: InsightsTopic[];
  speakers: Speaker[];

  constructor(initialData?) {
    if (initialData) {
      this.videoId = initialData.videoId;
      this.name = initialData.name;
      this.showTranscript = initialData.showTranscript;
      this.keywords = initialData.keywords;
      this.topics = initialData.topics;
      this.confidenceScore = initialData.confidenceScore;
      this.zipFile = initialData.zipFile;
      this.chapterId = initialData.chapterId
        ? initialData.chapterId
        : this.generateGuid();
      this.indexed = initialData.indexed;
      this.thumbnailId = initialData.thumbnailId;
      this.hiddenFaces = initialData.hiddenFaces || [];
      this.speakers = initialData.speakers || [];
    } else {
      this.indexed = false;
      this.hiddenFaces = [];
    }
  }

  static createChapterArray(list: Chapter[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }

  private generateGuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  }

  toFormGroup(formBuilder: FormBuilder) {
    return formBuilder.group({
      videoId: [this.videoId],
      name: [this.name],
      showTranscript: [this.showTranscript],
      confidenceScore: [this.confidenceScore],
      zipFile: [this.zipFile],
      chapterId: [this.chapterId],
      indexed: [this.indexed],
      hiddenFaces: [this.hiddenFaces]
    });
  }
}

export class DurationInterval {
  value: number;
  text: string;
}

export class WebinarBadge {
  followers: UserInfo[];
  replyCount: number;
  rootPostCount: number;

  constructor(initialData) {
    if (initialData) {
      this.followers = initialData.followers;
      this.replyCount = initialData.replyCount;
      this.rootPostCount = initialData.rootPostCount;
    }
  }
}

export enum BookingValidationProblem {
    MoreThan4EventsDay = 1,
    MoreThan2HoursDuration = 2,
    MoreThan2ContemporaryEvents = 3,
    RequiredParamsMissing = 4,
    LessThan9DaysAdvance = 5,
    HashtagAlreadyExistsOrBooked = 6,
    InvalidTimeSlot = 7
}

export enum BookingStatus {
  Pending = 1,
  Booked = 2,
  Confirmed = 3,
  Locked = 4,
  Canceled = 5
}

export enum WebinarStatus {
  Requested = 1,
  Published = 2
}

export enum WebinarVisibilityType {
  Public = 1,
  Private = 2
}

export enum WebinarType {
  Webinar = 1,
  Booking = 2
}

export enum WebinarLanguage {
  EN = "EN",
  IT = "IT"
}

export enum WebinarsMailType {
  BOOKING = "booking",
  WEBINAR = "webinar"
}
