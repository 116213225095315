<tabset>
  <tab heading="Welcome Experience" id="tab1">
    <kms-admin-card>
      <kms-admin-welcome-experience></kms-admin-welcome-experience>
    </kms-admin-card>
  </tab>
  <tab heading="Permissions">
    <kms-admin-card>
      <kms-admin-badge></kms-admin-badge>
    </kms-admin-card>
  </tab>
  <tab heading="Whitelist">
    <kms-admin-card>
      <kms-admin-whitelist></kms-admin-whitelist>
    </kms-admin-card>
  </tab>
  <tab heading="Private Network Users (Guests)">
    <kms-admin-card>
      <kms-admin-guest-whitelist></kms-admin-guest-whitelist>
    </kms-admin-card>
  </tab>
  <tab heading="IP Keywords">
    <kms-admin-card>
      <kms-admin-keywords></kms-admin-keywords>
    </kms-admin-card>
  </tab>
  <tab heading="Webinar Transcripts">
    <kms-admin-card>
      <kms-admin-ipmanagement></kms-admin-ipmanagement>
    </kms-admin-card>
  </tab>
</tabset>
