<br/>
<div class="row" [formGroup]="form">
  <div
  form-field
  class="input-alignment col-sm-9"
  [control]="form.get('query')"
  [group]="form"
>

  <input
    type="text"
    class="form-control no-box"
    formControlName="query"
  />
  <label
    class="floating-label"
    [ngClass]="{ 'go-top': form?.get('query')?.value }"
    >Search</label>
 
  </div> 

<div class="col-sm-3">
  <div class="text-center">
  <button
  type="button"
  class="btn btn-primary btn-raised mr-3"
  (click)="search()"
>
  Search
</button>

    <button
      type="button"
      class="btn btn-primary btn-raised mr-3"
      (click)="search()"
    >
      Reload
    </button>

    <button
      type="button"
      class="btn btn-primary btn-raised mr-3"
      (click)="onCreate()"
    >
      Add
    </button>
  </div>
  </div>
</div>
<br/>
<ngx-datatable
  class="bootstrap"
  [rows]="keywords | async"
  [columnMode]="'force'"
  [rowHeight]="'auto'"
  [footerHeight]="'auto'"
  [limit]="limit"
  [messages]="{
    emptyMessage: '',
    totalMessage: 'total',
    selectedMessage: 'selected'
  }"
  [sorts]="[{ prop: 'keyword', dir: 'asc' }]"
>
  <ngx-datatable-column
    [width]="30"
    [sortable]="false"
    [canAutoResize]="false"
    [draggable]="false"
    [resizeable]="false"
  >
    <ng-template let-keyword="row" ngx-datatable-cell-template>
      <a
        [tooltip]="'Edit'"
        containerClass="tooltip-class"
        container="body"
        class="clickable-icon"
        (click)="onEdit(keyword)"
      >
        <i class="fa fa-pencil fa-lg pencil"></i>
      </a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [width]="30"
    [sortable]="false"
    [canAutoResize]="false"
    [draggable]="false"
    [resizeable]="false"
  >
    <ng-template let-keyword="row" ngx-datatable-cell-template>
      <a
        [tooltip]="'Delete'"
        containerClass="tooltip-class"
        container="body"
        class="clickable-icon"
        (click)="openAlertModal(alertDelete, keyword)"
      >
        <i class="fa fa-trash-o fa-lg"></i>
      </a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Keyword *" prop="keyword">
    <ng-template let-keyword="value" ngx-datatable-cell-template>
      <div>{{ keyword }}</div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Recipients  list *" prop="userReferences">
    <ng-template let-userReferences="value" ngx-datatable-cell-template>
      <div *ngFor="let u of userReferences">{{ u.displayName }} </div>
     </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-footer *ngIf="loadingIndicator"></ngx-datatable-footer>
</ngx-datatable>

<div *ngIf="loadingIndicator">
  <kms-admin-spinner [loading]="true"></kms-admin-spinner>
</div>

<ng-template #alertDelete>
  <div class="modal-header">
    <h4 class="modal-title pull-left mt-0">Confirm keyword delete</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to remove the keyword?</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary btn-raised"
      (click)="onDelete()"
    >
      Ok
    </button>
    <button
      type="button"
      class="btn btn-warning btn-raised"
      (click)="modalRef?.hide()"
    >
      Cancel
    </button>
  </div>
</ng-template>
