import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthenticationGuard } from "microsoft-adal-angular6";

import { RedirectGuard } from "src/app/commons/guards";
import { ChallengeListPageComponent } from "./challenge-list-page/challenge-list-page.component";

const routes: Routes = [
  {
    path: "",
    component: ChallengeListPageComponent,
    canActivate: [RedirectGuard, AuthenticationGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChallengeRoutingModule {}
