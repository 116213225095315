import { NgModule } from "@angular/core";
import { NetworkPickerComponent } from "./network-picker/network-picker.component";
import { CommonModule } from "@angular/common";
import { CoreModule } from "../core/core.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";

const DECLARATIONS = [NetworkPickerComponent];
const IMPORTS = [
    CommonModule,
    CoreModule,
    NgSelectModule,
    FormsModule ];

@NgModule ({
    declarations: [...DECLARATIONS],
    imports: [...IMPORTS],
    exports: [...DECLARATIONS, ...IMPORTS]
})

export class NetworkPickerModule {}
