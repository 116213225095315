import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewEncapsulation
} from "@angular/core";
import { QueryBuilderConfig } from "angular2-query-builder";
import { Observable } from "rxjs";
import {
  SearchService,
  KMSSearchResults
} from "src/app/services/search.service";
import { map, tap } from "rxjs/operators";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BusinessUnitEditComponent } from "./business-unit-edit/business-unit-edit.component";
import { ConfigurationService } from "src/app/services/configuration.service";
import { BusinessUnits } from "src/app/commons/entities";
import { NotificationService } from "src/app/services/notification.service";
import { Constants } from "src/app/commons/util";

@Component({
  selector: "kms-admin-business-unit-list",
  templateUrl: "./business-unit-list.component.html",
  styleUrls: ["./business-unit-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class BusinessUnitListComponent implements OnInit {
  queryConfig: QueryBuilderConfig = {
    fields: {
      city: {
        name: "City",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      company: {
        name: "Company",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      name: {
        name: "Business Unit",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      grouping: {
        name: "Grouping",
        type: "string",
        operators: ["contains", "=", "!="]
      },
      customBU: {
        name: "Custom BU",
        type: "string",
        operators: ["contains", "=", "!="]
      }
    }
  };
  limit = Constants.TABLE_LIMITS;
  modalRef: BsModalRef;
  bu: Observable<BusinessUnits[]>;
  businessUnitList: BusinessUnits[];
  editedBusinessUnitList: { item1: string; item2: string }[] = [];
  loadingIndicator = false;
  isEditing = false;
  selected: BusinessUnits[] = [];
  private isModified = false;
  private searchQuery = "";

  constructor(
    private readonly modalSvc: BsModalService,
    private searchSvc: SearchService,
    private cd: ChangeDetectorRef,
    private readonly configSvc: ConfigurationService,
    private readonly notificationSvc: NotificationService
  ) {}

  ngOnInit() {
    this.search(null);
  }

  async search(query: string) {
    this.loadingIndicator = true;
    this.searchQuery = query;
    this.selected = [];
    this.editedBusinessUnitList = [];
    const filter = {
      filter: query,
      top: 1000
    };

    this.bu = this.searchSvc.queryRaw("BusinessUnits", "*", filter).pipe(
      map((networks: KMSSearchResults<any>) =>
        networks.results.map(r => r.document)
      ),
      tap(() => (this.loadingIndicator = false)),
      tap(list => (this.businessUnitList = list))
    );
    // this.bu.subscribe(list => {
    //   this.businessUnitList = list;
    // });
  }

  async searchOnlyEmptyName() {
    this.loadingIndicator = true;
    this.searchQuery =
      this.searchQuery && this.searchQuery !== ""
        ? `${this.searchQuery} and ( name eq '' or name eq null)`
        : " (name eq '' or name eq null)";
    this.selected = [];
    const filter = {
      filter: this.searchQuery,
      top: 1000
    };

    this.bu = this.searchSvc.queryRaw("BusinessUnits", "*", filter).pipe(
      map((networks: KMSSearchResults<any>) =>
        networks.results.map(r => r.document)
      ),
      tap(() => (this.loadingIndicator = false))
    );
    this.bu.subscribe(list => {
      this.businessUnitList = list;
    });
  }

  editClick() {
    this.isEditing = true;
  }
  cancelClick() {
    this.isEditing = false;
    this.search(this.searchQuery);
  }

  async saveClick(businessUnit: BusinessUnits) {
    let action: Promise<any>;
    this.isEditing = false;
    const tuple = {
      item1: businessUnit.name,
      item2: businessUnit.grouping,
      item3: businessUnit.customBU
    };
    try {
      action = this.configSvc
        .setBusinessUnitName(businessUnit.id, tuple)
        .toPromise();
      const { transactionId } = await action;

      await this.notificationSvc
        .waitForTransactionResult(transactionId)
        .toPromise();
      this.search(this.searchQuery);
    } catch (e) {
      this.notificationSvc.showDetailsError(e);
    }
  }

  async saveAllClick() {
    let action: Promise<any>;
    this.isEditing = false;
    this.isModified = false;
    if (this.editedBusinessUnitList.length > 0) {
      action = this.configSvc
        .setListBusinessUnitName(this.editedBusinessUnitList)
        .toPromise();
      // const { transactionId } = await action;

      // await this.notificationSvc
      //   .waitForTransactionResult(transactionId)
      //   .toPromise();
      // this.search(this.searchQuery);
      setTimeout(() => {
        this.search(this.searchQuery);
      }, 2000);
    }
    this.editedBusinessUnitList.slice(0, this.editedBusinessUnitList.length);
  }



  onSelect({selected}) {

    this.selected.splice(0, this.selected.length);
     this.selected.push(
       ...selected.filter((bu: BusinessUnits) => bu && !bu.name && !bu.grouping && !bu.customBU)
     );

  }


  editSelectedClick() {
    const initialState = {
      bus: this.selected,
      title: "Edit Selected Business Units",
      confirmBtnName: "Confirm",
      closeBtnName: "Cancel"
    };
    this.modalRef = this.modalSvc.show(BusinessUnitEditComponent, {
      initialState,
      class: "modal-transparent modal-md mt-6"
    });
    this.modalRef.content.onClose.subscribe((result: any) => {
      if (result && result.item1 !== "") {
        this.selected.forEach((element: BusinessUnits) => {
          const selectedBU: BusinessUnits = this.businessUnitList.find(
            bu => bu.id === element.id
          );
          if (selectedBU) {
            if (selectedBU.name !== result.item1) {
              this.editedBusinessUnitList.push({
                item1: selectedBU.id,
                item2: result
              });
              element.name = result.item1;
              element.grouping = result.item2;
              element.customBU = result.item3;
            }
          }
          this.isModified = true;
        });
      }
      this.cd.markForCheck();
    });
  }

  isDisable(bu: any) {
    return bu.name == null || bu.name === "" ? true : false;
  }
}
