import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthenticationGuard } from "microsoft-adal-angular6";

import { RedirectGuard } from "src/app/commons/guards";
import { StrategicTagsPageComponent } from "./strategic-tags-page/strategic-tags-page.component";

const routes: Routes = [
  {
    path: "",
    component: StrategicTagsPageComponent,
    canActivate: [RedirectGuard, AuthenticationGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StrategicTagsRoutingModule {}
