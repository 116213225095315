import { Injectable } from "@angular/core";
import { AppInsightsService } from "@markpieszak/ng-application-insights";
import { environment } from "src/environments/environment";
import { ConfigService } from "@ngx-config/core";
import { SnackbarService } from "ngx-snackbar";
import { Guid } from "../commons/util";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root"
})
export class LoggerService {
  constructor(
    private readonly svc: AppInsightsService,
    private readonly userSvc: UserService,
    private readonly config: ConfigService,
    private readonly snackbarSvc: SnackbarService
  ) {
    // https://www.npmjs.com/package/@markpieszak/ng-application-insights
    svc.config = {
      instrumentationKey: this.config.getSettings().instrumentationKey
    };
    // then make sure to initialize and start-up app insights
    svc.init();
  }

  warning(message: string) {
    this.svc.trackTrace(message, this.context, 2);
    if (!environment.production) {
      console.warn(message);
    }
  }

  information(message: string) {
    this.svc.trackTrace(message, this.context, 1);
    if (!environment.production) {
      // tslint:disable no-console
      console.info(message);
    }
  }

  verbose(message: string) {
    this.svc.trackTrace(message, this.context, 0);
    if (!environment.production) {
      console.log(message);
    }
  }

  error(err: Error, message: string = null) {
    this.svc.trackTrace(err.message, this.context, 3);
    this.snackbarSvc.add({
      msg: this.config.getSettings().showErrorDetails
        ? (message!=null) ? message: err.message
        : this.config.getSettings().defaultErrorMessage,
      background: "red",
      action: { text: null }
    });
    if (!environment.production) {
      console.error(message != null ? message : err.message);
    }
  }

  event(name: string, content?: any, sourceId?: string) {
    const currentUserId = this.userSvc.userId;
    this.svc.trackEvent(name, {
      ...content,
      kmsEventId: Guid.newGuid(),
      sourceUserId: sourceId || currentUserId
    });
  }

  eventMultiple(name: string, contents: any[]) {
    const kmsEventId = Guid.newGuid();
    const currentUserId = this.userSvc.userId;
    if (contents.length > 0) {
      contents.forEach(content =>
        this.svc.trackEvent(name, {
          ...content,
          kmsEventId,
          sourceUserId: currentUserId
        })
      );
    }
  }

  private get context() {
    return {};
  }
}
