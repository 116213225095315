import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { TooltipModule } from "ngx-bootstrap/tooltip";

import { QueryBuilderComponentModule } from "src/app/components/query-builder/query-builder.module";
import { CardModule } from "src/app/components/card/card.module";
import { TabsModule } from "ngx-bootstrap/tabs";
import { KMSFormsModule } from "src/app/components/forms/forms.module";
import { ChallengeRoutingModule } from "./challenge-routing.module";
import { ChallengeListPageComponent } from "./challenge-list-page/challenge-list-page.component";
import { ChallengeEditPageComponent } from "./challenge-edit-page/challenge-edit-page.component";
import { AdminDatePickerModule } from "src/app/components/date-picker/date-picker.module";
import { PeoplePickerModule } from "src/app/components/people-picker/people-picker.module";
import { AvatarViewModule } from "src/app/components/avatar-view/avatar-view.module";
import { ImageCropperModule } from "src/app/components/image-cropper/image-cropper.module";
import { CoreModule } from "src/app/components/core/core.module";
import { RichTextEditorModule } from "src/app/components/rich-text-editor/rich-text-editor.module";
import { ClickWaitModule } from "src/app/components/click-wait/click-wait.module";

const IMPORTS = [
  CommonModule,
  ChallengeRoutingModule,
  NgxDatatableModule,
  QueryBuilderComponentModule,
  CardModule,
  TabsModule,
  FormsModule,
  NgSelectModule,
  KMSFormsModule,
  AdminDatePickerModule,
  PeoplePickerModule,
  AvatarViewModule,
  TooltipModule,
  ImageCropperModule,
  CoreModule,
  RichTextEditorModule,
  ClickWaitModule
];
const DECLARATIONS = [ChallengeListPageComponent, ChallengeEditPageComponent];

@NgModule({
  imports: [...IMPORTS],
  declarations: [...DECLARATIONS],
  entryComponents: [ChallengeEditPageComponent],
  exports: [...IMPORTS, ...DECLARATIONS]
})
export class ChallengeModule {}
