import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import * as signalR from "@aspnet/signalr";
import { map, filter, first, tap } from "rxjs/operators";
import { ConfigService } from "@ngx-config/core";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";
import { SnackbarService } from "ngx-snackbar";
import { OperationResultStatus } from "../commons/entities";

export interface INotification {
  OperationId: string;
  SenderId: boolean;
  Result: OperationResultStatus;
  Command: string;
  Details: string;
}
@Injectable({
  providedIn: "root"
})
export class NotificationService {
  private notificationsSubject$: BehaviorSubject<INotification[]>;
  private connection: signalR.HubConnection;
  private hubCommands = ["operationresult"];

  constructor(
    private readonly config: ConfigService,
    private readonly authSvc: MsAdalAngular6Service,
    private readonly snackbarSvc: SnackbarService
  ) {
    this.notificationsSubject$ = new BehaviorSubject<INotification[]>([]);

    this.lastNotification$
      .pipe(
        filter(op => !!op),
        filter(op => op.Command !== "notify")
      )
      .subscribe(op => {
        if (op.Result === OperationResultStatus.Success) {
          this.showSuccess(
            `${op.Command}: ${OperationResultStatus[op.Result]}`
          );
        } else {
          if (
            this.config.getSettings().showErrorDetails &&
            op.Result === OperationResultStatus.Failure
          ) {
            this.showError(
              `${op.Command}: ${OperationResultStatus[op.Result]}`
            );
          }
        }
      });
  }

  get notifications$() {
    return this.notificationsSubject$.asObservable();
  }
  get lastNotification$() {
    return this.notificationsSubject$.pipe(map(list => list.slice(-1).pop()));
  }

  public async connect() {
    const { notificationHubUrl, apiUrlPrefix } = this.config.getSettings();
    // See https://docs.microsoft.com/en-us/aspnet/core/tutorials/signalr-typescript-webpack?view=aspnetcore-2.1&tabs=visual-studio
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${notificationHubUrl}`, {
        accessTokenFactory: () => this.authSvc.accessToken
      })
      .build();

    // Listen to all configured hub commands
    this.hubCommands.forEach(command =>
      this.connection.on(command, message => {
        const list = [...this.notificationsSubject$.value, message];
        this.notificationsSubject$.next(list);
      })
    );

    try {
      await this.connection.start();
    } catch (error) {
      console.error(error.message);
    }
  }

  public waitForTransactionResult(transactionId: string) {
    return this.notificationsSubject$.pipe(
      first(list => list.some(x => x.OperationId === transactionId)),
      map(list => list.find(x => x.OperationId === transactionId)),
      tap(notification => {
        if (notification) {
          if (notification.Result === OperationResultStatus.Success) {
            // console.log("Completed transaction: " + transactionId);
          } else {
            throw new Error(
              notification.Details
                ? notification.Details
                : "Operation Failed, Please Retry"
            );
          }
        }
      }),
      filter(
        notification =>
          notification && notification.Result === OperationResultStatus.Success
      ),
      map(() => true)
    );
  }

  public showDetailsError(msg: string) {
    alert(msg);
  }

  private showSuccess(msg: string) {
    if (this.config.getSettings().showSuccessMessages) {
      this.snackbarSvc.add({
        msg,
        background: "#33691E",
        action: { text: null }
      });
    }
  }

  private showError(msg: string) {
    if (this.config.getSettings().showSuccessMessages) {
      this.snackbarSvc.add({
        msg,
        background: "red",
        action: { text: null }
      });
    }
  }


  // public errorTest() {
  //   this.notificationsSubject$.next([
  //     ...this.notificationsSubject$.value,
  //     { message: "This is an Error Test", read: true, level: "error" }
  //   ]);
  // }
}
