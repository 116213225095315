import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, BehaviorSubject } from "rxjs";

import { SecurityProfile, ExternalUser, IPMKeywordInfo, GuestUser, GuestUsersCreatePayload } from "../commons/entities";

@Injectable({
  providedIn: "root"
})
export class SecurityService {
  public rolesUpdateSubject = new BehaviorSubject<void>(null);

  constructor(
    private httpClient: HttpClient
  ) {}

  public getUserBadges(): Observable<any> {
    return this.httpClient.get<any>(`admin/api/Security/Admins`);
  }

  public updateBadges(userId: string, roles: Array<number>): Observable<any> {
    return this.httpClient.post<Array<number>>(
      `admin/api/Security/Roles/${userId}`,
      roles
    );
  }

  // ipmkeywords (GET list of keywords)
  public getAllIpmKeywords( query: string): Observable<IPMKeywordInfo[]> {
    return this.httpClient.get<IPMKeywordInfo[]>(`admin/api/Security/ipmkeywords/query?q=${query}`);
  }

  // ipmkeywords/{id} (GET a specific keyword)
  public getIpmKeyword(id: string): Observable<IPMKeywordInfo> {
    return this.httpClient.get<IPMKeywordInfo>(`admin/api/Security/ipmkeywords/${id}`);
  }

	// ipmkeywords/create (POST with payload for IPMKeyword serialized )
  public createIpmKeyword(keyword: IPMKeywordInfo): Observable<any> {
    return this.httpClient.post<IPMKeywordInfo>(
      `admin/api/Security/ipmkeywords/create`,
      keyword
    );
  }

  // ipmkeywords/update (POST with payload for IPMKeyword serialized )
  public updateIpmKeyword(keyword: IPMKeywordInfo): Observable<any> {
    return this.httpClient.post<IPMKeywordInfo>(
      `admin/api/Security/ipmkeywords/update`,
      keyword
    );
  }

  // ipmkeywords/{id} (DELETE a specific keyword)
  public deleteIpmKeyword(id: string): Observable<any> {
    return this.httpClient.delete<any>(
      `admin/api/Security/ipmkeywords/${id}`
    );
  }

  // public getExternalUsers(): Observable<Array<ExternalUser>> {
  //   return this.httpClient.get<Array<ExternalUser>>(`Security/externalusers`);
  // }

  public getExternalUserByUPN(userUPN: string): Observable<ExternalUser> {
    return this.httpClient.get<ExternalUser>(
      `admin/api/Security/externalusers/` + userUPN
    );
  }

  public createExternalUser(user: ExternalUser): Observable<any> {
    return this.httpClient.post<ExternalUser>(
      `admin/api/Security/externalusers/create`,
      user
    );
  }

  public createMultipleExternalUser(users: Array<ExternalUser>): Observable<any> {
    return this.httpClient.post<ExternalUser>(
      `admin/api/Security/multipleexternalusers/create`,
      users
    );
  }

  public updateExternalUser(user: ExternalUser): Observable<any> {
    return this.httpClient.post<ExternalUser>(
      `admin/api/Security/externalusers/update`,
      user
    );
  }
  
  public updateMultipleExternalUser(users: Array<ExternalUser>): Observable<any> {
    return this.httpClient.post<ExternalUser>(
      `admin/api/Security/externalusers/update`,
      users
    );
  }

  public deleteExternalUser(id: string): Observable<any> {
    return this.httpClient.delete<any>(
      `admin/api/Security/externalusers/${id}`
    );
  }

  // public getGuestUsers(): Observable<Array<GuestUser>> {
  //   return this.httpClient.get<Array<GuestUser>>(`Security/guestusers`);
  // }

  public getGuestUserByMail(userMail: string): Observable<GuestUser> {
    return this.httpClient.get<GuestUser>(
      `admin/api/Security/guestusers/` + userMail
    );
  }

  public createGuestUser(user: GuestUser): Observable<any> {
    return this.httpClient.post<any>(
      `admin/api/Security/guestusers/create`,
      user
    );
  }
  
  public createGuestUserByPayload(payload: GuestUsersCreatePayload): Observable<Array<GuestUser>> {
    return this.httpClient.post<any>(
      `admin/api/Security/guestusers/createbypayload`,
      payload
    );
  }

  public updateGuestUser(user: GuestUser): Observable<any> {
    return this.httpClient.post<any>(
      `admin/api/Security/guestusers/update`,
      user
    );
  }

  public deleteGuestUser(id: string): Observable<any> {
    return this.httpClient.delete<any>(
      `admin/api/Security/guestusers/${id}`
    );
  }

  public getUserByEmail(email: string): Observable<SecurityProfile> {
    return this.httpClient.get<SecurityProfile>(
      `admin/api/Security/profile/mail/${email}`
    );
  }
}
