import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { Disclaimer } from "src/app/commons/entities";
import { ConfigurationService } from "src/app/services/configuration.service";
import { LoggerService } from "src/app/services/logger.service";
import { Utilities } from "src/app/commons/util";

@Component({
  selector: "kms-admin-welcome-experience",
  templateUrl: "./welcome-experience.component.html",
  styleUrls: ["./welcome-experience.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class WelcomeExperienceComponent implements OnInit {
  form: FormGroup;

  currentDisclaimer: Disclaimer = null;
  saveEnabled = false;

  constructor(
    private configSvc: ConfigurationService,
    private cd: ChangeDetectorRef,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.configSvc.currentDisclaimer$.subscribe(d => {
      if (d) {
        this.currentDisclaimer = d;
        this.currentDisclaimer.content = Utilities.replaceToken(
          this.currentDisclaimer.content,
          this.configSvc.assetsConfig
        );
        this.currentDisclaimer.bestPractice = Utilities.replaceToken(
          this.currentDisclaimer.bestPractice,
          this.configSvc.assetsConfig
        );
        if (this.form) {
          this.form.get("disclaimer").setValue(this.currentDisclaimer.content);
          this.form
            .get("bestPractice")
            .setValue(this.currentDisclaimer.bestPractice);
          this.form.updateValueAndValidity();
        }
      }
    });



    this.form = new FormGroup({
      disclaimer: new FormControl(
        this.currentDisclaimer ? this.currentDisclaimer.content : null,
        Validators.required
      ),
      bestPractice: new FormControl(
        this.currentDisclaimer ? this.currentDisclaimer.bestPractice : null,
        Validators.required
      )
    });

    this.form.valueChanges.subscribe(() => {
      this.cd.markForCheck();
    });
  }

  updateFile(fileName: string, index: number) {
    if (
      this.currentDisclaimer &&
      this.currentDisclaimer.attachmentUrl &&
      this.currentDisclaimer.attachmentUrl[index]
    ) {
      this.saveEnabled = true;
      this.currentDisclaimer.attachmentUrl[index] = fileName;
    }
  }

  onTextChanged(){
    //alert("ciao");
    if(!this.saveEnabled)
      this.saveEnabled = true;
  }

  onSaveDisclaimer() {
    this.currentDisclaimer.id = null; // Remove Id to perform the creation correctly
    this.currentDisclaimer.content =
      this.form && this.form.value
        ? this.formatDisclaimerContent(this.form.get("disclaimer").value)
        : this.currentDisclaimer.content;
    this.currentDisclaimer.bestPractice =
      this.form && this.form.value
        ? this.formatDisclaimerContent(this.form.get("bestPractice").value)
        : this.currentDisclaimer.bestPractice;
    this.currentDisclaimer.content = this.setImagesPlaceholder(
      this.currentDisclaimer.content
    );
    this.currentDisclaimer.bestPractice = this.setImagesPlaceholder(
      this.currentDisclaimer.bestPractice
    );
    this.configSvc.saveDisclaimer(this.currentDisclaimer).subscribe(
      () => {
        alert("Action acquired successfully.\nPlease note the update will complete in some minutes.\nYou can return on this page afterwards to see the updated information.");
        this.saveEnabled = false;
        this.cd.markForCheck();
      },
      error => {
        //alert("eee "+error.message);
        //this.logger.error(error);
        this.logger.error(error, "Nothing to save. Perhaps you didn't change anything on this page.");
        this.saveEnabled = false;
        this.cd.markForCheck();
      }
    );
  }

  private formatDisclaimerContent(content: string): string {
    // let disclaimer = this.form.value.disclaimer;
    return content.replace(
      /"(http:\/\/|https:\/\/)?www\.[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?"(\starget="_blank")?/g,
      (match: string) => {
        if (
          /(https?:\/\/[^\s]+)/g.test(match) ||
          /(http?:\/\/[^\s]+)/g.test(match)
        ) {
          return `${match}`;
        } else {
          return `"https://${match.slice(1, -1)}" target="_blank"`;
        }
      }
    );
    // return disclaimer;
  }
  setImagesPlaceholder(content: string) {
    const assetKey = this.configSvc.assetsConfig.value["item2"];
    const assetDomain = this.configSvc.assetsConfig.value["item1"];
    content = content.split("&amp;").join("&");
    const temp = content.split(assetKey).join("$_TOKEN");
    const temp2 = temp.split(assetDomain).join("$_BASEURL");
    return temp2;
  }
}
