import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthenticationGuard } from "microsoft-adal-angular6";

import { RedirectGuard } from "./commons/guards";

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    canActivate: [RedirectGuard, AuthenticationGuard]
  },
  {
    path: "configuration",
    loadChildren:
      () => import('./pages/configuration/configuration.module').then(m => m.ConfigurationModule),
    canActivate: [RedirectGuard, AuthenticationGuard]
  },
  {
    path: "hashtags",
    loadChildren:
      () => import('./pages/hashtags/hashtags.module').then(m => m.HashtagsModule),
    canActivate: [RedirectGuard, AuthenticationGuard]
  },
  {
    path: "networks",
    loadChildren: () => import('./pages/network/network.module').then(m => m.NetworkModule),
    canActivate: [RedirectGuard, AuthenticationGuard]
  },
  {
    path: "strategic-tags",
    loadChildren:
      () => import('./pages/strategic-tags/strategic-tags.module').then(m => m.StrategicTagsModule),
    canActivate: [RedirectGuard, AuthenticationGuard]
  },
  {
    path: "challenge",
    loadChildren: () => import('./pages/challenge/challenge.module').then(m => m.ChallengeModule),
    canActivate: [RedirectGuard, AuthenticationGuard]
  },
  {
    path: "deleted-posts",
    loadChildren:
      () => import('./pages/deleted-posts/deleted-posts.module').then(m => m.DeletedPostsModule),
    canActivate: [RedirectGuard, AuthenticationGuard]
  },
  {
    path: "deny-access",
    loadChildren:
      () => import('src/app/pages/deny-access/deny-access.module').then(m => m.DenyAccessModule),
    canActivate: [RedirectGuard, AuthenticationGuard]
  },
  {
    path: "gamification",
    loadChildren:
      () => import('src/app/pages/gamification/gamification.module').then(m => m.GamificationModule),
    canActivate: [RedirectGuard, AuthenticationGuard]
  },
  {
    path: "business-units",
    loadChildren:
      () => import('src/app/pages/business-unit/business-unit.module').then(m => m.BusinessUnitModule),
    canActivate: [RedirectGuard, AuthenticationGuard]
  },
  { path: "**",
    redirectTo: "" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
