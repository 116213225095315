import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ConfigService } from "@ngx-config/core";
import { KMSObject, ImpactingKnowledgeType } from "../commons/entities";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class BusinessUnitService {
  constructor(
    private httpClient: HttpClient,
    private readonly config: ConfigService
  ) {}

  public setPostBusinessUnits(
    postsList: Array<{ item1: string; item2: string }>
  ): Observable<any> {
    return this.httpClient.post(
      `portal/api/Newsfeed/post/businessunits`,
      postsList
    );
  }

  public setIKBusinessUnits(
    ikList: Array<{ item1: string; item2: string; item3: string }>,
    type: ImpactingKnowledgeType = ImpactingKnowledgeType.KnowledgeNugget
  ): Observable<any> {
    let url = "";
    if (type === ImpactingKnowledgeType.KnowledgeNugget) {
      url = `ik/knowledgenugget/businessunits`;
    } else if (type === ImpactingKnowledgeType.Idea) {
      url = `ik/idea/businessunits`;
    } else if (type === ImpactingKnowledgeType.SuccessStory) {
      url = `ik/successstory/businessunits`;
    } else if (type === ImpactingKnowledgeType.Challenge) {
      url = `ik/challenge/businessunits`;
    } else if (type === ImpactingKnowledgeType.Webinar) {
      return this.httpClient.post(`webinar/api/ik/webinar/businessunits`, ikList);
    }

    return this.httpClient.post(`portal/api/${url}`, ikList);
  }
}
