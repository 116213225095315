import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { ConfigService } from "@ngx-config/core";
import { GuestUsersCreatePayload } from "../commons/entities";

@Injectable({
  providedIn: "root"
})
export class UploadService {
  httpClient: any;
  constructor(private readonly config: ConfigService) {}

  public uploadImage(url: string, file: File): Observable<string> {
    const formData = new FormData();
    formData.append("files", file);
    return from(
      fetch(`${this.config.getSettings().apiPortalUrlPrefix}${url}`, {
        method: "POST",
        body: formData,
        headers: {
          authorization: "Bearer " + localStorage.getItem("adal.idtoken")
        }
      }).then(res => res.json())
    );
  }
  // Remember to change settings to apiUrlPrefix (for admin) instead of apiPortalUrlPrefix

  public uploadFile(url: string, file: File): Observable<string> {
    const formData = new FormData();
    formData.append("files", file);
    return from(
      fetch(`${this.config.getSettings().apiUrlPrefix}${url}`, {
        method: "POST",
        body: formData,
        headers: {
          authorization: "Bearer " + localStorage.getItem("adal.idtoken")
        }
      }).then(res => res.json())
    );
  }

  public uploadGuestCsvFile(url: string, file: File): Observable<GuestUsersCreatePayload> {
    const formData = new FormData();
    formData.append("files", file);
    return from(
      fetch(`${this.config.getSettings().apiUrlPrefix}${url}`, {
        method: "POST",
        body: formData,
        headers: {
          authorization: "Bearer " + localStorage.getItem("adal.idtoken")
        }
      }).then(res => {
        return res.json();
      })
    );
  }

  public uploadExternalCsvFile(url: string, file: File) {
    const formData = new FormData();
    formData.append("files", file);
    return from(
      fetch(`${this.config.getSettings().apiUrlPrefix}${url}`, {
        method: "POST",
        body: formData,
        headers: {
          authorization: "Bearer " + localStorage.getItem("adal.idtoken")
        }
      }).then(res => {
        return res.json();
      })
    );
  }
}
