import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FileUploadComponent } from "./file-upload/file-upload.component";

const IMPORTS = [CommonModule];

const DECLARATIONS = [FileUploadComponent];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...IMPORTS],
  exports: [...IMPORTS, ...DECLARATIONS]
})
export class FileUploadModule {}
