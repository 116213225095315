<div class="modal-header">
  <h4 class="modal-title pull-left mt-0">{{ title }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6 input-alignment mb-1">
        <label class="existing-hashtag-checkbox" *ngIf="isNew">
          <input type="checkbox" [(ngModel)]="checkModelPromote" />
          <span class="checkbox">Use an existing Tag</span>
        </label>
      </div>
    </div>
    <div
      class="col-sm-12 input-alignment row pr-0 pl-0 ml-0"
      *ngIf="isNew && !checkModelPromote"
    >
      <span class="mt-07">#</span>
      <div
        class="col-11 pl-0 pb-2 pr-0 change-size"
        *ngIf="isNew && !checkModelPromote"
      >
        <input
          type="text"
          class="no-box form-control"
          required
          id="inputTag"
          [(ngModel)]="strategicTagName"
          (ngModelChange)="onChangeName($event)"
          (keydown)="hashtagKeyDown($event)"
          #name="ngModel"
        />
        <label
          class="floating-label"
          [ngClass]="{
            'go-top': strategicTagName && strategicTagName.length > 0
          }"
          >Strategic Tag *</label
        >

        <div
          [hidden]="name.valid || name.untouched"
          class="alert alert-danger alert-custom"
        >
          Name is required
        </div>
        <div
          *ngIf="strategicTagName?.length > 100"
          class="alert alert-danger alert-custom"
        >
          Field max length is 100
        </div>
      </div>
    </div>
    <div
      class="col-sm-12 row pr-0 pl-0 ml-0"
      *ngIf="isNew && checkModelPromote"
    >
      <span class="mt-2">#</span>
      <ng-select
        required
        class="edit-network col-11 pl-0 pr-0 change-size"
        [items]="hashtag$ | async"
        bindLabel="name"
        [addTag]="false"
        [multiple]="false"
        [hideSelected]="true"
        [loading]="hashtagLoading"
        [placeholder]="'Promote existing hashtag *'"
        [typeahead]="hashtagInput$"
        [(ngModel)]="hashtag"
        #hash="ngModel"
      >
      </ng-select>
      <div
        [hidden]="hash.valid || hash.untouched"
        class="alert alert-danger alert-custom"
      >
        Hashtag to promote is required
      </div>
    </div>
    <div class="row">
      <div
        class="input-alignment mb-1"
        [ngClass]="{ 'col-sm-6': isNew, 'col-sm-12': !isNew }"
      >
        <label>
          <input
            type="checkbox"
            [(ngModel)]="checkModelYears"
            (ngModelChange)="checkboxChanged($event)"
          />
          <span class="checkbox">Use for The next Years</span>
        </label>
      </div>
    </div>
    <div class="row">
      <!-- <div [ngClass]="{ 'col-sm-6': isNew, 'col-sm-12': !isNew }"> -->
      <div class="col-sm-12">
        <ng-select
          required
          class="edit-network"
          [items]="itemYear"
          [multiple]="isMultiple"
          [(ngModel)]="isMultiple ? selectedYears : selectedSingleYear"
          bindLabel="name"
          (ngModelChange)="changeYear($event)"
          [dropdownPosition]="'bottom'"
          [placeholder]="'Reference Years *'"
          #years="ngModel"
        >
        </ng-select>
        <div
          [hidden]="years.valid || years.untouched"
          class="alert alert-danger alert-custom"
        >
          Reference Years are required
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <ng-select
          class="edit-network"
          [items]="network$ | async"
          bindLabel="name"
          [addTag]="false"
          [multiple]="true"
          [hideSelected]="true"
          [loading]="networkLoading"
          [placeholder]="'Related networks'"
          [typeahead]="networkInput$"
          [(ngModel)]="network"
        >
        </ng-select>
      </div>
    </div>
    <div
      class="col-12 alert alert-danger alert-light mt-1 mb-1"
      role="alert"
      *ngIf="isAlreadyCreated$ | async"
    >
      <strong>Warning!</strong> This hashtag is already promoted
    </div>
    <div
      class="col-12 alert alert-danger alert-light mt-1 mb-1"
      role="alert"
      *ngIf="isAlreadyUsed$ | async"
    >
      <strong>Warning!</strong> You cannot use this tag, since it has already
      been used
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary btn-raised"
    [kmsClickWait]="onDemote.bind(this)"
  >
    {{ demote }}
  </button>

  <button
    type="button"
    class="btn btn-primary btn-raised"
    [disabled]="
      isConfirmDisabled() ||
      (isAlreadyCreated$ | async) ||
      strategicTagName?.length > 100
    "
    [kmsClickWait]="onConfirmClick.bind(this)"
  >
    {{ confirmBtnName }}
  </button>
  <button
    type="button"
    class="btn btn-warning btn-raised"
    (click)="onCloseClick()"
  >
    {{ closeBtnName }}
  </button>
</div>
