import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgSelectModule } from "@ng-select/ng-select";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { StrategicTagsRoutingModule } from "./strategic-tags-routing.module";
import { StrategicTagsPageComponent } from "./strategic-tags-page/strategic-tags-page.component";
import { QueryBuilderComponentModule } from "src/app/components/query-builder/query-builder.module";
import { CardModule } from "src/app/components/card/card.module";
import { StrategicTagsEditComponent } from "./strategic-tags-edit/strategic-tags-edit.component";
import { CoreModule } from "src/app/components/core/core.module";
import { PipesModule } from "src/app/components/pipes/pipes.module";
import { ClickWaitModule } from "src/app/components/click-wait/click-wait.module";

const IMPORTS = [
  CommonModule,
  StrategicTagsRoutingModule,
  NgxDatatableModule,
  NgSelectModule,
  QueryBuilderComponentModule,
  CardModule,
  TooltipModule,
  CoreModule,
  PipesModule,
  ClickWaitModule
];
const DECLARATIONS = [StrategicTagsPageComponent, StrategicTagsEditComponent];

@NgModule({
  imports: [...IMPORTS],
  declarations: [...DECLARATIONS],
  entryComponents: [StrategicTagsEditComponent],
  exports: [...IMPORTS, ...DECLARATIONS]
})
export class StrategicTagsModule {}
