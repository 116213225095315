import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators
} from "@angular/forms";

export class UserInfo {
  // public id: string;
  public userId: string;
  public displayName: string;
  public email: string;
  public avatarUrl: string;
  public isExternalConsultant: boolean;
  public roles: Role;
  public isKOEligible: boolean;
  public unitCode: string;

  constructor(initialData?: any) {
    if (initialData) {
      // this.id = initialData.id;
      this.userId = initialData.userId;
      this.displayName = initialData.displayName;
      this.email = initialData.email;
      this.avatarUrl = initialData.avatarUrl;
      this.isExternalConsultant = initialData.isExternalConsultant;
      this.roles = initialData.roles ? new Role(initialData.roles) : null;
      this.isKOEligible = initialData.isKOEligible;
      this.unitCode = initialData.unitCode;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      userId: [this.userId],
      displayName: [this.displayName],
      email: [this.email],
      avatarUrl: [this.avatarUrl],
      isExternalConsultant: [this.isExternalConsultant],
      roles: this.roles ? this.roles.toFormGroup(builder) : null,
      unitCode: [this.unitCode]
    });
  }

  createStringArray(list: string[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(new FormControl(s));
    });

    return results;
  }
}

export class UserPermissions extends UserInfo {
  public roleList: Array<string>;

  constructor(initData?: UserInfo, stripAdmin?: boolean) {
    super(initData);

    this.roleList = new Array<string>();
    if (initData) {
      if (initData.roles) {
        if (initData.roles.kmTeam) {
          this.roleList.push(UserBadgeRoles[UserBadgeRoles.KMTeam]);
        }
        if (initData.roles.isKOEligible) {
          this.roleList.push(UserBadgeRoles[UserBadgeRoles.KOEligible]);
        }
        if (initData.roles.webinarTeam) {
          this.roleList.push(UserBadgeRoles[UserBadgeRoles.WebinarTeam]);
        }
        if (!stripAdmin && initData.roles.admin) {
          this.roleList.push(UserBadgeRoles[UserBadgeRoles.Admin]);
        }
      }
    }
  }
}

export enum ChoiceType {
  BusinessImpact = 1,
  EconomicReturn = 2,
  TimeOfDeployment = 3,
  CompanyPenetration = 4
}

export enum EvaluationCategory {
  AddedValueType = 1,
  InnovationLevel = 2,
  MostlyImpactedArea = 3
}

export class ChoiceField {
  public fieldId: string;
  public fieldTitle: string;
  public fieldCategory: number;
  public fieldType: number;
  public choices: BasicLookup[];
  public weight: number;

  constructor(initialData?: any) {
    if (initialData) {
      this.fieldId = initialData.fieldId;
      this.fieldTitle = initialData.fieldTitle;
      this.fieldCategory = initialData.fieldCategory;
      this.fieldType = initialData.fieldType;
      this.choices = initialData.choices
        ? initialData.choices.map(c => new BasicLookup(c))
        : [];
      this.weight = initialData.weight;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      fieldId: [this.fieldId],
      fieldTitle: [this.fieldTitle],
      fieldCategory: [this.fieldCategory],
      fieldType: [this.fieldType],
      choices: this.createBasicLookupArray(this.choices || [], builder),
      weight: [this.weight]
    });
  }

  createBasicLookupArray(list: BasicLookup[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(h => {
      results.push(h.toFormGroup(builder));
    });

    return results;
  }
}

export class EvaluationField {
  public choices: BasicLookup[];
  public fieldCategory: number;
  public fieldId: string;
  public fieldTitle: string;
  public fieldTooltip: string;
  public fieldType: number;

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      fieldId: [this.fieldId],
      fieldTitle: [this.fieldTitle],
      fieldCategory: [this.fieldCategory],
      fieldType: [this.fieldType],
      choices: this.createBasicLookupArray(this.choices || [], builder)
    });
  }

  createBasicLookupArray(list: BasicLookup[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(h => {
      results.push(h.toFormGroup(builder));
    });

    return results;
  }
}

export class Disclaimer {
  public id: string;
  public activeSince: Date;
  public content: string;
  public bestPractice: string;
  public attachmentUrl: Array<string>;
}

export class StickyPost {
  public id: string;
  public stickyPostId: string;
  public networkId: string;
  public plainText: string;
  public order: number;
  public created: Date;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.stickyPostId = initialData.stickyPostId;
      this.networkId = initialData.networkId;
      this.plainText = initialData.plainText;
      this.order = initialData.order;
      this.created = initialData.created;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      stickyPostId: [this.stickyPostId],
      networkId: [this.networkId],
      plainText: [this.plainText],
      order: [this.order],
      created: [this.created]
    });
  }
}

export class NavLink {
  public imageUrl: string;
  public description: string;
  public link: string;
  public title: string;
  public id: string;
  public children: NavLink[];

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.imageUrl = initialData.imageUrl;
      this.description = initialData.description;
      this.link = initialData.link;
      this.title = initialData.title;
      this.children = initialData.children;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      imageUrl: [this.imageUrl],
      description: [this.description],
      link: [this.link],
      title: [this.title]
    });
  }
}

export enum ActivityType {
  LikePost = 1,
  WritePost = 2,
  FollowUser = 3,
  FollowTag = 4,
  FollowNetwork = 5
}

export enum HashtagType {
  Generic = 1,
  Network = 2,
  ImpactingKnowledge = 3,
  BusinessUnit = 4,
  StrategicTag = 5,
  SpecialTag = 6,
  External = 7
}

export enum ImpactingKnowledgeType {
  KnowledgeNugget = 1,
  Challenge = 2,
  Idea = 3,
  SuccessStory = 4,
  Webinar = 5
}

export enum SuccessStoryStatus {
  Draft = 1,
  Proposed = 2,
  Published = 3
}

export enum ParentPageForAvatar {
  ProfileHeader = 1,
  ProfileWidget = 2,
  TimelineRootPost = 3,
  TimelineReply = 4,
  FollowPage = 5
}

export class IKChoices {
  crucialGap: Choice[];
  deploymentCost: Choice;
  deploymentTime: Choice;
  contentDeliverer: Choice;
  availabilityTime: Choice;
  expectedRevenue: Choice;

  constructor(initialData?: any) {
    if (initialData) {
      this.crucialGap =
        initialData.crucialGap && initialData.crucialGap.length > 0
          ? initialData.crucialGap.map(el => new Choice(el))
          : null;
      this.deploymentCost = initialData.deploymentCost
        ? new Choice(initialData.deploymentCost)
        : null;
      this.deploymentTime = initialData.deploymentTime
        ? new Choice(initialData.deploymentTime)
        : null;
      this.contentDeliverer = initialData.contentDeliverer
        ? new Choice(initialData.contentDeliverer)
        : null;
      this.availabilityTime = initialData.availabilityTime
        ? new Choice(initialData.availabilityTime)
        : null;
      this.expectedRevenue = initialData.expectedRevenue
        ? new Choice(initialData.expectedRevenue)
        : null;
    }
  }
}

export class HashtagInfo {
  public hashtagId: string;
  public name: string;
  public type: any;
  public description: string;
  public logoUrl: string;
  public owners: UserInfo[];
  public isPrivate: boolean;

  constructor(initialData?: any) {
    if (initialData) {
      this.hashtagId = initialData.hashtagId || initialData.id;
      this.name = initialData.name;
      this.type = this.convertHashtagType(initialData.type);
      this.description = initialData.description;
      this.logoUrl = initialData.logoUrl;
      this.owners = initialData.owners
        ? initialData.owners.map(o => new UserInfo(o))
        : [];
      this.isPrivate = initialData.isPrivate;
    }
  }


  convertHashtagType(type:string){
    switch(type){
      case "Generic" :
        return HashtagType.Generic;

      case "SpecialTag":
        return HashtagType.SpecialTag;

      case "StrategicTag":
        return HashtagType.StrategicTag;

      default: return type;

    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      hashtagId: [this.hashtagId],
      name: [this.name, Validators.maxLength(100)],
      type: [this.type],
      description: [this.description],
      logoUrl: [this.logoUrl],
      owners: this.createUserInfoArray(this.owners || [], builder),
      isPrivate: [this.isPrivate]
    });
  }

  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }
}

export class KnowledgeNugget {
  id: string;
  type = ImpactingKnowledgeType.KnowledgeNugget;
  hashtag: HashtagInfo;
  name: string;
  description: string;
  logoUrl: string;
  coverUrl: string;
  promotingNetworks: NetworkInfo[];
  involvedPeople: UserInfo[];
  relatedTags: HashtagInfo[];
  sourcePostId: string;
  businessImpact: Choice;
  impactingKnowledge: string;
  originalEvaluationChoices: string;
  attachments: IAttachment[];
  created: Date;
  createdBy: UserInfo;
  modified: Date;
  modifiedBy: UserInfo;
  isDeleted: boolean;
  deleted: Date;
  deletedBy: UserInfo;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.type = initialData.type;
      this.hashtag = new HashtagInfo(initialData.hashtag);
      this.name = initialData.name;
      this.logoUrl = initialData.logoUrl;
      this.description = initialData.description;
      this.coverUrl = initialData.coverUrl;
      this.sourcePostId = initialData.sourcePostId;
      this.promotingNetworks = initialData.promotingNetworks
        ? initialData.promotingNetworks.map(p => new NetworkInfo(p))
        : [];
      this.involvedPeople = initialData.involvedPeople
        ? initialData.involvedPeople.map(p => new UserInfo(p))
        : [];
      this.relatedTags = initialData.relatedTags
        ? initialData.relatedTags.map(p => new HashtagInfo(p))
        : [];
      this.attachments = initialData.attachments;
      this.created = initialData.created;
      this.createdBy = new UserInfo(initialData.createdBy);
      this.modified = initialData.modified;
      this.modifiedBy = new UserInfo(initialData.modifiedBy);
      this.businessImpact = initialData.businessImpact
        ? new Choice(initialData.businessImpact)
        : null;
      this.originalEvaluationChoices = initialData.originalEvaluationChoices;
      this.isDeleted = initialData.isDeleted;
      this.deleted = initialData.deleted;
      this.deletedBy = new UserInfo(initialData.deletedBy);
    }
  }
}

export class Role {
  public userId: string;
  public upn: string;
  public admin: boolean;
  public kmTeam: boolean;
  // public champion: boolean;
  public copFacilitatorScopes: string[];
  public copFacilitatorScopeNames: string[];
  public copCoFacilitatorScopes: string[];
  public copCoFacilitatorScopeNames: string[];
  public focalPointScopes: string[];
  public focalPointScopeNames: string[];
  public koScopes: string[];
  public koScopeNames: string[];
  public isKOEligible: boolean;
  public webinarTeam: boolean;

  constructor(initialData?: any) {
    if (initialData) {
      this.userId = initialData.userId;
      this.upn = initialData.upn;
      this.admin = initialData.admin;
      this.kmTeam = initialData.kmTeam;
      // this.champion = initialData.champion;
      this.copFacilitatorScopes = initialData.copFacilitatorScopes
        ? initialData.copFacilitatorScopes
        : [];
      this.copCoFacilitatorScopes = initialData.copCoFacilitatorScopes
        ? initialData.copCoFacilitatorScopes
        : [];
      this.koScopes = initialData.koScopes ? initialData.koScopes : [];
      this.focalPointScopes = initialData.focalPointScopes
        ? initialData.focalPointScopes
        : [];
      this.focalPointScopeNames = initialData.focalPointScopeNames
        ? initialData.focalPointScopeNames
        : [];
      this.koScopeNames = initialData.koScopeNames
        ? initialData.koScopeNames
        : [];
      this.copCoFacilitatorScopeNames = initialData.copCoFacilitatorScopeNames
        ? initialData.copCoFacilitatorScopeNames
        : [];
      this.copFacilitatorScopeNames = initialData.copFacilitatorScopeNames
        ? initialData.copFacilitatorScopeNames
        : [];
      this.isKOEligible = initialData.isKOEligible;
      this.webinarTeam = initialData.webinarTeam;
    }
  }

  CoFacilitatorMapping() {
    return this.copCoFacilitatorScopes.map(
      x => `${RoleNameVariable.CoFacilitator}_${x}`
    );
  }

  FacilitatorMapping() {
    return this.copFacilitatorScopes.map(
      x => `${RoleNameVariable.Facilitator}_${x}`
    );
  }

  FocalPointMapping() {
    return this.focalPointScopes.map(
      x => `${RoleNameVariable.FocalPoint}_${x}`
    );
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      userId: [this.userId],
      upn: [this.upn],
      admin: [this.admin],
      kmTeam: [this.kmTeam],
      // champion: [this.champion],
      webinarTeam: [this.webinarTeam],

      copFacilitatorScopes: this.createStringArray(
        this.copFacilitatorScopes,
        builder
      ),
      copCoFacilitatorScopes: this.createStringArray(
        this.copCoFacilitatorScopes,
        builder
      ),
      koScopes: this.createStringArray(this.koScopes, builder),
      focalPointScopeNames: this.createStringArray(
        this.focalPointScopeNames,
        builder
      ),
      koScopeNames: this.createStringArray(this.koScopeNames, builder),
      copCoFacilitatorScopeNames: this.createStringArray(
        this.copCoFacilitatorScopeNames,
        builder
      ),
      copFacilitatorScopeNames: this.createStringArray(
        this.copFacilitatorScopeNames,
        builder
      )
    });
  }

  createStringArray(list: string[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(new FormControl(s));
    });

    return results;
  }
}

export class Hashtag {
  public id: string;
  public hashtagId: string;
  public name: string;
  public type: number;
  public description: string;
  public isDeprecated: boolean;
  public isPromoted: boolean;
  public logoUrl: string;
  public relatedHashtags: HashtagInfo[];
  public owners: UserInfo[];
  public referenceYear: number[];
  public promotingNetworks: string[];
  public postCount :number;
  public isPrivate: boolean;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.hashtagId = initialData.hashtagId;
      this.name = initialData.name;
      this.type = initialData.type;
      this.postCount = initialData.postCount
      this.description = initialData.description;
      this.isDeprecated = initialData.isDeprecated;
      this.isPromoted = initialData.isPromoted;
      this.logoUrl = initialData.logoUrl;
      this.relatedHashtags = (initialData.relatedHashtags || []).map(
        h => new HashtagInfo(h)
      );
      this.owners = initialData.owners
        ? initialData.owners.map(o => new UserInfo(o))
        : [];
      this.referenceYear = initialData.referenceYear
        ? initialData.referenceYear
        : [];
      this.promotingNetworks = initialData.promotingNetworks
        ? initialData.promotingNetworks
        : [];
      this.isPrivate = initialData.isPrivate;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      hashtagId: [this.hashtagId],
      name: [this.name, Validators.maxLength(100)],
      type: [this.type],
      description: [this.description],
      isDeprecated: [this.isDeprecated],
      isPromoted: [this.isPromoted],
      logoUrl: [this.logoUrl],
      relatedHashtags: this.createRelatedHashtagsArray(
        this.relatedHashtags || [],
        builder
      ),
      owners: this.createUserInfoArray(this.owners || [], builder),
      referenceYear: this.createNumberArray(this.referenceYear || [], builder),
      promotingNetworks: this.createStringArray(
        this.promotingNetworks || [],
        builder
      ),
      isPrivate: [this.isPrivate]
    });
  }
  createStringArray(list: string[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(new FormControl(s));
    });

    return results;
  }
  createNumberArray(list: number[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(new FormControl(s));
    });

    return results;
  }

  createRelatedHashtagsArray(list: HashtagInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }
}

export class Post {
  public id: string;
  public postId: string;
  public type: number;
  public isRoot: boolean;
  public rootPostId: string;
  public plainContent: string;
  public fullContent: string;
  public locked: boolean;
  public isBestAnswer: boolean;
  public attachments: Attachment[];
  public created: Date;
  public createdBy: string;
  public modified: Date;
  public modifiedBy: string;
  public deleted: Date;
  public deletedBy: string;
  public likes: string[];
  public hashtags: string[];
  public newHashtags?: any[];
  public mentions: string[];
  public audience: string[];
  public votes: number;
  public isDeleted: boolean;
  public isOfInterest: boolean;
  public authorBusinessUnit: string;

  public postAudiences: AudienceInfo[];
  public survey?: PostSurvey;

  public voters: Vote[];

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.postId = initialData.postId;
      this.type = initialData.type;
      this.isRoot = initialData.isRoot;
      this.rootPostId = initialData.rootPostId;
      this.plainContent = initialData.plainContent;
      this.fullContent = initialData.fullContent;
      this.locked = initialData.locked;
      this.isBestAnswer = initialData.isBestAnswer;
      this.attachments = initialData.attachments
        ? initialData.attachments.map(a => new Attachment(a))
        : [];
      this.created = initialData.created;
      this.createdBy = initialData.createdBy;
      this.modified = initialData.modified;
      this.modifiedBy = initialData.modifiedBy;
      this.likes = initialData.likes;
      this.hashtags = initialData.hashtags;
      this.mentions = initialData.mentions;
      this.audience = initialData.audience;
      this.votes = initialData.votes;
      this.isDeleted = initialData.isDeleted;
      this.isOfInterest = initialData.isOfInterest;
      this.postAudiences = initialData.postAudiences
        ? initialData.postAudiences.map(x => new AudienceInfo(x))
        : [];
      this.voters = initialData.voters
        ? initialData.voters.map(v => new Vote(v))
        : [];
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      postId: [this.postId],
      type: [this.type],
      isRoot: [this.isRoot],
      rootPostId: [this.rootPostId],
      plainContent: [this.plainContent],
      fullContent: [this.fullContent],
      locked: [this.locked],
      isBestAnswer: [this.isBestAnswer],
      attachments: this.createAttachmentsArray(this.attachments || [], builder),
      created: [this.created],
      createdBy: [this.createdBy],
      modified: [this.modified],
      modifiedBy: [this.modifiedBy],
      likes: this.createStringArray(this.likes || [], builder),
      hashtags: this.createStringArray(this.hashtags || [], builder),
      mentions: this.createStringArray(this.mentions || [], builder),
      audience: this.createStringArray(this.audience || [], builder),
      votes: [this.votes],
      isDeleted: [this.isDeleted],
      isOfInterest: [this.isOfInterest],
      postAudiences: this.createAudienceInfoArray(
        this.postAudiences || [],
        builder
      ),
      voters: this.createVotersArray(this.voters || [], builder)
    });
  }

  createAttachmentsArray(list: Attachment[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createStringArray(list: string[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(new FormControl(s));
    });

    return results;
  }

  createAudienceInfoArray(list: AudienceInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(s.toFormGroup(builder));
    });

    return results;
  }

  createVotersArray(list: Vote[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(s.toFormGroup(builder));
    });

    return results;
  }
}

export class Vote {
  public userId: string;
  public type: number;

  constructor(initialData?: any) {
    if (initialData) {
      this.userId = initialData.userId;
      this.type = initialData.type;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      userId: [this.userId],
      type: [this.type]
    });
  }
}

export class UserBadge {
  public followedUsers: UserInfo[];
  public followedHashtags: HashtagInfo[];
  public followedNetworks: NetworkInfo[];
  public followers: UserInfo[];
  public rootPostCount: number;
  public replyCount: number;

  public webinarCount: number;
  public ideaCount: number;
  public knowledgeNuggetCount: number;
  public challengeCount: number;

  constructor(initialData?: any) {
    if (initialData) {
      this.followedUsers = initialData.followedUsers
        ? initialData.followedUsers.map(u => new UserInfo(u))
        : [];
      this.followedHashtags = initialData.followedHashtags
        ? initialData.followedHashtags.map(h => new HashtagInfo(h))
        : [];
      this.followers = initialData.followers
        ? initialData.followers.map(u => new UserInfo(u))
        : [];
      this.rootPostCount = initialData.rootPostCount;
      this.replyCount = initialData.replyCount;
      this.webinarCount = initialData.webinarCount;
      this.ideaCount = initialData.ideaCount;
      this.knowledgeNuggetCount = initialData.knowledgeNuggetCount;
      this.challengeCount = initialData.challengeCount;
      this.followedNetworks = initialData.followedNetworks
        ? initialData.followedNetworks.map(n => new NetworkInfo(n))
        : [];
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      followedUsers: this.createUserInfoArray(this.followedUsers, builder),
      followedHashtags: this.createHashtagInfoArray(
        this.followedHashtags,
        builder
      ),
      followers: this.createUserInfoArray(this.followers, builder),
      rootPostCount: [this.rootPostCount],
      replyCount: [this.replyCount],
      webinarCount: [this.webinarCount],
      ideaCount: [this.ideaCount],
      knowledgeNuggetCount: [this.knowledgeNuggetCount],
      challengeCount: [this.challengeCount],
      followedNetworks: this.createNetworkInfoArray(
        this.followedNetworks,
        builder
      )
    });
  }

  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createHashtagInfoArray(list: HashtagInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createNetworkInfoArray(list: NetworkInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }
}

export class Activity {
  public type: ActivityType;
  public date: Date;
  public post: Post;
  public followedUser: UserInfo;
  public followedTag: HashtagInfo;
  public followedNetwork: NetworkInfo;
  public postUsers: UserInfo[];
  public postHashtags: HashtagInfo[];
  public user: UserInfo;

  constructor(initialData?: any) {
    if (initialData) {
      this.type = initialData.type;
      this.date = initialData.date;
      this.post = new Post(initialData.post);
      this.followedUser = new UserInfo(initialData.followedUser);
      this.followedTag = new HashtagInfo(initialData.followedTag);
      (this.followedNetwork = new NetworkInfo(initialData.followedNetwork)),
        (this.user = new UserInfo(initialData.user));
      this.postUsers = initialData.postUsers
        ? initialData.postUsers.map(u => new UserInfo(u))
        : [];
      this.postHashtags = initialData.postHashtags
        ? initialData.postHashtags.map(h => new HashtagInfo(h))
        : [];
    }
  }
  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      type: [this.type],
      date: [this.date],
      post: this.post.toFormGroup(builder),
      followedUser: this.followedUser.toFormGroup(builder),
      followedTag: this.followedTag.toFormGroup(builder),
      followedNetwork: this.followedNetwork.toFormGroup(builder),
      user: this.user.toFormGroup(builder),
      postUsers: this.createUserInfoArray(this.postUsers, builder),
      postHashtags: this.createHashtagInfoArray(this.postHashtags, builder)
    });
  }

  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createHashtagInfoArray(list: HashtagInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }
}

export class User {
  // public id: string;
  public userId: string;
  public upn: string;
  public displayName: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public avatarUrl: string;
  public followedPeople: UserInfo[];
  public followedHashtags: Hashtag[];
  public manager: UserInfo;
  public businessUnit: any;
  public unitCode: string;
  public orgaUnitCode: string;
  public aboutMe: string;
  public knowledgeExperience: string;
  public city: string;
  public country: string;
  public company: string;
  public disclaimerAccepted: boolean;
  public disclaimerAcceptedDate: Date;
  public isExternalConsultant: boolean;
  public societyBusiness: string;
  public professionalArea: string;
  public phone: string;
  public skypeForBusiness: string;
  public askMeAbout: string;
  // public roles: string[];
  public preferences: any;
  public skills: HashtagInfo[];
  public coverUrl: string;
  public isKOEligible: boolean;
  public roles: Role;
  public onPremisesImmutableId: string;

  constructor(initialData?: any) {
    if (initialData) {
      // this.id = initialData.id;
      this.userId = initialData.userId;
      this.upn = initialData.upn;
      this.displayName = initialData.displayName;
      this.firstName = initialData.firstName;
      this.lastName = initialData.lastName;
      this.email = initialData.email;
      this.avatarUrl = initialData.avatarUrl;
      this.followedPeople = initialData.followedPeople
        ? initialData.followedPeople.map(u => new UserInfo(u))
        : [];
      this.followedHashtags = initialData.followedHashtags
        ? initialData.followedHashtags.map(u => new HashtagInfo(u))
        : [];
      this.manager = new UserInfo(initialData.manager);
      this.businessUnit = new BusinessUnit(initialData.businessUnit);
      this.unitCode = initialData.unitCode;
      this.orgaUnitCode = initialData.orgaUnitCode;
      this.aboutMe = initialData.aboutMe;
      this.knowledgeExperience = initialData.knowledgeExperience;
      this.city = initialData.city;
      this.country = initialData.country;
      this.company = initialData.company;
      this.disclaimerAccepted = initialData.disclaimerAccepted;
      this.disclaimerAcceptedDate = initialData.disclaimerAcceptedDate;
      this.isExternalConsultant = initialData.isExternalConsultant;
      this.societyBusiness = initialData.societyBusiness;
      this.professionalArea = initialData.professionalArea;
      this.phone = initialData.phone;
      this.skypeForBusiness = initialData.skypeForBusiness;
      // this.roles = initialData.roles;
      this.askMeAbout = initialData.askMeAbout;
      this.preferences = new Preferences(initialData.preferences);
      this.skills = initialData.skills
        ? initialData.skills.map(x => new HashtagInfo(x))
        : [];
      this.coverUrl = initialData.coverUrl;
      this.roles = initialData.roles ? new Role(initialData.roles) : null;
      this.isKOEligible = initialData.isKOEligible;
      this.onPremisesImmutableId = initialData.onPremisesImmutableId;
    }
  }

  mapRoleToString(): string[] {
    const roles: string[] = [];

    if (!this.roles) {
      return roles;
    }

    if (this.roles.admin) {
      roles.push(RoleNameVariable.Admin);
    }
    if (this.roles.kmTeam) {
      roles.push(RoleNameVariable.KMSTeam);
    }
    // if (this.roles.champion) {
    //   roles.push(RoleNameVariable.Champion);
    // }

    if (this.roles.webinarTeam) {
      roles.push(RoleNameVariable.WebinarTeam);
    }

    this.roles.copFacilitatorScopes.forEach(copId => {
      roles.push(`${RoleNameVariable.Facilitator}_${copId}`);
    });
    this.roles.copCoFacilitatorScopes.forEach(copId => {
      roles.push(`${RoleNameVariable.CoFacilitator}_${copId}`);
    });
    this.roles.focalPointScopes.forEach(copId => {
      roles.push(`${RoleNameVariable.FocalPoint}_${copId}`);
    });
    this.roles.koScopes.forEach(hashtagId => {
      roles.push(`${RoleNameVariable.KnowledgeOwner}_${hashtagId}`);
    });

    return roles;
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      // id: [this.id],
      userId: [this.userId],
      upn: [this.upn, Validators.required],
      displayName: [this.displayName, Validators.required],
      firstName: [this.firstName],
      lastName: [this.lastName],
      email: [this.email, [Validators.required, Validators.email]],
      avatarUrl: [this.avatarUrl],
      followedPeople: this.createFollowedPeopleArray(
        this.followedPeople || [],
        builder
      ),
      followedHashtags: this.createFollowedHashtagArray(
        this.followedHashtags || [],
        builder
      ),
      manager: this.manager
        ? this.manager.toFormGroup(builder)
        : new UserInfo().toFormGroup(builder),
      businessUnit: this.businessUnit
        ? this.businessUnit.toFormGroup(builder)
        : new BusinessUnit().toFormGroup(builder),
      unitCode: [this.unitCode],
      orgaUnitCode: [this.orgaUnitCode],
      aboutMe: [this.aboutMe],
      knowledgeExperience: [this.knowledgeExperience],
      city: [this.city],
      country: [this.country],
      company: [this.company],
      disclaimerAccepted: [this.disclaimerAccepted],
      disclaimerAcceptedDate: [this.disclaimerAcceptedDate],
      isExternalConsultant: [this.isExternalConsultant || false],
      societyBusiness: [this.societyBusiness],
      professionalArea: [this.professionalArea],
      phone: [this.phone],
      skypeForBusiness: this.skypeForBusiness,
      // roles: this.createStringArray(this.roles || [], builder),
      askMeAbout: [this.askMeAbout],
      preferences: this.preferences
        ? this.preferences.toFormGroup(builder)
        : new Preferences().toFormGroup(builder),
      skills: this.createHashtagInfoArray(this.skills || [], builder),
      coverUrl: [this.coverUrl],
      roles: this.roles ? this.roles.toFormGroup(builder) : null,
      isKOEligible: [this.isKOEligible],
      onPremisesImmutableId: [this.onPremisesImmutableId]
    });
  }

  createFollowedPeopleArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createFollowedHashtagArray(list: Hashtag[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createStringArray(list: string[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(new FormControl(s));
    });

    return results;
  }

  createHashtagInfoArray(list: HashtagInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }
}
export class IAttachment {
  public url: string;
  public name: string;
  public mimeType: string;
  public attachmentId: string;
}

export class Attachment {
  public url: string;
  public name: string;
  public mimeType: string;
  public id: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.url = initialData.url;
      this.name = initialData.name;
      this.id = initialData.id;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      url: [this.url],
      name: [this.name],
      mimeType: [this.id]
    });
  }
}

export class Choice {
  public fieldId: string;
  public fieldLabel: string;
  public choiceId: number;
  public choiceLabel: string;
  public choiceValue: number;

  constructor(initialData?: any) {
    if (initialData) {
      this.fieldId = initialData.fieldId;
      this.choiceId = initialData.choiceId;
      this.fieldLabel = initialData.fieldLabel;
      this.choiceLabel = initialData.choiceLabel;
      this.choiceValue = initialData.choiceValue;
    }
  }

  toFormGroup(builder: FormBuilder) {
    return builder.group({
      fieldId: [this.fieldId],
      choiceId: [this.choiceId],
      fieldLabel: [this.fieldLabel],
      choiceLabel: [this.choiceLabel],
      choiceValue: [this.choiceValue]
    });
  }
}

export class MergedNetwork
{
  public newNetwork: Network;
  public merged: string[];
}
export class Network {
  public networkId: string;
  public logoUrl: string;
  public coverUrl: string;
  public teamUrl: string;
  public name: string;
  public hashtag: any;
  public hashtagId: string;
  public focalPoints: any[];
  public facilitators: any[];
  public coFacilitators: any[];
  public members: any[];
  public guests: any[];
  public koUsers: UserInfo[];
  public postCounter: number;
  public followerCounter: number;
  public webinarCount: number;
  public knowledgeNuggetCount: number;
  public challengeCount: number;
  public ideaCount: number;
  public avgContributors: number;
  public abstract: string;
  public strategicTags: any[];
  public ourTags: any[];
  public stickyPosts: any[];
  public navLinks: any[];
  // public conversations: Conversation[];
  public allowExternalUsers: boolean;
  public isHidden: boolean;
  public created: Date;
  public createdBy: any;
  public modified: Date;
  public modifiedBy: any;
  public deleted: Date;
  public deletedBy: any;
  public isDeleted: boolean;
  public coreTeam: any[];
  public type: any;
  public typeId: number;
  public navLinksTitle: string;
  public isPrivate: boolean;

  public status: VisibilityType;

  constructor(initialData?: any) {
    if (initialData) {
      this.networkId = initialData.networkId;
      this.logoUrl = initialData.logoUrl;
      this.coverUrl = initialData.coverUrl;
      this.teamUrl = initialData.teamUrl;
      this.name = initialData.name;
      this.hashtagId = initialData.hashtagId;
      this.hashtag = new HashtagInfo(initialData.hashtag);
      this.focalPoints = initialData.focalPoints.map(u => new UserInfo(u));
      this.facilitators = initialData.facilitators.map(u => new UserInfo(u));
      this.coFacilitators = initialData.coFacilitators.map(
        u => new UserInfo(u)
      );
      this.members = initialData.members.map(u => new UserInfo(u));
      this.guests = initialData.guests
        ? initialData.guests.map(u => new UserInfo(u))
        : [];
      this.postCounter = initialData.postCounter;
      this.followerCounter = initialData.followerCounter;
      this.webinarCount = initialData.webinarCount;
      this.knowledgeNuggetCount = initialData.knowledgeNuggetCount;
      this.challengeCount = initialData.challengeCount;
      this.ideaCount = initialData.ideaCount;
      this.avgContributors = initialData.avgContributors;
      this.abstract = initialData.abstract;
      this.strategicTags = initialData.strategicTags
        ? initialData.strategicTags.map(h => new HashtagInfo(h))
        : [];
      this.ourTags = initialData.ourTags
        ? initialData.ourTags.map(h => new HashtagInfo(h))
        : [];
      this.stickyPosts = initialData.stickyPosts
        .sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          return 0;
        })
        .map(s => new StickyPost(s));
      this.navLinks = initialData.navLinks.map(n => new NavLink(n));
      // public conversations: Conversation[];
      this.allowExternalUsers = initialData.allowExternalUsers;
      this.isHidden = initialData.isHidden;
      this.created = initialData.created;
      this.createdBy = new UserInfo(initialData.createdBy);
      this.modified = initialData.modified;
      this.modifiedBy = new UserInfo(initialData.modifiedBy);
      this.deleted = initialData.deleted;
      this.deletedBy = new UserInfo(initialData.deletedBy);
      this.isDeleted = initialData.isDeleted;
      this.coreTeam = initialData.coreTeam
        ? initialData.coreTeam.map(h => new UserInfo(h))
        : [];
      this.type = initialData.type;
      this.navLinksTitle = initialData.navLinksTitle;
      this.isPrivate = initialData.isPrivate;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      networkId: [this.networkId],
      logoUrl: [this.logoUrl],
      coverUrl: [this.coverUrl],
      teamUrl: [this.teamUrl],
      hashtagId: [this.hashtagId],
      name: [this.name],
      hashtag: this.hashtag.toFormGroup(builder),
      focalPoints: this.createUserInfoArray(this.focalPoints, builder),
      facilitators: this.createUserInfoArray(this.facilitators, builder),
      coFacilitators: this.createUserInfoArray(this.coFacilitators, builder),
      members: this.createUserInfoArray(this.members, builder),
      guests: this.createUserInfoArray(this.guests, builder),
      postCounter: [this.postCounter],
      followerCounter: [this.followerCounter],
      webinarCount: [this.webinarCount],
      knowledgeNuggetCount: [this.knowledgeNuggetCount],
      challengeCount: [this.challengeCount],
      ideaCount: [this.ideaCount],
      avgContributors: [this.avgContributors],
      abstract: [this.abstract],
      strategicTags: this.createHastagInfoArray(this.strategicTags, builder),
      ourTags: this.createHastagInfoArray(this.ourTags, builder),
      stickyPosts: this.createStickyPostArray(this.stickyPosts, builder),
      navLinks: this.createNavLinkArray(this.navLinks, builder),
      // conversations: this.createConversationArray(this.conversations, builder),
      allowExternalUsers: [this.allowExternalUsers],
      isHidden: [this.isHidden],
      created: [this.created],
      createdBy: this.createdBy.toFormGroup(builder),
      modified: [this.modified],
      modifiedBy: this.modifiedBy.toFormGroup(builder),
      deleted: [this.deleted],
      deletedBy: this.deletedBy.toFormGroup(builder),
      isDeleted: [this.isDeleted],
      coreTeam: this.createUserInfoArray(this.coreTeam || [], builder),
      type: [this.type],
      navLinksTitle: [this.navLinksTitle],
      isPrivate: [this.isPrivate]
    });
  }

  createStickyPostArray(list: any[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(sticky => {
      results.push(sticky.toFormGroup(builder));
    });

    return results;
  }

  createUserInfoArray(list: any[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createHastagInfoArray(list: any[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(h => {
      results.push(h.toFormGroup(builder));
    });

    return results;
  }

  createPostArray(list: any[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(p => {
      results.push(p.toFormGroup(builder));
    });

    return results;
  }

  createNavLinkArray(list: any[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }

  createConversationArray(list: any[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(c => {
      results.push(c.toFormGroup(builder));
    });

    return results;
  }

  createStringArray(list: string[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(new FormControl(s));
    });

    return results;
  }
}

export enum NetworkType {
  CoP = 1,
  BusinessUnit = 2,
  StrategicTag = 3,
  SpecialTag = 4,
  External = 5,
  CoPAndPrivate=8,
  Private = 9
}

export enum NetworkTypeAdmin {
  CoP = 1,
  BusinessUnit = 2,
  StrategicTag = 3,
  SpecialTag = 4,
  External = 5,
  // CoPAndPrivate=8,
  // Private = 9
}

export class NetworkInfo {
  public networkId: string;
  public logoUrl: string;
  public coverUrl: string;
  public name: string;
  public hashtag: HashtagInfo;
  public abstract: string;
  public type: NetworkType;
  public isPrivate: boolean;
  public facilitatorsIds: string[];
  public coFacilitatorsIds: string[];
  public guestsIds: string[];

  constructor(initialData?: any) {
    if (initialData) {
      this.networkId = initialData.networkId;
      this.logoUrl = initialData.logoUrl;
      this.coverUrl = initialData.coverUrl;
      this.name = initialData.name;
      this.hashtag = new HashtagInfo(initialData.hashtag);
      this.abstract = initialData.abstract;
      this.type = initialData.type;
      this.isPrivate = initialData.isPrivate;
      this.facilitatorsIds = initialData.facilitatorsIds ? initialData.facilitatorsIds : [];
      this.coFacilitatorsIds = initialData.coFacilitatorsIds ? initialData.coFacilitatorsIds :[];
      this.guestsIds = initialData.guestsIds ? initialData.guestsIds : [];
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      networkId: [this.networkId],
      logoUrl: [this.logoUrl],
      coverUrl: [this.coverUrl],
      name: [this.name],
      hashtag: this.hashtag.toFormGroup(builder),
      abstract: [this.abstract],
      type: [this.type],
      isPrivate: [this.isPrivate],
      facilitatorsIds:[this.facilitatorsIds],
      coFacilitatorsIds:[this.coFacilitatorsIds],
      guestsIds:[this.guestsIds]
    });
  }
}

export enum VisibilityType {
  Draft = 1,
  Published = 2,
  Closed = 3,
  //Merged = 4
}

export class AudienceInfo {
  public postId: string;
  public hashtagId: string;
  public created: Date;
  public createdBy: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.postId = initialData.postId;
      this.hashtagId = initialData.hashtagId;
      this.created = initialData.created;
      this.createdBy = initialData.createdBy;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      postId: [this.postId],
      hashtagId: [this.hashtagId],
      created: [this.created],
      createdBy: [this.createdBy]
    });
  }
}
export class Challenge {
  id: string;
  type = ImpactingKnowledgeType.Challenge;
  hashtag: HashtagInfo;
  name: string;
  description: string;
  logoUrl: string;
  coverUrl: string;
  promotingNetworks: NetworkInfo[];
  involvedPeople: UserInfo[];
  relatedTags: HashtagInfo[];
  // sourcePostId: string;
  businessImpact: Choice;
  // businessImpact: string;
  originalEvaluationChoices: string;
  startDate: Date;
  endDate: Date;
  daysLeft: number;
  evaluationCommitteeDate: Date;
  evaluationCommittee: UserInfo[];
  result: string;
  status: Lookup<number, string>;
  created: Date;
  createdBy: UserInfo;
  modified: Date;
  modifiedBy: UserInfo;
  isDeleted: boolean;
  deleted: Date;
  deletedBy: UserInfo;
  attachments: IAttachment[];
  mostlyImpactedArea: Choice;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.type = initialData.type;
      this.hashtag = new HashtagInfo(initialData.hashtag);
      this.name = initialData.name;
      this.logoUrl = initialData.logoUrl;
      this.description = initialData.description;
      this.coverUrl = initialData.coverUrl;
      // this.sourcePostId = initialData.sourcePostId;
      this.promotingNetworks = initialData.promotingNetworks
        ? initialData.promotingNetworks.map(p => new NetworkInfo(p))
        : [];
      this.involvedPeople = initialData.involvedPeople
        ? initialData.involvedPeople.map(p => new UserInfo(p))
        : [];
      this.relatedTags = initialData.relatedTags
        ? initialData.relatedTags.map(p => new HashtagInfo(p))
        : [];
      this.startDate = initialData.startDate;
      this.endDate = initialData.endDate;
      this.daysLeft = this.endDate
        ? Math.round(
            (new Date(this.endDate).getTime() - new Date().getTime()) /
              (1000 * 60 * 60 * 24)
          )
        : null;
      this.evaluationCommitteeDate = initialData.evaluationCommitteeDate;
      this.evaluationCommittee = initialData.evaluationCommittee
        ? initialData.evaluationCommittee.map(p => new UserInfo(p))
        : [];
      this.result = initialData.result;
      this.status = initialData.status;
      this.created = initialData.created;
      this.createdBy = new UserInfo(initialData.createdBy);
      this.modified = initialData.modified;
      this.modifiedBy = new UserInfo(initialData.modifiedBy);
      this.businessImpact = initialData.businessImpact
        ? new Choice(initialData.businessImpact)
        : null;
      this.mostlyImpactedArea = initialData.mostlyImpactedArea
        ? new Choice(initialData.mostlyImpactedArea)
        : null;
      this.originalEvaluationChoices = initialData.originalEvaluationChoices;
      this.isDeleted = initialData.isDeleted;
      this.deleted = initialData.deleted;
      this.deletedBy = new UserInfo(initialData.deletedBy);
      this.attachments = initialData.attachments;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      type: [this.type],
      hashtag: this.hashtag
        ? this.hashtag.toFormGroup(builder)
        : new HashtagInfo().toFormGroup(builder),
      name: [this.name],
      logoUrl: [this.logoUrl],
      description: [this.description],
      coverUrl: [this.coverUrl],
      // sourcePostId: [this.sourcePostId],
      promotingNetworks: this.createNetworkInfoArray(
        this.promotingNetworks || [],
        builder
      ),
      involvedPeople: this.createUserInfoArray(
        this.involvedPeople || [],
        builder
      ),
      relatedTags: this.createHashtagInfoArray(this.relatedTags || [], builder),
      startDate: [this.startDate],
      endDate: [this.endDate],
      evaluationCommitteeDate: [this.evaluationCommitteeDate],
      evaluationCommittee: this.createUserInfoArray(
        this.evaluationCommittee || [],
        builder
      ),
      result: [this.result],
      status: [this.status],
      created: [this.created],
      createdBy: this.createdBy.toFormGroup(builder),
      modified: [this.modified],
      modifiedBy: this.modifiedBy
        ? this.modifiedBy.toFormGroup(builder)
        : new UserInfo().toFormGroup(builder),
      isDeleted: [this.isDeleted],
      deleted: [this.deleted],
      deletedBy: this.deletedBy
        ? this.deletedBy.toFormGroup(builder)
        : new UserInfo().toFormGroup(builder),
      businessImpact: this.businessImpact
        ? new FormControl(this.businessImpact)
        : null,
      originalEvaluationChoices: [this.originalEvaluationChoices],
      mostlyImpactedArea: this.mostlyImpactedArea
        ? new FormControl(this.mostlyImpactedArea)
        : null
    });
  }

  createNetworkInfoArray(list: NetworkInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }

  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }

  createHashtagInfoArray(list: HashtagInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(h => {
      results.push(h.toFormGroup(builder));
    });

    return results;
  }
}

export class ChallengeInfo {
  public id: string;
  public type = ImpactingKnowledgeType.Challenge;
  public hashtag: HashtagInfo;
  public name: string;
  public description: string;
  public logoUrl: string;
  public coverUrl: string;
  public created: Date;
  public modified: Date;
  public businessImpact: Choice;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.type = initialData.type;
      this.hashtag = initialData.hashtag;
      this.name = initialData.name;
      this.description = initialData.description;
      this.logoUrl = initialData.logoUrl;
      this.coverUrl = initialData.coverUrl;
      this.created = initialData.created;
      this.modified = initialData.modified;
      this.businessImpact = initialData.businessImpact
        ? new Choice(initialData.businessImpact)
        : null;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      type: [this.type],
      hashtag: [this.hashtag],
      name: [this.name],
      description: [this.description],
      logoUrl: [this.logoUrl],
      coverUrl: [this.coverUrl],
      created: [this.created],
      modified: [this.modified],
      businessImpact: this.businessImpact
        ? new FormControl(this.businessImpact)
        : null
    });
  }
}

export class Preferences {
  public preferredLanguage: string;
  public digestOption: any;

  constructor(initialData?: any) {
    if (initialData) {
      this.preferredLanguage = initialData.preferredLanguage;
      this.digestOption = initialData.digestOption;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      preferredLanguage: [this.preferredLanguage],
      digestOption: [this.digestOption]
    });
  }
}

export class BusinessUnit {
  public businessUnitId: string;
  public name: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.name = initialData.name;
      this.businessUnitId = initialData.businessUnitId;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      businessUnitId: [this.businessUnitId],
      name: [this.name]
    });
  }
}

export interface HashtagBadge {
  followers: UserInfo[];
  rootPostCount: number;
  replyCount: number;
}

export enum PeoplePickerUserRestriction {
  None = 1,
  OnlyKO = 2,
  onlyHRE = 3
}

export enum UserType {
  Standard = 1,
  KnowledgeOwner = 2
}

export enum KMSObject {
  User = 1,
  Hashtag = 2,
  Network = 3,
  IK = 4,
  Conversation = 5,
  Idea = 6,
  SuccessStory = 7,
  Challenge = 8
}

export class Lookup<K, V> {
  public key: K;
  public value: V;
}

export class BasicLookup extends Lookup<number, string> {
  constructor(initialData?: any) {
    super();
    if (initialData) {
      this.key = initialData.key;
      this.value = initialData.value;
    }
  }

  toFormGroup(builder: FormBuilder) {
    return builder.group({
      Key: [this.key],
      value: [this.value]
    });
  }
}

export class LookUpString {
  key: string;
  value: any;

  constructor(key: string, value: any) {
    this.key = key;
    this.value = value;
  }
}

export enum OperationResultStatus {
  Success = 1,
  Failure = 2
}

export enum UserBadgeRoles {
  Admin = 1,
  KMTeam = 2,
  CoPFacilitator = 3,
  CoPCoFacilitator = 4,
  BUFocalPoint = 5,
  KnowledgeOwner = 6,
  WebinarTeam = 8,
  BUReferent = 11,
  SpecialTagReferent = 12,
  SpecialTagCoreTeam = 13,
  ExternalNetworkReferent = 14,
  ExternalNetworkCoreteam = 15,
  KOEligible = 50
}
export class IkBadge {
  followers: UserInfo[];
  rootPostCount: number;
  replyCount: number;

  constructor(initialData?: any) {
    if (initialData) {
      this.followers = initialData.followers
        ? initialData.followers.map(u => new UserInfo(u))
        : [];
      this.rootPostCount = initialData.rootPostCount;
      this.replyCount = initialData.replyCount;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      followers: this.createUserInfoArray(this.followers, builder),
      rootPostCount: [this.rootPostCount],
      replyCount: [this.replyCount]
    });
  }

  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }
}

export class IdeaBadge {
  followersCount: number;
  rootPostCount: number;
  replyCount: number;
  challenges: ChallengeInfo[];

  constructor(initialData?: any) {
    if (initialData) {
      this.followersCount = initialData.followersCount;
      this.rootPostCount = initialData.rootPostCount;
      this.replyCount = initialData.replyCount;
      this.challenges = initialData.challenges
        ? initialData.challenges.map(c => new ChallengeInfo(c))
        : [];
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      followersCount: [this.followersCount],
      rootPostCount: [this.rootPostCount],
      replyCount: [this.replyCount],
      challenges: this.createChallengeInfoArray(this.challenges, builder)
    });
  }

  createChallengeInfoArray(list: ChallengeInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }
}

export class KeyPhraseResult {
  public documents: KeyPhraseBatchResultItem[];

  public errors: ErrorRecord[];

  public keyPhrases: string[];

  public id: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.documents = initialData.documents
        ? initialData.documents.map(d => new KeyPhraseBatchResultItem(d))
        : [];
      this.errors = initialData.errors
        ? initialData.errors.map(e => new ErrorRecord(e))
        : [];
      this.keyPhrases = initialData.keyPhrases ? initialData.keyPhrases : [];
      this.id = initialData.id;
    }
  }
}

export class KeyPhraseBatchResultItem {
  public keyPhrases: string[];
  public id: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.keyPhrases = initialData.keyPhrases;
      this.id = initialData.id;
    }
  }
}

export class ErrorRecord {
  public id: string;
  public message: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.message = initialData.message;
      this.id = initialData.id;
    }
  }
}
export class RoleNameVariable {
  public static Admin = "Admin";
  public static KMSTeam = "KMTeam";
  public static FocalPoint = "FocalPoint";
  public static Facilitator = "CoPFacilitator";
  public static CoFacilitator = "CoPCoFacilitator";
  // public static ActiveUser = "ActiveUser";
  // public static Visitor = "Visitor";
  // public static Champion = "Champion";
  public static KnowledgeOwner = "KnowledgeOwner";
  public static WebinarTeam = "WebinarTeam";
  // public static BUFocalPoint = "BUFocalPoint";
  // public static BUReferent = "BUReferent";
  // public static SpecialTagReferent = "SpecialTagReferent";
  // public static SpecialTagCoreTeam = "SpecialTagCoreTeam";
  // public static KOEligible = "KOEligible";
}

export interface PostSurvey {
  pollId: string;
  type?: number;
  title?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  questions: Question[];
  created?: string;
  createdBy?: string;
  modified?: string;
  modifiedBy?: string;
}

export interface Question {
  questionId: string;
  type?: number;
  order?: number;
  text: string;
  choices: QuestionChoice[];
  minAnswer?: number;
  maxAnswer?: number;
  required?: boolean;
}

export interface QuestionChoice {
  key: number;
  value: string;
}

export interface ITransaction {
  transactionId: string;
}

export class MapDoc {
  static docToNetInfo(val) {
    const netInfo = new NetworkInfo(val);
    netInfo.networkId = val.id;
    netInfo.type = val.typeId;
    const hash = new HashtagInfo();
    hash.hashtagId = val.hashtagId;
    hash.name = val.hashtag;
    netInfo.hashtag = hash;
    netInfo.logoUrl = val.logoUrl;
    return netInfo;
  }
  static docToHashInfo(val) {
    const hash = new HashtagInfo(val);
    hash.hashtagId = val.id;
    hash.type = val.typeId;
    return hash;
  }
}

export class GamificationSettings {
  id: string;
  events: Medal[];
  constructor(initialData?: any) {
    this.id = initialData.id;
    this.events = initialData.events;
  }
}
export class Gamification {
  name: string;
  sourceLabel: string;
  targetLabel: string;
  sourcePoints: number;
  targetPoints: number;
  constructor(initialData?: any) {
    this.name = initialData.name;
    this.sourceLabel = initialData.sourceLabel;
    this.targetLabel = initialData.targetLabel;
    this.sourcePoints = initialData.sourcePoints;
    this.targetPoints = initialData.targetPoints;
  }
  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      name: [this.name],
      sourceLabel: [this.sourceLabel],
      targetLabel: [this.targetLabel],
      sourcePoints: [this.sourcePoints, Validators.required],
      targetPoints: [this.targetPoints, Validators.required]
    });
  }
}

export class MedalSettings {
  id: string;
  medals: Medal[];
  constructor(initialData?: any) {
    this.id = initialData.id;
    this.medals = initialData.medals;
  }
}
export class Medal {
  name: string;
  minPoints: number;
  maxPoints: number;
  cssClass: string;
  constructor(initialData?: any) {
    this.name = initialData.name;
    this.minPoints = initialData.minPoints;
    this.maxPoints = initialData.maxPoints;
    this.cssClass = initialData.cssClass;
  }
  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      name: [this.name, Validators.required],
      minPoints: [this.minPoints, Validators.required],
      maxPoints: [this.maxPoints, Validators.required],
      cssClass: [this.cssClass]
    });
  }
}

export class BusinessUnits {
  id: string;
  description: string;
  author: string;
  name: string;
  city: string;
  company: string;
  grouping: string;
  customBU: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.description = initialData.description;
      this.author = initialData.author;
      this.name = initialData.name;
      this.city = initialData.city;
      this.company = initialData.company;
      this.grouping = initialData.grouping;
      this.customBU = initialData.customBU;
    }
  }
  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      name: [this.name],
      city: [this.city],
      company: [this.company],
      description: [this.description],
      author: [this.author],
      grouping: [this.grouping],
      customBU: [this.customBU]
    });
  }
}

export class SecurityProfile {
  id: string;
  upn: string;
  email: string;
  displayName: string;
  lastName: string;
  firstName: string;
  businessPhone: Array<string>;
  mobilePhone: string;
  jobTitle: string;
  officeLocation: string;
  preferredLanguage: string;

  constructor(initialData?: any) {
    if (initialData) {
      // this.id = initialData.id;
      this.id = initialData.id;
      this.upn = initialData.upn;
      this.email = initialData.email;
      this.displayName = initialData.displayName;
      this.lastName = initialData.lastName;
      this.firstName = initialData.firstName;
      this.businessPhone = initialData.businessPhone;
      this.mobilePhone = initialData.mobilePhone;
      this.jobTitle = initialData.jobTitle;
      this.officeLocation = initialData.officeLocation;
      this.preferredLanguage = initialData.preferredLanguage;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      // id: [this.id],
      // upn: [this.displayName],
      // email: [this.email],
      // avatarUrl: [this.avatarUrl],
      // isExternalConsultant: [this.isExternalConsultant],
      // roles: this.roles ? this.roles.toFormGroup(builder) : null
    });
  }
}

export class ExternalUser {
  public id: string;
  public displayName: string;
  public lastName: string;
  public firstName: string;
  public email: string;
  public upn: string;
  public employeeReference: UserInfo;
  public businessUnit: BusinessUnit;
  public company: string;
  public city: string;
  public isLeleEnabled: boolean; 
  public networks: any; 
  public assignedCops: string;
  public assignedBU: string;
  public assignedSpTags: string;
  public assignedPN: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.upn = initialData.upn;
      this.email = initialData.email;
      this.displayName = initialData.displayName;
      this.lastName = initialData.lastName;
      this.firstName = initialData.firstName;
      this.employeeReference = new UserInfo({
        userId: initialData.managerId,
        displayName: initialData.manager
      });
      this.businessUnit = new BusinessUnit({
        businessUnitId: initialData.businessUnitId,
        name: initialData.businessUnit
      });
      this.company = initialData.company;
      this.city = initialData.city;
      this.isLeleEnabled = initialData.isLeleEnabled;
      this.networks = initialData.networks;
    }
  }
}

export class IPMKeywordInfo {
  public id: string;
  public keyword: string;
  public userReferences: Array<UserInfo>;
  public isDeleted: boolean;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.keyword = initialData.keyword;
      this.userReferences = initialData.userReferences
        ? initialData.userReferences.map( user => {
          new UserInfo(user);
        } )
        : [];
      this.isDeleted = initialData.isDeleted;
    }
  }
}

export class GuestUser {
  public id: string;
  public userId: string;
  public upn: string;
  public displayName: string;
  public email: string;
  public employeeReference: UserInfo;
  public networks: Array<NetworkInfo>;
  public status: GuestInvitationStatus;
  public statusName: string;
  public networkNames: Array<string>;
  public isDeleted: boolean;
  public company: string;
  public toSimplyUpdate: boolean;

  constructor(initialData?: any) {
    if (initialData) {
      //alert(initialData.company);
      this.id = initialData.id;
      this.userId = initialData.userId;
      this.upn = initialData.upn;
      this.email = initialData.email;
      this.displayName = initialData.name;
      this.company = initialData.company;
      this.employeeReference = new UserInfo({
        userId: initialData.managerId,
        displayName: initialData.manager
      });
      this.networks = initialData.networkIds
        ? initialData.networkIds.map(
            (id: string, index: number) =>
              new NetworkInfo({
                networkId: id,
                name: initialData.networks[index]
              })
          )
        : [];
      this.networkNames = this.networks
        ? this.networks.map(network => network.name)
        : [];
      this.statusName = initialData.status;
      this.status = initialData.statusId;
      this.isDeleted = initialData.isDeleted;
      this.toSimplyUpdate = initialData.toSimplyUpdate;
    }
  }
}

export enum GuestInvitationStatus {
  Created = 1,
  Sent = 2,
  Accepted = 3
}



export class GuestUsersCreatePayload {

  public employeeReference: UserInfo;
  public networks: Array<NetworkInfo>;
  public networkNames: Array<string>;
  public guestUsers: Array<GuestUser>;
  public skippedAsNotGuestUsers : Array<GuestUser>;

  constructor(initialData?: any) {
    if (initialData) {
      this.employeeReference = new UserInfo({
        userId: initialData.managerId,
        displayName: initialData.manager
      });
      this.networks = initialData.networkIds
        ? initialData.networkIds.map(
            (id: string, index: number) =>
              new NetworkInfo({
                networkId: id,
                name: initialData.networks[index]
              })
          )
        : [];
      this.networkNames = this.networks
        ? this.networks.map(network => network.name)
        : [];

      this.guestUsers = initialData.guestUsers
        ? initialData.guestUsers
        : [];

      this.skippedAsNotGuestUsers = initialData.skippedAsNotGuestUsers
        ? initialData.skippedAsNotGuestUsers
        : [];
    }
  }
}

export class ImpactingKnowledge {
  id: string;
  type = ImpactingKnowledgeType.KnowledgeNugget;
  hashtag: HashtagInfo;
  name: string;
  description: string;
  logoUrl: string;
  coverUrl: string;
  promotingNetworks: NetworkInfo[];
  involvedPeople: UserInfo[];
  relatedTags: HashtagInfo[];
  sourcePostId: string;
  businessImpact: Choice;
  impactingKnowledge: string;
  originalEvaluationChoices: string;
  attachments: IAttachment[];
  created: Date;
  createdBy: UserInfo;
  modified: Date;
  modifiedBy: UserInfo;
  isDeleted: boolean;
  deleted: Date;
  deletedBy: UserInfo;

  involvedPeopleBUsName: Array<string>;
  involvedPeopleBUsUser: Array<string>;
  involvedPeopleBUsUserIds: Array<string>;
  buName: string;
  involvedPeopleName: string;
  involvedPeopleId: string;
  alreadyPopulatedBU: boolean;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.type = initialData.type;
      this.hashtag = new HashtagInfo(initialData.hashtag);
      this.name = initialData.name;
      this.logoUrl = initialData.logoUrl;
      this.description = initialData.description;
      this.coverUrl = initialData.coverUrl;
      this.sourcePostId = initialData.sourcePostId;
      this.promotingNetworks = initialData.promotingNetworks
        ? initialData.promotingNetworks.map(p => new NetworkInfo(p))
        : [];
      this.involvedPeople = initialData.involvedPeople
        ? initialData.involvedPeople.map(p => new UserInfo(p))
        : [];
      this.relatedTags = initialData.relatedTags
        ? initialData.relatedTags.map(p => new HashtagInfo(p))
        : [];
      this.attachments = initialData.attachments;
      this.created = initialData.created;
      this.createdBy = new UserInfo(initialData.createdBy);
      this.modified = initialData.modified;
      this.modifiedBy = new UserInfo(initialData.modifiedBy);
      this.businessImpact = initialData.businessImpact
        ? new Choice(initialData.businessImpact)
        : null;
      this.originalEvaluationChoices = initialData.originalEvaluationChoices;
      this.isDeleted = initialData.isDeleted;
      this.deleted = initialData.deleted;
      this.deletedBy = new UserInfo(initialData.deletedBy);
    }
  }
}

export class SimilarHashtag {
  item1: Hashtag;
  item2: Hashtag[];
  valueArr: string[];
  constructor(initialData?: any) {
    if (initialData) {
      this.item1 = initialData.item1;
      this.item2 = initialData.item2;
    }
  }
}
