import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, BehaviorSubject, of, from } from "rxjs";
import { map, shareReplay, catchError } from "rxjs/operators";
import { Cacheable } from "ngx-cacheable";
import { ConfigService } from "@ngx-config/core";
import { NgxPermissionsService } from "ngx-permissions";

import {
  PeoplePickerUserRestriction,
  UserInfo,
  User,
  GuestUser,
  GuestUsersCreatePayload
} from "../commons/entities";
import { UploadService } from "./upload.service";
import { KMSSearchResults, SearchService } from "./search.service";
import { stringify } from "querystring";


@Injectable({
  providedIn: "root"
})
export class UserService {
  private userProfileSubject = new BehaviorSubject<User>(null);

  prefLanguage: string;
  // used for check if user is null because is not already loaded or is null because don't have a profile
  public isLoaded = new BehaviorSubject<boolean>(false);

  public authorizedUser = false;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly config: ConfigService,
    private readonly permissionsService: NgxPermissionsService,
    private readonly uploaderSvc: UploadService,
    private readonly searchSvc: SearchService
  ) {}

  public getMyProfile() {
    this.httpClient
      .get<User>(`${this.config.getSettings().apiPortalUrlPrefix}user/Profile`)
      .subscribe(val => {
        this.isLoaded.next(true);
        if (val) {
          this.prefLanguage = val.preferences.preferredLanguage;
          this.authorizedUser = true;
        }
        this.userProfileSubject.next(val);
      });
  }

  public getUserProfile(userId: string) {
    return this.httpClient.get<User>(
      `${this.config.getSettings().apiPortalUrlPrefix}user/Profile/${userId}`
    );
  }

  get myProfile() {
    return this.userProfileSubject.asObservable();
  }

  public uploadUserAvatar(file: File, UPN: string): Observable<string> {
    return this.uploaderSvc.uploadImage("user/avatar/upload", file);
  }

  public getUserAvatar(imageUrl: string): Observable<string> {
    return this.httpClient.get<string>(
      `portal/api/user/avatar/get/image?path=${encodeURIComponent(imageUrl)}`
    );
  }

  public getDefaultUserAvatar(): Observable<string> {
    return this.httpClient.get<string>(`portal/api/user/avatar/default/get`);
  }

  public getUserThumbnail(imageUrl: string): Observable<string> {
    return this.httpClient.get<string>(
      `portal/api/user/avatar/thumbnail/get/image?path=${encodeURIComponent(
        imageUrl
      )}`
    );
  }

  @Cacheable({ maxCacheCount: 10 })
  public getDefaultUserThumbnail(): Observable<string> {
    return this.httpClient.get<string>(
      `portal/api/user/avatar/default/thumbnail/get`
    );
  }

  public async GetUserInfoFor(userId: string[]): Promise<UserInfo[]>{
    //alert("getUserInfo payload will be "+"[\""+userId.join("\",")+"\"]");
      return this.httpClient.post<UserInfo[]>(
        `portal/api/user/Profiles`,
        "[\""+((Array.isArray(userId))?userId.join("\",\""):userId)+"\"]"
      ).toPromise();
  }


  public ValidatedSingleUserFromCsvOrNull(guestUser: GuestUser, payloadNotForGuest: boolean) : Promise<GuestUser>{
    return this.httpClient.post<GuestUser>(
      `${this.config.getSettings().apiUrlPrefix}/admin/api/Security/guestusers/returnvalidatedsingleuserfromcsvornull?payloadNotForGuests=${payloadNotForGuest}`,
      guestUser
    ).toPromise();
  }

  public searchUser(
    query: string,
    restrictions: PeoplePickerUserRestriction = PeoplePickerUserRestriction.None
  ): Observable<UserInfo[]> {
    //alert("SearchUser "+query);
    const params = {
      filter: `isKOEligible eq true`
    };

    const onlyHREParams = {
      filter: `isExternalConsultant eq false`
    };

    const paramsDefault = {
      filter:
        "search.ismatch('" +
        this.searchSvc.escapingNames(query) +
        "', '" +
        "name" +
        "', 'full', 'all')",
      searchFields: ["name", "email", "lastName", "firstName"]
    };

    switch (restrictions) {
      case PeoplePickerUserRestriction.None:
        return this.searchSvc.query("Users", query, paramsDefault).pipe(
          catchError(() => of([])),
          map((searchRes: KMSSearchResults<any>) =>
            searchRes.results
              .map(result => result.document)
              .map(val => this.docToUserInfo(val))
          )
        );
      case PeoplePickerUserRestriction.OnlyKO:
        return this.searchSvc.query("Users", query, params).pipe(
          catchError(() => of([])),
          map((searchRes: KMSSearchResults<any>) =>
            searchRes.results
              .map(result => result.document)
              .map(val => this.docToUserInfo(val))
          )
        );
      case PeoplePickerUserRestriction.onlyHRE:
        return this.searchSvc.query("Users", query, onlyHREParams).pipe(
          catchError(() => of([])),
          map((searchRes: KMSSearchResults<any>) =>
            searchRes.results
              .map(result => result.document)
              .map(val => this.docToUserInfo(val))
          )
        );
    }
  }

  get userProfile$() {
    return this.userProfileSubject.asObservable();
  }

  get userProfile() {
    return this.userProfileSubject.value;
  }

  get userId() {
    return this.userProfileSubject.value
      ? this.userProfileSubject.value.userId
      : null;
  }

  get userRoles() {
    return this.userProfileSubject.value.roles;
  }

  private docToUserInfo(val) {
    const user = new UserInfo(val);
    user.userId = val.id;
    user.displayName = val.name;
    user.avatarUrl = val.avatarUrl;
    return user;
  }
}
