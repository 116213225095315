import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";

@Injectable()
export class RedirectGuard implements CanActivate {
  constructor(private router: Router, private adalSvc: MsAdalAngular6Service) {}

  // use this guard to save reply url when redirect for 401 authorization error
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.adalSvc.isAuthenticated) {
      localStorage.setItem("kms.login.redirectUri", state.url);
    }
    return true;
  }
}