import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewEncapsulation
} from "@angular/core";

import { User, UserInfo } from "src/app/commons/entities";

@Component({
  selector: "kms-admin-mini-user",
  templateUrl: "./mini-user.component.html",
  styleUrls: ["./mini-user.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MiniUserComponent implements OnInit {
  @Input()
  user: UserInfo | User;
  @Input()
  color: string;
  @Input()
  showDisplayName = true;

  @Input()
  showUnit = false;

  constructor() {}

  ngOnInit() {}
}
