import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray
} from "@angular/forms";

import { BsModalRef } from "ngx-bootstrap/modal";

import {
  Network,
  NetworkType,
  VisibilityType,
  PeoplePickerUserRestriction
} from "src/app/commons/entities";
import { NetworkService } from "src/app/services/network.service";
import { UserInfo } from "src/app/commons/entities";
import { SearchService } from "src/app/services/search.service";
import { BehaviorSubject, Subject, forkJoin } from "rxjs";
import { SnackbarService } from "ngx-snackbar";
import { LoggerService } from "src/app/services/logger.service";
import { NotificationService } from "src/app/services/notification.service";
import { Guid } from "src/app/commons/util";

@Component({
  selector: "kms-admin-network-merge-page",
  templateUrl: "./network-merge-page.component.html",
  styleUrls: ["./network-merge-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NetworkMergePageComponent implements OnInit {
  networksList: Network[];
  title: string;
  confirmBtnName: string;
  closeBtnName: string; 
  form: FormGroup;
  csvLoading = false;

  canEditTag = true;
  canEditVisibility = true;
  networkTypes = NetworkType;
  network = null;
  newNetwork = false;
  visibilityTypes = VisibilityType;

  types = [];
  visibilities = [];

  isAlreadyCreated$ = new BehaviorSubject(false);

  selectedVisibility = {
    id: VisibilityType.Draft,
    name: VisibilityType[VisibilityType.Draft]
  };
  selectedType = null;

  members = [];

  PeoplePickerUserRestriction = PeoplePickerUserRestriction;
  public onClose: Subject<boolean>;
  get facilitatorPlaceholder(): string {
    if (this.form && this.form.get("type") && this.form.get("type").value) {
      switch (this.form.get("type").value.id) {
        case this.networkTypes.BusinessUnit:
          return (
            "#KMS Focal Points" +
            (this.selectedVisibility.id === VisibilityType.Published
              ? " *"
              : "")
          );
        case this.networkTypes.SpecialTag:
        case this.networkTypes.External:
          return (
            "Referents" +
            (this.selectedVisibility.id === VisibilityType.Published
              ? " *"
              : "")
          );
        case this.networkTypes.CoP:
        default:
          return (
            "Facilitators" +
            (this.selectedVisibility.id === VisibilityType.Published
              ? " *"
              : "")
          );
      }
    }
  }

  get coFacilitatorPlaceholder(): string {
    if (this.form && this.form.get("type") && this.form.get("type").value) {
      switch (this.form.get("type").value.id) {
        case this.networkTypes.BusinessUnit:
          return "Other KM Referent";
        case this.networkTypes.SpecialTag:
          return "Core Team";
        case this.networkTypes.External:
          return "Members (eni.com and external.eni.com Users)";
        case this.networkTypes.CoP:
        default:
          return "CoFacilitators";
      }
    }
  }

  constructor(
    public bsModalRef: BsModalRef,
    private readonly builder: FormBuilder,
    public networkSvc: NetworkService,
    public searchSvc: SearchService,
    private readonly snackbarSvc: SnackbarService,
    private notificationSvc: NotificationService,
    private cd: ChangeDetectorRef,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.onClose = new Subject();
   
    // Dropdown creation from static Enums
    this.types = Object.keys(this.networkTypes)
      .filter(key => /^\d+$/.test(key))
      .map(key => ({
        id: Number(key),
        name:
          Number(key) !== this.networkTypes.External
            ? this.networkTypes[key]
            : "Private"
      }))
      .filter(types => types.id !== this.networkTypes.StrategicTag);

    this.visibilities = Object.keys(this.visibilityTypes)
      .filter(key => /^\d+$/.test(key))
      .map(key => ({ id: Number(key), name: this.visibilityTypes[key] }));

    this.network = new Network();
    var tempFacilitators = [];
    var tempCoFacilitators = [];
    var tempFacilitatorsIds = [];
    var tempCoFacilitatorsIds = [];
    var tempOwnerIds = [];
    var tempOwner = [];
    this.networksList.forEach(net => {
       
      net.facilitators.forEach(fac =>{
        if (tempFacilitatorsIds.indexOf(fac.userId) < 0)
        {
          tempFacilitatorsIds.push(fac.userId);
          tempFacilitators.push(fac);
          //alert(fac.userId);
        }       
      });

      if(net.coFacilitators != undefined)
      {
        net.coFacilitators.forEach(coFac =>{
          if (tempCoFacilitatorsIds.indexOf(coFac.userId) < 0)
          {
            tempCoFacilitators.push(coFac);
            tempCoFacilitatorsIds.push(coFac.userId);
          }
        });
      }
     

      if(net.koUsers != undefined)
      {
        net.koUsers.forEach(kou =>{
          if (tempOwnerIds.indexOf(kou.userId) < 0)
          {
            tempOwnerIds.push(kou.userId);
            tempOwner.push(kou);
          }
        });
      }
     
    });

    this.network.coFacilitators = tempCoFacilitators;
    this.network.facilitators = tempFacilitators;
    this.network.koUsers = tempOwner;
    this.network.type = "CoP";
    this.network.typeId = NetworkType.CoP;
    this.selectedType = "CoP";
      
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      title: new FormControl(
        this.network ? this.network.name : null,
        Validators.required
      ),
      type: new FormControl(
        { value: this.selectedType, disabled: true, id: this.network.typeId, name: this.network.type },
        Validators.required
      ),
      tag: new FormControl(
        {
          value:
            this.network && this.network.hashtag
              ? this.network.hashtag.name
              : null,
          disabled: !this.canEditTag
        },
        [Validators.required, Validators.maxLength(100)]
      ),
      visibility: new FormControl({
        value: this.selectedVisibility,
        disabled: !this.canEditVisibility
      }),
      facilitators: this.createUserInfoArray(
        this.network ? this.network.facilitators : [],
        this.builder
      ),
      coFacilitators: this.createUserInfoArray(
        this.network ? this.network.coFacilitators : [],
        this.builder
      ),
      knowledgeOwners: this.createUserInfoArray(
        this.network ? this.network.koUsers : [],
        this.builder
      )
    });

    this.members = this.network ? this.network.coFacilitators : [];

    this.setValidators();

    (<FormGroup>this.form.get("tag")).valueChanges.subscribe(value => {
      // call to check warning or alert
      this.creationIkHashtagCheck();
    });
    this.creationIkHashtagCheck();
  }

  onTitleChange(title: KeyboardEvent) {
    if (this.canEditTag) {
      const hashtag = (<any>title.target).value
        .replace(/(à|á|ã|ä)/gi, "a")
        .replace(/(è|é|ë|ē|ę)/gi, "e")
        .replace(/(í|ì|ī|ï)/gi, "i")
        .replace(/(õ|ó|ò|ö)/gi, "o")
        .replace(/(ü|û|ú|ù|ū)/gi, "u")
        .replace(/(^|\s)(the|a|an)\b/gi, "")
        .replace(/\b[a-z]/gi, c => c.toUpperCase())
        .replace(/\s/g, "")
        .replace(/[^a-zA-Z0-9 ]/g, "");
      this.form.get("tag").setValue(hashtag);

      // this.creationIkHashtagCheck();
    }
  }

  async confirm() {
    let action: Promise<any>;
    let network = null;

    //if (this.newNetwork) {
      const newNet = new Network();
      newNet.networkId = Guid.newGuid();
      newNet.type = "CoP";
      newNet.typeId = NetworkType.CoP;

      network = this.remapNetwork(newNet);
      action = this.networkSvc.mergeNetwork(network, this.networksList).toPromise();
    //} else {
    //  network = this.remapNetwork(this.network);
    //  action = this.networkSvc.updateNetwork(network).toPromise();
    //}

    try {
      const { transactionId } = await action;

      await this.notificationSvc
        .waitForTransactionResult(transactionId)
        .toPromise();

      if (
        network &&
        network.networkId &&
        network.type === NetworkType.External
      ) {
        const addedUsers: string[] = network.coFacilitators
          .map(r => r.userId)
          .filter(user => !this.members.map(r => r.userId).includes(user));

        this.logger.eventMultiple(
          "network.changemembers",
          addedUsers.map(userId => ({
            targetUserId: userId,
            networkId: network.networkId
          }))
        );
      }

      this.onClose.next(true);
      this.bsModalRef.hide();
    } catch (e) {
      this.notificationSvc.showDetailsError(e);
    }
  }

  setValidators() {
    if (
      this.selectedVisibility &&
      this.selectedVisibility.id === this.visibilityTypes.Published
    ) {
      this.form.get("facilitators").setValidators([Validators.required]);
    } else {
      this.form.get("facilitators").clearValidators();
    }
    this.form.get("facilitators").updateValueAndValidity();
  }

  hashtagKeyDown(event) {
    if (event.keyCode === 32) {
      event.preventDefault();
    }

    if (/[^a-zA-Z0-9 ]/g.test(event.key) === true) {
      event.preventDefault();
    }
  }

  creationIkHashtagCheck() {
    const Hashtag = <FormGroup>this.form.get("tag");
    const name = Hashtag && Hashtag.value ? Hashtag.value : "";

    if (name !== null) {
      this.isAlreadyCreated$.next(false);

      if (this.canEditTag) {
        forkJoin(
          this.searchSvc.query("Hashtags", name),
          this.searchSvc.query("Bookings", name)
        ).subscribe(([res1, res2]) => {
          const hashtag = (<any>res1).results.find(
            r => name.toLowerCase() === r.document.name.toLowerCase()
          );

          const candidateHashtag = (<any>res2).results.find(
            c =>
              name.toLowerCase() === c.document.candidateHashtag.toLowerCase()
          );

          this.isAlreadyCreated$.next(
            (hashtag != null &&
              hashtag.document != null &&
              hashtag.document.isPromoted) ||
              !!candidateHashtag
          );

          this.cd.markForCheck();
        });
      }
    }
  }

  changeNetworkType(type) {
    if (type.id !== NetworkType.CoP) {
      while ((<FormArray>this.form.get("knowledgeOwners").value).length !== 0) {
        (<FormArray>this.form.get("knowledgeOwners")).removeAt(0);
      }
    }
  }

  private remapNetwork(network: Network): Network {
    const selectedValues = this.form.value;

    network.name = selectedValues.title;
    network.facilitators = selectedValues.facilitators;
    network.coFacilitators = selectedValues.coFacilitators;
    network.type = selectedValues.type ? selectedValues.type.id : network.type;
    network.koUsers =
      network.type === this.networkTypes.CoP
        ? selectedValues.knowledgeOwners
        : [];
    if (
      !network.hashtag ||
      (selectedValues.tag && selectedValues.tag !== network.hashtag.name)
    ) {
      network.hashtag = { name: selectedValues.tag };
    }

    network.status = selectedValues.visibility
      ? selectedValues.visibility.id
      : network.status;

    return network;
  }

  private createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    if (list) {
      list.forEach(n => {
        const user = new UserInfo(n);
        results.push(user.toFormGroup(builder));
      });
    }

    return results;
  }

  uploadingProgressFunction(isUploading: boolean){
    if(isUploading){
      this.csvLoading = true;
      this.cd.markForCheck();
    }
    //the toggle of this flag will be done as signaled within on fileHasBeenProcessed
  }

  fileHasBeenProcessed(guestUsersCreatePayload: any) {
/*
    //eventual error are already processed by kms-admin-people-picker
    if(guestUsersCreatePayload.message){
      alert("An error has occurred\n\n"+ guestUsersCreatePayload.message);
    }else{
*/
      if(guestUsersCreatePayload == null){
        //something went wrong but the alert should be done by people-picker component itself
        //the follwing is commented out because I'd like to let the save button disabled to force the cancel
        //this.csvLoading = false;
        //this.cd.markForCheck();
      }
      else{

        var kmsUserEmailsMsg = "";
        if(guestUsersCreatePayload.skippedAsNotGuestUsers.length > 0){

          //these are not valid KMS User (maybe guest?) so they will be not considered
          kmsUserEmailsMsg = guestUsersCreatePayload.skippedAsNotGuestUsers.map(u => { 
            var idx = u.email.indexOf("_manual");

            if(idx!=-1){
              //alert("manage manually "+u.email);
              return "Manage manually -> " + u.email.substring(0, idx);
            }else
              return u.email;

          }).join("\n");
        }

        if(guestUsersCreatePayload.guestUsers.length>0){

          alert("CSV file loaded successfuly.\nIdentified '"+guestUsersCreatePayload.guestUsers.length+"' valid KMS users and they will be added to the network when you click 'SAVE'.");

          if(kmsUserEmailsMsg.length>0){
            alert("Please note the following users are not valid KMS Users OR need to be managed manually.\nAnyone of the following users will not be considered at all when you click SAVE\nThe right section for adding these users is 'Private Network Users'.\n\n"+kmsUserEmailsMsg);
          }
        }else{
          alert("No potential KMS Users were identified on the CSV.\n"+ (kmsUserEmailsMsg.length>0)?"Following users were found but they are not valid KMS Users\n\n"+kmsUserEmailsMsg:"Is really populated the CSV?");
        }

        this.csvLoading = false;
        this.cd.markForCheck();
      }
    //}
  }
}
