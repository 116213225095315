import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "@ngx-config/core";

@Injectable({
  providedIn: "root"
})
export class SearchService {
  constructor(
    private readonly client: HttpClient,
    private readonly config: ConfigService
  ) {}

  public queryRaw<T>(indexName: IndexName, query: string, params: any = {}) {
    return this.client.post<KMSSearchResults<T>>(
      `admin/api/search/query/${indexName}`,
      { item1: query.replace(/^#/, ""), item2: { ...this.defaultQueryParameters, ...params } }
    );
  }

  public queryRawSearch<T>(indexName: IndexName, query: string, params: any = {}) {
    return this.client.post<KMSSearchResults<T>>(
      `search/api/search/query/${indexName}`,
      { item1: `${query.replace(/^#/, "")}*`, item2: { ...this.defaultQueryParameters, ...params } }
    );
  }

  public query<T>(indexName: IndexName, query: string, params: any = {}) {
    return this.client.post<KMSSearchResults<T>>(
      `admin/api/search/query/${indexName}`,
      {
        item1: this.buildQuery(query),
        item2: { ...this.defaultQueryParameters, ...params }
      }
    );
  }

  public escapingNames(name: string): string {
    if (!name) {
      return "/.*.*/";
    }
    name = name.replace(/^'/, "");
    name = name.replace(/'$/, "");
    return name
      .split(" ")
      .map(r => "/.*" + r + ".*/")
      .join(" ");
  }

  private buildQuery(input: string) {
    return input != null
      ? input
          .replace(/^#/, "")
          .replace(/[^\w\s]/g, "")
          .split(/\s/g)
          .map(s => `/.*${s}.*/`)
          .join(" ")
      : input;
  }

  get defaultQueryParameters() {
    return {
      queryType: "full"
    };
  }

  public queryWithExternal<T>(query: string, params: any = {})
  {
    return this.client.post<KMSSearchResults<T>>(
      `search/api/search/getNetWithExt`,
      {
        item1: this.buildQuery(query),
        item2: { ...this.defaultQueryParameters, ...params }
      }
    );
    
  }
}

export type IndexName =
  | "Default"
  | "Hashtags"
  | "Users"
  | "Posts"
  | "Networks"
  | "ImpactingKnowledges"
  | "PostDeleted"
  | "BusinessUnits"
  | "ExternalUsers"
  | "Bookings"
  | "GuestUsers";

export interface KMSSearchResults<T> {
  facets: { [key: string]: Facet[] };
  results: SearchResult<T>[];
  count: number;
  coverage: number;
}

export interface SearchResult<T> {
  document: T;
  highlights: { [key: string]: string[] };
  score: number;
}

export interface Facet {
  type: FacetType;
  from;
  to;
  value;
  count: number;
}

export enum FacetType {
  //
  // Summary:
  //     The facet counts documents with a particular field value.
  Value = 0,
  //
  // Summary:
  //     The facet counts documents with a field value in a particular range.
  Range = 1
}
