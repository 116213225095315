import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SafeHtmlPipe } from "./pipes/trust-html.pipe";
import { EscapeHtmlPipe } from "./pipes/escape-html.pipe";
import { AmFromNowPipe } from "./pipes/am-from-now.pipe";
import { AmDateFormatPipe } from "./pipes/am-format.pipe";

const PIPES = [SafeHtmlPipe, EscapeHtmlPipe, AmFromNowPipe, AmDateFormatPipe];
const IMPORTS = [CommonModule];

@NgModule({
  declarations: [...PIPES],
  imports: [...IMPORTS],
  exports: [...PIPES, ...IMPORTS]
})
export class PipesModule {}
