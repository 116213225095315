import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewEncapsulation
} from "@angular/core";

import { BsModalRef } from "ngx-bootstrap/modal";

import { TranscriptElementEditInfo  } from "src/app/commons/webinars-entities";
import { Constants } from "src/app/commons/util";
import { WebinarsService } from 'src/app/services/webinars.service';

@Component({
  selector: "kms-admin-ipmanagement-edit",
  templateUrl: "./ipmanagement-edit.component.html",
  styleUrls: ["./ipmanagement-edit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})


export class IPManagementEditComponent implements OnInit {
  transcript: TranscriptElementEditInfo;
  title: string;
  confirmBtnName: string;
  closeBtnName: string;
  isCommittedOnVI: boolean;

  transcriptEdits: any[] = [];

  loadingIndicator = true;
  limit = Constants.TABLE_LIMITS;

 
  constructor(
    public bsModalRef: BsModalRef,
    private cd: ChangeDetectorRef,
    private webinarService: WebinarsService
  ) {
    
  }

  ngOnInit() {
    // fill table with transcript entries
    this.transcript.transcriptEdits.forEach((editHistory) => {
      this.transcriptEdits.push(
        {
          id: editHistory.speakerId,
          confidence: editHistory.confidence,
          text: editHistory.textEdits[editHistory.textEdits.length-1].text,
          editedText: editHistory.textEdits[editHistory.textEdits.length-1].editedText
        }
      );
    });

    this.isCommittedOnVI = this.transcript.isCommittedOnVI;
    this.loadingIndicator = false;
    this.cd.markForCheck();
  }

  confirm() {
    this.loadingIndicator = true;   

    try
    {
      this.webinarService.generateTranscriptLearningFile(this.transcript.webinarId).subscribe((returnedLines) => {
        //console.log("Now in generateTranscriptLearningFile method");
        //console.log(JSON.stringify(returnedLines));
       
        this.isCommittedOnVI = true;
        this.bsModalRef.hide();

      },
      (err) => {
        this.isCommittedOnVI = false;
        this.loadingIndicator = false;
        this.cd.markForCheck();
        alert("It can happens, transcripts push on VideoIndexer failed.\nYou can freely retry." );
      });
    }
    catch(error){
      //nothing is caught here
    }
  }
}
