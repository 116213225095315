import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

import { filter } from "rxjs/operators";
import { NgxPermissionsService } from "ngx-permissions";

import { UserService } from "./services/user.service";
import { User, RoleNameVariable } from "./commons/entities";
import { NotificationService } from "./services/notification.service";
import { ConfigurationService } from "./services/configuration.service";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";

@Component({
  selector: "kms-admin-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = "Admin";

  constructor(
    private readonly notificationSvc: NotificationService,
    public readonly userSvc: UserService,
    public readonly configSvc: ConfigurationService,
    private readonly permissionSvc: NgxPermissionsService,
    private router: Router,
    private adalSvc: MsAdalAngular6Service
  ) {}

  ngOnInit() {
    this.notificationSvc.connect();
    this.userSvc.getMyProfile();
    this.configSvc.getUserDisclaimer();
    this.configSvc.getAssetsServiceConfig();
    this.handleResetPageScrollOnNavigate();

    if (!this.adalSvc.isAuthenticated) {
      localStorage.setItem("kms.login.redirectUri", document.location.href);
    } else {
      this.handlePostLoginRedirect();
    }

    this.router.events
      .pipe(filter(ev => ev instanceof NavigationEnd))
      .subscribe(() => {
        window.scrollTo(0, 0);
      });

    this.userSvc.userProfile$.subscribe(u => {
      if (this.userSvc.isLoaded.value === true) {
        if (u !== null) {
          const roles = new User(u).mapRoleToString();

          if (
            roles.indexOf(RoleNameVariable.Admin) < 0 &&
            roles.indexOf(RoleNameVariable.KMSTeam) < 0
          ) {
            // If the user is not an admin or a KMTeam, redirect on curtesy page.
            // Also, set authorize flag to false, to hide the nav bar
            this.userSvc.authorizedUser = false;
            this.router.navigate(["/deny-access"]);
          }

          this.permissionSvc.loadPermissions(roles);
        } else {
          this.router.navigate(["/deny-access"]);
        }
      }
    });
  }

  private handleResetPageScrollOnNavigate() {
    this.router.events
      .pipe(filter(ev => ev instanceof NavigationEnd))
      .subscribe(() => {
        window.scrollTo(0, 0);
      });
  }

  private handlePostLoginRedirect() {
    const postLoginRedirect = localStorage.getItem("kms.login.redirectUri");
    if (postLoginRedirect && postLoginRedirect !== "/deny-access") {
      // postLoginRedirect.replace(document.location.origin, "")
      this.router.navigateByUrl(
        postLoginRedirect.replace(document.location.origin, "")
      );
      localStorage.setItem("kms.login.redirectUri", "");
    }
  }
}
