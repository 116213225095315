import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

import { Observable, BehaviorSubject } from "rxjs";
import { filter, map } from "rxjs/operators";

import { UserService } from "src/app/services/user.service";
import { HashtagService } from "src/app/services/hashtag.service";
import { NetworkService } from "src/app/services/network.service";
import { NotificationService } from "src/app/services/notification.service";
import { IkService } from "src/app/services/ik.service";
import { Role, KMSObject, ParentPageForAvatar } from "src/app/commons/entities";
import { ConfigurationService } from "src/app/services/configuration.service";

@Component({
  selector: "kms-admin-avatar-view",
  templateUrl: "./avatar-view.component.html",
  styleUrls: ["./avatar-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AvatarViewComponent implements OnInit {
  @Input()
  imageUrl: string;

  @Input()
  isKO = false;

  @Input()
  type: KMSObject;

  @Input()
  zoomIn = false;

  @Input()
  smallVersion = false;

  @Input()
  extraSmallVersion = false;

  @Input()
  superSmallVersion = false;

  @Input()
  superExtraSmallVersion = false;

  @Input()
  color: string;

  @Input()
  canEditImage = false;

  @Output()
  edit = new EventEmitter();

  @Input()
  roles: Role = null;

  @Input()
  koBadgeCssFromPage = null;

  // imgData$: Observable<any> = null;

  imgUrl$: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    private userSvc: UserService,
    private networkSvc: NetworkService,
    private hashtagSvc: HashtagService,
    private ikSvc: IkService,
    private domSanitizationSvc: DomSanitizer,
    private configSvc: ConfigurationService
  ) {}

  ngOnInit() {
    this.configSvc.assetsConfig.subscribe((val: [string, string]) => {
      if (val) {
        this.downloadAvatarUrl();
      }
    });
  }

  downloadAvatarUrl() {
    if (this.imageUrl) {
      const url = this.configSvc.createCDNImageUrl(
        this.imageUrl,
        this.smallVersion
      );
      this.imgUrl$.next(url);
    } else {
      if (this.type === KMSObject.User) {
        this.getDefaultUserAvatar();
      } else if (this.type === KMSObject.Network) {
        this.getDefaultNetworkAvatar();
      } else if (this.type === KMSObject.Hashtag) {
        this.getDefaultHahstagAvatar();
      } else if (this.type === KMSObject.IK) {
        this.getIKDefaultAvatar();
      } else if (this.type === KMSObject.Idea) {
        this.getIdeaDefaultAvatar();
      } else if (this.type === KMSObject.SuccessStory) {
        this.getSuccessStoryDefaultAvatar();
      } else if (this.type === KMSObject.Challenge) {
        this.getChallengeDefaultAvatar();
      }
    }
  }

  // downloadAvatar() {
  //   if (this.imageUrl) {
  //     if (this.type === KMSObject.User) {
  //       this.getUserAvatar();
  //     } else if (this.type === KMSObject.Network) {
  //       this.getNetworkAvatar();
  //     } else if (this.type === KMSObject.Hashtag) {
  //       this.getHashtagAvatar();
  //     } else if (this.type === KMSObject.IK) {
  //       this.getIKAvatar();
  //     } else if (this.type === KMSObject.Idea) {
  //       this.getIdeaAvatar();
  //     } else if (this.type === KMSObject.SuccessStory) {
  //       this.getSuccessStoryAvatar();
  //     } else if (this.type === KMSObject.Challenge) {
  //       this.getChallengeAvatar();
  //     }
  //   } else {
  //     if (this.type === KMSObject.User) {
  //       this.getDefaultUserAvatar();
  //     } else if (this.type === KMSObject.Network) {
  //       this.getDefaultNetworkAvatar();
  //     } else if (this.type === KMSObject.Hashtag) {
  //       this.getDefaultHahstagAvatar();
  //     } else if (this.type === KMSObject.IK) {
  //       this.getIKDefaultAvatar();
  //     } else if (this.type === KMSObject.Idea) {
  //       this.getIdeaDefaultAvatar();
  //     } else if (this.type === KMSObject.SuccessStory) {
  //       this.getSuccessStoryDefaultAvatar();
  //     } else if (this.type === KMSObject.Challenge) {
  //       this.getChallengeDefaultAvatar();
  //     }
  //   }
  // }

  editImage() {
    this.edit.emit(null);
  }

  private setupImgData(call: Observable<any>) {
    call.subscribe(data => {
      this.imgUrl$.next(this.configSvc.createCDNImageUrl(data));
    });
  }

  getDefaultUserAvatar() {
    const call = !this.smallVersion
      ? this.userSvc.getDefaultUserAvatar()
      : this.userSvc.getDefaultUserThumbnail();

    this.setupImgData(call);
  }

  getUserAvatar() {
    const call = !this.smallVersion
      ? this.userSvc.getUserAvatar(this.imageUrl)
      : this.userSvc.getUserThumbnail(this.imageUrl);

    this.setupImgData(call);
  }

  getHashtagAvatar() {
    const call = !this.smallVersion
      ? this.hashtagSvc.getHashtagAvatar(this.imageUrl)
      : this.hashtagSvc.getHashtagThumbnail(this.imageUrl);

    this.setupImgData(call);
  }

  getDefaultHahstagAvatar() {
    const call = !this.smallVersion
      ? this.hashtagSvc.getDefaultHashtagAvatar()
      : this.hashtagSvc.getDefaultHashtagThumbnail();

    this.setupImgData(call);
  }

  getDefaultNetworkAvatar() {
    this.setupImgData(this.networkSvc.getDefaultNetworkAvatar());
  }

  getNetworkAvatar() {
    const call = !this.smallVersion
      ? this.networkSvc.getNetworkAvatar(this.imageUrl)
      : this.networkSvc.getNetworkThumbnail(this.imageUrl);

    this.setupImgData(call);
  }

  getIKAvatar() {
    const call = !this.smallVersion
      ? this.ikSvc.getIKAvatar(this.imageUrl)
      : this.ikSvc.getIKThumbnail(this.imageUrl);

    this.setupImgData(call);
  }

  getIKDefaultAvatar() {
    const call = !this.smallVersion
      ? this.ikSvc.getDefaultIKAvatar()
      : this.ikSvc.getDefaultIKThumbnail();

    this.setupImgData(call);
  }

  getIdeaAvatar() {
    const call = !this.smallVersion
      ? this.ikSvc.getIKAvatar(this.imageUrl, KMSObject.Idea)
      : this.ikSvc.getIKThumbnail(this.imageUrl, KMSObject.Idea);

    this.setupImgData(call);
  }
  getIdeaDefaultAvatar() {
    const call = !this.smallVersion
      ? this.ikSvc.getDefaultIKAvatar(KMSObject.Idea)
      : this.ikSvc.getDefaultIKThumbnail(KMSObject.Idea);

    this.setupImgData(call);
  }

  getSuccessStoryAvatar() {
    const call = !this.smallVersion
      ? this.ikSvc.getIKAvatar(this.imageUrl, KMSObject.SuccessStory)
      : this.ikSvc.getIKThumbnail(this.imageUrl, KMSObject.SuccessStory);

    this.setupImgData(call);
  }
  getSuccessStoryDefaultAvatar() {
    const call = !this.smallVersion
      ? this.ikSvc.getDefaultIKAvatar(KMSObject.SuccessStory)
      : this.ikSvc.getDefaultIKThumbnail(KMSObject.SuccessStory);

    this.setupImgData(call);
  }

  getChallengeAvatar() {
    const call = !this.smallVersion
      ? this.ikSvc.getIKAvatar(this.imageUrl, KMSObject.Challenge)
      : this.ikSvc.getIKThumbnail(this.imageUrl, KMSObject.Challenge);

    this.setupImgData(call);
  }

  getChallengeDefaultAvatar() {
    const call = !this.smallVersion
      ? this.ikSvc.getDefaultIKAvatar(KMSObject.Challenge)
      : this.ikSvc.getDefaultIKThumbnail(KMSObject.Challenge);

    this.setupImgData(call);
  }

  badgeClass() {
    // const str = "ko-profile-pill";
    // return str;
    let str: string;
    switch (this.koBadgeCssFromPage) {
      case ParentPageForAvatar.ProfileHeader:
        str = "ko-profile-pill avatar-pill";
        return str;
      case ParentPageForAvatar.ProfileWidget:
        str = "ko-profile-widget-pill avatar-pill";
        return str;
      case ParentPageForAvatar.FollowPage:
        str = "ko-profile-follow-pill avatar-pill";
        return str;
      case ParentPageForAvatar.TimelineRootPost:
        str = "ko-timeline-rootpost avatar-pill small-pill";
        return str;
      case ParentPageForAvatar.TimelineReply:
        str = "ko-timeline-reply xsmall-pill";
        return str;
      default:
        str = "ko-default xsmall-pill";
        return str;
    }
  }
  get koTooltip() {
    let str = "";
    if (
      this.roles &&
      this.roles.koScopeNames &&
      this.roles.koScopeNames.length > 0
    ) {
      str = "K.O. of:\n";
      this.roles.koScopeNames.forEach(s => {
        str += s + "\n";
      });
    } else if (this.isKO) {
      str = "K.O.";
    }

    return str;
  }
}
