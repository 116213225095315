import {
  Directive,
  Input,
  HostListener,
  ElementRef
} from "@angular/core";

@Directive({
  selector: "[kmsClickWait]"
})
export class ClickWaitDirective {
  @Input() kmsClickWait: () => Promise<any>;
  @Input() size = "md";
  constructor(private readonly el: ElementRef<HTMLElement>) {}

  @HostListener("click", ["$event"])
  async clickEvent(event) {
    event.preventDefault();

    this.setWaiting(true);

    await this.kmsClickWait();

    this.setWaiting(false);
  }
  setWaiting(flag: boolean): any {
    if (flag) {
      this.el.nativeElement.setAttribute("disabled", null);
      this.el.nativeElement.classList.add("spinning");
      const spinner = document.createElement("div");
      if (this.el.nativeElement.textContent !== "") {
        spinner.innerHTML = `<span class="spinner-border align-middle spinner-border-${
          this.size
        } mr-1 " style="margin-top: 0.3rem" role="status" aria-hidden="true"></span>`;
      } else {
        spinner.innerHTML = `<span class="spinner-border  spinner-border-${
          this.size
        } mr-1" style="width: 4rem; height: 4rem; margin-top: 0.3rem" role="status" aria-hidden="true"></span>`;
      }
      this.el.nativeElement.insertBefore(
        spinner.firstChild,
        this.el.nativeElement.firstChild
      );
    } else {
      this.el.nativeElement.removeAttribute("disabled");
      this.el.nativeElement.classList.remove("spinning");
      this.el.nativeElement.removeChild(this.el.nativeElement.firstChild);
    }
  }
}
