import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthenticationGuard } from "microsoft-adal-angular6";

import { RedirectGuard } from "src/app/commons/guards";
import { ConfigurationPageComponent } from "./configuration-page/configuration-page.component";

const routes: Routes = [
  {
    path: "",
    component: ConfigurationPageComponent,
    canActivate: [RedirectGuard, AuthenticationGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfigurationRoutingModule {}
