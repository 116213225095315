import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  OnDestroy,
  ChangeDetectorRef,
  TemplateRef
} from "@angular/core";

import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject } from "rxjs";
import { map, tap, takeUntil } from "rxjs/operators";
import { SnackbarService } from "ngx-snackbar";

import { BadgeEditComponent } from "./badge-edit/badge-edit.component";
import { UserInfo, UserPermissions } from "src/app/commons/entities";
import { LoggerService } from "src/app/services/logger.service";
import { SecurityService } from "src/app/services/security.service";
import { Constants } from "src/app/commons/util";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "kms-admin-badge",
  templateUrl: "./badge.component.html",
  styleUrls: ["./badge.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class BadgeComponent implements OnInit, OnDestroy {
  users: Observable<UserPermissions[]>;
  loadingIndicator = false;
  limit = Constants.TABLE_LIMITS;
  modalRef: BsModalRef;

  userToDelete: UserPermissions = null;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private securitySvc: SecurityService,
    private readonly modalSvc: BsModalService,
    private readonly snackbarSvc: SnackbarService,
    private cd: ChangeDetectorRef,
    private logger: LoggerService,
    private userSvc: UserService
  ) {}

  ngOnInit() {
    this.securitySvc.rolesUpdateSubject
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => {
          this.search();
        },
        error => {
          this.logger.error(error);
        }
      );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openAlertModal(template: TemplateRef<any>, selectedUser: UserPermissions) {
    this.userToDelete = selectedUser;
    this.modalRef = this.modalSvc.show(template);
  }

  async search() {
    this.loadingIndicator = true;
    this.cd.markForCheck();

    this.users = await this.securitySvc.getUserBadges().pipe(
      map((users: Array<UserInfo>) =>
        users.map(user => new UserPermissions(user, !this.userSvc.userRoles.admin))
      ),
      tap(() => {
        this.loadingIndicator = false;
        this.cd.markForCheck();
      })
    );
  }

  onCreate() {
    const initialState = {
      user: null,
      title: "Add User Permissions",
      confirmBtnName: "Save",
      closeBtnName: "Cancel"
    };
    this.modalRef = this.modalSvc.show(BadgeEditComponent, {
      initialState,
      class: "modal-transparent modal-lg mt-6"
    });
  }

  async onEdit(selectedUser: UserPermissions) {
    if (selectedUser) {
      const initialState = {
        user: selectedUser,
        title: "Edit User Permissions",
        confirmBtnName: "Save",
        closeBtnName: "Cancel"
      };
      this.modalRef = this.modalSvc.show(BadgeEditComponent, {
        initialState,
        class: "modal-transparent modal-lg mt-6"
      });
    } else {
      this.logger.warning(`Cannot find the selected user: ${selectedUser}`);
      this.snackbarSvc.add({
        msg: `Cannot find the selected user`,
        background: "red",
        action: { text: null }
      });
    }
  }

  onDelete() {
    if (
      !(
        this.userToDelete &&
        this.userToDelete.roles &&
        this.userToDelete.roles.koScopes &&
        this.userToDelete.roles.koScopes.length > 0
      )
    ) {
      this.securitySvc.updateBadges(this.userToDelete.userId, []).subscribe(
        () => {
          this.securitySvc.rolesUpdateSubject.next();
        },
        error => {
          this.logger.error(error);
        }
      );
    } else {
      this.logger.warning(
        `This user is a KO, cannot remove KO Eligibility: ${this.userToDelete}`
      );
      this.snackbarSvc.add({
        msg: `This user is a KO, cannot remove KO Eligibility`,
        background: "red",
        action: { text: null }
      });
    }
    this.modalRef.hide();
  }
}
