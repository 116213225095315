import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, BehaviorSubject, of } from "rxjs";
import { ConfigService } from "@ngx-config/core";

import {
  Disclaimer,
  ChoiceField,
  ChoiceType,
  Gamification,
  Medal,
  BusinessUnits,
  MedalSettings,
  GamificationSettings,
  EvaluationCategory,
  EvaluationField,
  GuestUser,
  GuestUsersCreatePayload
} from "../commons/entities";
import { UploadService } from "./upload.service";
import { GamificationRoutingModule } from "../pages/gamification/gamification-routing.module";

@Injectable({
  providedIn: "root"
})
export class ConfigurationService {
  private currentDisclaimerSubject = new BehaviorSubject<Disclaimer>(null);
  assetsConfig: BehaviorSubject<[string, string]> = new BehaviorSubject(null);
  constructor(
    private httpClient: HttpClient,
    private readonly config: ConfigService,
    private readonly uploaderSvc: UploadService
  ) {
    setInterval(() => {
      this.getAssetsServiceConfig();
    }, 1800000);
  }

  public getUserDisclaimer() {
    return this.httpClient
      .get<Disclaimer>("admin/api/configuration/ActiveDisclaimer")
      .subscribe(val => this.currentDisclaimerSubject.next(val));
  }

  get currentDisclaimer$() {
    return this.currentDisclaimerSubject.asObservable();
  }

  public uploadFile(file: File): Observable<string> {
    return this.uploaderSvc.uploadFile("admin/api/configuration/disclaimer/upload", file);
  }


  public uploadGuestUSerCsvFile(file: File, queryStringCsvParams: string) : Observable<GuestUsersCreatePayload>{
      return this.uploaderSvc.uploadGuestCsvFile("admin/api/Security/guestusers/validatebyattach"+((queryStringCsvParams)?"?"+queryStringCsvParams:""), file);
  }

  public uploadExternalUserCsvFile(file: File) {
    return this.uploaderSvc.uploadExternalCsvFile("admin/api/Security/externalusers/validatebyattach", file);
  }


  public getChoiceField(type: ChoiceType): Observable<ChoiceField> {
    return this.httpClient.get<ChoiceField>(
      `portal/api/configuration/ChoiceField/${type}`
    );
  }

  public getEvaluationField(category: EvaluationCategory): Observable<EvaluationField[]> {
    return this.httpClient.get<EvaluationField[]>(
      `portal/api/configuration/IKEvaluationFields/${category}`
    );
  }

  public saveDisclaimer(disclaimer: Disclaimer): Observable<any> {
    return this.httpClient.post<Disclaimer>(
      `admin/api/configuration/disclaimer/create`,
      disclaimer
    );
  }

  public downloadDisclaimer(): Observable<any> {
    return this.httpClient.get<string>(
      `welcome/api/user/disclaimer/get`
    );
  }

  public getBusinessUnits() {
    return this.httpClient.get<BusinessUnits[]>(
      "admin/api/configuration/BusinessUnit/Get/Unassigned"
    );
  }
  public setBusinessUnitName(businessUnitId: string, tuple: any) {
    return this.httpClient.post(
      `admin/api/configuration/BusinessUnit/${businessUnitId}/Set/Name`,
      tuple
    );
  }
  public setListBusinessUnitName(
    businessUnitsList: Array<{ item1: string; item2: string }>
  ) {
    return this.httpClient.post(
      `admin/api/configuration/BusinessUnit/Set/Name`,
      businessUnitsList
    );
  }
  public getBusinessUnit(company: string, city: string) {
    return this.httpClient.get<BusinessUnits>(
      `admin/api/configuration/BusinessUnit/list/${company}/${city}`
    );
  }

  public getGamificationScores(): Observable<GamificationSettings> {
    return this.httpClient.get<GamificationSettings>(
      `admin/api/configuration/Gamification/scores`
    );
  }

  public getGamificationMedals(): Observable<MedalSettings> {
    return this.httpClient.get<MedalSettings>(
      `admin/api/configuration/Gamification/medals`
    );
  }

  public saveGamificationEvent(scores: GamificationSettings) {
    return this.httpClient.post(`admin/api/configuration/Gamification/scores`, scores);
  }

  public saveGamificationMedalsEvent(settings: MedalSettings) {
    return this.httpClient.post(`admin/api/configuration/Gamification/medals`, settings);
  }
  public async getAssetsServiceConfig() {
    const val = await this.httpClient
      .get<[string, string]>(`portal/api/configuration/assets/images/config`)
      .toPromise();

    this.assetsConfig.next(val);

    return val;
  }

  public createCDNImageUrl(imageUrl: string, isSmallVersion = false) {
    if (imageUrl) {
      const regex = /[^:\/]\/(.*)/g;
      const m = regex.exec(imageUrl);
      let url = m[1];
      const smallRegExp = /\/?avatar\/(.+)/i;

      if (isSmallVersion) {
        url = url.replace(smallRegExp, "/avatar/small_$1");
      }
      const assetKey = this.assetsConfig.value["item2"];
      const assetDomain = this.assetsConfig.value["item1"];
      return `${assetDomain}/${url}${assetKey}`;
    } else {
      return null;
    }
  }
}
