import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "@ngx-config/core";
import { Observable } from "rxjs";

import { Challenge } from "src/app/commons/entities";
import { SearchService } from "./search.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class ChallengeService {
  constructor(
    private readonly client: HttpClient,
    private readonly searchSvc: SearchService,
    private readonly config: ConfigService
  ) {}

  public getChallengeById(challengeId: string): Observable<Challenge> {
    return this.client.get<Challenge>(`portal/api/IK/challenge/` + challengeId);
  }

  public getInvolvedNetworksStakeholders(networkIds: string[]) {
    return this.searchSvc
      .queryRawSearch<any>("Networks", "", {
        filter: `search.in(id, '${networkIds.join(",")}')`,
        selectFields: ["facilitatorsIds,coFacilitatorsIds"],
        queryType: "full"
      })
      .pipe(
        map(payload =>
          payload.results
            .map(x => [
              ...(x.document.facilitatorsIds as string[]),
              ...(x.document.coFacilitatorsIds as string[])
            ])
            .reduce((sum, val) => [...sum, ...val], [])
        )
      );
  }

  public getInvolvedNetworksStakeholdersAndKO(networkIds: string[]) {
    return this.searchSvc
      .queryRawSearch<any>("Networks", "", {
        filter: `search.in(id, '${networkIds.join(",")}')`,
        selectFields: ["facilitatorsIds,coFacilitatorsIds,koUsersIds"],
        queryType: "full"
      })
      .pipe(
        map(payload =>
          payload.results
            .map(x => [
              ...(x.document.facilitatorsIds as string[]),
              ...(x.document.coFacilitatorsIds as string[]),
              ...(x.document.koUsersIds as string[])
            ])
            .reduce((sum, val) => [...sum, ...val], [])
        )
      );
  }

  public createChallenge(challenge: Challenge): Observable<string> {
    return this.client.post<string>(
      `portal/api/IK/challenge/Create`,
      challenge
    );
  }

  public updateChallenge(challenge: Challenge): Observable<any> {
    return this.client.post<Challenge>(
      `portal/api/IK/challenge/Update`,
      challenge
    );
  }

  public deleteChallenge(challengeId: string): Observable<any> {
    return this.client.delete(`portal/api/IK/challenge/${challengeId}`);
  }
}
