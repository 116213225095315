import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef,
} from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import {
  Observable,
  Subject,
  concat,
  of,
  BehaviorSubject,
  forkJoin,
} from "rxjs";
import {
  Hashtag,
  Network,
  NetworkInfo,
  HashtagInfo,
} from "src/app/commons/entities";
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
  map,
} from "rxjs/operators";
import {
  SearchService,
  KMSSearchResults,
} from "src/app/services/search.service";
import { HashtagService } from "src/app/services/hashtag.service";
import { LoggerService } from "src/app/services/logger.service";
import { NotificationService } from "src/app/services/notification.service";

const HASH_PARAMS = {
  filter: "typeId eq 1 and isPrivate eq false and isDeprecated eq false",
  limit: 10,
};
@Component({
  selector: "kms-admin-strategic-tags-edit",
  templateUrl: "./strategic-tags-edit.component.html",
  styleUrls: ["./strategic-tags-edit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class StrategicTagsEditComponent implements OnInit {
  isNew: boolean;
  existingHash: Hashtag;
  currentYear = new Date().getFullYear();
  selectedYears: number[] = [];
  selectedSingleYear: number;
  itemYear = [];
  title: string;
  isMultiple = true;
  checkModelYears = false;
  checkModelPromote = false;
  strategicTagName: string;
  confirmBtnName: string;
  closeBtnName: string;
  demote: string = "demote";
  network$: Observable<Network[]>;
  networkLoading = false;
  networkInput$ = new Subject<string>();
  network: NetworkInfo[] = [];

  hashtag$: Observable<Hashtag[]>;
  hashtagLoading = false;
  hashtagInput$ = new Subject<string>();
  hashtag: Hashtag;

  isAlreadyCreated$ = new BehaviorSubject(false);
  isAlreadyUsed$ = new BehaviorSubject(false);

  networkHashtagIds: string[] = [];

  public onClose: Subject<boolean>;
  constructor(
    public bsModalRef: BsModalRef,
    private cd: ChangeDetectorRef,
    private searchSvc: SearchService,
    private hashSvc: HashtagService,
    private readonly notificationSvc: NotificationService,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.onClose = new Subject();
    this.selectedYears = this.alreadySelectedYears
      ? [...this.alreadySelectedYears]
      : [];
    this.selectedSingleYear = this.currentYear;
    if (this.alreadySelectedYears) {
      this.alreadySelectedYears.forEach((year) => {
        this.selectedSingleYear =
          year < this.selectedSingleYear ? year : this.selectedSingleYear;
      });
    }
    if (this.existingHash && this.existingHash.promotingNetworks) {
      this.existingHash.promotingNetworks.forEach((val, i) => {
        const net: NetworkInfo = new NetworkInfo();
        net.name = val;
        net.networkId = (this.existingHash as any).promotingNetworksIds[i];

        net.hashtag = new HashtagInfo();
        net.hashtag.hashtagId = (
          this.existingHash as any
        ).promotingNetworksHashtagIds[i];
        this.network.push(net);
      });
    }
    for (let i = 0; i < 100; i++) {
      this.itemYear.push(this.currentYear + i);
    }
    this.loadNetworks();
    this.loadHashtag();
  }
  get alreadySelectedYears() {
    if (!this.isNew) {
      return this.existingHash.referenceYear;
    }
  }
  isConfirmDisabled() {
    if (this.isNew) {
      if (this.checkModelPromote) {
        return (
          !this.hashtag ||
          (this.checkModelYears
            ? !this.selectedSingleYear
            : this.selectedYears.length <= 0)
        );
      } else {
        return (
          (this.checkModelYears
            ? !this.selectedSingleYear
            : this.selectedYears.length <= 0) ||
          !this.strategicTagName ||
          this.strategicTagName === ""
        );
      }
    } else {
      return this.checkModelYears
        ? !this.selectedSingleYear
        : this.selectedYears.length <= 0;
    }
  }
  checkboxChanged(event) {
    if (this.selectedYears && this.selectedYears.length > 0) {
      this.selectedSingleYear = this.getHigherYear(this.selectedYears);
    }
    this.isMultiple = !event;
  }
  getHigherYear(years: number[]) {
    let number: number = years[0];
    years.forEach((year) => {
      number = year > number ? year : number;
    });
    return number;
  }

  private loadHashtag() {
    this.hashtag$ = concat(
      of([]), // default items
      this.hashtagInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          this.hashtagLoading = true;
          this.cd.markForCheck();
        }),
        switchMap((term) =>
          this.searchSvc.query("Hashtags", term, HASH_PARAMS).pipe(
            catchError(() => of([])),
            map((searchRes: KMSSearchResults<Hashtag>) =>
              searchRes.results.map((result) => result.document)
            ), // empty list on error
            tap(() => {
              this.hashtagLoading = false;
              this.cd.markForCheck();
            })
          )
        )
      )
    );
  }

  private loadNetworks() {
    const params = {
      filter: "isPrivate eq false",
    };
    this.network$ = concat(
      of([]), // default items
      this.networkInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          this.networkLoading = true;
          this.cd.markForCheck();
        }),
        switchMap((term) =>
          this.searchSvc.query("Networks", term, params).pipe(
            catchError(() => of([])),
            map((searchRes: KMSSearchResults<Network>) =>
              searchRes.results.map((result) => result.document)
            ), // empty list on error
            tap(() => {
              this.networkLoading = false;
              this.cd.markForCheck();
            })
          )
        )
      )
    );
  }
  changeYear(event) {
    this.isMultiple
      ? (this.selectedYears = event)
      : (this.selectedSingleYear = event);
  }
  onChangeName(event) {
    this.strategicTagName = event
      .replace(/(à|á|ã|ä)/gi, "a")
      .replace(/(è|é|ë|ē|ę)/gi, "e")
      .replace(/(í|ì|ī|ï)/gi, "i")
      .replace(/(õ|ó|ò|ö)/gi, "o")
      .replace(/(ü|û|ú|ù|ū)/gi, "u")
      .replace(/(^|\s)(the|a|an)\b/gi, "")
      .replace(/\b[a-z]/gi, (c) => c.toUpperCase())
      .replace(/\s/g, "")
      .replace(/[^a-zA-Z0-9 ]/g, "");
    this.creationIkHashtagCheck(this.strategicTagName);
  }

  creationIkHashtagCheck(event: string) {
    if (event !== null) {
      this.isAlreadyCreated$.next(false);
      this.isAlreadyUsed$.next(false);

      forkJoin(
        this.searchSvc.query("Hashtags", event),
        this.searchSvc.query("Bookings", event)
      ).subscribe(async ([res1, res2]) => {
        const hashtag = (<any>res1).results.find(
          (r) => event.toLowerCase() === r.document.name.toLowerCase()
        );

        const candidateHashtag = (<any>res2).results.find(
          (c) =>
            event.toLowerCase() === c.document.candidateHashtag.toLowerCase()
        );

        if (!hashtag && event && event !== "") {
          // check if hashtag exists
          let hashtagExists;

          hashtagExists = await this.hashtagExists(event);
          if (hashtagExists) {
            this.isAlreadyUsed$.next(true);
          }
        } else {
          this.isAlreadyCreated$.next(
            (hashtag != null && hashtag.document != null) || !!candidateHashtag
          );
        }

        this.cd.markForCheck();
      });
    }
  }

  hashtagKeyDown(event) {
    if (event.keyCode === 32) {
      event.preventDefault();
    }

    if (/[^a-zA-Z0-9 ]/g.test(event.key) === true) {
      event.preventDefault();
    }
  }

  onCloseClick() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  async onConfirmClick() {
    try {
      let action: Promise<any>;
      if (this.isNew) {
        const hash = this.createOrPromoteStrategicHashtag();
        if (this.checkModelPromote) {
          action = this.hashSvc.updateStrategicTags(hash).toPromise();
        } else {
          action = this.hashSvc.createStrategicTags(hash).toPromise();
        }
      } else {
        const hash = this.updateStrategicTag();
        action = this.hashSvc.updateStrategicTags(hash).toPromise();
      }
      const { transactionId } = await action;

      await this.notificationSvc
        .waitForTransactionResult(transactionId)
        .toPromise();
      this.onClose.next(true);
      this.bsModalRef.hide();
    } catch (e) {
      const errrorMessage = e && e.error ? e.error : e;
      this.notificationSvc.showDetailsError(errrorMessage);
    }
  }

  createOrPromoteStrategicHashtag() {
    const hash = new Hashtag();
    hash.name = this.checkModelPromote
      ? this.hashtag.name
      : this.strategicTagName;
    hash.hashtagId = this.checkModelPromote ? this.hashtag.id : null;
    hash.isPromoted = true;
    hash.promotingNetworks = [];
    hash.type = 5;
    if (this.checkModelYears) {
      hash.referenceYear = [...this.addTenYearsToReferenceYears()];
    } else {
      hash.referenceYear = [...this.selectedYears];
    }
    this.network.forEach((net: any) => {
      hash.promotingNetworks.push(net.hashtagId);
    });
    return hash;
  }
  addTenYearsToReferenceYears(): number[] {
    const yearsArray: number[] = [];
    for (let i = 0; i < 10; i++) {
      yearsArray.push(this.selectedSingleYear + i);
    }
    return yearsArray;
  }

  updateStrategicTag() {
    const hash = new Hashtag();
    hash.name = this.existingHash.name;
    hash.hashtagId = this.existingHash.id;
    hash.isPromoted = true;
    hash.promotingNetworks = [];
    hash.type = 5;
    if (this.checkModelYears) {
      hash.referenceYear = [...this.addTenYearsToReferenceYears()];
    } else {
      hash.referenceYear = [...this.selectedYears];
    }
    this.network.forEach((net: any) => {
      hash.promotingNetworks.push(
        net.hashtagId
          ? net.hashtagId
          : net.hashtag && net.hashtag.hashtagId
          ? net.hashtag.hashtagId
          : net.id
      );
    });
    return hash;
  }

  demoteStrategicTag() {
    const hash = new Hashtag();
    hash.name = this.existingHash.name;
    hash.hashtagId = this.existingHash.id;
    hash.isPromoted = true;
    hash.promotingNetworks = [];
    hash.type = 1;
    hash.referenceYear = [];
      
    this.network.forEach((net: any) => {
      hash.promotingNetworks.push(
        net.hashtagId
          ? net.hashtagId
          : net.hashtag && net.hashtag.hashtagId
          ? net.hashtag.hashtagId
          : net.id
      );
    });
    return hash;
  }

  private hashtagExists(name) {
    return this.hashSvc.existsByName(name).toPromise();
  }

  async onDemote() {
    try {
      let action: Promise<any>;

      const hash = this.demoteStrategicTag();
      this.hashSvc.demoteStrategicTags(hash).toPromise();
      this.onClose.next(true);
      this.bsModalRef.hide();
    } catch (e) {
      const errrorMessage = e && e.error ? e.error : e;
      this.notificationSvc.showDetailsError(errrorMessage);
    }
  }
}
