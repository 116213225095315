import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
  ElementRef,
  ViewEncapsulation
} from "@angular/core";
import {
  BsDatepickerConfig,
  BsDaterangepickerDirective
} from "ngx-bootstrap/datepicker";

@Component({
  selector: "kms-admin-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DatePickerComponent implements OnInit {
  @Input() isDisabled = false;
  @Input() isOpen = false;
  @Input() range: [Date, Date] = null;
  @Input() singleDate: Date = null;
  @Input() isSingleDate = false;
  @ViewChild(BsDaterangepickerDirective, { static: false }) datepicker: BsDaterangepickerDirective;
  @ViewChild("toggler", { static: false }) toggler: ElementRef<HTMLButtonElement>;
  @Output() rangeChange = new EventEmitter<[Date, Date]>();
  @Output() singleChange = new EventEmitter<Date>();
  bsConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  colorTheme = "blue";

  constructor() {
    this.bsConfig.containerClass = "theme-blue";
    this.bsConfig.rangeInputFormat = "DD-MM-YYYY";
    this.bsConfig.showWeekNumbers = false;
  }

  ngOnInit() {}

  // get minDate(){
  //   return new Date(this.range[0].setDate(new Date().getDate() + 1));
  // }

  get today() {
    return new Date();
  }

  clear() {
    this.range = null;
  }

  clearSingle() {
    this.singleDate = null;
  }
  valueChange(range) {
    this.range = range;
    this.rangeChange.emit(range);
  }

  singleValueChange(endDate: Date) {
    this.range[1] = endDate;
    this.rangeChange.emit(this.range);
  }
  onlyValueChange(date: Date) {
    this.singleDate = date;
    this.singleChange.emit(date);
  }
}
