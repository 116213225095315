import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from "@angular/core";

import { ImpactingKnowledgeType } from "src/app/commons/entities";

@Component({
  selector: "kms-admin-business-unit-page",
  templateUrl: "./business-unit-page.component.html",
  styleUrls: ["./business-unit-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class BusinessUnitPageComponent implements OnInit {
  impactingKnowledgeType = ImpactingKnowledgeType;

  constructor() {}

  ngOnInit() {}
}
