<div class="modal-header">
  <ol style="list-style-type:none;">
    <li><h4 class="modal-title pull-left mt-0">{{ title }}</h4></li>
    <li><h5 class="modal-subtitle pull-left mt-0">{{ transcript.chapterName }}</h5></li>
  </ol>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body-transcript">
  <ngx-datatable
    class="bootstrap"
    [rows]="transcriptEdits"
    [columnMode]="'force'"
    [rowHeight]="'auto'"
    [footerHeight]="'auto'"
    [limit]="limit"
    [messages]="{
      emptyMessage: 'no table entries',
      totalMessage: 'total',
      selectedMessage: 'selected'
    }"
    [sorts]="[{ prop: 'id', dir: 'asc' }]"
  >
    <ngx-datatable-column name="Speaker ID" prop="id">
      <ng-template let-id="value" ngx-datatable-cell-template>
        <div>{{ id }}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Original text" prop="text">
      <ng-template let-text="value" ngx-datatable-cell-template>
        <div>{{ text }}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Edited text" prop="editedText">
      <ng-template let-editedText="value" ngx-datatable-cell-template>
        <div>{{ editedText }}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Confidence level" prop="confidence">
      <ng-template let-confidence="value" ngx-datatable-cell-template>
        <div>{{ confidence }}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer *ngIf="loadingIndicator"></ngx-datatable-footer>
  </ngx-datatable>
  <div *ngIf="loadingIndicator">
    <kms-admin-spinner [loading]="true"></kms-admin-spinner>
  </div>
</div>
<div class="modal-footer">
  <!--span 
  *ngIf="!isCommittedOnVI"-->
<button
    type="button"
    [disabled]="loadingIndicator"
    class="btn btn-primary btn-disabled-style btn-raised"
    (click)="confirm()"
  >
    {{ confirmBtnName }}
</button>
<!--/span-->
  <button
    type="button"
    class="btn btn-warning btn-raised"
    (click)="bsModalRef.hide()"
  >
    {{ closeBtnName }}
  </button>
</div>
