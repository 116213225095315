<div class="row mb-1">
  <div class="col-sm-6">
    <kms-admin-card class="inner-card">
      <header><p>Best Practice</p></header>
      <kms-admin-rich-text-editor-widget
        [group]="form"
        [isEditable]="true"
        [propertyName]="'bestPractice'"
        [placeholder]="'Please Enter the Best Practice'"
        (textChanged)="onTextChanged()"
      ></kms-admin-rich-text-editor-widget>
    </kms-admin-card>
  </div>
  <div class="col-sm-6">
    <kms-admin-card class="inner-card">
      <header><p>Disclaimer</p></header>
      <kms-admin-rich-text-editor-widget
        [group]="form"
        [isEditable]="true"
        [propertyName]="'disclaimer'"
        [placeholder]="'Please Enter the disclaimer'"
        (textChanged)="onTextChanged()"
      ></kms-admin-rich-text-editor-widget>
    </kms-admin-card>
  </div>
</div>
<div class="row">
  <div class="col-sm-6">
    <kms-admin-card class="inner-card">
      <header><p>Attachments</p></header>
      <kms-admin-file-upload
        *ngFor="
          let attachment of currentDisclaimer?.attachmentUrl;
          let i = index
        "
        [fileUrl]="attachment"
        (fileLoaded)="updateFile($event, i)"
      ></kms-admin-file-upload>
    </kms-admin-card>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="text-right" [formGroup]="form">
      <button
        type="button"
        class="btn btn-primary btn-disabled-style btn-raised mr-3"
        [disabled]="!saveEnabled"
        (click)="onSaveDisclaimer()"
      >
        Save
      </button>
    </div>
  </div>
</div>
