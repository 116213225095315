import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  EventEmitter,
  Output
} from "@angular/core";

import * as FileSaver from "file-saver";

import { ConfigurationService } from "src/app/services/configuration.service";
import { SelectMultipleControlValueAccessor } from "@angular/forms";

@Component({
  selector: "kms-admin-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent implements OnInit {
  @Input() fileUrl = "";
  
  @Input() isGuestCsvFile = false;

  @Input() isExternalCsvFile = false;

  @Input() showCsvHelp = true;

  @Input() queryStringCsvParams = "";

  @Output() fileLoaded = new EventEmitter<any>();

  @Output() uploadingProgress = new EventEmitter<boolean>(); //true is uploading

  fileUrlToDownload: string = null;

  accept: string = [
    "image/*",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  ].join(", ");

  get fileName() {
    const regexp = new RegExp("(?=\\w+\\.\\w{3,4}$).+");
    const matchedString = this.fileUrl.match(regexp);
    return matchedString ? matchedString[0] : this.fileUrl;
  }

  get fileNameBis() {
    const regexp = new RegExp("(?=\\w+\\.\\w{3,4}$).+");
    const matchedString = this.fileUrlToDownload.match(regexp);
    return matchedString ? matchedString[0] : this.fileUrlToDownload;
  }

  constructor(
    private configSvc: ConfigurationService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if(!this.isGuestCsvFile){
      this.configSvc.downloadDisclaimer().subscribe(file => {
        this.fileUrlToDownload = file;
        this.cdRef.markForCheck();
        //alert("done" + this.fileUrlToDownload);
        //alert("fileUrl"+this.fileUrl);
        //alert("file "+file);
        //alert("file "+this.fileNameBis);
        //FileSaver.saveAs(file, this.fileName);
      });
    }else{
      //reset everything else
      this.accept = "text/csv";
    }
    if(this.isExternalCsvFile){
      this.accept = "text/csv";
    }
  }

  /*
  downloadDisclaimer() {
    this.configSvc.downloadDisclaimer().subscribe(file => {
      alert("fileUrl"+this.fileUrl);
      alert("file "+file);
      alert("file "+this.fileName);

      this.fileUrlToDownload = file;
      //FileSaver.saveAs(file, this.fileName);
    });
  }
*/

  fileChange(event: any) {
    const fileList: FileList = event.target.files;
    //alert("fileChange " + fileList.length);
    if (fileList.length > 0) {
      const file: File = fileList[0];
      this.uploadingProgress.emit(true);
      if(this.isGuestCsvFile){
        this.configSvc.uploadGuestUSerCsvFile(file, this.queryStringCsvParams).subscribe(reply => {
          this.uploadingProgress.emit(false);
          this.fileLoaded.emit(reply);
        });
      } else if(this.isExternalCsvFile){
        this.configSvc.uploadExternalUserCsvFile(file).subscribe(reply => {
          this.uploadingProgress.emit(false);
          this.fileLoaded.emit(reply);
          // console.log(reply)
        });
      }else{
        this.configSvc.uploadFile(file).subscribe(reply => {
          this.uploadingProgress.emit(false);
          this.fileLoaded.emit(reply);
        });
      }
    }
  }
}
