import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";
import { Router } from "@angular/router";

import { Observable, throwError } from "rxjs";
import { ConfigService } from "@ngx-config/core";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";
import { timeout, catchError, switchMap } from "rxjs/operators";

import { UserService } from "./user.service";

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  constructor(
    private readonly userSvc: UserService,
    private readonly adalSvc: MsAdalAngular6Service,
    private readonly config: ConfigService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Skip for initial config loading
    req = this.setHeaders(req);

    return next.handle(req).pipe(
      timeout(30000),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 401:
              if (this.adalSvc.userInfo) {
                return this.adalSvc.acquireToken(document.location.origin).pipe(
                  switchMap(() => {
                    req = this.setHeaders(req);
                    return next.handle(req).pipe(timeout(10 * 1000));
                  }),
                  catchError(err => {
                    // console.log("error", err);
                    return throwError(err);
                  })
                );
              } else {
                return throwError(error);
              }
            case 403: {
              this.router.navigate(["/deny-access"]);
              this.userSvc.isLoaded.next(true);
              break;
            }
            default:
              return throwError(error);
          }
        } else {
          return throwError(error);
        }
      })
    );
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    // this.adalSvc.logout();
    return next.handle(req);
  }

  private setHeaders(req: HttpRequest<any>): HttpRequest<any> {
    if (!/config\//.test(req.url)) {
      const url = this.config.getSettings().apiUrlPrefix;
      const setHeaders = {
        authorization: `Bearer ${this.adalSvc.accessToken}`
      };
      // (!/upload/i.test(req.url) && !/abstract/i.test(req.url))
      if (
        req.method === "POST" &&
        !/upload/i.test(req.url) &&
        !req.headers.has("x-skipcontenttype")
      ) {
        setHeaders["content-type"] = "application/json";
      }
      req = req.clone({
        url: !/https:\/\//.test(req.url) ? url + req.url : req.url,
        setHeaders
      });
    }
    return req;
  }
}
