<div class="modal-header pl-2">
  <span class="d-flex flex-column">
    <h4 class="modal-title pull-left mt-0 pl-2">{{ title }}</h4>
    <i class="font-weight-light small pl-2"
      >This operation will set a new name for all the selected business units</i
    >
  </span>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  ></button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6 input-alignment">
        <input
          type="text"
          class="no-box form-control"
          required
          id="inputName"
          [(ngModel)]="name"
        />
        <label class="floating-label" [ngClass]="{ 'go-top': name }"
          >Business Unit Name *</label
        >
      </div>
      <div class="col-sm-6 input-alignment">
        <input
          type="text"
          class="no-box form-control"
          id="inputGroup"
          [(ngModel)]="grouping"
        />
        <label class="floating-label" [ngClass]="{ 'go-top': grouping }"
          >Grouping</label
        >
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 input-alignment">
        <input
          type="text"
          class="no-box form-control"
          id="inputCustom"
          [(ngModel)]="customBU"
        />
        <label class="floating-label" [ngClass]="{ 'go-top': customBU }"
          >Custom BU</label
        >
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary btn-disabled-style btn-raised"
    (click)="onConfirmClick()"
  >
    {{ confirmBtnName }}
  </button>
  <button
    type="button"
    class="btn btn-warning btn-raised"
    (click)="bsModalRef.hide()"
  >
    {{ closeBtnName }}
  </button>
</div>
