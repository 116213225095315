import {
  Component,
  Input,
  OnInit,
  SkipSelf,
  Host,
  Optional
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  ControlContainer,
  AbstractControl
} from "@angular/forms";

@Component({
  selector: "[form-field]",
  templateUrl: "./form-field.component.html"
})
export class FormFieldComponent implements OnInit {
  @Input()
  control: AbstractControl;

  @Input()
  group: FormGroup;

  @Input()
  formControlName: string;

  @Input()
  visibleGroupErrors: Array<string> = null;

  @Input()
  excludeGroupErrors = false;

  public form: FormGroup;
  public ctrl: AbstractControl;
  public useGroupValidation: boolean;

  constructor(
    // This is VERY advanced! Don't edit without understanding the docs!
    // See https://angular.io/docs/ts/latest/cookbook/dependency-injection.html#!#qualify-dependency-lookup
    // and https://github.com/angular/angular/blob/14ee75924b6ae770115f7f260d720efa8bfb576a/modules/%40angular/forms/src/directives/reactive_directives/form_group_name.ts#L75
    @Optional()
    @Host()
    @SkipSelf()
    private parent: ControlContainer
  ) {}

  ngOnInit() {
    // Scenario 1: group and control input are set
    this.form = this.group;
    this.ctrl = this.control;

    // Scenario 2: no group or control input
    // extract them from current context
    if (!this.control && this.formControlName) {
      if (this.parent && this.parent["form"]) {
        this.form = this.parent["form"] as FormGroup;
        this.ctrl = this.group.get(this.formControlName);
      } else if (this.parent && this.parent.control) {
        this.form = this.parent.control as FormGroup;
        this.ctrl = this.parent.control.get(this.formControlName);
      }
    }

    this.useGroupValidation = this.group != null;
  }

  public showError(): boolean {
    if (!this.excludeGroupErrors) {
      return true; // Se non si vogliono escludere errori di gruppo, mostra sempre ogni errore.
    }
    let showGroupError = false;
    this.visibleGroupErrors.forEach(error => {
      if (this.group && this.group.errors && this.group.errors[error]) {
        showGroupError = true;
      }
    });

    return !this.ctrl.valid || showGroupError;
  }
}
