import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  ViewEncapsulation
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { map, filter, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import { Routes } from "src/app/commons/routes";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "kms-admin-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {
  activePage: string;
  routes = Routes;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  get env() {
    return window['__KMS_ENV'];
  }

  constructor(
    private router: Router,
    public readonly userSvc: UserService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(url => {
        this.activePage = url;
        this.cd.markForCheck();
      });

    if (
      this.router.routerState &&
      this.router.routerState.snapshot &&
      this.router.routerState.snapshot.url
    ) {
      this.activePage = this.router.routerState.snapshot.url;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
  }
}
